import { ProductMacroCategory } from 'azshared';
import React from 'react';
import { Loader, LoaderSize } from '../../../../../components/loader/loader';
import { ProductsDetailDialog } from '../products-detail-dialog/products-detail-dialog';
import { FinancialPositionsCard } from './financial-positions-card';
import { PirBoxDisclaimerFinancialPosition } from '../../../pir-box/pir-box-disclaimer-financial-position';

interface FinancialPositionsSectionProps {
	macroCategories: ProductMacroCategory[];
	closeProductDetails: () => void;
	goToRealEconomyDetailPage: () => void;
	isLoading: boolean;
	flagShowBalance: boolean;
	sectionMode: string
}

export const FinancialPositionsSection = (props: FinancialPositionsSectionProps) => {
	const { macroCategories, closeProductDetails, isLoading, flagShowBalance, goToRealEconomyDetailPage, sectionMode } = props;	
 
	if (isLoading) {
		return (
			<div className='financial-positions-section financial-positions-section-loader'>
				<Loader loadersize={LoaderSize.XL} />
			</div>
		);
	}

	return (
		<div className='financial-positions-section'>
			<FinancialPositionsCard macroCategories={macroCategories} flagShowBalance={flagShowBalance} goToRealEconomyDetailPage={goToRealEconomyDetailPage} />
			{sectionMode === 'MY_WALLET' &&<PirBoxDisclaimerFinancialPosition />}
			<ProductsDetailDialog closeProductDetails={closeProductDetails} />	
		</div>
	);
};
