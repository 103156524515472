import { dateFormatter, RangeDate } from 'azshared';
import React, { useMemo } from 'react';

export interface AppDateSelectProps {
	placeholder?: string;
	value: RangeDate;
}

export const AppDateSelectInnerText = (props: AppDateSelectProps) => {
	const { placeholder, value } = props;

	const innerText = useMemo(
		() => {
			const text = value?.from && value.to ? `${dateFormatter(value.from)} - ${dateFormatter(value.to)}` : null;
			return text || placeholder || '';
		},
		[ placeholder, value ]
	);

	return (<p>{innerText}</p>);
};
