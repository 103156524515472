import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import Icon from '@mdi/react';
import { Collapse, IconButton } from '@mui/material';
import { commonButtonMessages, Dossier, useMyWalletDossierFilterLogic, walletDossierFiltersMessages, walletPageMessages } from 'azshared';
import React, { Fragment, MouseEvent, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType, AppButtonVariant } from '../../../../../components/buttons/app-button';
import { AppCheckbox } from '../../../../../components/checkbox/app-checkbox';
import { AppDialog } from '../../../../../components/dialog/app-dialog';
import { AppSvgIcon } from '../../../../../components/svg-icon/app-svg-icon';
import { AppTooltip } from '../../../../../components/tooltip/tooltip';
import './dossier-filter.scss';

export const DossierFilter = () => {
  const { formatMessage } = useIntl();

  const { onDossierFilterChange, dossierFilter, onSubmit, onCancel, setFlagOpenDossierFilter, flagOpenDossierFilter } = useMyWalletDossierFilterLogic();

  const handleClickOpenDossierFilter = useCallback(
    (e: MouseEvent<HTMLButtonElement | HTMLLabelElement>) => {
      e.preventDefault();
      setFlagOpenDossierFilter(true);
    },
    [setFlagOpenDossierFilter]
  );

  return (
    <Fragment>
      <div className='my-wallet-section-icon my-wallet-section-icon-filter'>
        <IconButton onClick={handleClickOpenDossierFilter}>
          <AppSvgIcon icon='filter' />
        </IconButton>
        <label onClick={handleClickOpenDossierFilter} className='my-wallet-section-icon-label'>
          {formatMessage(walletPageMessages.filterWallet)}
        </label>
      </div>
      <AppDialog open={flagOpenDossierFilter}>
        <div className='dossier-filter'>
          <div className='dossier-filter-title'>{formatMessage(walletDossierFiltersMessages.filterModalTitle)}</div>
          {dossierFilter?.dossier?.map(dossier => (
            <DossierFilterDialogItem dossier={dossier} key={dossier.code} onFilterChange={onDossierFilterChange} />
          ))}
          <div className='dossier-filter-button-section'>
            <AppButton type={AppButtonType.BUTTON} variant={AppButtonVariant.Secondary} label={formatMessage(commonButtonMessages.cancel)} onClick={onCancel} />
            <AppButton
              type={AppButtonType.BUTTON}
              variant={AppButtonVariant.Primary}
              label={formatMessage(walletDossierFiltersMessages.filterRecalculateButton)}
              onClick={onSubmit}
            />
          </div>
        </div>
      </AppDialog>
    </Fragment>
  );
};

interface DossierFilterDialogItemProps {
  dossier: Dossier;
  onFilterChange: (code: string, value: boolean) => void;
}

export const DossierFilterDialogItem = (props: DossierFilterDialogItemProps) => {
  const { dossier, onFilterChange } = props;
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen(!open), [open]);
  const { formatMessage } = useIntl();

  const handleChecked = useCallback(
    (value: boolean, code?: string) => {
      onFilterChange(code || '', value);
    },
    [onFilterChange]
  );

  const label = useMemo(() => {
    if (dossier.consultingType) {
      return `${dossier.code} - ${dossier.consultingType.type} - ${dossier.consultingType.contract}`;
    } else {
      return dossier.code;
    }
  }, [dossier.consultingType, dossier.code]);

  return (
    <div className='dossier-filter-items'>
      <div className='dossier-filter-item'>
        <AppTooltip title={formatMessage(dossier.flagOpen ? walletDossierFiltersMessages.openTooltip : walletDossierFiltersMessages.closeTooltip)}>
        <div className={`dossier-filter-dot ${dossier.flagOpen ? 'open' : ''}`} />
        </AppTooltip>
        <div className='dossier-filter-item-label'>
          <AppCheckbox id={dossier.code} label={label} checked={dossier.flagSelected} onChange={handleChecked} />
          <div className='coowner'>
            {dossier.coOwner &&
              dossier.coOwner.map(coowner => (
                <div key={coowner} className='coowner-label'>
                  <AppSvgIcon icon={'coowner'} />
                  <span>{coowner}</span>
                </div>
              ))}
          </div>
        </div>
        {dossier.subDossier && (
          <IconButton aria-label='expand row' size='small' onClick={toggleOpen} className='dossier-filter-accordion-icon'>
            <Icon size={1} path={open ? mdiChevronUp : mdiChevronDown} color={'#2E6CD1'} />
          </IconButton>
        )}
      </div>
      <div className={open ? 'is-expansion-visible' : ''}>
        <Collapse in={open} timeout='auto' unmountOnExit className='dossier-filter-accordion-box'>
          <div className='dossier-filter-accordion-box-content'>
            {dossier.subDossier &&
              dossier.subDossier.length > 0 &&
              dossier.subDossier.map(subDossier => (
                <div key={subDossier.contract} className='dossier-filter-accordion-item'>
                  <div className='dossier-filter-item-label'>
                    <AppCheckbox
                      id={subDossier.contract}
                      label={`${subDossier.contract?.substring(5)} ${subDossier.macroProductName ? '- ' + subDossier.macroProductName : ''} ${subDossier.productName ? '- ' + subDossier.productName : ''} `}
                      checked={subDossier.flagSelected}
                      onChange={handleChecked}
                    />
                  </div>
                </div>
              ))}
          </div>
        </Collapse>
      </div>
    </div>
  );
};
