import {
	commonSelectMessages,
	documentsAreaMessages,
	DocumentsOrderCriteria,
	DocumentType,
	labelForDocumentsChip,
	useDocumentsFilterLogic
} from 'azshared';
import React, { MouseEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppCheckbox } from '../../../../../components/checkbox/app-checkbox';
import { AppChip } from '../../../../../components/chip/chip';
import { AppSelect } from '../../../../../components/select/app-select';
import './documents-filter.scss';

export const AppDocumentsFilter = () => {
	const { formatMessage } = useIntl();

	const {
		documentsOrderCriteria,
		handleOrderCriteriaSelection,
		handleChipClick,
		selectedOrderCriteria,
		chipsConfig,
		handleShowUnreadDocumentsChange,
		showUnreadDocuments
	} = useDocumentsFilterLogic();

	const handleOrderCriteriaChange = useCallback(
		(value: string| string[]) => {
			handleOrderCriteriaSelection(value as DocumentsOrderCriteria);
		},
		[ handleOrderCriteriaSelection ]
	);

	const handleChipChange = useCallback(
		(_: MouseEvent<HTMLDivElement>, id?: string) => {
			handleChipClick(id as DocumentType);
		},
		[ handleChipClick ]
	);

	return (
		<div className='app-documents-filter'>
			<div className='app-documents-filter-content'>
				<div className='app-documents-filter-chips'>
					{chipsConfig.map((chip) => (
						<AppChip
							key={chip.value}
							id={chip.value}
							active={chip.isSelected}
							label={formatMessage(labelForDocumentsChip(chip.value))}
							count={chip.badgeCount}
							onClick={handleChipChange}
						/>
					))}
				</div>
				<AppSelect
					placeholder={formatMessage(commonSelectMessages.uploadDate)}
					value={selectedOrderCriteria}
					options={documentsOrderCriteria}
					onChange={handleOrderCriteriaChange}
				/>
				<AppCheckbox
					label={formatMessage(documentsAreaMessages.notRead)}
					checked={showUnreadDocuments}
					onChange={handleShowUnreadDocumentsChange}
				/>
			</div>
		</div>
	);
};
