import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import Icon from '@mdi/react';
import { Collapse, IconButton } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FaqQuestion } from '../../../../../utils/faq-questions';

import './faq-accordion.scss';

export const FaqAccordion = (props: {
	id: string;
	faq: FaqQuestion;
	selectedFaq: string;
	onToggleOpen: (id: string) => void;
}) => {
	const { faq, id, onToggleOpen, selectedFaq } = props;
	const { formatMessage } = useIntl();

	const open = useMemo(
		() => {
			return selectedFaq === id;
		},
		[ selectedFaq, id ]
	);

	const toggleOpen = useCallback(
		() => {
			onToggleOpen(id);
		},
		[ id, onToggleOpen ]
	);

	return (
		<div className='faq-item'>
			<div className='faq-accordion'>
				{formatMessage(faq.question)}
				<IconButton aria-label='expand row' size='small' onClick={toggleOpen}>
					<Icon size={1} path={open ? mdiChevronUp : mdiChevronDown} color={'#2E6CD1'} />
				</IconButton>
			</div>
			<div className={open ? 'is-expansion-visible' : ''}>
				<Collapse in={open} timeout='auto' unmountOnExit className='faq-accordion-box'>
					{faq.answers.map(answer => (
						<ul key={answer.answer.id} className='answer'>
							<li>
								{answer.title && <div className='answer-title'>{formatMessage(answer.title)}</div>}
								<div>{formatMessage(answer.answer)}</div>
							</li>
							{answer.subAnswer &&
								answer.subAnswer.map((subAnswer, index) => (
									<div key={subAnswer.title.id}>
										<ul className='sub-answer'>
											<li>
												<span className='sub-answer-title'>
													{index + 1 + '. '}
													{formatMessage(subAnswer.title) + ' '}
												</span>
												<span>{formatMessage(subAnswer.subAnswer)}</span>
											</li>
										</ul>
									</div>
								))}
						</ul>
					))}
				</Collapse>
			</div>
		</div>
	);
};
