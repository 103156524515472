/* eslint-disable react/jsx-no-bind */
import { getAmlQuestionById, homeOnboardingSelfAmlFatcaCrsSurveyMessages } from 'azshared';
import { Domanda } from 'azshared/build/main/lib/api/generated';
import React from 'react';
import { useIntl } from 'react-intl';
import { AppCheckboxGroup, AppCheckboxItem } from '../../../../../../components/checkbox/app-checkbox-group';
import { AppBaseItem, AppRadioButtonConfig } from '../../../../../../components/radio/app-radio-button';
import { AppRadioGroup } from '../../../../../../components/radio/app-radio-group';
import { AmlSectionBodyProps } from '../../aml-fatca-crs-survey-page';

export const AmlFinancialSectionBody = (props: AmlSectionBodyProps) => {
  const { amlConsistency } = props;

  const { formatMessage } = useIntl();
  const incomeQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 272);
  const amountIncomeQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 273);
  const savingsQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 274);
  const movableAssetQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 275);
  const realEstateQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 276);
  const firstHouseQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 277);
  const otherHouseQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 278);
  const realEstateAssetQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 279);
  const financialAssetQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 280);
  const loanQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 281);
  const monthlySavingsQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 282);
  const dependencyQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 283);

  const mapListaRisposteToOptions = <T extends AppBaseItem>(question: Domanda) =>
    question?.listaRisposte?.map(
      (resp) =>
        ({
          value: resp.idRisposta?.toString() ?? '',
          label: `${resp.descRisposta ?? ''} ${resp.descSubRisposta ?? ''}`
        } as T)
    ) ?? [];

  return (
    <div className='section'>
      <h3 className={'margin-top'}>{formatMessage(homeOnboardingSelfAmlFatcaCrsSurveyMessages.attivitaFinanziariaSectionTitle)}</h3>
      <div
        className={`pep-sub-section ${
          amlConsistency?.consistency?.find((c) => c.questionId === incomeQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
        }`}>
        <h4>{incomeQuestion?.testoDomanda}</h4>
        <AppCheckboxGroup
          selectedValues={props.getAmlAnswerDetailsByQuestionId('' + incomeQuestion?.idDomanda, true) as any}
          options={mapListaRisposteToOptions<AppCheckboxItem>(incomeQuestion)}
          onSelectedValueChanged={(value: string[]) => {
            if (value?.length > 0) {
              props.onSave([{ questionCode: '' + incomeQuestion?.idDomanda, value }]);
            } else {
              props.deleteAnswerByQuestionId('' + incomeQuestion?.idDomanda);
            }
          }}
        />
      </div>
      <div
        className={`pep-sub-section ${
          amlConsistency?.consistency?.find((c) => c.questionId === amountIncomeQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
        }`}>
        <h4>{amountIncomeQuestion?.testoDomanda}</h4>
        <AppRadioGroup
          selectedValue={props.getAmlAnswerDetailsByQuestionId('' + amountIncomeQuestion?.idDomanda) as string}
          options={mapListaRisposteToOptions<AppRadioButtonConfig>(amountIncomeQuestion)}
          onSelectedValueChanged={(value) => {
            props.onSave([{ questionCode: '' + amountIncomeQuestion?.idDomanda, value }]);
          }}
        />
      </div>
      <div
        className={`pep-sub-section ${
          amlConsistency?.consistency?.find((c) => c.questionId === savingsQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
        }`}>
        <h4>{savingsQuestion?.testoDomanda}</h4>
        <AppRadioGroup
          selectedValue={props.getAmlAnswerDetailsByQuestionId('' + savingsQuestion?.idDomanda) as string}
          options={mapListaRisposteToOptions<AppRadioButtonConfig>(savingsQuestion)}
          onSelectedValueChanged={(value) => {
            props.onSave([{ questionCode: '' + savingsQuestion?.idDomanda, value }]);
          }}
        />
      </div>
      <div
        className={`pep-sub-section ${
          amlConsistency?.consistency?.find((c) => c.questionId === movableAssetQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
        }`}>
        <h4>{movableAssetQuestion?.testoDomanda}</h4>
        <AppRadioGroup
          selectedValue={props.getAmlAnswerDetailsByQuestionId('' + movableAssetQuestion?.idDomanda) as string}
          options={mapListaRisposteToOptions<AppRadioButtonConfig>(movableAssetQuestion)}
          onSelectedValueChanged={(value) => {
            props.onSave([{ questionCode: '' + movableAssetQuestion?.idDomanda, value }]);
          }}
        />
      </div>
      <h4>{realEstateQuestion?.testoDomanda}</h4>
      <div
        className={`sub-section sub-section-text-and-radio ${
          amlConsistency?.consistency?.find((c) => c.questionId === firstHouseQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
        }`}>
        <h4>{firstHouseQuestion?.testoDomanda}</h4>
        <AppRadioGroup
          selectedValue={props.getAmlAnswerDetailsByQuestionId('' + firstHouseQuestion?.idDomanda) as string}
          options={mapListaRisposteToOptions<AppRadioButtonConfig>(firstHouseQuestion)}
          onSelectedValueChanged={(value) => {
            props.onSave([{ questionCode: '' + firstHouseQuestion?.idDomanda, value }]);
          }}
        />
      </div>
      <div
        className={`sub-section sub-section-text-and-radio ${
          amlConsistency?.consistency?.find((c) => c.questionId === otherHouseQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
        }`}>
        <h4>{otherHouseQuestion?.testoDomanda}</h4>
        <AppRadioGroup
          selectedValue={props.getAmlAnswerDetailsByQuestionId('' + otherHouseQuestion?.idDomanda) as string}
          options={mapListaRisposteToOptions<AppRadioButtonConfig>(otherHouseQuestion)}
          onSelectedValueChanged={(value) => {
            props.onSave([{ questionCode: '' + otherHouseQuestion?.idDomanda, value }]);
          }}
        />
      </div>
      <div
        className={`pep-sub-section ${
          amlConsistency?.consistency?.find((c) => c.questionId === realEstateAssetQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
        }`}>
        <h4>{realEstateAssetQuestion?.testoDomanda}</h4>
        <AppRadioGroup
          selectedValue={props.getAmlAnswerDetailsByQuestionId('' + realEstateAssetQuestion?.idDomanda) as string}
          options={mapListaRisposteToOptions<AppRadioButtonConfig>(realEstateAssetQuestion)}
          onSelectedValueChanged={(value) => {
            props.onSave([{ questionCode: '' + realEstateAssetQuestion?.idDomanda, value }]);
          }}
        />
      </div>
      <div
        className={`pep-sub-section ${
          amlConsistency?.consistency?.find((c) => c.questionId === financialAssetQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
        }`}>
        <h4>{financialAssetQuestion?.testoDomanda}</h4>
        <AppRadioGroup
          selectedValue={props.getAmlAnswerDetailsByQuestionId('' + financialAssetQuestion?.idDomanda) as string}
          options={mapListaRisposteToOptions<AppRadioButtonConfig>(financialAssetQuestion)}
          onSelectedValueChanged={(value) => {
            props.onSave([{ questionCode: '' + financialAssetQuestion?.idDomanda, value }]);
          }}
        />
      </div>
      <div
        className={`pep-sub-section ${
          amlConsistency?.consistency?.find((c) => c.questionId === loanQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
        }`}>
        <h4>{loanQuestion?.testoDomanda}</h4>
        <AppRadioGroup
          selectedValue={props.getAmlAnswerDetailsByQuestionId('' + loanQuestion?.idDomanda) as string}
          options={mapListaRisposteToOptions<AppRadioButtonConfig>(loanQuestion)}
          onSelectedValueChanged={(value) => {
            props.onSave([{ questionCode: '' + loanQuestion?.idDomanda, value }]);
          }}
        />
      </div>
      <div
        className={`pep-sub-section ${
          amlConsistency?.consistency?.find((c) => c.questionId === monthlySavingsQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
        }`}>
        <h4>{monthlySavingsQuestion?.testoDomanda}</h4>
        <AppRadioGroup
          selectedValue={props.getAmlAnswerDetailsByQuestionId('' + monthlySavingsQuestion?.idDomanda) as string}
          options={mapListaRisposteToOptions<AppRadioButtonConfig>(monthlySavingsQuestion)}
          onSelectedValueChanged={(value) => {
            props.onSave([{ questionCode: '' + monthlySavingsQuestion?.idDomanda, value }]);
          }}
        />
      </div>
      <div
        className={`pep-sub-section ${
          amlConsistency?.consistency?.find((c) => c.questionId === dependencyQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
        }`}>
        <h4>{dependencyQuestion?.testoDomanda}</h4>
        <AppRadioGroup
          selectedValue={props.getAmlAnswerDetailsByQuestionId('' + dependencyQuestion?.idDomanda) as string}
          options={mapListaRisposteToOptions<AppRadioButtonConfig>(dependencyQuestion)}
          onSelectedValueChanged={(value) => {
            props.onSave([{ questionCode: '' + dependencyQuestion?.idDomanda, value }]);
          }}
        />
      </div>
    </div>
  );
};
