import { Amount, amountFormatter, walletPageMessages } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import { AppSvgIcon } from '../../../../../components/svg-icon/app-svg-icon';
import './total-amount-card.scss';

export const TotalAmountCard = (props: { total: Amount; flagShowBalance: boolean }) => {
	const { formatMessage } = useIntl();
	const { total } = props;
	return (
		<div className='app-total-amount-card'>
			<div className='app-total-amount-card-header'>
				<AppSvgIcon icon={'wallet'} />
				<span>{formatMessage(walletPageMessages.cardTitle)}</span>
				<div className={`app-total-amount-card-amount ${!props.flagShowBalance ? 'blurry-text' : ''}`}>
					<h2>{amountFormatter(total)}</h2>
				</div>
			</div>
		</div>
	);
};
