import { mdiCloseCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import {
  appRoutes,
  commonButtonMessages,
  insertPhoneNumberUserRegistrationMessages,
  phoneVerificationUserRegistrationMessages,
  useInsertPhoneNumberPageLogic
} from 'azshared';
import React, { FormEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType } from '../../../../components/buttons/app-button';
import { Card } from '../../../../components/card/card';
import { AppDialog } from '../../../../components/dialog/app-dialog';
import { AppInputText } from '../../../../components/input-text/app-input-text';
import { AppLink } from '../../../../components/labels/app-link';
import { useGlobalActions, useGoToLoginFromRegistration } from '../../../../hooks/global-actions';
import { LoginContainer } from '../../login-container/login-container';
import './phone-number-user-registration-page.scss';

export const InsertPhoneNumberUserRegistrationPage = () => {
  const { formatMessage } = useIntl();
  const goToLoginFromRegistration = useGoToLoginFromRegistration();

  const {
    buttonDisabled,
    phoneNumber,
    insertPhoneNumberRequestStatus,
    insertPhoneNumberError,
    phoneNumberAlreadyExisting,
    handleSubmit,
    updatePhoneNumber,
    handleClose
  } = useInsertPhoneNumberPageLogic(useGlobalActions());

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );

  return (
    <div>
      <LoginContainer>
        <div className='insert-phone-number-user-registration-page'>
          <Card
            title={formatMessage(insertPhoneNumberUserRegistrationMessages.pageTitle)}
            subtitle={formatMessage(insertPhoneNumberUserRegistrationMessages.pageSubtitle)}
            back={formatMessage(commonButtonMessages.backLink)}
          >
            <form onSubmit={onSubmit}>
              <AppInputText
                label={formatMessage(insertPhoneNumberUserRegistrationMessages.labelInsertPhoneNumberInputText)}
                placeholder={formatMessage(insertPhoneNumberUserRegistrationMessages.placeholderInsertPhoneNumberInputText)}
                value={phoneNumber}
                onChange={updatePhoneNumber}
                disabled={phoneNumberAlreadyExisting}
              />
              <div className='button'>
                <AppButton
                  label={formatMessage(insertPhoneNumberUserRegistrationMessages.buttonSendCodeByPhone)}
                  type={AppButtonType.SUBMIT}
                  disabled={phoneNumberAlreadyExisting ? false : buttonDisabled}
                  loading={insertPhoneNumberRequestStatus === 'loading'}
                />
              </div>
            </form>
            <div className='description-disclaimer insert-phone-number'>
              {formatMessage(insertPhoneNumberUserRegistrationMessages.descriptionDisclaimerInsertPhoneNumber)}
            </div>
            <div className='disclaimer'>
              {formatMessage(commonButtonMessages.linkBackToLogin)}
              <AppLink to={appRoutes.Login} replace={false} label={formatMessage(commonButtonMessages.linkToLogin)} onClick={goToLoginFromRegistration} />
            </div>
          </Card>
        </div>
      </LoginContainer>
      <AppDialog open={insertPhoneNumberError === 'CELLULARE_NON_UTILIZZABILE'}>
        <div className='dialog-error-phone-number-verification'>
          <Icon path={mdiCloseCircleOutline} size={2} color={'red'} />
          <div className='title-dialog'>{formatMessage(phoneVerificationUserRegistrationMessages.errorPhoneNumberNotUsableTitle)}</div>
          <div className='text-dialog'>{formatMessage(phoneVerificationUserRegistrationMessages.errorPhoneNumberNotUsableMessages)}</div>
          <div className='button-dialog'>
            <AppButton
              type={AppButtonType.BUTTON}
              onClick={handleClose}
              label={formatMessage(phoneVerificationUserRegistrationMessages.errorPhoneNumberNotUsableButtonLabel)}
            />
          </div>
        </div>
      </AppDialog>
    </div>
  );
};
