import { FinancialAdvisor, HomeCard, homeFinancialConsultantCardMessages } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import { Loader, LoaderSize } from '../../../../components/loader/loader';
import { AppSvgIcon } from '../../../../components/svg-icon/app-svg-icon';
import './home-financial-consultant-card.scss';


export const HomeFinancialConsultantCard = (props: { consultant: HomeCard<FinancialAdvisor>, isProspect: boolean }) => {
  const { formatMessage } = useIntl();
  const { consultant, isProspect } = props;
  const homeFinancialConsultantCardClass: string = isProspect ? 'home-financial-consultant-card' : 'home-financial-consultant-card margin';

  return (
    <div className={homeFinancialConsultantCardClass}>
      <div className='home-financial-consultant-card-header'>
        <AppSvgIcon icon='home-consultant'/>
        <h4> {formatMessage(homeFinancialConsultantCardMessages.cardTitle)} </h4>
      </div>
      {consultant.requestStatus !== 'loading' && (
        <>
          <div className='home-financial-consultant-card-name'>
            <div className='name-consultant'>
              {consultant.value?.name} {consultant.value?.surname}
            </div>
            <div className='avatar'>
              {!!consultant.value?.avatar && <img width='100%' height='100%' src={consultant.value?.avatar}/>}
              {!consultant.value?.avatar && <svg className='app-menu-header-avatar'/>}
            </div>
          </div>
          <div className='home-financial-consultant-card-detail'>
            <AppSvgIcon icon='home-mail'/> <span>{consultant.value?.email}</span>
          </div>
          <div className='home-financial-consultant-card-detail'>
            <AppSvgIcon icon='home-phone'/> <span>{consultant.value?.phone}</span>
          </div>
          <div className='home-financial-consultant-card-detail'>
            <AppSvgIcon icon='home-maps'/>
            {!!consultant.value?.address.address && <span>
              {consultant.value?.address.address + ' - '}
              {consultant.value?.address.zipCode + ' - '}
              {consultant.value?.address.city + ' - '}
              {consultant.value?.address.country}
            </span>}
          </div>
        </>
      )}
      {consultant.requestStatus === 'loading' && (
        <div className='home-financial-consultant-card-loader'>
          <Loader loadersize={LoaderSize.XL}/>
        </div>
      )}
    </div>
  );
};