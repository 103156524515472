import React, { ReactNode } from 'react';
import './login-container.scss';

export interface LoginContainerProps {
  children: ReactNode;
}

export const LoginContainer = (props: LoginContainerProps) => {
  const { children } = props;
  return (
    <div className='login-container'>
      <div className='content'>{children}</div>
      <div className='logo' />
    </div>
  );
};
