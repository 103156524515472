import { percentageFormatter, walletPageMessages } from 'azshared';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { RadialBar, RadialBarChart } from 'recharts';
import './radial-bar-chart.scss';

export interface RadialBarChartProps {
	value: number;
}

interface Data {
	value: number;
	fill: string;
}

export const AppRadialBarChart = (props: RadialBarChartProps) => {
	const { formatMessage } = useIntl();
	const MAX_VALUE = 50;
	const { value } = props;
	const hiddenArch: Data[] = useMemo(() => {
		return [
			{
				value: MAX_VALUE,
				fill: '#FFFFFF'
			}
		];
	}, []);

	const dataPositive = useMemo(
		() => {
			return [
				...hiddenArch,
				{
					value: value > 0 ? value : 0,
					fill: '#AFE994'
				}
			];
		},
		[ value, hiddenArch ]
	);

	const dataNegative = useMemo(
		() => {
			return [
				...hiddenArch,
				{
					value: value < 0 ? Math.abs(value) : 0,
					fill: '#FB7E65'
				}
			];
		},
		[ value, hiddenArch ]
	);

	return (
		<div className='app-radial-bar-chart'>
			<div className='app-radial-bar-chart-content'>
				<RadialBarChart
					className={`app-radial-bar-chart-semi-arch ${dataPositive[1].value > 0 ? 'active' : ''}`}
					width={500}
					height={300}
					barSize={10}
					startAngle={dataPositive[1].value > 0 ? 90 : 180}
					endAngle={0}
					data={dataPositive}>
					<RadialBar background dataKey='value' cornerRadius={10} />
				</RadialBarChart>
				<RadialBarChart
					className={`app-radial-bar-chart-semi-arch ${dataNegative[1].value > 0 ? 'active' : ''}`}
					width={500}
					height={300}
					startAngle={dataNegative[1].value > 0 ? 90 : 0}
					endAngle={180}
					barSize={10}
					data={dataNegative}>
					<RadialBar background dataKey='value' cornerRadius={10} />
				</RadialBarChart>
			</div>
			<div className='app-radial-bar-chart-legend'>
				{formatMessage(walletPageMessages.gainLabel)}
				<span className={value >= 0 ? 'positive' : 'negative'}>
					{value > 0 ? '+' : ''}
					{percentageFormatter(value)}
				</span>
			</div>
		</div>
	);
};
