import { adminConsoleLogin, AdminConsoleUser, useAppSelector } from 'azshared';
import React, { Fragment, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Menu } from '../../components/menu/menu';
import { useAppDispatch } from '../../hooks/redux';
import './admin-console-container.scss';
import { Header } from './menu-header/menu-header';

export const AdminConsoleContainer = () => {
  const user: AdminConsoleUser = useAppSelector((state) => state.features.adminConsole.common.user);
  const dispatch = useAppDispatch();
  const [countLogin, setCountLogin] = useState(0);

  useEffect(() => {
    if (!user && countLogin === 0) {
      dispatch(adminConsoleLogin());
      setCountLogin(countLogin + 1);
    }
  }, [user, dispatch, countLogin]);

  


  return (
    <Fragment>
   {user && <div className='admin-console-container'>
      <Menu>
        <Header userName={`${user?.name} ${user?.surname}`} />
      </Menu>

      <div className='content'>
        <Outlet />
      </div>
    </div>}
    </Fragment>
  );
};
