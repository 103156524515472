import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {AppButton} from '../../../components/buttons/app-button';
import {AppSvgIcon} from '../../../components/svg-icon/app-svg-icon';
import {finalizeOnboardingPageMessages, useFinalizeOnboardingPageLogic} from 'azshared';
import {useIntl} from 'react-intl';
import { useGlobalActions } from '../../../hooks/global-actions';
import { Loader, LoaderSize } from '../../../components/loader/loader';
import './finalize-onboarding-page.scss';

export const FinalizeOnboardingPage = () => {
  const {formatMessage} = useIntl();

  const {
    testPassed, onClick, isDeletePending, isLoading, setReferralCode
  } = useFinalizeOnboardingPageLogic(useGlobalActions());

  const [searchParams] = useSearchParams();

  useEffect(() => {
    setReferralCode(searchParams.get('referralCode') ?? '');
  }, [searchParams, setReferralCode]);

  return (
    <div className='finalize-onboarding-page-container'>
      <div className='finalize-onboarding-page-body-headerLogo'>
        <AppSvgIcon icon='logo'/>
      </div>
    <div className='finalize-onboarding-page-body-content'>
        {!isLoading && (
          <div className='finalize-onboarding-page-body-result'>
            {testPassed ? <AppSvgIcon icon='smile'/> : <AppSvgIcon icon='x-circle'/>}
            {!testPassed &&
              <>
                <h2 className='finalize-onboarding-page-body-result-title'>{formatMessage(finalizeOnboardingPageMessages.failTitle)}</h2>
                <h4 className='finalize-onboarding-page-body-subtitle'>{formatMessage(finalizeOnboardingPageMessages.failSubtitle)}</h4>
              </>
            }
            {testPassed &&
              <>
                <h2 className='finalize-onboarding-page-body-result-title'>{formatMessage(finalizeOnboardingPageMessages.successTitle)}</h2>
                <h4 className='finalize-onboarding-page-body-subtitle'>{formatMessage(finalizeOnboardingPageMessages.successSubtitle)}</h4>
                <h4 className='finalize-onboarding-page-body-subtitle'>{formatMessage(finalizeOnboardingPageMessages.successSubtitle2)}</h4>
                <h4 className='finalize-onboarding-page-body-subtitle'>{formatMessage(finalizeOnboardingPageMessages.successSubtitle3)}</h4>
              </>
            }
            <div className='finalize-onboarding-page-body-button'>
              <AppButton
                label={formatMessage(finalizeOnboardingPageMessages.buttonLabel)}
                onClick={onClick}
                loading={isDeletePending}
              />
            </div>
          </div>
        )}
        {isLoading && <Loader loadersize={LoaderSize.L} />}
      </div>
    </div>
  );
};
