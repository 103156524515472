import { PaginationResponse } from 'azshared';
import React, { useCallback, useMemo } from 'react';
import { AppSvgIcon } from '../../../../../components/svg-icon/app-svg-icon';
import { PageIndicator } from './page-indicator';
import './page-selector.scss';

export const hasMorePagesIndicator = '...';


interface PageSelectorProps {
  pagination: PaginationResponse;

  disableNavigation?: boolean;

  showPreviousPage: () => void;
  showNextPage: () => void;
  showPage: (pageNumber: number) => void;
}

export const PageSelector = (props: PageSelectorProps) => {
  const { pagination, disableNavigation, showNextPage, showPreviousPage, showPage } = props;

  const totalPages = useMemo(() => Math.ceil(pagination.count / pagination.perPage), [pagination]);
  const canMoveBack = useMemo(() => pagination.page > 1 && !disableNavigation, [pagination, disableNavigation]);
  const canMoveForward = useMemo(() => pagination.page < totalPages && !disableNavigation, [pagination, disableNavigation, totalPages]);

  const handlePageSelected = useCallback((page: number) => showPage(page), [showPage]);
  // Init UI page selectors array

  const [firstPage, lastPage, pages] = useMemo(() => calculatePageIndicators(pagination.page, totalPages), [pagination, totalPages]);

  return (
    <div className='page-selector-row'>
      {pages.length > 0 && (
        <>
          <AppSvgIcon icon={'arrow-previous'} onClick={canMoveBack ? showPreviousPage : undefined} />
          {firstPage > 1 && <span className='page-indicator'>{hasMorePagesIndicator}</span>}
          {pages.map(pageNumber => (
            <PageIndicator key={pageNumber} currentPage={pagination.page} pageNumber={pageNumber} onPageSelected={handlePageSelected} />
          ))}
          {lastPage < totalPages && <span className='page-indicator'>{hasMorePagesIndicator}</span>}
          <AppSvgIcon icon={'arrow-next'} onClick={canMoveForward ? showNextPage : undefined} />
        </>
      )}
    </div>
  );
};

const calculatePageIndicators = (currentPage: number, totalPages: number): [number, number, number[]] => {
  let firstPage: number, lastPage: number;

  if (totalPages <= 7) {
    firstPage = 1;
    lastPage = totalPages;
  } else {
    firstPage = currentPage - 3;
    lastPage = currentPage + 3;

    while (firstPage < 1) {
      firstPage++;
      lastPage++;
    }
    while (lastPage > totalPages) {
      firstPage--;
      lastPage--;
    }

    if (firstPage > 1) {
      firstPage++;
    }
    if (lastPage < totalPages) {
      lastPage--;
    }
  }
  return [
    firstPage,
    lastPage,
    totalPages > 0
      ? Array(lastPage - firstPage + 1)
          .fill(0)
          .map((_, index) => firstPage + index)
      : []
  ];
};
