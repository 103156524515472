import { mdiCloseCircleOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import {
  appRoutes,
  clearRetrieveUsernameError,
  commonButtonMessages,
  messagesForRetrieveUsernameError,
  resetRetrieveUsernameState,
  retrieveUsername,
  retrieveUsernameMessages,
  RetrieveUsernameOptions
} from 'azshared';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppButton, AppButtonType } from '../../../components/buttons/app-button';
import { Card } from '../../../components/card/card';
import { AppDialog } from '../../../components/dialog/app-dialog';
import { AppSuccessDialog } from '../../../components/dialog/app-success-dialog';
import { AppInputText } from '../../../components/input-text/app-input-text';
import { AppLink } from '../../../components/labels/app-link';
import { showAppToast } from '../../../components/toast/app-toast';
import { closeAppToast } from '../../../hooks/global-actions';
import { useAppSelector } from '../../../hooks/redux';
import { LoginContainer } from '../login-container/login-container';
import './username-recovery-page.scss';

export const UserRecoveryMultipleMatchPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const [userCode, setUserCode] = useState<string>();
  const [squareAgreementCode, setSquareAgreementCode] = useState<string>();
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const { retrieveUsernameRequestStatus, retrieveUsernameError, selectedOption } = useAppSelector(state => state.features.retrieveUsername);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      dispatch(
        retrieveUsername({
          squareAgreementCode,
          userCode,
          isRequestFromMultipleMatchPage: true
        })
      );
      
    },
    [dispatch, squareAgreementCode, userCode]
  );

  useEffect(() => {
    if (retrieveUsernameRequestStatus === 'succeeded') {
      setOpenSuccessDialog(true);
    }
  },  [retrieveUsernameRequestStatus]);

  const closeSuccessDialog = useCallback(() => {
    dispatch(resetRetrieveUsernameState());
    setOpenSuccessDialog(false);
    navigate(appRoutes.Login, { replace: true });
  }, [dispatch, navigate]);

  const handleClose = useCallback(() => {
    dispatch(resetRetrieveUsernameState());
    navigate(appRoutes.Login, { replace: true });
  }, [dispatch, navigate]);

  useEffect(() => {
    if (retrieveUsernameError && retrieveUsernameError !== 'EMAIL_NOT_FOUND') {
      const errorMessages = messagesForRetrieveUsernameError(retrieveUsernameError);
      showAppToast({
        type: 'error',
        title: formatMessage(errorMessages.title),
        description: errorMessages.description && formatMessage(errorMessages.description),
        close: closeAppToast
      });
      dispatch(clearRetrieveUsernameError());
    }
  }, [retrieveUsernameError, dispatch, formatMessage]);

  return (
    <LoginContainer>
      <div className='user-recovery'>
        <Card
          title={formatMessage(retrieveUsernameMessages.pageTitleMultipleMatch)}
          subtitle={formatMessage(retrieveUsernameMessages.pageSubtitleMultipleMatch)}
          back={formatMessage(retrieveUsernameMessages.backLinkMultipleMatch)}
        >
          <form onSubmit={handleSubmit}>
            <div className='input-box'>
              <AppInputText
                label={formatMessage(retrieveUsernameMessages.labelUserCodeInputText)}
                placeholder={formatMessage(retrieveUsernameMessages.placeholderUserCodeInputText)}
                value={userCode}
                onChange={setUserCode}
              />
              <AppInputText
                label={formatMessage(retrieveUsernameMessages.labelSquareAgreementCodeInputText)}
                placeholder={formatMessage(retrieveUsernameMessages.placeholderSquareAgreementCodeInputText)}
                value={squareAgreementCode}
                onChange={setSquareAgreementCode}
              />
            </div>
            <div className='button'>
              <AppButton
                type={AppButtonType.SUBMIT}
                label={formatMessage(retrieveUsernameMessages.retrieveUsernameMultipleMatchButton)}
                loading={retrieveUsernameRequestStatus === 'loading'}
              />
            </div>
          </form>
          <span className='disclaimer'>
            {formatMessage(retrieveUsernameMessages.disclaimer)}
            <AppLink to={appRoutes.Login} label={formatMessage(commonButtonMessages.linkToLogin)} />
          </span>
        </Card>
      </div>
      <AppDialog open={retrieveUsernameError === 'EMAIL_NOT_FOUND'}>
        <div className='dialog-error-username-recovery'>
          <Icon path={mdiCloseCircleOutline} size={2} color={'red'} />
          <div className='text-dialog'>{formatMessage(retrieveUsernameMessages.messageErrorModalInvalidUser)}</div>
          <div className='button-dialog'>
              <AppButton
                type={AppButtonType.BUTTON}
                onClick={handleClose}
                label={formatMessage(commonButtonMessages.ok)}
              />
            </div>
        </div>
        
      </AppDialog>
      <AppSuccessDialog
        open={openSuccessDialog}
        onClose={closeSuccessDialog}
        buttonLabel={formatMessage(retrieveUsernameMessages.successDialogRetrieveUsernameButtonLabel)}
      >
        <div>
          {selectedOption === RetrieveUsernameOptions.ByMail && (
            <div className='text-dialog'>{formatMessage(retrieveUsernameMessages.successMessageRetrieveUsernameByEmail)}</div>
          )}
          {selectedOption === RetrieveUsernameOptions.BySMS && (
            <div className='text-dialog'>{formatMessage(retrieveUsernameMessages.successMessageRetrieveUsernameBySMS)}</div>
          )}
        </div>
      </AppSuccessDialog>
    </LoginContainer>
  );
};
