import { appRoutes, credentialsRecoveryMessages } from 'azshared';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AppButton, AppButtonType } from '../../../components/buttons/app-button';
import { Card } from '../../../components/card/card';
import { AppRadioButtonProps } from '../../../components/radio/app-radio-button';
import { AppRadioGroup } from '../../../components/radio/app-radio-group';
import { LoginContainer } from '../login-container/login-container';
import './credentials-recovery-page.scss';

const recoveryOptionsValues = {
  recoveryPassword: 'recoveryPwd',
  recoveryUser: 'recoveryUser'
};

export const CredentialsRecoveryPage = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState<string>(recoveryOptionsValues.recoveryUser);

  const recoveryOptions: AppRadioButtonProps[] = [
    {
      value: recoveryOptionsValues.recoveryUser,
      label: formatMessage(credentialsRecoveryMessages.labelRecoveryUsernameRadioButton)
    },
    {
      value: recoveryOptionsValues.recoveryPassword,
      label: formatMessage(credentialsRecoveryMessages.labelRecoveryPasswordRadioButton)
    }
  ];

  const goAhead = useCallback(() => {
    if (selectedOption === recoveryOptionsValues.recoveryPassword) {
      navigate(appRoutes.RetrievePassword);
    } else if (selectedOption === recoveryOptionsValues.recoveryUser) {
      navigate(appRoutes.RetrieveUserType);
    }
  }, [selectedOption, navigate]);

  return (
    <LoginContainer>
      <div className='credentials-recovery'>
        <Card
          title={formatMessage(credentialsRecoveryMessages.pageTitle)}
          subtitle={formatMessage(credentialsRecoveryMessages.pageSubtitle)}
          back={formatMessage(credentialsRecoveryMessages.backTo)}
        >
          <div className='radio-button'>
            <AppRadioGroup options={recoveryOptions} selectedValue={selectedOption} onSelectedValueChanged={setSelectedOption} />
          </div>
          <div className='button'>
            <AppButton type={AppButtonType.SUBMIT} label={formatMessage(credentialsRecoveryMessages.buttonCredentialRecovery)} onClick={goAhead} />
          </div>
        </Card>
      </div>
    </LoginContainer>
  );
};
