import TableCell, { TableCellProps } from '@mui/material/TableCell/TableCell';
import { Amount, amountFormatter, AppTableCellType, dateFormatter, numberFormatter, percentageFormatter } from 'azshared';
import React, { ReactNode } from 'react';
import './table.scss';

export interface AppTableCellProps extends TableCellProps {
	type: AppTableCellType;
}

const formatChild = (type: AppTableCellType, value: Amount | number | Date | string | ReactNode) => {
	switch (type) {
		case 'amount':
			return amountFormatter(value as Amount, { currencyDisplay: 'code' });
		case 'date':
			return dateFormatter(value as Date) || '-';
		case 'percentage':
			return percentageFormatter(value as number) || '-';
		case 'number':
			return numberFormatter(value as number);
		default:
			return value;
	}
};

export const AppTableCell = (props: AppTableCellProps) => (
	<TableCell {...props} className={`app-table-cell app-table-cell-${props.type} ${props.className || ''}`}>
		{formatChild(props.type, props.children as Amount | number | Date | string | ReactNode)}
	</TableCell>
);
