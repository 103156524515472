import { env } from 'azshared';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loader, LoaderSize } from '../../components/loader/loader';

const IosMarketplace = () => {
    const {token} = useParams();

    useEffect(() => {
      const mktUrl= `${env.baseUrl.replace('preprod','sit').replace('uat','sit')}/${env.idpVersion}/marketplace?token=${token}&url='${env.marketplaceUrl}'`;
      console.log(mktUrl);
      window.open(mktUrl);
    },[token]);

    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
        }}
      >
        <Loader loadersize={LoaderSize.XL}></Loader>
      </div>
    );
};

export default IosMarketplace;