import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter } from '@mui/material';
import { amountFormatter, dateFormatter, summaryTableMessages } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import './single-product-detail-table.scss';

export const SingleProductDetailTable = (props: any) => {
    const {position} = props;
    const {formatMessage} = useIntl();

    let totalNumQuotes = 0;

    for (let i = 0; i < position.activity.length; i++) {
        totalNumQuotes += position.activity[i].numQuotes;
    }

    return (
    <div className='single-product-container'>
        <div className='header-single-table-container'>
            <div>
                <p className='cell-weight'>{formatMessage(summaryTableMessages.productTitle)}<sup></sup></p>
                <p >{position.description}</p>
            </div>
            <div className='right-content'>
                <div>
                <p className='cell-weight'>{formatMessage(summaryTableMessages.labelForContract)}<sup></sup></p>
                <p>{position.codPirPlain.slice(5,15)}</p>
                </div>
                <div>
                <p className='cell-weight'>{formatMessage(summaryTableMessages.labelEntireCMP)}<sup className='superior'>2</sup></p>
                <p>{amountFormatter(position.totalCMP)}</p> 
                </div>
                <div>
                <p className='cell-weight'>{formatMessage(summaryTableMessages.commitment)}<sup></sup></p>
                <p>{amountFormatter(position.commitment)}</p>
                </div>
            </div>
        </div>
        <div className='table'>
        <TableContainer className='document-detail-operations-table'>
            <Table>
                <TableHead>
                    <TableRow className='document-detail-operations-table-row'>
                        <TableCell>
                        {formatMessage(summaryTableMessages.movementDate)}
                        </TableCell>
                        <TableCell>
                        {formatMessage(summaryTableMessages.invested)}<sup>1</sup>
                        </TableCell>
                        <TableCell>
                        {formatMessage(summaryTableMessages.divested)}<sup>1</sup>
                        </TableCell>
                        <TableCell>
                        {formatMessage(summaryTableMessages.quantityLabel)}
                        </TableCell>
                        <TableCell>
                        {formatMessage(summaryTableMessages.currencyLabel)}
                        </TableCell>
                        <TableCell>
                        {formatMessage(summaryTableMessages.holdingMinimunPeriod)}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {position.activity.map((operation: any) => {
                        return <TableRow key={operation.codMandato}>
                            <TableCell>
                                {dateFormatter(operation.dateRegulation)}
                            </TableCell>
                            <TableCell>
                                {amountFormatter(operation.amountInvested)}
                            </TableCell>
                            <TableCell>
                                {amountFormatter(operation.amountDivested)}
                            </TableCell>
                            <TableCell>
                                {operation.numQuotes.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 3})}
                            </TableCell>
                            <TableCell>
                                {operation.currency}
                            </TableCell>
                            <TableCell>
                                {dateFormatter(operation.dateEndHHoldingPeriod)}
                            </TableCell>
                        </TableRow>;
                    }) }
                </TableBody>
                <TableFooter>
                <TableRow className='document-detail-operations-table-row'>
                        <TableCell>
                            <p className='cell-weight'>{formatMessage(summaryTableMessages.total)}</p>
                        </TableCell>
                        <TableCell>
                            <p className='cell-weight'>{amountFormatter(position.totalAmountInvested)}</p>
                        </TableCell>
                        <TableCell>
                            <p className='cell-weight'>{amountFormatter(position.totalAmountDivested)}</p>
                        </TableCell>
                        <TableCell>
                        <p className='cell-weight'>{totalNumQuotes.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 3})}</p>
                        </TableCell>
                        <TableCell>
                            
                        </TableCell>
                        <TableCell>
                            
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        </div>
    </div>
);};