import { mdiCloseCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { commonButtonMessages, commonErrorMessages } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import { AppButton } from '../buttons/app-button';
import { AppDialog, AppDialogProps } from './app-dialog';
import './app-success-dialog.scss';

interface AppErrorDialogProps extends AppDialogProps {
  buttonLabel?: string;
}

export const AppErrorDialog = (props: AppErrorDialogProps) => {
  const { formatMessage } = useIntl();
  return (
    <AppDialog open={props.open}>
      <div>
        <div className='success-dialog-content'>
          <Icon path={mdiCloseCircleOutline} size={2} color={'red'} />
          <div className='title-dialog'>{formatMessage(commonErrorMessages.errorTitlePopup)}</div>
          <div className='text-dialog'>{props.children}</div>
        </div>
        <div className='success-dialog-button'>
          <AppButton label={props.buttonLabel || formatMessage(commonButtonMessages.closeButton)} onClick={props.onClose} />
        </div>
      </div>
    </AppDialog>
  );
};
