import { splashPageMessages } from 'azshared';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import useWindowSize from '../../../hooks/use-window-size';
import './splash-page.scss';

export const SplashPage = () => {
	const { formatMessage } = useIntl();
	const { height } = useWindowSize();

	useEffect(
		() => {
			document.documentElement.style.setProperty('--app-height', `${height}px`);
		},
		[ height ]
	);

	return (
		<div className='splash-page'>
			<div className='splash-page-logo'>
				<div className='logo-mobile' />
			</div>
			<div className='splash-page-text'>
				<p>{formatMessage(splashPageMessages.text)}</p>
				<p>{formatMessage(splashPageMessages.text1)}</p>
			</div>
			<div className='splash-page-store-badges'>
				<a href='https://play.google.com/store/apps/details?id=it.azimut.portaleclienti'>
					<div className='splash-page-store-badge splash-page-store-badge-android' />
				</a>
				<a href='https://apps.apple.com/it/app/azimut-home/id1625287301'>
					<img
						className='splash-page-store-badge'
						src='https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/it-it?size=250x83&amp;releaseDate=1450310400&h=edcfd997122fb03533389577560411cf'
						alt='Download on the App Store'
					/>
				</a>
			</div>
			<div className='splash-page-link-azimut'>
				<div>
					<span>{formatMessage(splashPageMessages.disclaimer)}</span>{' '}
					<a href='https://www.azimut.it/it/'>{formatMessage(splashPageMessages.link)}</a>
				</div>
			</div>
		</div>
	);
};
