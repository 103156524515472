import { mdiCloseCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import {
  appRoutes,
  commonButtonMessages,
  emailVerificationUserRegistrationMessages,
  insertEmailUserRegistrationMessages,
  useInsertEmailPageLogic
} from 'azshared';
import React, { FormEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType } from '../../../../components/buttons/app-button';
import { Card } from '../../../../components/card/card';
import { AppDialog } from '../../../../components/dialog/app-dialog';
import { AppInputText } from '../../../../components/input-text/app-input-text';
import { AppLink } from '../../../../components/labels/app-link';
import { useGlobalActions, useGoToLoginFromRegistration } from '../../../../hooks/global-actions';
import { LoginContainer } from '../../login-container/login-container';
import './email-user-registration-page.scss';

export const InsertEmailUserRegistrationPage = () => {
  const { formatMessage } = useIntl();
  const goToLoginFromRegistration = useGoToLoginFromRegistration();

  const { buttonDisabled, email, insertEmailRequestStatus, insertEmailError, handleSubmit, updateEmail, handleClose } = useInsertEmailPageLogic(
    useGlobalActions()
  );

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );
  return (
    <div>
      <LoginContainer>
        <div className='insert-email-user-registration-page'>
          <Card
            title={formatMessage(insertEmailUserRegistrationMessages.pageTitle)}
            subtitle={formatMessage(insertEmailUserRegistrationMessages.pageSubtitle)}
            back={formatMessage(commonButtonMessages.backLink)}
          >
            <form onSubmit={onSubmit}>
              <AppInputText
                label={formatMessage(insertEmailUserRegistrationMessages.labelInsertEmailInputText)}
                placeholder={formatMessage(insertEmailUserRegistrationMessages.placeholderInsertEmailInputText)}
                value={email}
                onChange={updateEmail}
              />
              <div className='button'>
                <AppButton
                  label={formatMessage(insertEmailUserRegistrationMessages.buttonSendCodeByMail)}
                  type={AppButtonType.SUBMIT}
                  disabled={buttonDisabled}
                  loading={insertEmailRequestStatus === 'loading'}
                />
              </div>
            </form>

            <div className='disclaimer'>
              {formatMessage(commonButtonMessages.linkBackToLogin)}
              <AppLink to={appRoutes.Login} replace={false} label={formatMessage(commonButtonMessages.linkToLogin)} onClick={goToLoginFromRegistration} />
            </div>
          </Card>
        </div>
      </LoginContainer>
      <AppDialog open={insertEmailError === 'EMAIL_NON_UTILIZZABILE'}>
        <div className='dialog-error-email-verification'>
          <Icon path={mdiCloseCircleOutline} size={2} color={'red'} />
          <div className='title-dialog'>{formatMessage(emailVerificationUserRegistrationMessages.errorEmailNotUsableTitle)}</div>
          <div className='text-dialog'>{formatMessage(emailVerificationUserRegistrationMessages.errorEmailNotUsableMessages)}</div>
          <div className='button-dialog'>
            <AppButton
              type={AppButtonType.BUTTON}
              onClick={handleClose}
              label={formatMessage(emailVerificationUserRegistrationMessages.errorEmailNotUsableButtonLabel)}
            ></AppButton>
          </div>
        </div>
      </AppDialog>
    </div>
  );
};
