import { amountFormatter } from 'azshared';
import React from 'react';
import './chart-bar.scss';
export interface ChartBarProps {
  value: number;
  max: number;
  isMain?: boolean;
}

export const ChartBar = (props: ChartBarProps) => {
  const { value, max, isMain } = props;
  const width = `${((value / max) * 100)}%`;

  return (
    <div className='chart-bar-container'>
      <div className={`chart-bar ${isMain ? 'main' : 'secondary'}`} style={{ width }} />
      <span className={`chart-bar-label ${isMain ? 'main' : 'secondary'} paragraph-200`}>{amountFormatter({value: value, currency: 'EUR'})}</span>
    </div>
  );
};
