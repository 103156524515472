import { dateFormatter, Video } from 'azshared';
import React, { Fragment, MouseEvent, RefObject, useCallback, useRef, useState } from 'react';
import { AppCustomIcons } from '../../utils/custom-icons';
import { AppSvgIcon } from '../svg-icon/app-svg-icon';

import './video-player.scss';

interface VideoPlayerProps {
  video: Video;
  foreground?: boolean;
  title?: string;
  titleIcon?: AppCustomIcons;
  hideDetails?: boolean;
}

export const VideoPlayer = (props: VideoPlayerProps) => {
  const { video, foreground, title, titleIcon, hideDetails = false } = props;

  const [layer, setLayer] = useState(true);
  const [poster, setPoster] = useState(true);

  const [idTimeout, setIdTimeout] = useState<number>();

  const videoRef: RefObject<HTMLVideoElement> = useRef(null);

  const togglePreview = useCallback((action: 'play' | 'pause',showPoster: boolean, flogOnEnter = false) => {
    if (videoRef?.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.muted = true;
      action === 'play' ?  videoRef.current.play() : videoRef.current.pause();
      setPoster(showPoster);

      if (flogOnEnter) {
        setIdTimeout(
          setTimeout(() => {
            togglePreview('pause', false);
          }, 5000) as unknown as number
        );
      }
    }
  }, [videoRef, setIdTimeout]);

  const mouseEnter = useCallback(() => {
    togglePreview('play',false, true );
  }, [togglePreview]);

  const mouseLeave = useCallback(() => {
    togglePreview('pause',true);
    clearTimeout(idTimeout);
    setIdTimeout(undefined);
  }, [idTimeout, setIdTimeout, togglePreview]);

  const onPlay = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setLayer(false);
      togglePreview('play', false);
      clearTimeout(idTimeout);
      setIdTimeout(undefined);
    },
    [idTimeout, setLayer, setIdTimeout, togglePreview]
  );

  return (
    <div className='video-player-component' key={video.videoId}>
      <div className='video-player-wrapper'>
        {layer && (
          <div className='video-player-layer' onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onClick={onPlay}>
            {poster && 
              <Fragment>
                <div className='video-player-layer-darkblue'/>
                <img width='100%' height='100%' src={video.posterUrl} />
              </Fragment>}
            {title && (
              <div className='title'>
                {titleIcon && <AppSvgIcon icon={titleIcon} />}
                <h4> {title}</h4>
              </div>
            )}
            <div className='play-button'>
              <AppSvgIcon icon={'play'} />
            </div>
          </div>
        )}
        <div className={`video-player ${foreground ? 'foreground' : ''}`}>
          <video controls ref={videoRef}>
            <source src={video.videoUrl} />
          </video>
        </div>
      </div>
      {!hideDetails && (
        <div className='video-player-detail'>
          <div className='title-data'>
            <span>{video.title}</span>
            {foreground && <span>{dateFormatter(video.uploadDate)}</span>}
          </div>
          <div className='detail'>{video.description}</div>
        </div>
      )}
    </div>
  );
};
