import { appRoutes, commonButtonMessages, insertDataUserRegistrationMessages, PersonaTipoUtenteEnum, useInsertDataPageLogic } from 'azshared';
import React, { FormEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType } from '../../../../components/buttons/app-button';
import { Card } from '../../../../components/card/card';
import { AppInputText } from '../../../../components/input-text/app-input-text';
import { AppLink } from '../../../../components/labels/app-link';
import { AppRequiredNote } from '../../../../components/required-note/required-note';
import { useGlobalActions, useGoToLoginFromRegistration } from '../../../../hooks/global-actions';
import { LoginContainer } from '../../login-container/login-container';
import './insert-data-user-registration-page.scss';

export const InsertDataUserRegistrationPage = () => {
  const { formatMessage } = useIntl();
  const goToLoginFromRegistration = useGoToLoginFromRegistration();

  const {
    buttonDisabled,
    selectedOptionUserType,
    userCode,
    fiscalCode,
    vatCodeFiscalCode,
    setupAccountRequestStatus,
    goBack,
    handleSubmit,
    updateUserCode,
    updateFiscalCode,
    updateVatCodeFiscalCode
  } = useInsertDataPageLogic(useGlobalActions());

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );

  return (
    <LoginContainer>
      <div className='insert-data-user-registration-page'>
        <Card
          title={formatMessage(insertDataUserRegistrationMessages.pageTitle)}
          subtitle={formatMessage(insertDataUserRegistrationMessages.pageSubtitle)}
          back={formatMessage(commonButtonMessages.backLink)}
          clickGoBack={goBack}
        >
          <form onSubmit={onSubmit}>
            <div>
              <AppInputText
                placeholder={formatMessage(insertDataUserRegistrationMessages.placeholderUserCodeInputText)}
                label={formatMessage(insertDataUserRegistrationMessages.labelUserCodeInputText)}
                required
                value={userCode}
                onChange={updateUserCode}
              />
              {selectedOptionUserType === PersonaTipoUtenteEnum.Pf && (
                <AppInputText
                  placeholder={formatMessage(insertDataUserRegistrationMessages.placeholderFiscalCodeInputText)}
                  label={formatMessage(insertDataUserRegistrationMessages.labelFiscalCodeInputText)}
                  required
                  value={fiscalCode}
                  onChange={updateFiscalCode}
                />
              )}

              {/* solo per PERSONA GIURIDICA */}

              {selectedOptionUserType === PersonaTipoUtenteEnum.Pg && (
                <AppInputText
                  placeholder={formatMessage(insertDataUserRegistrationMessages.placeholderVatCodeInputText)}
                  label={formatMessage(insertDataUserRegistrationMessages.labelVatCodeInputText)}
                  required
                  value={vatCodeFiscalCode}
                  onChange={updateVatCodeFiscalCode}
                />
              )}
            </div>
            <AppRequiredNote />
            <div className='button'>
              <AppButton
                type={AppButtonType.SUBMIT}
                label={formatMessage(insertDataUserRegistrationMessages.buttonInserDataUserRegistration)}
                disabled={buttonDisabled}
                loading={setupAccountRequestStatus === 'loading'}
              />
            </div>
          </form>
          <span className='disclaimer'>
            {formatMessage(commonButtonMessages.linkBackToLogin)}{' '}
            <AppLink to={appRoutes.Login} replace={true} label={formatMessage(commonButtonMessages.linkToLogin)} onClick={goToLoginFromRegistration} />
          </span>
        </Card>
      </div>
    </LoginContainer>
  );
};
