import React from 'react';
import { amountFormatter, dateFormatter, summaryTableMessages } from 'azshared';
import './summary-table.scss';
import { useIntl } from 'react-intl';
import { PirBoxPosition, PirBoxProductDetail, PirBoxProductSummary } from 'azshared/build/main/lib/model/pirbox/pirbox-product-summary';
import { PirBoxSummaryTablePosition } from './summary-table-position';

export interface SummeryTableProps{
    summary: PirBoxProductSummary;
    expirationDate: Date;
}

export const SummaryTable = (props: SummeryTableProps) => {
  const { summary, expirationDate } = props;
  const { formatMessage } = useIntl();

  const isExpired= expirationDate < new Date(Date.now());
  
  const displayDisclaimer7 = !Number.isNaN(expirationDate.getTime()) && !isExpired && summary.productsDetails.find((value : PirBoxProductDetail) => value.concentration>20) !== undefined;


  const positions: (PirBoxPosition | undefined)[] = [
    summary.compliantPercentage !== 0 ? {
      typology: formatMessage(summaryTableMessages.qualifiedTypology),
      percentage: summary.compliantPercentage,
      products: summary.productsDetails.filter(product => product.compliant)
    } : undefined,
    summary.percentageNoCompliant !== 0 ? {
      typology: formatMessage(summaryTableMessages.notQualifiedTypology),
      percentage: summary.percentageNoCompliant,
      products: summary.productsDetails.filter(product => !product.compliant)
    } : undefined
  ];
const displayDisclaimer6= positions.some((position) => position && (!Number.isNaN(expirationDate.getTime()) && !isExpired && ((position.typology === 'Non Qualificata' && position.percentage > 30) || (position.typology === 'Qualificata' && position.percentage > 70))));
 
  return (
    <div className='table-summary'>
      <div className='header-section'>
        <div>
          <p className='cell-weight'>
            {formatMessage(summaryTableMessages.maxAmountInvestibleCurrentYear)}
            <sup>3</sup>
          </p>
          <p>{amountFormatter(summary?.maxAnnualInvestable)}</p>
        </div>
        <div>
          <p className='cell-weight'>
            {formatMessage(summaryTableMessages.remainingInvestableCurrentYear)}
            <sup>4</sup>
          </p>
          <p>{amountFormatter(summary?.annualInvestableResidue)}</p>
        </div>
      </div>
        {positions.map((position) => position && <PirBoxSummaryTablePosition key={position?.typology} expirationDate={expirationDate}  position={position as PirBoxPosition} />)}
      <div>
        <div>
          <p>{formatMessage(summaryTableMessages.disclaimer3)}</p>
          <p>{formatMessage(summaryTableMessages.disclaimer4)}</p>
          <p>{formatMessage(summaryTableMessages.disclaimer5)}</p>
          <p>{displayDisclaimer6 && formatMessage(summaryTableMessages.disclaimer6, { data: dateFormatter(expirationDate) })}</p>
          <p>{displayDisclaimer7 && formatMessage(summaryTableMessages.disclaimer7, { data: dateFormatter(expirationDate) })}</p>
          <p>{!Number.isNaN(expirationDate.getTime()) && isExpired && formatMessage(summaryTableMessages.disclaimer8)}</p>
        </div>
      </div> 
    </div>
  );
};
