import { appRoutes, unlockUserRequestMessages, useAppSelector } from 'azshared';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AppButton, AppButtonType } from '../../../../components/buttons/app-button';
import { Card } from '../../../../components/card/card';
import { LoginContainer } from '../../login-container/login-container';
import './unlock-user-request-page.scss';

export const UnlockUserRequestPage = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const goAhead = useCallback(() => {
    navigate(appRoutes.UnlockUserInsertUsername);
  }, [navigate]);

  const { username } = useAppSelector(state => state.data.user);

  return (
    <LoginContainer>
      <div className='unlock-user-request'>
        <Card
          title={formatMessage(unlockUserRequestMessages.pageTitle)}
          subtitle={formatMessage(unlockUserRequestMessages.pageSubtitle)}
          back={formatMessage(unlockUserRequestMessages.backTo)}
        >
          <div className='user'>{username}</div>
          <div className='disclaimer'>{formatMessage(unlockUserRequestMessages.disclaimerUnlockUserRequest)}</div>
          <div className='button'>
            <AppButton type={AppButtonType.SUBMIT} label={formatMessage(unlockUserRequestMessages.buttonUnlockUserRequest)} onClick={goAhead} />
          </div>
        </Card>
      </div>
    </LoginContainer>
  );
};
