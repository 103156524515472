import {appRoutes, loginMessages, useLoginPageLogic} from 'azshared';
import React, { FormEvent, Fragment, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { BannerCookiePolicy } from '../../../components/banner-cookie-policy/banner-cookie-policy';
import { AppButton, AppButtonType } from '../../../components/buttons/app-button';
import { Card } from '../../../components/card/card';
import { AppInputPassword } from '../../../components/input-text/app-input-password';
import { AppInputText } from '../../../components/input-text/app-input-text';
import { AppLink } from '../../../components/labels/app-link';
import { AppToggle } from '../../../components/toggle/app-toggle';
import { useGlobalActions } from '../../../hooks/global-actions';
import { LoginContainer } from '../login-container/login-container';
import './login-page.scss';

export const LoginPage = () => {

	const { formatMessage } = useIntl();
	const {
		username,
		password,
		isRememberMe,
		loginRequestStatus,
		handleSubmit,
		setUsername,
		setPassword,
		rememberMeToggle,
		showPasswordInput
	} = useLoginPageLogic(useGlobalActions());

	const onSubmit = useCallback(
		(e: FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			handleSubmit();
		},
		[ handleSubmit ]
	);

	const title = document.getElementsByTagName('title')[0];
  title.textContent = 'MyAzimut';

	/** Layout */
	return (
		<Fragment>
			<div className='login'>
				<LoginContainer>
					<div className='login-page'>
						<Card
							title={formatMessage(loginMessages.pageTitle)}
							subtitle={formatMessage(loginMessages.pageSubtitle)}
							disclaimer={formatMessage(loginMessages.assistanceDisclaimer)}
						>
							<form onSubmit={onSubmit}>
								<AppInputText
									label={formatMessage(loginMessages.usernameLabel)}
									placeholder={formatMessage(loginMessages.usernamePlaceholder)}
									value={username}
									onChange={setUsername}
								/>
								{showPasswordInput && <AppInputPassword
									label={formatMessage(loginMessages.passwordLabel)}
									placeholder={formatMessage(loginMessages.passwordPlaceholder)}
									value={password}
									onChange={setPassword}
								/>}
								<div className='helper'>
									<AppToggle
										checked={isRememberMe}
										onToggle={rememberMeToggle}
										label={formatMessage(loginMessages.toggleRememberMe)}
									/>
									<AppLink
										to={showPasswordInput ? appRoutes.RetrieveCredentials : appRoutes.RetrieveUserType}
										replace={false}
										label={formatMessage(showPasswordInput ? loginMessages.retrieveCredentialLink : loginMessages.retrieveUsernameLink)}
									/>
								</div>
								<div className='button'>
									<AppButton
										label={formatMessage(loginMessages.loginButton)}
										type={AppButtonType.SUBMIT}
										loading={loginRequestStatus === 'loading'}
									/>
								</div>
							</form>
							<div className='disclaimer'>{formatMessage(loginMessages.attemptsDisclaimer)}</div>
							<div className='disclaimer'>
								{formatMessage(loginMessages.notSignUp)}
								<AppLink
									to={appRoutes.UserRegistration}
									replace={false}
									label={formatMessage(loginMessages.signUp)}
								/>
							</div>
						</Card>
					</div>
				</LoginContainer>
				<BannerCookiePolicy />
			</div>
		</Fragment>
	);
};
