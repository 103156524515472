import {
  amountFormatter,
  FinancialInsuranceProductCategory,
  labelForCommitment,
  labelForQuoteEmissione,
  labelForRealEconomyCta,
  labelForRealEconomyExplain,
  labelForWalletMacroCategoryType,
  ProductMacroCategory
} from 'azshared';
import {useIntl} from 'react-intl';
import {FinancialPositionsCategoryBox} from './financial-positions-category-box';
import {AppButton, AppButtonVariant} from '../../../../../components/buttons/app-button';
import React from 'react';

interface FinancialPositionsMacroCategoryItemProps {
  macroCategory: ProductMacroCategory;
  flagShowBalance: boolean;
  onGoToPrevinet: () => void;
  goToRealEconomyDetailPage: () => void
}

export const FinancialPositionsMacroCategoryItem = (props: FinancialPositionsMacroCategoryItemProps) => {
  const {formatMessage} = useIntl();
  const {macroCategory, flagShowBalance, onGoToPrevinet, goToRealEconomyDetailPage} = props;

  const {type, amount, categories, commitment, quoteEmissione} = macroCategory;

  return (<>
      <div className='financial-positions-item'>
        <div className='macro-categories'>
          <div>{formatMessage(labelForWalletMacroCategoryType(type))}</div>
          <div className={`macro-categories-amount ${!flagShowBalance ? 'blurry-text' : ''}`}>
            {amount ? amountFormatter(amount) : ''}
          </div>
          {type === 'RealEconomy' && quoteEmissione &&
            <div className={'real-economy-quote-emissione'}>
              <span>{formatMessage(labelForQuoteEmissione())}</span>
              <span className='real-economy-note'><sup>2</sup></span>
              <span className={`real-economy-amount ${!flagShowBalance ? 'blurry-text' : ''}`}>
							{amountFormatter(quoteEmissione)}
						</span>
            </div>}
          {type === 'RealEconomy' && commitment &&
            <div className={'real-economy-commitment'}>
              <span>{formatMessage(labelForCommitment())}</span>
              <span className={`real-economy-amount ${!flagShowBalance ? 'blurry-text' : ''}`}>
							{amountFormatter(commitment)}
						</span>
            </div>
          }
        </div>
        <div className='macro-categories-accordion'>
          {categories.filter(c => type === 'FinancialInsurance' ? (c as FinancialInsuranceProductCategory)?.products?.length > 0 : true).map((category, index) => (
            <FinancialPositionsCategoryBox key={index} category={category} macroCategory={macroCategory}
                                           onGoToPrevinet={onGoToPrevinet}/>
          ))}
          
        </div>
        {type === 'RealEconomy' && <>
          <div/>
          <div className='real-economy-cta'>
            <AppButton
              variant={AppButtonVariant.Primary}
              label={formatMessage(labelForRealEconomyCta())}
              onClick={goToRealEconomyDetailPage}
            />
          </div>
        </>
        }
        {type === 'RealEconomy' && <div className='real-economy-explain'>
          <div>{formatMessage(labelForRealEconomyExplain())}</div>
          
        </div>}      
          
      </div>    
    </>
  );
};
