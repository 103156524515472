import { commonButtonMessages, commonErrorMessages } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType, AppButtonVariant } from '../../../../../../components/buttons/app-button';
import { AppDialog } from '../../../../../../components/dialog/app-dialog';
import './error-popup-dialog.scss';

interface ErrorPopupDialogProps {
  open: boolean;
  message: string;
  onClose: () => void;
}

export const ErrorPopupDialog = (props: ErrorPopupDialogProps) => {
  const { formatMessage } = useIntl();
  return (
    <AppDialog open={props.open} onClose={props.onClose}>
      <div className='error-popup-dialog'>
        <div className='error-popup-dialog-section'>
          <h3 className='error-popup-dialog-title'>{formatMessage(commonErrorMessages.errorTitlePopup)}</h3>
          <p className='error-popup-dialog-content'>{props.message}</p>
        </div>
        <div className='error-popup-dialog-button-section'>
          <AppButton
            type={AppButtonType.BUTTON}
            variant={AppButtonVariant.Primary}
            label={formatMessage(commonButtonMessages.closeButton)}
            onClick={props.onClose}
            className='error-popup-dialog-button'
            labelClassName='error-popup-dialog-button-label'
          />
        </div>
      </div>
    </AppDialog>
  );
};
