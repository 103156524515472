import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Table from '@mui/material/Table/Table';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { Column, currencyFormatter, ManagedFundsProductUnderlying, productPerformanceColumns, ProductUnderlyingGroupsList, UnitLinkedProductUnderlying, walletProductDetailDialogMessages } from 'azshared';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Loader, LoaderSize } from '../../../../../components/loader/loader';
import { AppTableCell } from '../../../../../components/table/table-cell';
import { AppTableCellHeader } from '../../../../../components/table/table-cell-header';
import { PageSelector } from '../../../../admin-console/customer-service/results/page-selector/page-selector';
import './underlying-table.scss';

interface UnderlyingTableProps {
  underlyingGroups: ProductUnderlyingGroupsList;
  columns: Column<ManagedFundsProductUnderlying>[] | Column<UnitLinkedProductUnderlying>[];
  isLoading: boolean;
  onUnderlyingPaginationChange: (page: number) => void;
  onUnderlyingPaginationNext: () => void;
  onUnderlyingPaginationPrev: () => void;
}

export const UnderlyingTable = (props: UnderlyingTableProps) => {
  const { underlyingGroups, onUnderlyingPaginationChange, onUnderlyingPaginationNext, onUnderlyingPaginationPrev, columns, isLoading } = props;
  const { formatMessage } = useIntl();

  return (
    <div className='underlying'>
      <div className='underlying-total-amount'>
        {formatMessage(walletProductDetailDialogMessages.totalAmount) +
          ' ' +
          currencyFormatter(underlyingGroups?.equivalent?.value ?? 0, { currency: underlyingGroups?.equivalent?.currency })}
      </div>
      <TableContainer className='underlying-table'>
        <Table aria-label='table'>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <AppTableCellHeader key={index} className='underlying-table-header'>
                  {formatMessage(column.headerLabel)}
                </AppTableCellHeader>
              ))}
            </TableRow>
          </TableHead>
          {isLoading && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={productPerformanceColumns.length + 1}>
                  <div className='table-loader'>
                    <Loader loadersize={LoaderSize.XL} />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!isLoading && <TableBody>
            {underlyingGroups.underlyingGroupsList.map(group => (
              <Fragment key={group.name}>
                <TableRow className='underlying-table-row underlying-table-row-group-name'>
                  <AppTableCell align='left' type={'string'} className='chip-cell'>
                   <span className='underlying-chip'> {group.name} </span> 
                  </AppTableCell>
                  {<AppTableCell type={'string'} colSpan={columns.length - 1} />}
                </TableRow>
                {group.underlying.map((underlying, index) => (
                  <TableRow className='underlying-table-row' key={group.name + index}>
                    {columns.map(cell => (
                      <Fragment key={cell.key}>
                        {(cell.key === 'percentage' || cell.key === 'marketPriceDate') && <AppTableCell align='left' type={cell.type}>
                          {(underlying as UnitLinkedProductUnderlying)[cell.key]}
                        </AppTableCell>}
                        {(cell.key === 'averagePurchaseExchange' || cell.key === 'rate' || cell.key === 'marketExchange' || cell.key === 'incPercentage' || cell.key === 'riskCoefficientPercentage') && <AppTableCell align='left' type={cell.type}>
                          {(underlying as ManagedFundsProductUnderlying)[cell.key]}
                        </AppTableCell>}
                        {(cell.key === 'type' || cell.key === 'productName' || cell.key === 'averagePurchasePrice' || cell.key === 'quantity' || cell.key === 'marketPrice' || cell.key === 'equivalent') && <AppTableCell align='left' type={cell.type}>
                          {underlying[cell.key]}
                        </AppTableCell>}
                      </Fragment>
                    ))}
                  </TableRow>
                ))}
              </Fragment>
            ))}
          </TableBody>}
        </Table>
        {/* {!isLoading && !underlyingGroups.underlyingGroupsList?.length && <div className='underlying-no-data'>{formatMessage(walletProductDetailDialogMessages.noData)}</div>} */}
      </TableContainer>
      {underlyingGroups?.pageInfo.count > underlyingGroups?.pageInfo.perPage && (
        <div className='underlying-paginator'>
          <PageSelector
            pagination={underlyingGroups?.pageInfo}
            showPreviousPage={onUnderlyingPaginationPrev}
            showNextPage={onUnderlyingPaginationNext}
            showPage={onUnderlyingPaginationChange}
          />
        </div>
      )}
    </div>
  );
};
