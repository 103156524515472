import {
  CommunicationBenefitsType,
  CommunicationDividendsType,
  CommunicationDocumentsStatus,
  CommunicationOperationsType,
  CommunicationOtherLettersType,
  filterPageMessages,
  useCommunicationDocumentsFilterLogic
} from 'azshared';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppButton } from '../../../../../components/buttons/app-button';
import { AppDateSelect } from '../../../../../components/select/app-date-select';
import { AppSelect } from '../../../../../components/select/app-select';
import './communications-filter.scss';

export const CommunicationsFilter = () => {
  const { formatMessage } = useIntl();

  const {
    filter,
    operations,
    status,
    dividends,
    benefits,
    other,
    handleDateChange,
    handleStatusChange,
    handleSubmitFilter,
    handleResetFilter,
    isDateRangeValid,
    handleCategoryOperationsChange,
    handleCategoryDividendsChange,
    handleCategoryBenefitsChange,
    handleCategoryOtherLettersChange,
    handleSelectAll
  } = useCommunicationDocumentsFilterLogic();

  const handleStatus = useCallback(
    (values: CommunicationDocumentsStatus | CommunicationDocumentsStatus[]) => {
      if (Array.isArray(values)) {
        handleStatusChange(values);
      }
    },
    [handleStatusChange]
  );

  const handleCategoryOperations = useCallback(
    (values: CommunicationOperationsType | CommunicationOperationsType[]) => {
      if (Array.isArray(values)) {
        handleCategoryOperationsChange(values);
      }
    },
    [handleCategoryOperationsChange]
  );
  const handleCategoryDividends = useCallback(
    (values: CommunicationDividendsType | CommunicationDividendsType[]) => {
      if (Array.isArray(values)) {
        handleCategoryDividendsChange(values);
      }
    },
    [handleCategoryDividendsChange]
  );
  const handleCategoryBenefits = useCallback(
    (values: CommunicationBenefitsType | CommunicationBenefitsType[]) => {
      if (Array.isArray(values)) {
        handleCategoryBenefitsChange(values);
      }
    },
    [handleCategoryBenefitsChange]
  );
  const handleCategoryOtherLetters = useCallback(
    (values: CommunicationOtherLettersType | CommunicationOtherLettersType[]) => {
      if (Array.isArray(values)) {
        handleCategoryOtherLettersChange(values);
      }
    },
    [handleCategoryOtherLettersChange]
  );

  const handleSelectAllStatus = useCallback(() => handleSelectAll('status'), [handleSelectAll]);
  const handleSelectAllOperations = useCallback(() => handleSelectAll('operations'), [handleSelectAll]);
  const handleSelectAllDividends = useCallback(() => handleSelectAll('dividends'), [handleSelectAll]);
  const handleSelectAllBenefits = useCallback(() => handleSelectAll('benefits'), [handleSelectAll]);
  const handleSelectAllOthers = useCallback(() => handleSelectAll('other'), [handleSelectAll]);

  return (
    <div className='communications-filter'>
      <div className='communications-filter-status'>
        <AppSelect
          placeholder={formatMessage(filterPageMessages.headColumnAllLettersConfirmStatus)}
          multiple={true}
          value={filter?.status}
          onChange={handleStatus}
          options={status}
          onSelectAll={handleSelectAllStatus}
        />
        <AppButton label={formatMessage(filterPageMessages.applyFilter)} onClick={handleSubmitFilter} />
      </div>
      <div className='communications-filter-categories'>
        <AppSelect
          placeholder={formatMessage(filterPageMessages.headColumnAllLettersConfirmOperation)}
          multiple={true}
          value={filter?.operations}
          onChange={handleCategoryOperations}
          options={operations}
          onSelectAll={handleSelectAllOperations}
        />
        <AppSelect
          placeholder={formatMessage(filterPageMessages.headColumnAllLettersDividends)}
          multiple={true}
          value={filter?.dividends}
          onChange={handleCategoryDividends}
          options={dividends}
          onSelectAll={handleSelectAllDividends}
        />
        <AppSelect
          placeholder={formatMessage(filterPageMessages.headColumnAllWelcomeLetters)}
          multiple={true}
          value={filter?.benefits}
          onChange={handleCategoryBenefits}
          options={benefits}
          onSelectAll={handleSelectAllBenefits}
        />
        <AppSelect
          placeholder={formatMessage(filterPageMessages.headColumnAllOtherLetters)}
          multiple={true}
          value={filter?.other}
          onChange={handleCategoryOtherLetters}
          options={other}
          onSelectAll={handleSelectAllOthers}
        />
        <AppDateSelect
          placeholder={formatMessage(filterPageMessages.headColumnSelectTimespan)}
          onChange={handleDateChange}
          value={filter?.rangeDate}
          isValid={isDateRangeValid}
        />
        <div className='communications-filter-reset-button' onClick={handleResetFilter}>
          {formatMessage(filterPageMessages.resetFilter)}
        </div>
      </div>
    </div>
  );
};
