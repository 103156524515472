import { adminConsoleMessages } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';

export interface HeaderProps {
  userName: string;
}

export const Header = (props: HeaderProps) => {
  const { formatMessage } = useIntl();
  return (
    <div className='profile'>
      <span className='user-name'>{props.userName}</span>
      <div className='horizontal-separator' />
      <div className='user-type'>{formatMessage(adminConsoleMessages.userType)}</div>
    </div>
  );
};
