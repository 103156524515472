import { newsMessages, useNewsPageLogic } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import { AppHeader } from '../../../components/header/header';
import { Loader, LoaderSize } from '../../../components/loader/loader';
import { useGlobalActions } from '../../../hooks/global-actions';
import { NewsCard } from './components/news-card/news-card';
import './news-page.scss';

export const NewsPage = () => {
  const { formatMessage } = useIntl();

  const globalActions = useGlobalActions();

  const { news: newsList, isLoadingNews } = useNewsPageLogic(globalActions);

  return (
    <div className='news-page'>
      <AppHeader />
      <h2>{formatMessage(newsMessages.pageTitle)}</h2>
      {!isLoadingNews && <div className='news-page-cards'>{newsList && newsList.map((news, index) => <NewsCard news={news} key={index} />)}</div>}
      {isLoadingNews && (
        <div className='news-page-cards news-page-cards-loader'>
          <Loader loadersize={LoaderSize.XL} />
        </div>
      )}
    </div>
  );
};
