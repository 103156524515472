import React, { Fragment } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import * as rdd from 'react-device-detect';

export const DesktopRoute = () => {
  return (
    <Fragment>
      { rdd.isMobile && !rdd.isTablet ? <Navigate to={'/splashpage'} replace /> : <Outlet />}
    </Fragment>
  );
};
