import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import {
  Column,
  communicationsColumns,
  CommunicationsDocument,
  documentsAreaMessages,
  labelForCommunicationsDocumentsType,
  DocumentsAreaSections
} from 'azshared';
import React, { Fragment, MouseEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppButtonIcon } from '../../../../../components/button-icon/app-button-icon';
import { AppPill } from '../../../../../components/pill/pill';
import { AppTableCell } from '../../../../../components/table/table-cell';
import { AppTableCellHeader } from '../../../../../components/table/table-cell-header';
import './communications-table.scss';


export interface CommunicationsTableProps {
  documents: CommunicationsDocument[];
  onReadDocument: (document: CommunicationsDocument, documentsAreaSection: DocumentsAreaSections) => void;
  onDownloadDocument: (document: CommunicationsDocument, documentsAreaSection: DocumentsAreaSections) => void;
}

export const CommunicationsTable = (props: CommunicationsTableProps) => {
  const { formatMessage } = useIntl();
  const { documents, onReadDocument, onDownloadDocument } = props;
  const columns: Column<CommunicationsDocument>[] = communicationsColumns;


  const handleReadDocument = useCallback(
    (e: MouseEvent) => {
      const id = (e.target as HTMLAnchorElement).id;
      const document = documents.find((doc) => doc.id === id);
      if (document) {
        onReadDocument(document, 'COMMUNICATIONS');
      }
    },
    [documents, onReadDocument]
  );

  const handleDownload = useCallback(
    (_: MouseEvent, id?: string) => {
      const document = documents.find((doc) => doc.id === id);
      if (document?.id) {
        onDownloadDocument(document, 'COMMUNICATIONS');
      }
    },
    [documents, onDownloadDocument]
  );

  return (
    <TableContainer className='communications-table'>
      <Table aria-label='table'>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <AppTableCellHeader key={index} className='communications-table-header'>
                {column.headerLabel && formatMessage(column.headerLabel)}
              </AppTableCellHeader>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {documents?.map((document, index) => (
            <TableRow key={index} className={`communications-table-row ${!document.flagIsRead ? 'communications-table-row-bold' : ''}`}>
              {columns.map(col => (
                <Fragment key={col.key}>
                  {col.key === 'title' && (
                    <AppTableCell key={col.key} align='left' type={col.type}>
                      <div className='communications-table-row-title'>
                        <AppButtonIcon icon='download-document' onClick={handleDownload} id={document.id}/>
                        <a href={document.fileUrl} target='_blank' rel='noreferrer' id={document.id} onClick={handleReadDocument}>
                          {document[col.key]}
                        </a>
                      </div>
                    </AppTableCell>
                  )}
                  {col.key === 'flagIsRead' && (
                    <AppTableCell key={col.key} align='left' type={col.type}>
                      {!document.flagIsRead && <AppPill color='success' label={formatMessage(documentsAreaMessages.notReadBadge)}></AppPill>}
                    </AppTableCell>
                  )}
                  {col.key === 'code' && (
                    <AppTableCell key={col.key} align='left' type={col.type}>
                      {formatMessage(labelForCommunicationsDocumentsType(document[col.key]))}
                    </AppTableCell>
                  )}
                  {col.key !== 'title' && col.key !== 'flagIsRead' && col.key !== 'code' && (
                    <AppTableCell key={col.key} align='left' type={col.type}>
                      {document[col.key]}
                    </AppTableCell>
                  )}
                </Fragment>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
