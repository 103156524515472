import { Box, Collapse } from '@mui/material';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import { getPracticeTitleDescriptor, signatureAreaMessages, SignatureDocument, useAppSelector, UserState } from 'azshared';
import React, {useEffect} from 'react';
import { useIntl } from 'react-intl';
import { Loader, LoaderSize } from '../../../../../../components/loader/loader';
import { SignatureAreaDocumentContent } from '../document-content/signature-area-document-content';
import './signature-area-document.scss';

export const SignatureAreaDocument = (props: { document: SignatureDocument; isLoading: boolean; clickGoForward: (bundleUrl: string) => void; }) => {
  const { document, isLoading } = props;
  const { formatMessage } = useIntl();
  const { isProspect } = useAppSelector<UserState>(
    (state) => state.data.user
  );

  useEffect(() => {
    console.log('asfds: ' + isProspect);
  }, [isProspect]);

  if (isLoading) {
    return <div className='signature-area-document-loader'>
      <Loader loadersize={LoaderSize.XL} />
    </div>;
  }

  if (!document) {
    return null;
  }

  return (
    <TableContainer className='signature-area-document-table'>
      <Table aria-label='sticky collapsible table'>
        <TableHead>
          <TableRow>
            <TableCell className='signature-area-document-table-header'>
              <div className='signature-area-document-table-header-content'>{formatMessage(signatureAreaMessages.TitleDocumentLabel)}</div>
            </TableCell>
            <TableCell className='signature-area-document-table-header'>
              <div className='signature-area-document-table-header-content'>{formatMessage(signatureAreaMessages.clientLabel)}</div>
            </TableCell>
            <TableCell className='signature-area-document-table-header' />
          </TableRow>
        </TableHead>
        { isProspect ?
          <TableBody>
          </TableBody> :
          <TableBody>
            <TableRow className='signature-area-document-table-row signature-area-document-table-row-header'>
              <TableCell
                className='signature-area-document-table-row-cell'
                component='th'
                scope='row'
                sx={{
                  borderBottomLeftRadius: 8,
                  borderTopLeftRadius: 8
                }}
              >
              {formatMessage(getPracticeTitleDescriptor(document?.type?.practiceType), {practiceNumber: document?.practiceNumber})}
              </TableCell>
              <TableCell className='signature-area-document-table-row-cell'>{document.customerName}</TableCell>
              <TableCell
                className='signature-area-document-table-row-cell'
                sx={{
                  borderBottomRightRadius: 8,
                  borderTopRightRadius: 8
                }}
              />
            </TableRow>
            <TableRow className='signature-area-document-table-row signature-area-document-table-row-content'>
              <TableCell style={{ padding: 0, border: 'none' }} colSpan={6}>
                <Collapse in={true} timeout='auto' unmountOnExit>
                  <Box>
                    <SignatureAreaDocumentContent {...props} />
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
            <TableRow />
          </TableBody> }
      </Table>
    </TableContainer>
  );
};
