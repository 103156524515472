import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import { labelForOfferingDocumentGroups, OfferingDocument, OfferingDocumentGroup, offeringDocumentsTabMessages, DocumentsAreaSections } from 'azshared';
import React, { Fragment, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { InformationOfferTable } from '../information-offer-table/information-offer-table';
import './information-offer-accordion.scss';

export interface InformationOfferAccordionProps {
	offeringGroup: OfferingDocumentGroup;
	onReadDocument: (document: OfferingDocument, documentsAreaSection: DocumentsAreaSections) => void;
	onDownloadDocument: (document: OfferingDocument, documentsAreaSection: DocumentsAreaSections) => void;
}

export const InformationOfferAccordion = (props: InformationOfferAccordionProps) => {
	const { offeringGroup } = props;
	const { formatMessage } = useIntl();

	const [ open, setOpen ] = useState(false);
	const toggleOpen = useCallback(() => setOpen(!open), [ open ]);

	return (
		<Fragment>
			<TableRow className={`information-offer-accordion ${open ? 'is-open' : ''}`}>
				<TableCell
					className='information-offer-accordion-cell'
					scope='row'
					sx={{
						borderBottomLeftRadius: 8,
						borderTopLeftRadius: 8
					}}
				>
					{formatMessage(labelForOfferingDocumentGroups(offeringGroup.type))}
				</TableCell>
				<TableCell className='information-offer-accordion-cell'>
					{formatMessage(offeringDocumentsTabMessages.documentsNumber, {
						value: offeringGroup.documents.length
					})}
				</TableCell>
				<TableCell
					className='information-offer-accordion-cell information-offer-accordion-cell-expansion'
					sx={{
						borderBottomRightRadius: 8,
						borderTopRightRadius: 8
					}}
				>
					<IconButton aria-label='expand row' size='small' onClick={toggleOpen}>
						<Icon size={1.5} path={open ? mdiChevronUp : mdiChevronDown} color={'#2E6CD1'} />
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow className={open ? 'is-expansion-visible-information-offer' : ''}>
				<TableCell className='is-expansion-visible-cell' colSpan={6}>
					<Collapse in={open} timeout='auto' unmountOnExit>
						<Box>
							<InformationOfferTable documents={offeringGroup.documents} {...props} />
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ padding: 0, border: 'none' }} colSpan={6}>
					<div className='white-accordion' />
				</TableCell>
			</TableRow>
		</Fragment>
	);
};
