import {AppMainSection, commonButtonMessages, useAppSelector, UserState} from 'azshared';
import React, { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { MenuItem } from '../../components/menu-item/menu-item';
import { MenuItemInterface } from '../../utils/menu-items';
import './menu.scss';

export interface MenuProps {
  menuItems?: MenuItemInterface[];
  selectedSection?: AppMainSection;
  isLoggingOut?:boolean;
  onSelectedSectionChanged?: (section?: AppMainSection) => void;
  onLogout?: () => void;
  children: ReactNode;
}

export const Menu = (props: MenuProps) => {
  const { formatMessage } = useIntl();
  const { selectedSection, onSelectedSectionChanged, menuItems, children, isLoggingOut, onLogout } = props;
  const { isProspect } = useAppSelector<UserState>((state) => state.data.user);

  const handleSelectedSection = useCallback((section?: AppMainSection) => {
    onSelectedSectionChanged && onSelectedSectionChanged(section);
  }, [onSelectedSectionChanged]);

  return (
    <aside className='app-menu'>
      <div className='app-menu-navbar'>
        {children}
        <div className='app-menu-bar'>
          {menuItems?.map(item => (
            isProspect && (item.icon == 'wallet' || item.icon == 'documents') ? null :
            <MenuItem
              key={item.section}
              icon={item.icon}
              label={formatMessage(item.label)}
              isSelected={selectedSection === item.section}
              section={item.section}
              onSelected={handleSelectedSection}
            />
          ))}
        </div>
      </div>
      <div className='app-menu-footer'>
        {onLogout && <MenuItem icon='logout' label={formatMessage(commonButtonMessages.logout)} onSelected={onLogout} isLoading={isLoggingOut}/>}
        <div className='logo-azimut' />
      </div>
    </aside>
  );
};
