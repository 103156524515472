import React from 'react';
import toast from 'react-hot-toast';

import { AppToast, AppToastType } from 'azshared';

import { mdiAlertCircle, mdiClose, mdiInformation, mdiCheckCircle, mdiAlert } from '@mdi/js';

import './app-toast.scss';
import Icon from '@mdi/react';

/** Local class used to store configuration for each alert type */

class ToastLocalConfig {
  icon: string;

  constructor(icon: string) {
    this.icon = icon;
  }
}

const getConfigForType = (type: AppToastType): ToastLocalConfig => {
  switch (type) {
    case 'error':
      return new ToastLocalConfig(mdiAlertCircle);
    case 'info':
      return new ToastLocalConfig(mdiInformation);
    case 'success':
      return new ToastLocalConfig(mdiCheckCircle);
      case 'warning': default:
      return new ToastLocalConfig(mdiAlert);
  }
};

export function showAppToast(props: AppToast) {
  const { type, title, description, close } = props;

  const { icon } = getConfigForType(type);

  toast.remove();
  toast(
    <div className={`toast-content toast ${type}-toast`}>
      <span onClick={close}>
        <Icon className='toast-icon-close' path={mdiClose} />
      </span>
      <div>
        <Icon className='toast-icon' path={icon} />
        <span className='toast-title' dangerouslySetInnerHTML={{__html: title}} />
      </div>
      {description && <span className='toast-description'>{description}</span>}
    </div>,
    {
      className: `toast ${type}-toast`,
      duration: 15000,
      style: {
        background: 'transparent',
        color: 'transparent',
        border: 'none',
        boxShadow: 'none',
        padding: 0,
        maxWidth: 500
      }
    }
  );
}
