import { labelForWalletMacroCategorySummaryType, ProductMacroCategorySummary } from 'azshared';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Amount } from '../../../../../../../azshared/src/lib/model/amount';
import { Loader, LoaderSize } from '../../../../../components/loader/loader';
import { AppSvgIcon } from '../../../../../components/svg-icon/app-svg-icon';
import useElementSize from '../../../../../hooks/use-element-size';
import { ProductMacroCategoryCard } from '../product-macro-category-card/product-macro-category-card';
import { TotalAmountCard } from '../total-amount-card/total-amount-card';
import './my-wallet-summary.scss';

const cardSize = 183;
const cardAmountWidth = 298;
const cardGap = 20;

export interface AppMyWalletSummaryProps {
	totalAmount: Amount;
	flagShowBalance: boolean;
	macroCategorySummary: ProductMacroCategorySummary[];
	isLoading: boolean;
}

const computeVisibleSlides = (width: number, totalSlides: number): number => {
	const carouselWidth = width - cardAmountWidth - cardGap;
	return Math.min(Math.floor(carouselWidth / (cardSize + cardGap)), totalSlides);
};

export const AppMyWalletSummary = (props: AppMyWalletSummaryProps) => {
	const { totalAmount, flagShowBalance, macroCategorySummary, isLoading } = props;
	const { formatMessage } = useIntl();
	const [ carouselRef, { width } ] = useElementSize();
	const visibleSlides = useMemo(() => computeVisibleSlides(width, macroCategorySummary?.length), [
		width,
		macroCategorySummary
	]);

	if (isLoading) {
		return (
			<div className='my-wallet-summary my-wallet-summary-loader'>
				<Loader loadersize={LoaderSize.XL} />
			</div>
		);
	}

	return (
		<div className='my-wallet-summary' ref={carouselRef}>
			<TotalAmountCard total={totalAmount} flagShowBalance={flagShowBalance} />
			<div className='my-wallet-summary-carousel'>
				<CarouselProvider
					naturalSlideWidth={cardSize}
					naturalSlideHeight={cardSize}
					totalSlides={macroCategorySummary?.length}
					visibleSlides={visibleSlides}
					isIntrinsicHeight={true}
					step={1}
					touchEnabled={false}
					dragEnabled={true}
				>
					<Slider>
						{macroCategorySummary?.map((item, index) => (
							<Slide key={item.type} index={index}>
								<ProductMacroCategoryCard
									title={formatMessage(labelForWalletMacroCategorySummaryType(item.type))}
									amount={item.amount}
									gain={item.gain}
									flagShowBalance={flagShowBalance}
								/>
							</Slide>
						))}
					</Slider>
					{ macroCategorySummary?.length > 0 && <div className='my-wallet-summary-arrow-buttons'>
						<ButtonBack>
							<AppSvgIcon icon={'arrow-left'} />
						</ButtonBack>
						<ButtonNext>
							<AppSvgIcon icon={'arrow-right'} />
						</ButtonNext>
					</div> }
				</CarouselProvider>
			</div>
		</div>
	);
};
