import { TableCell } from '@mui/material';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import {
	productPerformanceColumns,
	ProductPerformanceList, walletPagePerformanceSectionMessages
} from 'azshared';
import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Loader, LoaderSize } from '../../../../../components/loader/loader';
import { AppTableCell } from '../../../../../components/table/table-cell';
import { AppTableCellHeader } from '../../../../../components/table/table-cell-header';
import './performance-table.scss';


interface PerformanceTableProps {
	performanceList: ProductPerformanceList;
	isLoading: boolean;
}

export const PerformanceTable = (props: PerformanceTableProps) => {
	const { performanceList, isLoading } = props;

	const { formatMessage } = useIntl();
	const [flagShowDisclaimer, setFlagShowDisclaimer] = useState(false);
	

	useEffect(() => {
		const hasSGRFlag = performanceList?.products.some(product => product.flagSGR) || false;
		setFlagShowDisclaimer(hasSGRFlag);
	}, [performanceList]);

	return (
		<Fragment>
		<TableContainer className='performance-table'>
			<Table aria-label='table'>
				<TableHead>
					<TableRow>
						{productPerformanceColumns.map((column, index) => (
							<AppTableCellHeader key={index} className='performance-table-header'>
								{formatMessage(column.headerLabel)}
							</AppTableCellHeader>
						))}
					</TableRow>
				</TableHead>
				{isLoading && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={productPerformanceColumns.length + 1}>
                  <div className='table-loader'>
                    <Loader loadersize={LoaderSize.XL} />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
				{!isLoading && <TableBody>
					{performanceList?.products.map((product, index) => (
						<TableRow key={index} className='performance-table-row'>
							{productPerformanceColumns.map((col) => (
								<Fragment key={col.key}>
									{col.key === 'description' && <AppTableCell key={col.key} align='left' type={col.type} className='performance-table-cell-description'>
										<p>{product[col.key]} {product.flagSGR && '*'}</p>
									</AppTableCell>}
									{col.key !== 'description' && <AppTableCell key={col.key} align='left' type={col.type}>
										{product[col.key]}
									</AppTableCell>}
								</Fragment>
							))}
						</TableRow>
					))}
				</TableBody>}
				{!isLoading && <TableFooter>
					<TableRow className='performance-table-row-footer'>
					{productPerformanceColumns.map((col) => (
								<Fragment key={col.key}>
									{col.key === 'description' && <AppTableCell key={col.key} align='left' type='string' className='performance-table-footer-total'>
										{formatMessage(walletPagePerformanceSectionMessages.footerTotalLabel)}
									</AppTableCell>}
									{col.key === 'amount' && <AppTableCell key={col.key} align='left' type='amount' className='performance-table-footer-total'>
										{performanceList?.totalEquivalent}
									</AppTableCell>}
									{col.key === 'gain' && <AppTableCell key={col.key} align='left' type='percentage' className='performance-table-footer-total'>
										{performanceList?.totalGain}
									</AppTableCell>}
									{col.key !== 'description' && col.key !== 'amount' && col.key !== 'gain' && <AppTableCell key={col.key} align='left' type='string' className='performance-table-footer-total'>
										{''}
									</AppTableCell>}
								</Fragment>
							))}
					</TableRow>
				</TableFooter>}
			</Table>
		</TableContainer>
		{!isLoading && <div className='performance-table-disclaimer'>
			{flagShowDisclaimer && <p className='performance-table-disclaimer-text'>{formatMessage(walletPagePerformanceSectionMessages.bottomDisclaimer)}</p>}
			<p className='performance-table-disclaimer-text'>{formatMessage(walletPagePerformanceSectionMessages.disclaimer)}</p>
		</div>}
		</Fragment>
	);
};
