import React from 'react';
import { BarChart } from '../../../components/barchart/barchart';
import { AppButtonIcon } from '../../../components/button-icon/app-button-icon';
import './pir-box-charts.scss';
import { PirBoxPieChart } from '../components/pir-box-pie-chart/pir-box-pie-chart';
import { VisualizzaGraficoPIRResponse } from 'azshared/build/main/lib/api/generated/anagrafica';

export interface  PirBoxChartsProps {
  dataSource: VisualizzaGraficoPIRResponse;
  onPrevious: () => void;
}

export const PirBoxCharts = (props: PirBoxChartsProps) => {
  const {dataSource, onPrevious} = props;
  
  return (
    <div className='pirbox-charts'>
      <BarChart dataSource={dataSource}/>
      <div className='pirbox-pie'>
        <PirBoxPieChart dataSource={dataSource} />
      </div>
      <div>
        <AppButtonIcon className='pirbox-button-previous' icon='home-arrow' label='Indietro' onClick={onPrevious} />
      </div>
    </div>
  );
};
