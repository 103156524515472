import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector} from 'recharts';
import './infographic-pie.scss';
import {useRandomColor} from 'azshared';

interface InfographicItem {
  value: number;
  label?: string;
  color?: string;
  paragraphTitle?: string;
  paragraphText?: string;
}

interface InfographicPieProps {
  pieRadius?: number;
  title?: string;
  subtitle?: string;
  items: InfographicItem[];
  forcedIndex?: number;
  biggerActiveItem?: boolean;
  legend?: boolean;
  legendAlignment?: 'right' | 'left';
}

const pieConfig = {
  activeColor: '#007AFF',
  activeTextColor: '#5C5C5C',
  inactiveColor: '#ADBEC9',
  inactiveTextColor: '#ADBEC9'
};

const defaultColors = ['#2E6CD1', '#ADBEC9', '#14376F', '#DEE7EF', '#579C8D', '#372D76', '#7E2E92'];

function InfographicPie(props: InfographicPieProps) {
  const {
    pieRadius = 80,
    items,
    forcedIndex = 0,
    biggerActiveItem = false,
    legend = false,
    title = '',
    subtitle = '',
    legendAlignment = 'right'
  } = props;

  const [activeIndex] = useState<number>(forcedIndex);
  const [pageWidth, setPageWidth] = useState<number>(0);

  const {generateColor} = useRandomColor();

  const zoomRatio = useMemo(() => {
    if (pageWidth < 768) return 0.75;
    if (pageWidth < 400) return 0.5;
    return 1;
  }, [pageWidth]);

  const xPieTitle = useMemo(() => {
    if (!legend) return '50%';
    if (legendAlignment === 'right') return '38.5%';
    if (biggerActiveItem) return '62%';
    return '62%';
  }, [legend, legendAlignment, biggerActiveItem]);


  const normalShape = useCallback((props) => {
    const RADIAN = Math.PI / 180;
    const {cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, value} = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 2) * cos;
    const sy = cy + (outerRadius + 2) * sin;
    const mx = cx + (outerRadius + 24) * cos;
    const my = cy + (outerRadius + 24) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 16;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g className='activeSection'>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex + (cos >= 0 ? 1 : -1) * 80},${ey}`} stroke={fill} fill='none'/>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fontSize={14 * zoomRatio}
          fontWeight={600}
          fill={pieConfig.activeTextColor}
        >
          {value}%
        </text>
      </g>
    );
  }, [zoomRatio]);
  const biggerShape = useCallback((props) => {
    const RADIAN = Math.PI / 180;
    const {cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, value} = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 8) * cos;
    const sy = cy + (outerRadius + 8) * sin;
    const mx = cx + (outerRadius + 24) * cos;
    const my = cy + (outerRadius + 24) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 16;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g className='activeSection'>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={innerRadius - 5}
          outerRadius={outerRadius + 15}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex + (cos >= 0 ? 1 : -1) * 80},${ey}`} stroke={fill} fill='none'/>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fontSize={14 * zoomRatio}
          fontWeight={600}
          fill={pieConfig.activeTextColor}
        >
          {value}%
        </text>
      </g>
    );
  }, [zoomRatio]);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setPageWidth(window.innerWidth);
    }

    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  const getColor = useCallback((index: number) => {
    if (index > defaultColors.length-1) {
      return generateColor();
    }
    return defaultColors[index];
  }, [generateColor]);

  const usedColors = useMemo(() => {
    return items.map((item, index) => item.color || getColor(index));
  }, [getColor, items]);

  return (
    <div className='infographic-pie'>
      <ResponsiveContainer height={350} width={600}>
        <PieChart width={200} height={200}>
          {legend && <Legend layout='vertical' verticalAlign='top' align={legendAlignment} wrapperStyle={{width: 180, whiteSpace: 'break-spaces', marginTop: 20}}/>}
          <Pie
            activeIndex={activeIndex}
            activeShape={biggerActiveItem ? biggerShape : normalShape}
            // inactiveShape={normalShape}
            data={items}
            dataKey='value'
            nameKey='label'
            cx='50%'
            cy='50%'
            innerRadius={(pieRadius - 20) * zoomRatio}
            outerRadius={(pieRadius + 40) * zoomRatio}
          >
            {items.map((item, index) => (
              <Cell key={`cell-${index}`} fill={usedColors[index]}/>
            ))}
          </Pie>
          <g id='pieTitle'>
            <text
              className='pie-title'
              x={xPieTitle}
              y={`${50 - (subtitle ? 5.5 : 2.3) * 2 * zoomRatio}%`}
              textAnchor='middle'
              dy={`${1.2}em`}
              key={'title'}
              fill='#434548'
              fontSize={16 * zoomRatio}
              fontWeight={500}
              fontFamily='var(--font-outfit)'
            >
              {title}
            </text>
            <text
              className='pie-title'
              x={xPieTitle}
              y={`${50 - 0.5 * 2 * zoomRatio}%`}
              textAnchor='middle'
              dy={`${1.2}em`}
              key={'subtitle'}
              fill='#434548'
              fontSize={12 * zoomRatio}
              fontWeight={500}
              fontFamily='var(--font-outfit)'
            >
              {subtitle}
            </text>
          </g>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default InfographicPie;
