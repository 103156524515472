import { appRoutes, commonButtonMessages, informativeUserRegistrationMessages, privacyAgreementOptionsValues, useInformativePageLogic } from 'azshared';
import React, { FormEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType } from '../../../../components/buttons/app-button';
import { Card, CardSize } from '../../../../components/card/card';
import { AppLink } from '../../../../components/labels/app-link';
import { AppRadioButtonProps } from '../../../../components/radio/app-radio-button';
import { AppRadioGroup } from '../../../../components/radio/app-radio-group';
import { useGlobalActions, useGoToLoginFromRegistration } from '../../../../hooks/global-actions';
import { LoginContainer } from '../../login-container/login-container';
import './informative-user-registration-page.scss';
import { TextInformative } from './text-informative';

export const InformativeUserRegistrationPage = () => {
  const { formatMessage } = useIntl();
  const goToLoginFromRegistration = useGoToLoginFromRegistration();

  const { privacyAgreementRequestStatus, selectedOption, setSelectedOption, handleSubmit } = useInformativePageLogic(useGlobalActions());

  const informativeOptions: AppRadioButtonProps[] = [
    {
      value: privacyAgreementOptionsValues.agree,
      label: formatMessage(informativeUserRegistrationMessages.labelAgreeRadioButton)
    },
    {
      value: privacyAgreementOptionsValues.disagree,
      label: formatMessage(informativeUserRegistrationMessages.labelDisagreeRadioButton)
    }
  ];

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );

  return (
    <div>
      <LoginContainer>
        <div className='informative-user-registration'>
          <Card
            title={formatMessage(informativeUserRegistrationMessages.pageTitle)}
            subtitle={formatMessage(informativeUserRegistrationMessages.pageSubtitle)}
            back={formatMessage(commonButtonMessages.backLink)}
            sizeVariant={CardSize.MEDIUM}
          >
            <TextInformative />
            <div className='disclaimer'>{formatMessage(informativeUserRegistrationMessages.disclaimerInformativeUserRegistration)}</div>
            <form onSubmit={onSubmit}>
              <div className='radio-button'>
                <AppRadioGroup options={informativeOptions} selectedValue={selectedOption} onSelectedValueChanged={setSelectedOption} horizontal={true} />
              </div>
              <div className='button'>
                <AppButton
                  label={formatMessage(informativeUserRegistrationMessages.buttonInformativeUserRegistration)}
                  type={AppButtonType.SUBMIT}
                  loading={privacyAgreementRequestStatus === 'loading'}
                />
              </div>
            </form>
            <div className='disclaimer-link-to-login'>
              {formatMessage(commonButtonMessages.linkBackToLogin)}
              <AppLink to={appRoutes.Login} replace={true} label={formatMessage(commonButtonMessages.linkToLogin)} onClick={goToLoginFromRegistration} />
            </div>
          </Card>
        </div>
      </LoginContainer>
    </div>
  );
};
