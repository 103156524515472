import { SignatureDocument } from 'azshared';
import React, { Fragment, useCallback, useState } from 'react';
import { SignatureAreaRtoChecks } from './signature-area-rto-checks';
import { SignatureAreaRtoDisclaimer } from './signature-area-rto-disclaimer';
import './signature-area-rto-document-content.scss';

export interface SignatureAreaRtoDocumentContentProps {
  isLoadingRejectProposal: boolean;
	isLoadingGetUrlForSignature: boolean;
  document: SignatureDocument; 
  clickGoForward: (documentId: string) => void; 
  clickRejected: (documentId: string) => void;
}

export const SignatureAreaRtoDocumentContent = (props: SignatureAreaRtoDocumentContentProps) => {
  const { document, clickGoForward, clickRejected, isLoadingRejectProposal, isLoadingGetUrlForSignature } = props;
  const [showFirstStep, setShowFirstStep] = useState(true);

  const handelGoNextPage = useCallback(() => {
    setShowFirstStep(false);
  }, [setShowFirstStep]);

  const handelGoBackPage = useCallback(() => {
    setShowFirstStep(true);
  }, [setShowFirstStep]);

  return (
    <Fragment>
      {showFirstStep ? (
        <SignatureAreaRtoDisclaimer document={document} clickGoForward={handelGoNextPage} />
      ) : (
        <SignatureAreaRtoChecks
          document={document}
          clickGoForward={clickGoForward}
          clickGoBack={handelGoBackPage}
          clickRejected={clickRejected}
          isLoadingRejectProposal={isLoadingRejectProposal}
          isLoadingGetUrlForSignature={isLoadingGetUrlForSignature} 
        />
      )}
    </Fragment>
  );
};
