import React, { useCallback } from 'react';
import { mdiEyeOutline } from '@mdi/js';
import { AppButton } from '../../../../components/buttons/app-button';
import { appRoutes, HomeCard, homeNewsCardMessages, News } from 'azshared';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { AppSvgIcon } from '../../../../components/svg-icon/app-svg-icon';

import './home-news-card.scss';
import { Loader, LoaderSize } from '../../../../components/loader/loader';

export const HomeNewsCard = (props: { news: HomeCard<News>}) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { news } = props;

  const goToNews = useCallback(() => {
    navigate(appRoutes.News);
  }, [navigate]);

  return (
    <div className='home-news-card'>
      <div className='home-news-card-header'>
        <AppSvgIcon icon='home-news' />
        <h4> {formatMessage(homeNewsCardMessages.cardTitle)} </h4>
      </div>
      {news.value && news.requestStatus !== 'loading' && (
        <>
          <div className='home-news-card-description'>
            <div>{news.value.title}</div>
            <div>{news.value.description}</div>
          </div>
          <div className='home-news-card-button'>
            <AppButton label={formatMessage(homeNewsCardMessages.buttonLabelNewsCard)} iconLeft={mdiEyeOutline} onClick={goToNews} />
          </div>
        </>
      )}
      {!news.value && news.requestStatus !== 'loading' && (
        <div className='home-news-card-no-documents'>
          <div className='home-news-card-no-documents-description'>{formatMessage(homeNewsCardMessages.descriptionNewsCardNoNews)}</div>
        </div>
      )}
      {news.requestStatus === 'loading' &&  <div className='home-news-card-loader'> <Loader loadersize={LoaderSize.XL}/> </div>}
    </div>
  );
};
