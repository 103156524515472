import React from 'react';
import './app-select.scss';


interface AppSelectErrorMessageProps {
  errorMessage?: string;
}

export const AppSelectErrorMessage = (props: AppSelectErrorMessageProps) => {
  const { errorMessage = '' } = props;


  return (
    <>
      {errorMessage && (
        <div className='message'>
          <span className='error-message'>{errorMessage}</span>
        </div>
      )}
    </>
  );
};
