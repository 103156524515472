import { mdiHelpCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { faqsPageMessages } from 'azshared';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Card, CardSize } from '../../../../../components/card/card';
import { AppSvgIcon } from '../../../../../components/svg-icon/app-svg-icon';
import { faq as faqQuestions } from '../../../../../utils/faq-questions';
import { FaqAccordion } from '../faq-accordion/faq-accordion';

import './faq-card.scss';

export const FaqCard = () => {
	const { formatMessage } = useIntl();
	const [ selectedFaq, setSelectedFaq ] = useState<string>();

	const handleToggleOpen = useCallback(
		(id: string) => {
			setSelectedFaq(selectedFaq === id ? '' : id);
		},
		[ setSelectedFaq, selectedFaq ]
	);

	return (
		<div className='faq-card'>
			<Card sizeVariant={CardSize.MAXI}>
				<div className='faq-card-disclaimer'>
					<Icon
						path={mdiHelpCircleOutline}
						size={0.8}
						color={'#C4C4C4'}
						className='faq-card-disclaimer-icon'
					/>
					{formatMessage(faqsPageMessages.subtitle)}
				</div>
				<div className='faq-question'>
					{faqQuestions.map(faq => (
						<FaqAccordion
							faq={faq}
							key={faq.question.id}
							id={faq.question.id || ''}
							onToggleOpen={handleToggleOpen}
							selectedFaq={selectedFaq  || ''}
						/>
					))}
				</div>
				<a className='download-user-manual' href={`${process.env.PUBLIC_URL}/static/documents/guida-app-azimut-home.pdf`} download={'Guida APP Azimut Home.pdf'}>
					<AppSvgIcon icon={'user-manual'} />
					<span>{formatMessage(faqsPageMessages.userManual)}</span>
				</a>
			</Card>
		</div>
	);
};
