import { appRoutes, commonButtonMessages, firstAccessMessages, useFirstAccess } from 'azshared';
import React, { FormEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType } from '../../../components/buttons/app-button';
import { Card } from '../../../components/card/card';
import { AppSuccessDialog } from '../../../components/dialog/app-success-dialog';
import { AppInputPassword } from '../../../components/input-text/app-input-password';
import { AppLink } from '../../../components/labels/app-link';
import { AppRequiredNote } from '../../../components/required-note/required-note';
import { useGlobalActions } from '../../../hooks/global-actions';
import { LoginContainer } from '../login-container/login-container';
import './first-access-page.scss';

export const FirstAccessPage = () => {
  const { formatMessage } = useIntl();

  const globalActions = useGlobalActions();

  const {
    oldPassword,
    newPassword,
    confirmNewPassword,
    firstAccessRequestStatus,

    setConfirmNewPassword,
    setNewPassword,
    setOldPassword,
    handleClose,
    handleSubmit
  } = useFirstAccess(globalActions);

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );

  return (
    <LoginContainer>
      <div className='first-access-page'>
        <Card title={formatMessage(firstAccessMessages.pageTitle)} subtitle={formatMessage(firstAccessMessages.pageSubtitle)}>
          <form onSubmit={onSubmit} className='form-first-access-page'>
            <div>
              <AppInputPassword
                placeholder={formatMessage(firstAccessMessages.oldPasswordLabel)}
                label={formatMessage(firstAccessMessages.oldPasswordPlaceholder)}
                required
                value={oldPassword}
                onChange={setOldPassword}
              />
              <AppInputPassword
                placeholder={formatMessage(firstAccessMessages.newPasswordLabel)}
                label={formatMessage(firstAccessMessages.newPasswordPlaceholder)}
                required
                value={newPassword}
                onChange={setNewPassword}
              />
              <AppInputPassword
                placeholder={formatMessage(firstAccessMessages.confirmPasswordLabel)}
                label={formatMessage(firstAccessMessages.confirmPasswordPlaceholder)}
                required
                value={confirmNewPassword}
                onChange={setConfirmNewPassword}
              />
            </div>
            <AppRequiredNote />

            <div className='button'>
              <AppButton
                type={AppButtonType.SUBMIT}
                label={formatMessage(firstAccessMessages.firstAccessButton)}
                loading={firstAccessRequestStatus === 'loading'}
              />
            </div>
          </form>
          <span className='disclaimer'>
            {formatMessage(firstAccessMessages.disclaimer)} <AppLink to={appRoutes.Login} label={formatMessage(commonButtonMessages.linkToLogin)} />
          </span>
        </Card>
      </div>
      <AppSuccessDialog open={firstAccessRequestStatus === 'succeeded'} onClose={handleClose}>
        <div>{formatMessage(firstAccessMessages.firstAccessCompletedAlertMessage)}</div>
      </AppSuccessDialog>
    </LoginContainer>
  );
};
