import { appRoutes, commonButtonMessages, signatureAreaUserRegistrationMessages, useUserRegistrationSignatureAreaLogic } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import { Card, CardSize } from '../../../../components/card/card';
import { AppLink } from '../../../../components/labels/app-link';
import { useGlobalActions, useGoToLoginFromRegistration } from '../../../../hooks/global-actions';
import { LoginContainer } from '../../login-container/login-container';
import { SignatureAreaDocument } from './components/document/signature-area-document';
import './signature-area-page.scss';

export const SignatureAreaUserRegistrationPage = () => {
  const { formatMessage } = useIntl();
  const goToLoginFromRegistration = useGoToLoginFromRegistration();

  const { onAheadButtonPress, handleGoBack, document, isLoading } = useUserRegistrationSignatureAreaLogic(useGlobalActions());

  return (
    <div>
      <LoginContainer>
        <div className='signature-area-user-registration-page'>
          <Card
            title={formatMessage(signatureAreaUserRegistrationMessages.pageTitle)}
            subtitle={formatMessage(signatureAreaUserRegistrationMessages.pageSubtitle)}
            back={formatMessage(commonButtonMessages.backLink)}
            sizeVariant={CardSize.MEDIUM}
            clickGoBack={handleGoBack}
          >
            {<SignatureAreaDocument document={document} clickGoForward={onAheadButtonPress} isLoading={isLoading} />}
            <div className='disclaimer'>
              {formatMessage(commonButtonMessages.linkBackToLogin)}
              <AppLink to={appRoutes.Login} replace={false} label={formatMessage(commonButtonMessages.linkToLogin)} onClick={goToLoginFromRegistration} />
            </div>
          </Card>
        </div>
      </LoginContainer>
    </div>
  );
};
