import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { AZIMUT_URL, currencyFormatter, signatureAreaMessages, SignatureDocument, SignatureDocumentRtoImiModule } from 'azshared';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppButton } from '../../../../../components/buttons/app-button';
import { AppCheckbox } from '../../../../../components/checkbox/app-checkbox';
import { SignatureAreaLink } from '../signature-area-link/signature-area-link';
import './signature-area-rto-document-content.scss';

export const SignatureAreaRtoDisclaimer = (props: { document: SignatureDocument; clickGoForward: () => void }) => {
  const { document, clickGoForward } = props;
  const { formatMessage } = useIntl();
  const [checked, setChecked] = useState(false);
  const handleChange = useCallback(
    (value: boolean) => {
      setChecked(value);
    },
    [setChecked]
  );

  const rtoModule = document?.modules?.[0] as SignatureDocumentRtoImiModule;

  return (
    <div className='signature-area-rto-disclaimer'>
      <div className='signature-area-rto-disclaimer-header'>{formatMessage(signatureAreaMessages.basicConsultingDisclaimer)}</div>

      <TableContainer className='signature-area-rto-disclaimer-table'>
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow>
              <TableCell className='signature-area-rto-disclaimer-table-header'>{formatMessage(signatureAreaMessages.titleLinkLabel)}</TableCell>
              <TableCell className='signature-area-rto-disclaimer-table-header' align='left'>
                {formatMessage(signatureAreaMessages.documentLabel)}
              </TableCell>
              <TableCell className='signature-area-rto-disclaimer-table-header' align='left'>
                {formatMessage(signatureAreaMessages.amountLabel)}
              </TableCell>
              <TableCell className='signature-area-rto-disclaimer-table-header' colSpan={2} />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                className='signature-area-rto-disclaimer-table-row'
                component='th'
                scope='row'
                sx={{
                  borderBottomLeftRadius: 8,
                  borderTopLeftRadius: 8
                }}
              >
              <SignatureAreaLink module={rtoModule}/>
              </TableCell>
              <TableCell className='signature-area-rto-disclaimer-table-row' align='left'>
                {formatMessage(signatureAreaMessages.practiceTitle, {practiceNumber: document?.practiceNumber})}
              </TableCell>
              <TableCell className='signature-area-rto-disclaimer-table-row' align='left'>
                {currencyFormatter(rtoModule.price?.value, {currencyDisplay: 'code', currency: rtoModule.price?.currency })}
              </TableCell>
              <TableCell className='signature-area-rto-disclaimer-table-row' align='left'>
                <div className='signature-area-rto-disclaimer-buttons'>
                  <AppCheckbox
                    label={formatMessage(signatureAreaMessages.acknowledgmentLabel)}
                    checked={!!document.nextRequiredSignature || checked}
                    onChange={handleChange}
                    disabled={!!document.nextRequiredSignature}
                  />

                  <AppButton
                    onClick={clickGoForward}
                    label={formatMessage(document.nextRequiredSignature ? signatureAreaMessages.signedLabel : signatureAreaMessages.forwardLabel)}
                    disabled={!!document.nextRequiredSignature || !checked}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div className='signature-area-rto-disclaimer-footer'>
        {formatMessage(signatureAreaMessages.textFooterDisclaimer)}
        <a href={AZIMUT_URL} target='_blank' rel='noreferrer'>
          {formatMessage(signatureAreaMessages.linkFooterDisclaimer)}{' '}
        </a>
      </div>
    </div>
  );
};
