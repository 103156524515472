import { commonButtonMessages, DownloaderMediaType } from 'azshared';
import React, { MouseEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppButtonIcon } from '../button-icon/app-button-icon';
import './download-file.scss';

export interface AppDownloadFileProps {
	mediaType: DownloaderMediaType;
	className?: string;
	onClick: (mediaType: DownloaderMediaType) => void;
}

export const AppDownloadFile = (props: AppDownloadFileProps) => {
	const { formatMessage } = useIntl();

	const handleClick = useCallback(
		(e: MouseEvent<HTMLButtonElement | HTMLLabelElement | SVGSVGElement>) => {
			e.preventDefault();
			props.onClick(props.mediaType);
		},
		[ props ]
	);

	return (
		<AppButtonIcon
			className={`app-download-file ${props.className || ''}`}
			icon='download'
			label={formatMessage(
				props.mediaType === 'pdf' ? commonButtonMessages.downloadPdf : commonButtonMessages.downloadXls
			)}
			onClick={handleClick}
		/>
	);
};
