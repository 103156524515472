import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import { amountFormatter, managedColumns, ManagedProductCategory, usePirBoxPageLogic, walletFinancialPositionsMessages } from 'azshared';
import React, { Fragment, MouseEvent, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AppTableCell } from '../../../../../components/table/table-cell';
import { AppTableCellHeader } from '../../../../../components/table/table-cell-header';
import './managed-table.scss';

interface ManagedTableProps {
  productCategory: ManagedProductCategory;
  onSelectedProduct: (e: MouseEvent<HTMLSpanElement>) => void;
}

export const ManagedTable = (props: ManagedTableProps) => {
  const { productCategory, onSelectedProduct } = props;
  const { positions, mandateCodes} = usePirBoxPageLogic();

  const { formatMessage } = useIntl();
  const flagShowDisclaimer = useMemo(
    () => productCategory?.macroProducts.flatMap((macroProduct) => macroProduct.products).some((product) => product.flagSGR),
    [productCategory]
  );

  let contractIsPir = false;

 
  const pirDeposit = mandateCodes.map((value) => positions.get(value));
  const pirPositions = pirDeposit.forEach(pir => {
    return pir?.positions.forEach(position => {
      let codMandato = '';
      codMandato = position.codMandato;
      
      return productCategory.macroProducts.forEach(macro => {
        return macro.products.filter(product => {
           return contractIsPir = product.contract === codMandato;
        });
      });
    });
  });

  console.log(pirPositions);


  return (
    <Fragment>
      <TableContainer className='managed-table'>
        <Table aria-label='table'>
          <TableHead className='managed-table-head'>
            <TableRow>
              {managedColumns.map((column, index) => (
                <AppTableCellHeader key={index} className={`managed-table-cell-header-${column.key}`}>
                  {formatMessage(column.headerLabel)}
                </AppTableCellHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className='managed-table-body'>
            {productCategory.macroProducts.map((macroProduct) => (
              <Fragment key={macroProduct.name}>
                <TableRow className='managed-table-row managed-table-row-group-name'>
                  <AppTableCell align='left' type={'string'}>
                    {macroProduct.name}
                  </AppTableCell>
                  <AppTableCell type={'string'} colSpan={managedColumns.length - 1} />
                </TableRow>
                {macroProduct.products.map((product, index) => (
                  <TableRow key={index} className='managed-table-row'>
                    {managedColumns.map((col) => (
                      <Fragment key={col.key}>
                        {col.key === 'productName' && (
                          <AppTableCell key={col.key} align='left' type={col.type}>
                            <span id={product.isin} className='managed-table-row-cell-product-name' onClick={onSelectedProduct}>
                              {product[col.key]} {product.flagSGR && '*'} {contractIsPir && '*'}
                            </span>
                          </AppTableCell>
                        )}
                        {col.key !== 'productName' && (
                          <AppTableCell key={col.key} align='left' type={col.type}>
                            {product[col.key]}
                          </AppTableCell>
                        )}
                      </Fragment>
                    ))}
                  </TableRow>
                ))}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className='managed-table-footer'>
        <p className='managed-table-footer-total-amount'>
          <span>{formatMessage(walletFinancialPositionsMessages.footerTotalLabel)}</span>
          {amountFormatter(productCategory?.totalAmount, { currencyDisplay: 'code' })}
        </p>
        {flagShowDisclaimer && (
          <p className='managed-table-footer-disclaimer'>{formatMessage(walletFinancialPositionsMessages.managedCategoryFooterDisclaimer)}</p>
        )}
      </div>
    </Fragment>
  );
};
