import { commonFormMessages } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import './required-note.scss';

export const AppRequiredNote = () => {
  const { formatMessage } = useIntl();

  return <p className='required-note'>{formatMessage(commonFormMessages.requiredMessage)}</p>;
};
