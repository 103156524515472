import { referralCodeMessages, useReferralCodeLogic } from 'azshared';
import React, { FormEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType } from '../../../components/buttons/app-button';
import { Card } from '../../../components/card/card';
import { AppInputText } from '../../../components/input-text/app-input-text';
import { LoginContainer } from '../../user-access/login-container/login-container';
import './referral-code-page.scss';
import { useGlobalActions } from '../../../hooks/global-actions';

export const ReferralCodePage = () => {
  const { formatMessage } = useIntl();

  const { referralCode, setReferralCode, handleSubmit, checkReferralCodeRequestStatus } = useReferralCodeLogic(useGlobalActions());

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );

  const onCopy = useCallback((e: FormEvent) => {
    e.preventDefault();
  }, []);

  const onPaste = useCallback((e: FormEvent) => {
    e.preventDefault();
  }, []);

  return (
    <div className='referral-code-page'>
      <LoginContainer>
        <Card title={formatMessage(referralCodeMessages.pageTitle)} subtitle={formatMessage(referralCodeMessages.pageSubtitle)}>
          <form onSubmit={onSubmit}>
            <AppInputText
              placeholder={formatMessage(referralCodeMessages.referralCodeInputPlaceholder)}
              value={referralCode}
              label={formatMessage(referralCodeMessages.referralCodeInputLabel)}
              onChange={setReferralCode}
              onCopy={onCopy}
              onPaste={onPaste}
              required={true}
            />
            <AppButton
              type={AppButtonType.SUBMIT}
              label={formatMessage(referralCodeMessages.referralCodeButton)}
              loading={checkReferralCodeRequestStatus === 'loading'}
            />
          </form>
        </Card>
      </LoginContainer>
    </div>
  );
};
