import {
	documentDetailSections,
  DocumentModuleInformation,
  DocumentModuleOperation,
  DocumentModuleSignatory,
  signatureAreaMessages
} from 'azshared';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { AppDialog } from '../../components/dialog/app-dialog';
import { DocumentDetailInfoTable } from './components/document-detail-info-table/document-detail-info-table';
import { DocumentDetailOperationsTable } from './components/document-detail-operations-table/document-detail-operations-table';
import { DocumentDetailSignatoryTable } from './components/document-detail-signatory-table/document-detail-signatory-table';
import { SignatureAreaDetailTabElement } from './components/document-detail-tab/document-detail-tab';
import './document-detail-dialog.scss';

interface DocumentDetailDialogProps {
	documentDetailDialogOpen: boolean;
	closeDocumentDetailDialog: () => void;
	operations?: DocumentModuleOperation[];
	information?: DocumentModuleInformation[];
	signatories?: DocumentModuleSignatory[];
	type: string;
	filename: string;
}

export const DocumentDetailDialog = (props: DocumentDetailDialogProps) => {
	const {
		documentDetailDialogOpen,
		closeDocumentDetailDialog,
		operations,
		information,
		signatories,
		type,
		filename
	} = props;
	const { formatMessage } = useIntl();

	const isSelectedModuleAServiceActivationModule = type === 'ServiceActivationModule';
	const [ selectedDocumentDetailSections, setSelectedDocumentDetailSections ] = useState<documentDetailSections>(
		isSelectedModuleAServiceActivationModule ? 'OPERATIONS' : 'INFORMATION'
	);

	return (
		<AppDialog open={documentDetailDialogOpen} onClose={closeDocumentDetailDialog}>
			<div className='document-detail-dialog'>
				<h2>{filename?.replace(/\.[^.]*$/, '')}</h2>
				<div>
					{isSelectedModuleAServiceActivationModule && (
						<SignatureAreaDetailTabElement
							text={formatMessage(signatureAreaMessages.documentDetailDialogOperationTab)}
							isSelected={selectedDocumentDetailSections === 'OPERATIONS'}
							onTabSelected={setSelectedDocumentDetailSections}
							section={'OPERATIONS'}
						/>
					)}
					<SignatureAreaDetailTabElement
						text={formatMessage(signatureAreaMessages.documentDetailDialogInformationTab)}
						isSelected={selectedDocumentDetailSections === 'INFORMATION'}
						onTabSelected={setSelectedDocumentDetailSections}
						section={'INFORMATION'}
					/>
					<SignatureAreaDetailTabElement
						text={formatMessage(signatureAreaMessages.documentDetailDialogSignatoryTab)}
						isSelected={selectedDocumentDetailSections === 'SIGNATORIES'}
						onTabSelected={setSelectedDocumentDetailSections}
						section={'SIGNATORIES'}
					/>
				</div>

				<div>
					{selectedDocumentDetailSections === 'OPERATIONS' && (
						<DocumentDetailOperationsTable operations={operations} />
					)}
					{selectedDocumentDetailSections === 'INFORMATION' && (
						<DocumentDetailInfoTable information={information} />
					)}
					{selectedDocumentDetailSections === 'SIGNATORIES' && (
						<DocumentDetailSignatoryTable signatories={signatories} />
					)}
				</div>
			</div>
		</AppDialog>
	);
};
