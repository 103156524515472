import React from 'react';
import Select, { ActionMeta, SingleValue, components } from 'react-select';
import { AppSvgIcon } from '../svg-icon/app-svg-icon';

export interface AppSearchableSelectOption {
  value: string;
  label: string;
}

export interface AppSearchableSelectProps {
  options: AppSearchableSelectOption[];
  value?: AppSearchableSelectOption | null;
  placeholder: string;
  onChange: (value: SingleValue<AppSearchableSelectOption>, actionMeta: ActionMeta<AppSearchableSelectOption>) => void;
  valid?: boolean;
  menuMargin?: boolean;
  disabled?: boolean;
}

const customStyles = (error: boolean, menuMargin: boolean) => ({
  input: (provided: any) => ({
    ...provided,
    fontFamily: ['Futura-Book', 'sans-serif'],
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    color: '#8F95B2'
  }),
  option: (provided: any) => ({
    ...provided,
    fontFamily: ['Futura-Book', 'sans-serif'],
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    color: '#8F95B2'
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: '11px'
  }),
  control: (provided: any) => ({
    ...provided,
    border: `1px solid ${error ? '#D14343' : '#D8DAE5'} `,
    borderRadius: '1.4285rem',
    width: '300px'
  }),
  menu: (provided: any) => ({
    ...provided,
    padding: 0,
    marginTop: `${menuMargin ? '-25px' : '5px'}`,
    width: '300px'
  })
});

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <AppSvgIcon icon={'search'} />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator {...props}>
      <svg id={props.id} className='app-searchable-select-icon' />
    </components.ClearIndicator>
  );
};

export const AppSearchableSelect = (props: AppSearchableSelectProps) => {
  const { options, value, placeholder, onChange, valid = true, menuMargin = true } = props;

  return (
    <Select
      components={{ DropdownIndicator, ClearIndicator }}
      styles={customStyles(!valid, menuMargin)}
      options={options}
      value={value}
      placeholder={placeholder}
      isSearchable
      isClearable
      onChange={onChange}
      isDisabled={props.disabled}
      classNamePrefix='app-searchable-select'
    />
  );
};
