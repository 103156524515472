import { Amount, amountFormatter } from 'azshared';
import React from 'react';
import { AppRadialBarChart } from '../radial-bar-chart/radial-bar-chart';
import './product-macro-category-card.scss';

interface ProductMacroCategoryCardProps {
	title: string;
	amount: Amount;
	gain?: number;
	flagShowBalance: boolean;
}

export const ProductMacroCategoryCard = (props: ProductMacroCategoryCardProps) => {
	const { title, amount, gain, flagShowBalance } = props;

	return (
		<div className='app-product-macro-category-card'>
			<div className='app-product-macro-category-card-header'>
				<div className='app-product-macro-category-card-header-title'>{title}</div>
				<div
					className={`app-product-macro-category-card-header-amount ${!flagShowBalance ? 'blurry-text' : ''}`}
				>
					{amountFormatter(amount)}
				</div>
			</div>
			{gain !== undefined && (
				<div className='app-product-macro-category-card-content'>
					<AppRadialBarChart value={gain} />
				</div>
			)}
		</div>
	);
};
