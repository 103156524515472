import { TabItem } from 'azshared';
import React, { MouseEvent, useCallback } from 'react';
import { AppBadge } from '../badge/badge';
import './tab-bar.scss';

export interface AppTabBarProps<T> {
  tabs: TabItem<T>[];
  value: T;
  onTabSelected: (type: T) => void;
}

export const AppTabBar = <T extends string>(props: AppTabBarProps<T>) => {
  const { tabs, onTabSelected, value } = props;

  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      onTabSelected((e.target as HTMLButtonElement).id as T);
    },
    [onTabSelected]
  );

  return (
    <div className='app-tab-bar'>
      {tabs.map(tab => {
        const btn = (
          <button key={tab.type} id={tab.type} className='app-tab-bar-item' aria-selected={tab.type === value} onClick={onClick}>
            {tab.label}
          </button>
        );

        if (tab.count) {
          return (
            <AppBadge key={tab.type} counter={tab.count}>
              {btn}
            </AppBadge>
          );
        }

        return btn;
      })}
    </div>
  );
};
