import { mdiCloseCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { appRoutes, commonButtonMessages, phoneVerificationUserRegistrationMessages, usePhoneNumberVerificationPageLogic } from 'azshared';
import React, { FormEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType } from '../../../../components/buttons/app-button';
import { Card } from '../../../../components/card/card';
import { AppDialog } from '../../../../components/dialog/app-dialog';
import { AppInputText } from '../../../../components/input-text/app-input-text';
import { AppLink } from '../../../../components/labels/app-link';
import { useGlobalActions, useGoToLoginFromRegistration } from '../../../../hooks/global-actions';
import { LoginContainer } from '../../login-container/login-container';
import './phone-number-user-registration-page.scss';

export const PhoneNumberVerificationUserRegistrationPage = () => {
  const { formatMessage } = useIntl();
  const goToLoginFromRegistration = useGoToLoginFromRegistration();

  const {
    buttonDisabled,
    phoneNumber,
    verificationPhoneNumberCode,
    verificationPhoneNumberRequestStatus,
    verificationPhoneNumberError,
    handleClose,
    handleSubmit,
    updateVerificationPhoneNumberCode
  } = usePhoneNumberVerificationPageLogic(useGlobalActions());

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );

  return (
    <div>
      <LoginContainer>
        <div className='phone-number-verification-user-registration-page'>
          <Card
            title={formatMessage(phoneVerificationUserRegistrationMessages.pageTitle)}
            subtitle={formatMessage(phoneVerificationUserRegistrationMessages.pageSubtitle)}
            back={formatMessage(commonButtonMessages.backLink)}
          >
            <div className='phone-number-input-text'>
              <AppInputText
                label={formatMessage(phoneVerificationUserRegistrationMessages.labelInsertPhoneNumberInputTextDisabled)}
                value={phoneNumber}
                disabled
              />
            </div>

            {/* ********** */}
            <div className='description-disclaimer'>{formatMessage(phoneVerificationUserRegistrationMessages.descriptionDisclaimer)}</div>
            <form onSubmit={onSubmit}>
              <AppInputText
                label={formatMessage(phoneVerificationUserRegistrationMessages.labelInsertCodeVerificationInputText)}
                placeholder={formatMessage(phoneVerificationUserRegistrationMessages.placeholderInsertCodeVerificationInputText)}
                value={verificationPhoneNumberCode}
                onChange={updateVerificationPhoneNumberCode}
              />
              <div className='button'>
                <AppButton
                  label={formatMessage(phoneVerificationUserRegistrationMessages.buttonVerificationCodeByMail)}
                  type={AppButtonType.SUBMIT}
                  disabled={buttonDisabled}
                  loading={verificationPhoneNumberRequestStatus === 'loading'}
                />
              </div>
            </form>
            {/* ********** */}

            <div className='disclaimer'>
              {formatMessage(commonButtonMessages.linkBackToLogin)}
              <AppLink to={appRoutes.Login} replace={false} label={formatMessage(commonButtonMessages.linkToLogin)} onClick={goToLoginFromRegistration} />
            </div>
          </Card>
        </div>
        <AppDialog open={verificationPhoneNumberError?.type === 'TROPPI_TENTATIVI'}>
          <div className='dialog-error-phone-number-verification'>
            <Icon path={mdiCloseCircleOutline} size={2} color={'red'} />
            <div className='title-dialog'>{formatMessage(phoneVerificationUserRegistrationMessages.errorTooManyAttemptsTitle)}</div>
            <div className='text-dialog'>{formatMessage(phoneVerificationUserRegistrationMessages.errorTooManyAttemptsMessage)}</div>
            <div className='button-dialog'>
              <AppButton onClick={handleClose} label={formatMessage(phoneVerificationUserRegistrationMessages.errorTooManyAttemptsButtonLabel)} />
            </div>
          </div>
        </AppDialog>
      </LoginContainer>
    </div>
  );
};
