import {
  appRoutes,
  commonButtonMessages,
  insertSecondaryPhoneNumberUserRegistrationMessages,
  useInsertSecondaryPhoneNumberPageLogic
} from 'azshared';
import React, { FormEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType, AppButtonVariant } from '../../../../components/buttons/app-button';
import { Card } from '../../../../components/card/card';
import { AppInputText } from '../../../../components/input-text/app-input-text';
import { AppLink } from '../../../../components/labels/app-link';
import { useGlobalActions, useGoToLoginFromRegistration } from '../../../../hooks/global-actions';
import { LoginContainer } from '../../login-container/login-container';
import './phone-number-user-registration-page.scss';

export const InsertSecondaryPhoneNumberUserRegistrationPage = () => {
  const { formatMessage } = useIntl();
  const goToLoginFromRegistration = useGoToLoginFromRegistration();

  const {
    buttonDisabled,
    phoneNumber,
    insertPhoneNumberRequestStatus,
    handleSubmit,
    updatePhoneNumber,
    handleSkip
  } = useInsertSecondaryPhoneNumberPageLogic(useGlobalActions());

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );

  return (
    <div>
      <LoginContainer>
        <div className='insert-phone-number-user-registration-page'>
          <Card
            title={formatMessage(insertSecondaryPhoneNumberUserRegistrationMessages.pageTitle)}
            subtitle={formatMessage(insertSecondaryPhoneNumberUserRegistrationMessages.pageSubtitle)}
            back={formatMessage(commonButtonMessages.backLink)}
          >
            <form onSubmit={onSubmit}>
              <AppInputText
                label={formatMessage(insertSecondaryPhoneNumberUserRegistrationMessages.labelInsertPhoneNumberInputText)}
                placeholder={formatMessage(insertSecondaryPhoneNumberUserRegistrationMessages.placeholderInsertPhoneNumberInputText)}
                value={phoneNumber}
                onChange={updatePhoneNumber}
              />
              <div className='button'>
                <AppButton
                  label={formatMessage(insertSecondaryPhoneNumberUserRegistrationMessages.buttonSendCodeByPhone)}
                  type={AppButtonType.SUBMIT}
                  disabled={buttonDisabled}
                  loading={insertPhoneNumberRequestStatus === 'loading'}
                />
                <AppButton
                  label={formatMessage(insertSecondaryPhoneNumberUserRegistrationMessages.buttonSkip)}
                  variant={AppButtonVariant.Secondary}
                  disabled={insertPhoneNumberRequestStatus === 'loading'}
                  onClick={handleSkip}
                />
              </div>
            </form>
            <div className='description-disclaimer insert-phone-number'>
              {formatMessage(insertSecondaryPhoneNumberUserRegistrationMessages.descriptionDisclaimerInsertPhoneNumber)}
            </div>
            <div className='disclaimer'>
              {formatMessage(commonButtonMessages.linkBackToLogin)}
              <AppLink to={appRoutes.Login} replace={false} label={formatMessage(commonButtonMessages.linkToLogin)} onClick={goToLoginFromRegistration} />
            </div>
          </Card>
        </div>
      </LoginContainer>
    </div>
  );
};
