import {labelForWalletMacroCategorySummaryType, walletErDetailPageMessages} from 'azshared';
import {Loader, LoaderSize} from '../../../../../components/loader/loader';
import InfographicPie from '../../../../../components/infographic-pie/infographic-pie';
import React from 'react';
import {useIntl} from 'react-intl';
import {PortfolioSummary} from 'azshared/build/main/lib/model/wallet/portfolio-summary';
import './portfolio-summary.scss';

interface Props {
  loading: boolean;
  summary: PortfolioSummary;
}

export const PortfolioSummaryChart = (props: Props) => {
  const {formatMessage} = useIntl();
  const {loading, summary} = props;

  return <div className='portfolio-summary'>
    <h4>{formatMessage(walletErDetailPageMessages.portfolioSummaryTitle)}</h4>
    {!loading && <><p>
      <b>{summary?.macrocategory?.find(m => m.type === 'RealEconomy')?.quote || 0}{'%'}</b> {formatMessage(walletErDetailPageMessages.portfolioSummaryLabel)}
    </p></>}
    <div className={'portfolio-container' + (loading ? '-loader' : '')}>
      {loading && <Loader loadersize={LoaderSize.XL}/>}
      {!loading &&
        <InfographicPie
          items={summary?.macrocategory?.map(m => {
            return {value: m.quote, label: formatMessage(labelForWalletMacroCategorySummaryType(m.type))};
          })}
          biggerActiveItem
          forcedIndex={summary?.macrocategory?.findIndex(m => m.type === 'RealEconomy')}
          title={formatMessage(walletErDetailPageMessages.portfoglioSummaryTotal)}
          subtitle={'' + summary.totalAmount}
          legend
          legendAlignment={'left'}
        />
      }
    </div>
  </div>;
};