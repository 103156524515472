import React from 'react';
import './percentage-bar.scss';

export interface PercentageBarProps {
  percentage: number;
  title: string;
  xLegend?: string;
  yLegend?: string;
  start?: number;
  end?: number;
  step?: number;
  disabled?: boolean;
}

export const PercentageBar = (props: PercentageBarProps) => {
  const {xLegend, yLegend, disabled = false, percentage, title, start = 0, end = 100, step = 100} = props;
  const width = `${percentage}%`;

  const percentageBarProgress: number[] = [];
  for (let i = start; i <= end; i += step) percentageBarProgress.push(i);
  
  return (
    <div className='percentage-bar-container'>
      <div className='title'>
        {title}
      </div>
      <div className='percentage-container'>
        {yLegend && <div className='percentage-bar-yLegend'>{yLegend}</div>}
        <div className='percentage-bar__outer'>
          <div className={'percentage-bar__inner' + (disabled ? '__disabled' : '')} style={{width}}/>
        </div>
      </div>
      <div className={'percentage-bar-scale' + (yLegend ? '__legend' : '')}>
        {percentageBarProgress.map((n, i) => <div className='percentage-bar-number'
                                                  key={'percentage-bar-number_' + i}>{n}</div>)}
      </div>
      {xLegend && <div className={'percentage-bar-xLegend' + (yLegend ? '__legend' : '')}>{xLegend}</div>}
    </div>
  );
};
