import {commonButtonMessages, startWebCollaborationDialogMessages} from 'azshared';
import React from 'react';
import {useIntl} from 'react-intl';
import {AppButton, AppButtonType, AppButtonVariant} from '../../../../../components/buttons/app-button';
import {AppDialog} from '../../../../../components/dialog/app-dialog';
import './start-web-collaboration-dialog.scss';

interface StartWebCollaborationDialogProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
}

export const StartWebCollaborationDialog = (props: StartWebCollaborationDialogProps) => {
  const {formatMessage} = useIntl();
  return (
    <AppDialog open={props.open} onClose={props.onClose}>
      <div className='start-web-collaboration-dialog'>
        <div className='start-web-collaboration-dialog-section'>
          <h3
            className='start-web-collaboration-dialog-title'>{formatMessage(startWebCollaborationDialogMessages.title)}</h3>
          <p
            className='start-web-collaboration-dialog-content'>{formatMessage(startWebCollaborationDialogMessages.content)}</p>
        </div>
        <div className='start-web-collaboration-dialog-button-section'>
          <AppButton
            type={AppButtonType.BUTTON}
            variant={AppButtonVariant.Secondary}
            label={formatMessage(commonButtonMessages.cancel)}
            onClick={props.onClose}
          />
          <AppButton
            type={AppButtonType.BUTTON}
            variant={AppButtonVariant.Primary}
            label={formatMessage(startWebCollaborationDialogMessages.continuedButton)}
            onClick={props.onContinue}
          />
        </div>
      </div>
    </AppDialog>
  );
};