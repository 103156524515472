import { mdiChevronUp, mdiChevronDown } from '@mdi/js';
import { Collapse, IconButton } from '@mui/material';
import Icon from '@mdi/react';
import React, { useCallback, useState } from 'react';
import { Fragment } from 'react';

export const PirBoxAccordion = (props: any) => {
    const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(() => setOpen(!open), [open]);
    return(
        <Fragment>
            <div className='pirbox-item-accordion'>
        {props.title}
        <IconButton aria-label='expand row' size='small' onClick={toggleOpen}>
          <Icon size={1.5} path={open ? mdiChevronUp : mdiChevronDown} color={'#2E6CD1'} />
        </IconButton>
      </div>
      <div className={open ? 'is-expansion-visible' : ''}>
        <Collapse in={open} timeout='auto' unmountOnExit className='pirbox-item-accordion-box'>
          {props.children}
        </Collapse>
      </div>
        </Fragment>
    );
};