import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import * as React from 'react';

export const AppTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip placement='top' {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: '#2D3748',
		boxShadow: theme.shadows[1],
		fontSize: 12,
		fontFamily: 'Futura-Book'
	}
}));
