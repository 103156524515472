import { CommunicationsDocument, DocumentsAreaSections } from 'azshared';
import React, { Fragment } from 'react';
import { CommunicationsFilter } from '../communication-filter/communications-filter';
import { CommunicationsCard } from '../communications-card/communications-card';
import './communications-section.scss';

interface CommunicationSectionProps {
  onReadDocument: (document: CommunicationsDocument, documentsAreaSection: DocumentsAreaSections) => void;
  onDownloadDocument: (document: CommunicationsDocument, documentsAreaSection: DocumentsAreaSections) => void;
}

export const CommunicationsSection = (props: CommunicationSectionProps) => {
	return (
		<div className='communications-section'>
			<CommunicationsFilter />
			<div className='communications-section-cards'>
				<Fragment>
					<CommunicationsCard {...props}/>
				</Fragment>
			</div>
		</div>
	);
};
