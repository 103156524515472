import { commonButtonMessages, commonFormMessages, getToday, RangeDate } from 'azshared';
import React, { ChangeEvent, MouseEvent } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useIntl } from 'react-intl';
import { AppButton } from '../buttons/app-button';
import { AppDateInput } from '../date-input/date-input';

import './app-date-dropdown.scss';

export type DropdownDirectionType = 'horizontal' | 'vertical';

export interface AppDateDropdownProps {
  value: RangeDate;
  onDateChange: (values: Date | [Date, Date], e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: MouseEvent<HTMLButtonElement>) => void;
  enableApplyButton: boolean;
  arrangement?: DropdownDirectionType;
}

 const AppDateDropdown = React.forwardRef<HTMLDivElement, AppDateDropdownProps>((props: AppDateDropdownProps, ref) => {
  const { value, onDateChange, onSubmit, enableApplyButton, arrangement = 'horizontal' } = props;

  const { formatMessage } = useIntl();

  return (
    <div ref={ref} className={` ${arrangement === 'vertical' ? 'app-date-filter-specific-date-vertical' : 'app-date-filter-specific-date'}`}>
      <div className='app-date-filter-date'>
        <AppDateInput label={formatMessage(commonFormMessages.labelFrom)} value={value?.from} disabled={true} />
        <AppDateInput label={formatMessage(commonFormMessages.labelTo)} value={value?.to} disabled={true} />
      </div>
      <Calendar
        value={value && value.from && value.to ? [value.from, value.to] : undefined}
        onChange={onDateChange}
        returnValue={'range'}
        selectRange={true}
        className='app-date-filter-calendar'
        tileClassName='app-date-filter-calendar-tile'
        minDetail='month'
        allowPartialRange={true}
        maxDate={getToday()}
      />
      <div className='app-date-filter-date-apply-button'>
        <AppButton disabled={!enableApplyButton} label={formatMessage(commonButtonMessages.applyButton)} onClick={onSubmit} />
      </div>
    </div>
  );
});

AppDateDropdown.displayName = 'AppDateDropdown';

export default AppDateDropdown;