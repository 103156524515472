import {TableCell} from '@mui/material';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import {ProductConcentration, productErDetailColumns, walletErDetailPageMessages} from 'azshared';
import React, {Fragment} from 'react';
import {useIntl} from 'react-intl';
import {Loader, LoaderSize} from '../../../../../components/loader/loader';
import {AppTableCell} from '../../../../../components/table/table-cell';
import {AppTableCellHeader} from '../../../../../components/table/table-cell-header';
import './er-detail-table.scss';


interface ErDetailTableProps {
  products: ProductConcentration[];
  isLoading: boolean;
}

export const ErDetailTable = (props: ErDetailTableProps) => {
  const {products, isLoading} = props;
  const {formatMessage} = useIntl();


  return (
    <Fragment>
      <TableContainer className='er-detail-table'>
        <Table aria-label='table'>
          <TableHead>
            <TableRow>
              {productErDetailColumns.map((column, index) => (
                <AppTableCellHeader key={index} className='er-detail-table-header'>
                  {formatMessage(column.headerLabel)}
                  {column.key === 'versato' && <span><sup>{'1'}</sup></span>}
                </AppTableCellHeader>
              ))}
            </TableRow>
          </TableHead>
          {isLoading && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={productErDetailColumns.length + 1}>
                  <div className='table-loader'>
                    <Loader loadersize={LoaderSize.XL}/>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!isLoading && <TableBody>
            {products?.map((pc, index) => (
              <TableRow key={index} className='er-detail-table-row'>
                {productErDetailColumns.map((col) => (
                  <Fragment key={col.key}>
                    <AppTableCell key={col.key} align='left' type={col.type}>
                      {pc.product[col.key]}
                    </AppTableCell>
                  </Fragment>
                ))}
              </TableRow>
            ))}
          </TableBody>}
        </Table>
      </TableContainer>
      {!isLoading && <div className='er-detail-table-disclaimer'>
        <p
          className='er-detail-table-disclaimer-text'>{formatMessage(walletErDetailPageMessages.versatoDisclaimer)}</p>
      </div>}
    </Fragment>
  );
};
