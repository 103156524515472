import {
  AmlSurveyConsistency,
  UpdateAmlPartialResponseProp,
  appRoutes,
  commonButtonMessages,
  homeOnboardingSelfAmlFatcaCrsSurveyMessages,
  homeOnboardingSelfMifidSurveyMessages,
  resetAmlSurvey,
  useHomeOnboardingSelfAmlFatcaCrsSurveyLogic
} from 'azshared';
import { Domanda } from 'azshared/build/main/lib/api/generated';
import { DominioRequestIdDominioEnum, DominioResponse } from 'azshared/build/main/lib/api/generated/dob';
import React, { RefObject, useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Dependency } from '../../../../../../azshared/src/lib/hooks/home/onboarding-self/fields-validation';
import { AppButton, AppButtonType, AppButtonVariant } from '../../../../components/buttons/app-button';
import { Card, CardSize } from '../../../../components/card/card';
import { AppDialog } from '../../../../components/dialog/app-dialog';
import { AppHeader } from '../../../../components/header/header';
import { Loader, LoaderSize } from '../../../../components/loader/loader';
import { useGlobalActions } from '../../../../hooks/global-actions';
import useEventListener from '../../../../hooks/use-event-listener';
import './aml-fatca-crs-survey-page.scss';
import { AmlAttivitaSectionBody } from './components/aml-section/aml-attivita-section-body';
import { AmlFinancialSectionBody } from './components/aml-section/aml-financial-section-body';
import { AmlPepSectionBody } from './components/aml-section/aml-pep-section-body';
import { AmlTitolareSectionBody } from './components/aml-section/aml-titolare-section-body';
import { ErrorPopupDialog } from './components/error-popup-dialog/error-popup-dialog';
import { FatcaSection } from './components/fatca-section/fatca-section';

export const EXCLUDED_CLASS_FACTA = ['content', 'app-menu-bar'];
export const EXCLUDED_PREFIX_CLASS_FACTA = [
  'MuiDialog',
  'MuiPaper-root',
  'exit-app-dialog',
  'error-popup-dialog',
  'app-searchable-select',
  'app-dialog',
  'MuiTouchRipple'
];

export interface AmlSectionBodyProps {
  questionnaireSection: Domanda[] | undefined;
  listaDomini?: DominioResponse[] | undefined;
  onSave: (props: UpdateAmlPartialResponseProp[]) => void;
  getAmlAnswerDetailsByQuestionId: (questionCode: string, responseArray?: boolean) => string | string[];
  amlConsistency: AmlSurveyConsistency;
  isEnabled: (dependencies: Dependency[]) => boolean;
  deleteAnswerByQuestionId: (questionCode: string | undefined) => void;
}

export const AmlFatcaCrsSurveyPage = () => {
  const { formatMessage } = useIntl();
  const globalActions = useGlobalActions();
  const refPageAmlFacta: RefObject<HTMLDivElement> = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const {
    isLoadingAMLResponse,
    isResponseAmlError,
    titolareSubSectionQuestions,
    attivitaSubSectionQuestions,
    pepSubSectionQuestions,
    financialSubSectionQuestions,
    datiFatca,
    onDatiFatcaChange,
    getDominioResponse,
    updateAMLPartialResponse,
    getAmlAnswerDetailsByQuestionId,
    datiFatcaDraftConsistency,
    amlConsistency,
    isEnabled,
    deleteAnswerByQuestionId,
    saveQuestionnaire,
    openPopupError,
    popupErrorMessage,
    closePopupError
  } = useHomeOnboardingSelfAmlFatcaCrsSurveyLogic(globalActions);

  const handleClick = useCallback(
    (event: Event) => {
      if (
        refPageAmlFacta.current &&
        !refPageAmlFacta.current.contains(event.target as Node) &&
        !EXCLUDED_CLASS_FACTA.includes((event.target as HTMLElement).classList.value) &&
        !EXCLUDED_PREFIX_CLASS_FACTA.some((prefix) => (event.target as HTMLElement).classList?.value?.includes(prefix))
      ) {
        setIsOpen(true);
      }
    },
    [refPageAmlFacta]
  );

  useEventListener('mousedown', handleClick);

  const handleExit = useCallback(() => {
    dispatch(resetAmlSurvey());
    globalActions.navigate(appRoutes.Dashboard, { replace: true });
  }, [globalActions, dispatch]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <div className='aml-fatca-crs-survey-page'>
      <AppHeader />
      <div ref={refPageAmlFacta}>
        <h2>{formatMessage(homeOnboardingSelfAmlFatcaCrsSurveyMessages.pageTitle)}</h2>
        {!isResponseAmlError && (
          <Card sizeVariant={CardSize.MAXI}>
            {isLoadingAMLResponse && (
              <div className='aml-loader'>
                <Loader loadersize={LoaderSize.XL} />
              </div>
            )}
            {!isLoadingAMLResponse && (
              <div>
                <h3>{formatMessage(homeOnboardingSelfAmlFatcaCrsSurveyMessages.cardTitle)}</h3>
                <AmlTitolareSectionBody
                  amlConsistency={amlConsistency}
                  questionnaireSection={titolareSubSectionQuestions}
                  listaDomini={getDominioResponse?.listaDomini}
                  onSave={updateAMLPartialResponse}
                  getAmlAnswerDetailsByQuestionId={getAmlAnswerDetailsByQuestionId}
                  isEnabled={isEnabled}
                  deleteAnswerByQuestionId={deleteAnswerByQuestionId}
                />
                <AmlAttivitaSectionBody
                  amlConsistency={amlConsistency}
                  questionnaireSection={attivitaSubSectionQuestions}
                  listaDomini={getDominioResponse?.listaDomini}
                  onSave={updateAMLPartialResponse}
                  getAmlAnswerDetailsByQuestionId={getAmlAnswerDetailsByQuestionId}
                  isEnabled={isEnabled}
                  deleteAnswerByQuestionId={deleteAnswerByQuestionId}
                />
                <AmlPepSectionBody
                  amlConsistency={amlConsistency}
                  questionnaireSection={pepSubSectionQuestions}
                  onSave={updateAMLPartialResponse}
                  getAmlAnswerDetailsByQuestionId={getAmlAnswerDetailsByQuestionId}
                  isEnabled={isEnabled}
                  deleteAnswerByQuestionId={deleteAnswerByQuestionId}
                />

                <AmlFinancialSectionBody
                  amlConsistency={amlConsistency}
                  questionnaireSection={financialSubSectionQuestions}
                  onSave={updateAMLPartialResponse}
                  getAmlAnswerDetailsByQuestionId={getAmlAnswerDetailsByQuestionId}
                  isEnabled={isEnabled}
                  deleteAnswerByQuestionId={deleteAnswerByQuestionId}
                />
              </div>
            )}
          </Card>
        )}
        <FatcaSection
          dominioNazioni={getDominioResponse?.listaDomini?.find((d) => d.idDominio === DominioRequestIdDominioEnum.NazioneResidenzaFiscale)?.valoriDominio}
          fatca={datiFatca}
          consistency={datiFatcaDraftConsistency}
          onChange={onDatiFatcaChange}
        />
        <div className='actions'>
          <AppButton
            type={AppButtonType.SUBMIT}
            label={formatMessage(commonButtonMessages.confirm)}
            onClick={saveQuestionnaire}
            loading={isLoadingAMLResponse}
          />
        </div>
        <ErrorPopupDialog open={openPopupError} message={popupErrorMessage} onClose={closePopupError} />
        <AppDialog open={isOpen} onClose={onClose}>
          <div className='exit-app-dialog-section'>
            <div className='exit-app-dialog-section-desc'>{formatMessage(homeOnboardingSelfMifidSurveyMessages.exitDialogDesc)}</div>
            <div className='exit-app-dialog-section-btn'>
              <AppButton
                type={AppButtonType.BUTTON}
                variant={AppButtonVariant.Secondary}
                label={formatMessage(homeOnboardingSelfMifidSurveyMessages.exitDialogExitBtn)}
                onClick={handleExit}
              />
              <AppButton
                type={AppButtonType.BUTTON}
                variant={AppButtonVariant.Primary}
                label={formatMessage(homeOnboardingSelfMifidSurveyMessages.exitDialogBackBtn)}
                onClick={onClose}
              />
            </div>
          </div>
        </AppDialog>
      </div>
    </div>
  );
};
