import { LinearProgress, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

interface AppProgressBarProps {
  value: number;
  color: string;
}

export const AppProgressBar = (props: AppProgressBarProps) => {
  const { value, color } = props;

  const AppLinearProgress = styled(LinearProgress)(() => ({
    [`&.${linearProgressClasses.root}`]: {
      height: 15,
      borderRadius: 10,
      backgroundColor: '#EEF2F6'
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 10,
      backgroundColor: color
    }
  }));

  return <AppLinearProgress value={value} variant='determinate'/>;
};
