import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import {
	dateFormatter,
	DocumentModuleInformation,
	signatureAreaMessages
} from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import './document-detail-info-table.scss';

export const DocumentDetailInfoTable = (props: { information?: DocumentModuleInformation[] }) => {
	const { formatMessage } = useIntl();

	return (
		<TableContainer className='document-detail-info-table'>
			<Table aria-label='collapsible table'>
				<TableHead>
					<TableRow>
						<TableCell className='document-detail-info-table-header'>
							{formatMessage(signatureAreaMessages.documentDetailTableFieldsDocument)}
						</TableCell>
						<TableCell className='document-detail-info-table-header' align='left'>
							{formatMessage(signatureAreaMessages.documentDetailTableFieldsDocumentNumber)}
						</TableCell>
						<TableCell className='document-detail-info-table-header' align='left'>
							{formatMessage(signatureAreaMessages.documentDetailTableFieldsDispositionRTO)}
						</TableCell>
						<TableCell className='document-detail-info-table-header' align='left'>
							{formatMessage(signatureAreaMessages.documentDetailTableFieldsExpiryDate)}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.information?.map((document) => (
						<TableRow key={document.documentNumber} className='document-detail-info-table-row'>
							<TableCell
								className='document-detail-info-table-row-cell'
								component='th'
								scope='row'
								sx={{
									borderBottomLeftRadius: 8,
									borderTopLeftRadius: 8
								}}
							>
								{document.document}
							</TableCell>
							<TableCell className='document-detail-info-table-row-cell' align='left'>
								{document.documentNumber}
							</TableCell>
							<TableCell className='document-detail-info-table-row-cell' align='left'>
								{document.dispositionRTO}
							</TableCell>
							<TableCell
								className='document-detail-info-table-row-cell'
								sx={{
									borderBottomRightRadius: 8,
									borderTopRightRadius: 8
								}}
							>
								{dateFormatter(document.expiryDate)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
