import { UpdatePartialResponseProp, commonButtonMessages, homeOnboardingSelfMifidSurveyMessages, useMifidSurveyLogic } from 'azshared';
import { FormThematicAreaResponse } from 'azshared/src/lib/api/generated/prometeia/api';
import React, { RefObject, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType } from '../../../../components/buttons/app-button';
import { Card, CardSize } from '../../../../components/card/card';
import { AppHeader } from '../../../../components/header/header';
import { useGlobalActions } from '../../../../hooks/global-actions';

import './mifid-survey-page.scss';
import { MifidSection } from './components/mifid-section';

export const EXCLUDED_CLASS_MIFID = ['content', 'app-menu-bar'];
export const EXCLUDED_PREFIX_CLASS_MIFID = [
  'web-collaboration-overlay',
  'wait-for-cf-dialog',
  'web-collaboration-sharing-block-dialog',
  'wait-for-other-user-dialog',
  'web-collaboration-permission-requests-dialog',
  'MuiDialog',
  'MuiPaper-root'
];

export interface MifidSectionBodyProps {
  questionnaire: FormThematicAreaResponse | undefined;
  onSave: (props: UpdatePartialResponseProp[]) => void;
}

export const MifidSurveyPage = () => {
  const { formatMessage } = useIntl();
  const globalActions = useGlobalActions();
  const { template, form, orderSections } = useMifidSurveyLogic(globalActions);
  // const { isLoadingResponse, isResponseError, isSaveMifidLoading, updatePartialResponse, handleSubmit } = useHomeOnboardingSelfMifidSurveyLogic(globalActions);
  // const { surveyTemplate } = useAppSelector((state) => state.features.mifidSurvey as MifidSurveyState);
  // const [isOpen, setIsOpen] = useState(false);
  const refPageMifid: RefObject<HTMLDivElement> = useRef(null);
  // const refInput: RefObject<HTMLDivElement> = useRef(null);
  // const navigate = useNavigate();
  // const { roomId, closeCall, screenSharingActive } = useWebCollaborationLogic(globalActions);
  // const dispatch = useDispatch();

  // const handleClick = useCallback(
  //   (event: Event) => {
  //     if (
  //       refPageMifid.current &&
  //       !refPageMifid.current.contains(event.target as Node) &&
  //       !refInput?.current?.contains(event.target as Node) &&
  //       !EXCLUDED_CLASS_MIFID.includes((event.target as HTMLElement).classList.value) &&
  //       !EXCLUDED_PREFIX_CLASS_MIFID.some((prefix) => (event.target as HTMLElement).classList?.value?.includes(prefix))
  //     ) {
  //       setIsOpen(true);
  //     }
  //   },
  //   [refPageMifid, refInput]
  // );

  // useEventListener('mousedown', handleClick);

  // const handleExit = useCallback(() => {
  //   if (roomId) {
  //     closeCall();
  //   } else {
  //     navigate(appRoutes.Dashboard, { replace: true });
  //   }
  // }, [navigate, closeCall, roomId]);

  // const onClose = useCallback(() => {
  //   setIsOpen(false);
  // }, [setIsOpen]);

  // useEffect(() => {
  //   dispatch(webCollaborationSliceActions.setIsProspect(true));
  // }, [dispatch]);

  const handleSave = useCallback(()=>{return;}, []);

  return (
    <div className='mifid-survey-page'>
      <AppHeader />
      <div className='mifid-survey-page-content' ref={refPageMifid}>
        <h2>{formatMessage(homeOnboardingSelfMifidSurveyMessages.cardTitle)}</h2>
        {template && (
          <>
            <Card sizeVariant={CardSize.MAXI}>
              {template.sezioni
                ?.filter((s) => !s.idSezione?.startsWith('HEADER'))
                ?.sort(orderSections)
                ?.map((section) => (
                  <MifidSection key={section.idSezione} section={section} form={form} />
                ))}
            </Card>
            <div className='actions'>
              <AppButton
                loading={false}
                className={'mifid-survey-page-confirm-button'}
                type={AppButtonType.SUBMIT}
                label={formatMessage(commonButtonMessages.confirm)}
                onClick={handleSave}
                // disabled={!!roomId && !screenSharingActive}
              />
            </div>
          </>
        )}
        {/* <AppDialog open={isOpen} onClose={onClose}>
          <div className='exit-app-dialog-section'>
            <div className='exit-app-dialog-section-desc'>{formatMessage(homeOnboardingSelfMifidSurveyMessages.exitDialogDesc)}</div>
            <div className='exit-app-dialog-section-btn'>
              <AppButton
                type={AppButtonType.BUTTON}
                variant={AppButtonVariant.Secondary}
                label={formatMessage(homeOnboardingSelfMifidSurveyMessages.exitDialogExitBtn)}
                onClick={handleExit}
              />
              <AppButton
                type={AppButtonType.BUTTON}
                variant={AppButtonVariant.Primary}
                label={formatMessage(homeOnboardingSelfMifidSurveyMessages.exitDialogBackBtn)}
                onClick={onClose}
              />
            </div>
          </div>
        </AppDialog> */}
      </div>
      {/* {roomId && <WebCollaborationOverlay />} */}
    </div>
  );
};
