import {
  amountFormatter,
  DashboardWalletSummary,
  HomeCard,
  percentageFormatter,
  walletPageMessages
} from 'azshared';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import './home-my-wallet-card.scss';



interface HomeMyWalletCardTotalAmountProps {
  card: HomeCard<DashboardWalletSummary>;
}

export const HomeMyWalletCardTotalAmount = (props: HomeMyWalletCardTotalAmountProps) => {
  const { formatMessage } = useIntl();
  const { card } = props; 

  const blurryStyleClass = useMemo(() => card.value?.flagShowBalance ? '' : 'blurry-text', [card.value?.flagShowBalance ]);


  return card.value?.totalAmount?.value ? (
      <div className='total-amount'>
        <h4>{formatMessage(walletPageMessages.walletWidgetSubtitleLabel)}</h4>
        <h2 className={`amount ${blurryStyleClass}`}>
          {amountFormatter(card.value?.totalAmount).split(' ', 2)[0]}{' '}
          <span className={`amount-symbol ${blurryStyleClass}`}>
            {amountFormatter(card.value?.totalAmount).split(' ', 2)[1]}
          </span>
        </h2>
        <div className='total-progress-bar'>
          {card.value.macroCategories.map((macroCategory, index) => (
            <span
              key={index}
              style={{
                backgroundColor: macroCategory.color,
                width: percentageFormatter(macroCategory.percentage || 0, {decimalSeparator: '.', showSpace: false, isPercentage: true })
              }}
            />
          ))}
        </div>
      </div>
    ) : null;
};
