import React, { useCallback } from 'react';
import { AppBaseItem } from '../radio/app-radio-button';
import { AppCheckbox } from './app-checkbox';
import './app-checkbox.scss';

export interface AppCheckboxItem extends AppBaseItem {
  disabled?: boolean;
  indeterminate?: boolean;
}

export interface AppCheckboxGroupProps {
  selectedValues?: string[];
  options: AppCheckboxItem[];
  onSelectedValueChanged: (value: string[], field?: any) => void;
  valid?: boolean;
}

export const AppCheckboxGroup = (props: AppCheckboxGroupProps) => {
  const { options, onSelectedValueChanged, selectedValues = [], valid = true } = props;

  const onChange = useCallback(
    (value: boolean, id?: string) => {
      if (value) {
        onSelectedValueChanged(selectedValues?.concat(id ?? ''));
      } else {
        onSelectedValueChanged(selectedValues?.filter((val) => val !== id));
      }
    },
    [onSelectedValueChanged, selectedValues]
  );

  return (
    <div className={`app-checkbox-group ${!valid ? 'invalid-checkbox-group' : ''}`}>
      {options.map((option, index) => (
        <AppCheckbox key={index} checked={selectedValues?.includes(option.value)} onChange={onChange} id={option.value} {...option} />
      ))}
    </div>
  );
};
