import { FileDownloaded } from 'azshared';
import React, { useMemo } from 'react';
import { Loader, LoaderSize } from '../../../../../components/loader/loader';

export interface PdfViewProps {
  pdf?: FileDownloaded;
  url?: string;
  pdfLoading: boolean;
}

export const PdfView = (props: PdfViewProps) => {

  const { pdf, url, pdfLoading } = props;

  const urlPdf = useMemo(() => pdf && URL.createObjectURL(pdf.blob), [pdf]);
  
  return <div className='pdf-view'>
    {
      pdfLoading && <Loader className={'pdf-view-loader'} loadersize={LoaderSize.XL} />
    }
    {
      !pdfLoading && urlPdf &&  <iframe src={urlPdf}></iframe>
    }
    {
      !pdfLoading && !urlPdf && <iframe src={url}></iframe>
    }
  </div>;
};