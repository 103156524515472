import { IconButton } from '@mui/material';
import { DownloaderMediaType, useMyWalletDownloadFileLogic, useMyWalletLogic, walletPageMessages } from 'azshared';
import React, { MouseEvent, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Card, CardSize } from '../../../../components/card/card';
import { AppDownloadFile } from '../../../../components/download-file/download-file';
import { AppSvgIcon } from '../../../../components/svg-icon/app-svg-icon';
import { AppTabBar } from '../../../../components/tab-bar/tab-bar';
import { useGlobalActions } from '../../../../hooks/global-actions';
import { downloadFile } from '../../../../utils/utils';
import { DossierFilter } from '../components/dossier-filter/dossier-filter';
import { FinancialPositionsSection } from '../components/financial-positions/financial-positions-section';
import { PerformanceSection } from '../components/performance/performance-section';
import { AppMyWalletSummary } from '../components/summary/my-wallet-summary';
import './my-wallet-section.scss';

export const MyWalletSection = (props: any) => {

  const { sectionMode } = props;
  
  const { formatMessage } = useIntl();
  const globalActions = useGlobalActions();
  
  const {
    wallet,
    summary,
    flagShowBalance,
    sections,
    selectedSection,
    isLoadingProductPerformances,
    isLoadingSummary,
    isLoadingWallet,
    onSelectedSection,
    setFlagShowBalance,
    closeProductDetails,
    goToRealEconomyDetailPage
  } = useMyWalletLogic(globalActions);

  const { onDownloadFile, fileDownloaded, fileDownloadedRequestStatus } = useMyWalletDownloadFileLogic(globalActions);

  const handleClickShowBalance = useCallback(
    (e: MouseEvent<HTMLButtonElement | HTMLLabelElement>) => {
      e.preventDefault();
      setFlagShowBalance(!flagShowBalance);
    },
    [flagShowBalance, setFlagShowBalance]
  );

  const handleDownloadFile = useCallback(
    (mediaType: DownloaderMediaType) => {
      onDownloadFile(selectedSection === 'POSITION_LIST' ? 'holdings' : 'performance', mediaType);
    },
    [selectedSection, onDownloadFile]
  );

  useEffect(() => {
    if (fileDownloadedRequestStatus === 'succeeded' && (fileDownloaded.type === 'holdings' || fileDownloaded.type === 'performance')) {
      downloadFile(fileDownloaded.name, fileDownloaded.blob);
    }
  }, [fileDownloadedRequestStatus, fileDownloaded]);


  return (
    <div className='my-wallet-section'>
      <div className='my-wallet-section-icons'>
        <div className='my-wallet-section-icon my-wallet-section-icon-eye'>
          <IconButton onClick={handleClickShowBalance}>
            <AppSvgIcon icon={!flagShowBalance ? 'eye-blue' : 'blind-blue'} />
          </IconButton>
          <label onClick={handleClickShowBalance} className='my-wallet-section-icon-label'>
            {formatMessage(!flagShowBalance ? walletPageMessages.showBalance : walletPageMessages.blurBalance)}
          </label>
        </div>
        <DossierFilter />
      </div>
      <AppMyWalletSummary
        totalAmount={summary?.totalAmount}
        flagShowBalance={flagShowBalance}
        macroCategorySummary={summary?.macroCategories}
        isLoading={isLoadingSummary || isLoadingWallet}
      />

      <Card sizeVariant={CardSize.MAXI}>
        <AppTabBar tabs={sections} onTabSelected={onSelectedSection} value={selectedSection}/>
        <div className='my-wallet-section-icons my-wallet-section-icons-pdf'>
          <AppDownloadFile mediaType='pdf' className='my-wallet-section-icon' onClick={handleDownloadFile}></AppDownloadFile>
          <AppDownloadFile mediaType='xlsx' className='my-wallet-section-icon' onClick={handleDownloadFile}></AppDownloadFile>
        </div>
        {selectedSection === 'POSITION_LIST' && (
          //<div>
             <FinancialPositionsSection
            macroCategories={wallet?.macroCategories || []}
            closeProductDetails={closeProductDetails}
            isLoading={isLoadingWallet}
            flagShowBalance={flagShowBalance}
            goToRealEconomyDetailPage={goToRealEconomyDetailPage}
            sectionMode={sectionMode}
          />
          //</div>
         
        )}
        {selectedSection === 'PERFORMANCE' && <PerformanceSection performanceList={wallet?.performances} isLoading={isLoadingProductPerformances} />}
      </Card>
    </div>
  );
};
