import { signatureAreaMessages, signatureAreaUserRegistrationMessages, SignatureDocument, useAppSelector, UserRegistrationState } from 'azshared';
import React, { Fragment, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppButton } from '../../../../../../components/buttons/app-button';
import { AppCheckbox } from '../../../../../../components/checkbox/app-checkbox';
import { SignatureAreaLink } from '../../../../../home/signature-area/components/signature-area-link/signature-area-link';
import './signature-area-document-content.scss';

export const SignatureAreaDocumentContent = (props: { document: SignatureDocument; clickGoForward: (bundleUrl: string) => void }) => {
  const { document, clickGoForward } = props;
  const { formatMessage } = useIntl();
  const [checked, setChecked] = useState(false);

  const handleGoForward = useCallback(() => {
    clickGoForward(document?.bundleUrlForSignature || '');
  }, [document, clickGoForward]);

  const { tmpToken } = useAppSelector(
    (state) => state.features.userRegistration as UserRegistrationState
  );

  return (
    <Fragment>
      {document?.modules?.map((item, index) => (
        <div key={item.title} className='signature-area-document-content'>
          <div className='signature-area-document-content-title'>
            {index > 0 && <span>-</span>}
            <SignatureAreaLink module={item} token={tmpToken}/>
          </div>
          {index === 0 && (
            <Fragment>
              <div className='signature-area-document-content-disclaimer'>{formatMessage(signatureAreaUserRegistrationMessages.disclaimer)}</div>
              <div className='signature-area-document-content-buttons'>
                <AppCheckbox label={formatMessage(signatureAreaMessages.acknowledgmentLabel)} checked={checked} onChange={setChecked} />
                <AppButton label={formatMessage(signatureAreaMessages.forwardLabel)} disabled={!checked} onClick={handleGoForward} />
              </div>
            </Fragment>
          )}
        </div>
      ))}
    </Fragment>
  );
};
