import React from 'react';
import './app-timeline.scss';

export interface AppTimelineProps {
  steps: string[];
  stepSelected?: number[];
}

export const AppTimeline = (props: AppTimelineProps) => {
  const { steps, stepSelected } = props;

  return (
    <div className='timeline'>
      <ul>
        {steps
          .map((step, index) => {
            return (
              <li key={step} aria-selected={index === stepSelected?.indexOf(index)}>
                <span aria-selected={index === stepSelected?.indexOf(index)}>{step}</span>
                <div aria-selected={index === stepSelected?.indexOf(index)} />
              </li>
            );
          })
          .reverse()}
      </ul>
    </div>
  );
};
