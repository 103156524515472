import { useIntl } from 'react-intl';
import {useNavigate} from 'react-router';
import React, { useCallback } from 'react';
import {appRoutes, thankYouPageMessages, useAppSelector} from 'azshared';
import {LoginContainer} from '../../user-access/login-container/login-container';
import {Card} from '../../../components/card/card';
import {AppButton} from '../../../components/buttons/app-button';
import './thank-you-page.scss';

export const ThankYouPage = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const prospectName = useAppSelector(
      (state) => state.features.finalizeOnboarding.createProspectRequest.name
  );

  const onButtonPress = useCallback(()=>{
    navigate(appRoutes.Login);
  },[navigate]);

  return (
    <div className='thank-you-page'>
      <LoginContainer>
        <Card
          title={formatMessage(thankYouPageMessages.pageTitle)}
        >
          <p>{formatMessage(thankYouPageMessages.pageMessageHeader, {name: prospectName})}</p>
          <p>{formatMessage(thankYouPageMessages.pageMessageThankYouParagraph)}</p>
          <p>{formatMessage(thankYouPageMessages.pageMessageInviteParagraph)}</p>
          <AppButton
            label='Avanti'
            onClick={onButtonPress}
            // loading={checkReferralCodeRequestStatus === 'loading'}
          />
        </Card>
      </LoginContainer>
    </div>
  );
};
