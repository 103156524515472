import React from 'react';
import './product-concentration.scss';
import {useIntl} from 'react-intl';
import {ProductConcentration, walletErDetailPageMessages} from 'azshared';
import {Loader, LoaderSize} from '../../../../../components/loader/loader';
import InfographicPie from '../../../../../components/infographic-pie/infographic-pie';
interface Props {
  loading: boolean;
  products: ProductConcentration[];
}

export const ProductConcentrationChart = (props: Props) => {
  const {formatMessage} = useIntl();
  
  const {loading, products} = props;

  return <div className='product-concentration'>
    <h4>{formatMessage(walletErDetailPageMessages.productConcentrationTitle)}</h4>
    <div className={'product-concentration-container' + (loading ? '-loader' : '')}>
      {loading && <Loader loadersize={LoaderSize.XL}/>}
      {!loading &&
      <InfographicPie
        legend
        legendAlignment={'left'}
        items={products.map(p => {
          return {value: p.quote, label: p.product.productName};
        })}
      />}
    </div>
  </div>;

};