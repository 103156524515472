import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import {
	documentsAreaMessages,
	labelForOfferingDocumentsCompany,
	labelForOfferingDocumentsType, OfferingDocument, offeringDocumentsColumns, DocumentsAreaSections
} from 'azshared';
import React, { Fragment, MouseEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppButtonIcon } from '../../../../../components/button-icon/app-button-icon';
import { AppPill } from '../../../../../components/pill/pill';
import { AppSvgIcon } from '../../../../../components/svg-icon/app-svg-icon';
import { AppTableCell } from '../../../../../components/table/table-cell';
import { AppTableCellHeader } from '../../../../../components/table/table-cell-header';
import './information-offer-table.scss';


interface InformationOfferTableProps {
	documents: OfferingDocument[];
	onReadDocument: (document: OfferingDocument, documentsAreaSection: DocumentsAreaSections) => void;
	onDownloadDocument: (document: OfferingDocument, documentsAreaSection: DocumentsAreaSections) => void;
}

export const InformationOfferTable = (props: InformationOfferTableProps) => {
	const { documents, onReadDocument, onDownloadDocument } = props;
	const { formatMessage } = useIntl();

	const handleReadDocument = useCallback(
    (e: MouseEvent) => {
      const id = (e.target as HTMLAnchorElement).id;
      const document = documents.find((doc) => doc.id === id);
      if (document) {
        onReadDocument(document, 'INFO_OFFER');
      }
    },
    [documents, onReadDocument]
  );

  const handleDownload = useCallback(
    (_: MouseEvent, id?: string) => {
      const document = documents.find((doc) => doc.id === id);
      if (document?.id) {
		onDownloadDocument(document, 'INFO_OFFER');
      }
    },
    [documents, onDownloadDocument]
  );

	return (
		<TableContainer className='information-offer-table'>
			<Table aria-label='table'>
				<TableHead>
					<TableRow>
						{offeringDocumentsColumns.map((column, index) => (
							<AppTableCellHeader
								key={index}
								className={`information-offer-table-header information-offer-table-header-${column.key}`}
							>
								<div className='information-offer-table-header-content'>
									{column.headerLabel && formatMessage(column.headerLabel)}
									{!column.headerLabel && <span className='hidden-content'>hidden</span>}
								</div>
							</AppTableCellHeader>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{documents.map((document, index) => (
						<TableRow
							key={index}
							className={`information-offer-table-row ${!document.flagIsRead && !document.flagExternalLink
								? 'information-offer-table-row-bold'
								: ''}`}
						>
							{offeringDocumentsColumns.map((col, i) => (
								<Fragment key={i}>
									{col.key === 'title' && (
										<AppTableCell
											align='left'
											type={col.type}
											className={`information-offer-table-row-cell-${col.key}`}
										>
											<div className='information-offer-table-row-title'>
												{!document.flagExternalLink && (
													<AppButtonIcon icon='download-document' id={document.id} onClick={handleDownload} />
												)}
												{document.flagExternalLink && <AppSvgIcon icon='link' />}
												<a
													href={document.fileUrl}
													id={document.id}
													target='_blank'
													rel='noreferrer'
													onClick={
														!document.flagExternalLink ? handleReadDocument : undefined
													}
												>
													{document[col.key]}
												</a>
											</div>
										</AppTableCell>
									)}
									{col.key === 'type' && (
										<AppTableCell
											key={col.key}
											align='left'
											type={col.type}
											className={`information-offer-table-row-cell-${col.key}`}
										>
											{document[col.key] !== undefined ? (
												formatMessage(labelForOfferingDocumentsType(document[col.key]))
											) : (
												'-'
											)}
										</AppTableCell>
									)}
									{col.key === 'company' && (
										<AppTableCell
											key={col.key}
											align='left'
											type={col.type}
											className={`information-offer-table-row-cell-${col.key}`}
										>
											{formatMessage(labelForOfferingDocumentsCompany(document[col.key]))}
										</AppTableCell>
									)}
									{col.key === 'flagIsRead' &&
									!document.flagExternalLink && (
										<AppTableCell
											key={col.key}
											align='left'
											type={col.type}
											className={`information-offer-table-cell-${col.key}`}
										>
											{!document.flagIsRead && (
												<AppPill
													color='success'
													label={formatMessage(documentsAreaMessages.notReadBadge)}
												/>
											)}
										</AppTableCell>
									)}
								</Fragment>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
