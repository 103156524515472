import React, { ChangeEvent } from 'react';
import './app-radio-button.scss';

export interface AppBaseItem {
  value: string;
  label: string;
}

export interface AppRadioButtonConfig extends AppBaseItem {
  description?: string;
  disabled?: boolean;
  renderLabelFirst?: boolean;
  valid?: boolean;
}

export interface AppLayeredRadioButtonConfig extends AppBaseItem {
  description?: string;
  disabled?: boolean;
  renderLabelFirst?: boolean;
  valid?: boolean;
  subLayerComponent?: JSX.Element;
  onUnmountSubLayer?: () => void;
}

export interface AppRadioButtonProps extends AppRadioButtonConfig {
  checked?: boolean;
  onSelect?: (e: ChangeEvent<HTMLInputElement>) => void;
  layered?: boolean;
}

export const AppRadioButton = (props: AppRadioButtonProps) => {
  const { renderLabelFirst, label, value, checked = false, disabled = false, description, onSelect, valid = true } = props;

  return (
    <div>
      <label className={`container ${disabled ? ' disabled' : ''}`}>
        {renderLabelFirst && label}
        <input type='radio' value={value} checked={checked} disabled={disabled} onChange={onSelect} />
        <span className={`checkmark ${valid ? '' : ' checkmark-error'}`} />
        {!renderLabelFirst && label}
      </label>
      {description && <div className='description'>{description}</div>}
    </div>
  );
};
