import { Table, TableBody, TableContainer } from '@mui/material';
import { OfferingDocument, useOfferingDocumentsTabLogic, DocumentsAreaSections } from 'azshared';
import React from 'react';
import { Card, CardSize } from '../../../../../components/card/card';
import { Loader, LoaderSize } from '../../../../../components/loader/loader';
import { useGlobalActions } from '../../../../../hooks/global-actions';
import { InformationOfferAccordion } from '../information-offer-accordion/information-offer-accordion';
import './information-offer-card.scss';

interface InformationOfferCardProps {
  onReadDocument: (document: OfferingDocument, documentsAreaSection: DocumentsAreaSections) => void;
  onDownloadDocument: (document: OfferingDocument, documentsAreaSection: DocumentsAreaSections) => void;
}

export const InformationOfferCard = (props: InformationOfferCardProps) => {
	const globalActions = useGlobalActions();

	const { offerings, isRequestLoading } = useOfferingDocumentsTabLogic(globalActions);

	return (
		<div className='information-offer-card'>
			<Card sizeVariant={CardSize.MAXI}>
				{isRequestLoading && (
					<div className='information-offer-card-loader'>
						<Loader loadersize={LoaderSize.XL} />
					</div>
				)}
				{!isRequestLoading && (
					<TableContainer className='information-offer-card-table'>
						<Table aria-label='collapsible table'>
							<TableBody>
								{offerings.map((offering) => (
									<InformationOfferAccordion
										key={offering.type}
										offeringGroup={offering}
										{...props}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</Card>
		</div>
	);
};
