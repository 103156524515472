import { env, financialAdvisorLogin, useAppSelector, useWebCollaborationLogic, webCollaborationMessages, webCollaborationSliceActions } from 'azshared';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppButtonIcon } from '../../../components/button-icon/app-button-icon';
import { WaitForOtherUserDialog } from '../../../components/wait-for-other-user-dialog/wait-for-other-user-dialog';
import { WebCollaborationPermissionRequestsDialog } from '../../../components/web-collaboration-permission-requests-dialog/web-collaboration-permission-requests-dialog';
import { useGlobalActions } from '../../../hooks/global-actions';
import { useInterval } from '../../../hooks/use-interval';
import './cf-room-page.scss';

let _negotiating = false;
const mediaConstraints: MediaStreamConstraints = {
  video: true,
  audio: {
    echoCancellation: true,
    noiseSuppression: true
  }
};

let lastIceCandiDateTime: number | undefined;
let offerSended = false;

export const CFRoomPage = () => {
  const { formatMessage } = useIntl();
  const globalActions = useGlobalActions();
  const channel = useRef<RTCDataChannel>();
  const { status, answer, acceptAnswer, createOffer } = useWebCollaborationLogic(globalActions);
  let cfVideo: React.MutableRefObject<any> | null = useRef<any>({});
  const prospectVideo = useRef<HTMLVideoElement | null>(null);
  const peerRef = useRef<RTCPeerConnection>();
  const cfStreamRef = useRef<MediaStream>();
  const { roomID } = useParams();
  const [audioActive, setAudioActive] = useState<boolean>(true);
  const [cfVideoActive, setCfVideoActive] = useState<boolean>(true);
  const [prospectVideoActive, setProspectVideoActive] = useState<boolean>(true);
  const dispatch = useDispatch();
  const [countLogin, setCountLogin] = useState(0);
  const token = useAppSelector((state) => state.data?.user?.token);
  const [openReconnectingDialog, setOpenReconnectingDialog] = useState(false);
  const [isMifidCompleted, setIsMifidCompleted] = useState(false);
  const [isCallClosedByCf, setIsCallClosedByCf] = useState(false);
  const [isProspectDeviceMobile, setIsProspectDeviceMobile] = useState(true);
  const [isScreenSharingActive, setIsScreenSharingActive] = useState(false);
  const [videoSharingSize, setVideoSharingSize] = useState<{ width: number; height: number }>();
  const [prospectButtonsSize, setProspectButtonsSize] = useState<{ width: number; height: number }>();
  const [prospectButtonsTop, setProspectButtonsTop] = useState<number>(0);
  const [prospectBrowserLeft, setProspectBrowserLeft] = useState<number>(0);
  const [prospectScreenWidth, setProspectScreenWidth] = useState<number>(0);
  const [prospectBrowserWidth, setProspectBrowserWidth] = useState<number>(0);
  const [localAnswer, setLocalAnswer] = useState<string>();
  const [openPermissionRequestsDialog, setOpenPermissionRequestsDialog] = useState(false);
  const [isPlaying, setPlaying] = useState(false);

  useEffect(() => {
    if (!token && countLogin === 0) {
      dispatch(financialAdvisorLogin());
      setCountLogin(countLogin + 1);
    }
  }, [token, dispatch, countLogin]);

  useEffect(() => {
    dispatch(webCollaborationSliceActions.setIsProspect(false));
    dispatch(webCollaborationSliceActions.setRoomId(roomID));
  }, [dispatch, roomID]);

  const stopStream = useCallback(() => {
    const tracks = cfStreamRef.current?.getTracks();

    tracks?.forEach((track) => {
      track.stop();
      track.enabled = false;
    });
    channel.current?.close();
    peerRef.current?.close();
    cfStreamRef.current = undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    cfVideo = null;
  }, []);

  useEffect(() => {
    return () => {
      stopStream();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMessage = useCallback(
    (event) => {
      console.log(event.data);
      const data = JSON.parse(event.data);
      if (data.mifidStatus !== undefined) {
        setIsMifidCompleted(data.mifidStatus === 'completed');
        if (data.mifidStatus === 'completed') {
          stopStream();
        }
      }
      if (data.isMobile !== undefined) {
        setIsProspectDeviceMobile(data.isMobile);
      }
      if (data.screenSharingActive !== undefined) {
        setIsScreenSharingActive(data.screenSharingActive);
        setVideoSharingSize(data.windowSize);
        setProspectButtonsSize(data.buttonsSize);
        setProspectButtonsTop(data.buttonsTop);
        setProspectBrowserLeft(data.windowLeft);
        setProspectScreenWidth(data.screenWidth);
        setProspectBrowserWidth(data.browserWidth);
      }
      if (data.prospectVideoActive !== undefined) {
        setProspectVideoActive(data.prospectVideoActive);
      }
    },
    [stopStream]
  );

  const handleNegotiationNeededEvent = useCallback(
    async (iceRestart: boolean) => {
      if (!_negotiating) {
        _negotiating = true;
        const webcamStream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
        if (cfVideo) {
          cfVideo.current.srcObject = webcamStream;
        }
        for (const track of webcamStream.getTracks()) {
          peerRef.current?.addTrack(track, webcamStream);
        }
        setCfVideoActive(true);
        setAudioActive(true);
        cfStreamRef.current = webcamStream;
        offerSended = false;
        const offer = await peerRef.current?.createOffer({ iceRestart: iceRestart });
        await peerRef.current?.setLocalDescription(offer);
        setPlaying(true);
        lastIceCandiDateTime = 0;
        offerSended = false;
        if (iceRestart) {
          channel.current = peerRef.current?.createDataChannel('data');
          if (channel.current) {
            channel.current.onmessage = handleMessage;
          }
        }
      }
    },
    [handleMessage]
  );

  const handleTrackEvent = useCallback((e: RTCTrackEvent) => {
    if (prospectVideo.current) {
      prospectVideo.current.srcObject = e.streams[0];
    }
  }, []);

  const handleConnectionState = useCallback(() => {
    switch (peerRef.current?.connectionState) {
      case 'disconnected':
      case 'closed':
        setOpenReconnectingDialog(true);
        break;
      case 'failed':
        _negotiating = false;
        peerRef.current.restartIce();
        break;
      default:
        setOpenReconnectingDialog(false);
        break;
    }
  }, []);

  const handleIceConnectionState = useCallback(() => {
    switch (peerRef.current?.iceConnectionState) {
      case 'disconnected':
      case 'closed':
        setOpenReconnectingDialog(true);
        break;
      case 'failed':
        _negotiating = false;
        peerRef.current.restartIce();
        break;
      default:
        setOpenReconnectingDialog(false);
        break;
    }
  }, []);

  useInterval(
    () => {
      const now = new Date().getTime();
      if (!offerSended && lastIceCandiDateTime !== undefined && now - lastIceCandiDateTime > 1000) {
        createOffer(JSON.stringify(peerRef.current?.localDescription));
        setPlaying(false);
        lastIceCandiDateTime = undefined;
        offerSended = true;
      }
    },
    isPlaying ? 500 : null
  );

  const handleIceCandidate = useCallback(
    (ev: RTCPeerConnectionIceEvent) => {
      lastIceCandiDateTime = new Date().getTime();
      if (ev.candidate === null && !offerSended) {
        createOffer(JSON.stringify(peerRef.current?.localDescription));
        lastIceCandiDateTime = undefined;
        offerSended = true;
      }
    },
    [createOffer]
  );

  const createPeer = useCallback(
    async (locStatus) => {
      const peer = new RTCPeerConnection({
        iceServers: [
          {
            urls: env.iceServers
          }
        ]
      });

      peer.ontrack = handleTrackEvent;
      peer.onconnectionstatechange = handleConnectionState;
      peer.oniceconnectionstatechange = handleIceConnectionState;
      peer.onnegotiationneeded = () => handleNegotiationNeededEvent(locStatus === 'CONNECTED');
      peer.onicecandidate = handleIceCandidate;
      channel.current = peer.createDataChannel('data');
      if (channel.current) {
        channel.current.onmessage = handleMessage;
      }

      return peer;
    },
    [handleTrackEvent, handleConnectionState, handleIceConnectionState, handleIceCandidate, handleNegotiationNeededEvent, handleMessage]
  );

  const callUser = useCallback(
    async (locStatus) => {
      peerRef.current = await createPeer(locStatus);
    },
    [createPeer]
  );

  useEffect(() => {
    if (token && (status === 'WAIT_OFFER' || status === 'CONNECTED') && !peerRef.current) {
      callUser(status);
    } else if (token && status === 'WAIT_OFFER' && peerRef.current) {
      _negotiating = false;
      peerRef.current.restartIce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, status]);

  const handleAnswer = useCallback(
    (message: any) => {
      const desc = new RTCSessionDescription(message);
      peerRef.current?.setRemoteDescription(desc).catch((e: any) => console.log(e));
      acceptAnswer();
    },
    [acceptAnswer]
  );

  useEffect(() => {
    if (answer && status === 'WAIT_HANDSHAKE' && answer !== localAnswer) {
      handleAnswer(JSON.parse(answer));
      setLocalAnswer(answer);
    }
  }, [answer, handleAnswer, localAnswer, status]);

  const onToggleVideo = useCallback(async () => {
    if (cfStreamRef.current?.getVideoTracks()[0]) {
      if (cfStreamRef.current.getVideoTracks()[0].readyState === 'live') {
        cfStreamRef.current?.getVideoTracks()[0].stop();
        setCfVideoActive(false);
      } else {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true
        });
        cfStreamRef.current.removeTrack(cfStreamRef.current.getVideoTracks()[0]);
        cfStreamRef.current.addTrack(stream.getVideoTracks()[0]);
        setCfVideoActive(true);
      }
    }
    peerRef?.current
      ?.getSenders()
      ?.find((sender: any) => sender.track.kind === 'video')
      ?.replaceTrack(cfStreamRef.current?.getVideoTracks()[0] || null);
  }, []);

  const onToggleAudio = useCallback(async () => {
    if (cfStreamRef.current?.getVideoTracks()[0]) {
      if (cfStreamRef.current.getAudioTracks()[0].readyState === 'live') {
        cfStreamRef.current?.getAudioTracks()[0].stop();
        setAudioActive(false);
      } else {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true
        });
        cfStreamRef.current.removeTrack(cfStreamRef.current.getAudioTracks()[0]);
        cfStreamRef.current.addTrack(stream.getAudioTracks()[0]);
        if (cfVideo) {
          cfVideo.current.srcObject = cfStreamRef.current;
        }
        setAudioActive(true);
      }

      peerRef?.current
        ?.getSenders()
        ?.find((sender: any) => sender.track.kind === 'audio')
        ?.replaceTrack(cfStreamRef.current?.getAudioTracks()[0]);
    }
  }, []);

  const handleCloseCall = useCallback(() => {
    setIsCallClosedByCf(true);
    stopStream();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (channel.current?.readyState === 'open') {
      channel.current.send(JSON.stringify({ cfVideoActive }));
    }
  }, [cfVideoActive]);

  const pageButtonsStyles = useMemo(() => {
    if (!isScreenSharingActive) {
      return {};
    }

    const videoProspectResizedHeight = (window.innerWidth * (videoSharingSize?.height ?? 0)) / (videoSharingSize?.width ?? 1);
    const videoProspectResizedWidth = (window.innerHeight * (videoSharingSize?.width ?? 0)) / (videoSharingSize?.height ?? 1);
    const videoTop = Math.max((window.innerHeight - videoProspectResizedHeight) / 2, 0);
    const minWidth = ((prospectButtonsSize?.width ?? 0) * videoProspectResizedWidth) / (videoSharingSize?.width ?? 1);
    const minHeight = ((prospectButtonsSize?.height ?? 0) * videoProspectResizedHeight) / (videoSharingSize?.height ?? 1);

    const top = videoTop + Math.max((prospectButtonsTop * window.innerHeight) / (videoSharingSize?.height ?? 1) - 15, 0);
    const left =
      prospectBrowserLeft !== null && prospectBrowserLeft !== undefined ? (prospectBrowserLeft - (prospectScreenWidth - prospectBrowserWidth)) / 2 : 0;

    console.log(
      'left',
      (prospectBrowserLeft - (prospectScreenWidth - prospectBrowserWidth)) / 2,
      prospectBrowserLeft,
      prospectScreenWidth,
      prospectBrowserWidth
    );

    return {
      top: `${top}px`,
      minWidth: `${minWidth}px`,
      minHeight: `${minHeight}px`,
      transform: `translateX(calc(-50% ${Math.sign(left) > 0 ? '+' : '-'} ${Math.abs(left)}px))`
    };
  }, [videoSharingSize, prospectButtonsSize, prospectButtonsTop, isScreenSharingActive, prospectBrowserLeft, prospectScreenWidth, prospectBrowserWidth]);

  const getPermissions = useCallback(async () => {
    const permissionDescriptors = [{ name: 'camera' }, { name: 'microphone' }];

    const permissions = await Promise.all(
      permissionDescriptors.map(async (descriptor) => ({
        descriptor,
        permissionStatus: await navigator.permissions.query(descriptor as PermissionDescriptor)
      }))
    );

    return permissions;
  }, []);

  const restoreCall = useCallback(async () => {
    const permissions = await getPermissions();

    if (permissions?.every(({ permissionStatus }) => permissionStatus.state !== 'denied')) {
      setOpenPermissionRequestsDialog(false);

      if (peerRef.current?.connectionState !== 'connected' || peerRef.current?.iceConnectionState !== 'connected') {
        _negotiating = false;
        handleNegotiationNeededEvent(false);
      } else {
        if (permissions.find(({ descriptor, permissionStatus }) => descriptor.name === 'camera' && permissionStatus.state === 'prompt')) {
          onToggleVideo();
        }
        if (permissions.find(({ descriptor, permissionStatus }) => descriptor.name === 'microphone' && permissionStatus.state === 'prompt')) {
          onToggleAudio();
        }
      }
    }
  }, [getPermissions, onToggleVideo, onToggleAudio, handleNegotiationNeededEvent]);

  const checkPermissions = useCallback(async () => {
    const permissions = await getPermissions();

    for (const { permissionStatus } of permissions) {
      permissionStatus.onchange = () => {
        if (permissionStatus.state === 'denied') {
          setOpenPermissionRequestsDialog(true);
        }
      };
    }
  }, [getPermissions]);

  useEffect(() => {
    checkPermissions();
  }, [checkPermissions]);

  const handleRecall = useCallback(() => {
    _negotiating = false;
    callUser(status);
    setIsCallClosedByCf(false);
  }, [callUser, status]);

  return (
    <div className='cf-room-page'>
      {!isMifidCompleted && (
        <div
          className={`cf-room-page-buttons ${isProspectDeviceMobile ? 'cf-room-page-buttons-mobile' : ''}`}
          style={
            !isProspectDeviceMobile ? { ...pageButtonsStyles, zIndex: openReconnectingDialog ? 10000 : 100 } : { zIndex: openReconnectingDialog ? 10000 : 100 }
          }>
          <AppButtonIcon
            icon='close-call'
            className='cf-room-page-icon-button'
            label={formatMessage(webCollaborationMessages.callButton)}
            onClick={!isCallClosedByCf ? handleCloseCall : handleRecall}
          />
          {!isCallClosedByCf && (
            <>
              <AppButtonIcon
                icon={audioActive ? 'microphone-on' : 'microphone-off'}
                className='cf-room-page-icon-button'
                label={formatMessage(webCollaborationMessages.microphoneButton)}
                onClick={onToggleAudio}
              />
              <AppButtonIcon
                icon={cfVideoActive ? 'webcam-on' : 'webcam-off'}
                className='cf-room-page-icon-button'
                label={formatMessage(webCollaborationMessages.webcamButton)}
                onClick={onToggleVideo}
              />
            </>
          )}
        </div>
      )}
      <div
        className={`prospect-avatar ${
          peerRef.current?.connectionState !== 'connected' ||
          peerRef.current?.iceConnectionState !== 'connected' ||
          prospectVideoActive ||
          isScreenSharingActive
            ? 'invisible'
            : ''
        }`}
      />
      <video
        id='prospectVideo'
        autoPlay
        ref={prospectVideo}
        className={`${
          peerRef.current?.connectionState !== 'connected' ||
          peerRef.current?.iceConnectionState !== 'connected' ||
          (!prospectVideoActive && !isScreenSharingActive)
            ? 'invisible'
            : ''
        }`}
      />
      <div className='cf-room-page-videos' style={{ zIndex: openReconnectingDialog ? 10000 : 100 }}>
        <Draggable>
          <div className={`cf-room-page-video ${isScreenSharingActive ? 'invisible' : ''}`}>
            <video autoPlay ref={cfVideo} className={!cfVideoActive ? 'invisible' : ''} muted />
          </div>
        </Draggable>
      </div>
      <WaitForOtherUserDialog open={openReconnectingDialog || isMifidCompleted} waitForProspect={true} type={isMifidCompleted ? 'success' : 'warning'} />
      <WebCollaborationPermissionRequestsDialog open={openPermissionRequestsDialog} onClose={handleCloseCall} onContinue={restoreCall} />
    </div>
  );
};
