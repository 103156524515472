import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import { signatureAreaActions, signatureAreaMessages, SignatureAreaSections, SignatureDocument, useSignatureAreaLogic, getPracticeTitleDescriptor } from 'azshared';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { AppSvgIcon } from '../../../../components/svg-icon/app-svg-icon';
import { useGlobalActions } from '../../../../hooks/global-actions';
import { SignatureAreaBaseDocumentContent } from './base-document-content/signature-area-base-document-content';
import { SignatureAreaRtoDocumentContent } from './rto-document-content/signature-area-rto-document-content';

export const SignatureAreaRow = (props: {
  document: SignatureDocument;
  openDetail: (moduleId: string, documentId: string) => void;
  startProcedure: (documentId: string) => void;
  rejectProposal: (documentId: string) => void;
  selectedSection: SignatureAreaSections;
  isProspect: boolean;
}) => {
  const { document, openDetail, startProcedure, rejectProposal, selectedSection } = props;

  const globalActions = useGlobalActions();
  const dispatch = useDispatch();

  const { selectedDocument, setSelectedDocument, isLoadingGetUrlForSignature, isLoadingRejectProposal } = useSignatureAreaLogic(globalActions);

  const { formatMessage } = useIntl();

  const open = useMemo(() => {
    return selectedDocument?.id === document.id;
  }, [selectedDocument, document]);

  const openAccordion = useCallback(() => {
    return selectedDocument?.id === document.id ? dispatch(signatureAreaActions.clearSelectedDocuments()) : setSelectedDocument(document.id);
  }, [document.id, selectedDocument, dispatch, setSelectedDocument]);

  return (
    <Fragment>
      <TableRow className={`signature-area-row ${open ? 'is-open' : ''}`}>
        <TableCell
          className='signature-area-row-cell'
          component='th'
          scope='row'
          sx={{
            borderBottomLeftRadius: 8,
            borderTopLeftRadius: 8
          }}>
          {document?.underProcessing ? document?.name : formatMessage(getPracticeTitleDescriptor(document?.type?.practiceType), {practiceNumber: document?.practiceNumber})}
        </TableCell>
        <TableCell className='signature-area-row-cell'>{document.customerName}</TableCell>
        <TableCell className='signature-area-row-cell' align='center'>
          {document?.underProcessing ? document.totalModules : document.modules?.length}
        </TableCell>
        <TableCell className='signature-area-row-cell'>
          {selectedSection === 'ALL' && (
            <div>
              {document?.underProcessing && <div className={'next-required-signature'}>{formatMessage(signatureAreaMessages.underProcessing)}</div>}
              {!document?.underProcessing && document.isMySignatureRequired && (
                <div className='sign'>
                  <AppSvgIcon icon='pencil' />
                  {formatMessage(signatureAreaMessages.labelLinkToSign)}
                </div>
              )}
              {!document?.underProcessing && !document.isMySignatureRequired && <div className='next-required-signature'>{document.nextRequiredSignature}</div>}
            </div>
          )}
        </TableCell>
        <TableCell
          className='signature-area-row-cell'
          sx={{
            borderBottomRightRadius: 8,
            borderTopRightRadius: 8
          }}>
          {!document?.underProcessing && <IconButton aria-label='expand row' size='small' onClick={openAccordion}>
            <Icon size={1.5} path={open ? mdiChevronUp : mdiChevronDown} color={'#2E6CD1'}/>
          </IconButton>}
        </TableCell>
      </TableRow>
      {!document?.underProcessing && <TableRow className={open ? 'is-expansion-visible' : ''}>
        <TableCell style={{ padding: 0, border: 'none' }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box>
              {document.type?.practiceType === 'RtoImiOrder' ? (
                <SignatureAreaRtoDocumentContent
                  document={document}
                  clickGoForward={startProcedure}
                  clickRejected={rejectProposal}
                  isLoadingRejectProposal={isLoadingRejectProposal}
                  isLoadingGetUrlForSignature={isLoadingGetUrlForSignature}
                />
              ) : (
                <SignatureAreaBaseDocumentContent
                  document={document}
                  openDetail={openDetail}
                  startProcedure={startProcedure}
                  rejectProposal={rejectProposal}
                  isLoadingRejectProposal={isLoadingRejectProposal}
                  isLoadingGetUrlForSignature={isLoadingGetUrlForSignature}
                  isProspect={props.isProspect}
                />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>}
      <TableRow>
        <TableCell style={{ padding: 0, border: 'none' }} colSpan={6}>
          <div className='white-row' />
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
