import React from 'react';
import { BarPair } from '../bars/bar-pair';
import './datapoint.scss';

export interface Datapoint {
  label: string | undefined;
  value: number | undefined;
  max: number | undefined;
}

export interface BarChartDatapointProps {
  data: Datapoint;
}

export const BarChartDatapoint = (props: BarChartDatapointProps) => {
  const { label, value, max } = props.data;

  return (
    <div className='datapoint'>
      <div className='datapoint-label'><span>{label}</span></div>
      <div className='datapoint-divider'></div>
      <div className='datapoint-bars-container'>
        <div className='datapoint-bars-container__inner'>
          <BarPair max={max} value={value} />
        </div>
      </div>
    </div>
  );
};
