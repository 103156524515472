import { mdiChevronLeft } from '@mdi/js';
import Icon from '@mdi/react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import {
  commonButtonMessages,
  commonFormMessages,
  currencyFormatter,
  DocumentModuleRtoChecks,
  DocumentModuleRtoInformation,
  signatureAreaMessages,
  SignatureDocument,
  SignatureDocumentRtoImiModule
} from 'azshared';
import React, { useCallback, useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { AppButton, AppButtonVariant } from '../../../../../components/buttons/app-button';
import { AppCheckbox } from '../../../../../components/checkbox/app-checkbox';
import './signature-area-rto-document-content.scss';

const mapKeyLabel: Record<string, MessageDescriptor> = {
  appropriatenessOutcome: signatureAreaMessages.rtoChecksAppropriatenessOutcomeLabel,
  complex: signatureAreaMessages.rtoChecksComplexLabel,
  ballIn: signatureAreaMessages.rtoChecksBallInLabel,
  illiquid: signatureAreaMessages.rtoChecksIlliquidLabel,
  conflictOperation: signatureAreaMessages.rtoChecksConflictOperationLabel
};

interface Checks {
  appropriatenessOutcome: boolean;
  complex: boolean;
  ballIn: boolean;
  illiquid: boolean;
  conflictOperation: boolean;
}

export const SignatureAreaRtoChecks = (props: {
  document: SignatureDocument;
  isLoadingRejectProposal: boolean;
	isLoadingGetUrlForSignature: boolean;
  clickGoBack: () => void;
  clickGoForward: (documentId: string) => void;
  clickRejected: (documentId: string) => void;
}) => {
  const { document, clickGoBack, clickRejected, clickGoForward, isLoadingRejectProposal, isLoadingGetUrlForSignature } = props;
  const { formatMessage } = useIntl();
  const [checked, setChecked] = useState<Checks>({
    appropriatenessOutcome: false,
    complex: false,
    ballIn: false,
    illiquid: false,
    conflictOperation: false
  });

  const retrieveCheckValue = (value: string | boolean): string => {
    if (typeof value === 'string') {
      return value;
    }

    return formatMessage(value ? commonFormMessages.yesLabel : commonFormMessages.noLabel);
  };

  const handleChange = useCallback(
    (value: boolean, key?: string) => {
      if (key) {
        const copy = { ...checked };
        copy[key as keyof DocumentModuleRtoChecks] = value;
        setChecked(copy);
      }
    },
    [setChecked, checked]
  );

  const handleClickForward = useCallback(() => {
      clickGoForward(document.id);
  }, [document, clickGoForward]);

  const handleClickRejected = useCallback(() => {
    clickRejected(document.id);
  }, [document, clickRejected]);

  const disableButtons = (): boolean => {
    return Object.values(checked).some(value => !value);
  };

  const rtoModule = document?.modules?.[0] as SignatureDocumentRtoImiModule;

  return (
    <div className='signature-area-rto-checks'>
      <TableContainer className='signature-area-rto-checks-table'>
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow>
              <TableCell className='signature-area-rto-checks-table-header' align='left'>
                {formatMessage(signatureAreaMessages.rtoChecksHeaderTableName)}
              </TableCell>
              <TableCell className='signature-area-rto-checks-table-header' align='left'>
                {formatMessage(signatureAreaMessages.rtoChecksHeaderTableNIsinCode)}
              </TableCell>
              <TableCell className='signature-area-rto-checks-table-header' align='left'>
                {formatMessage(signatureAreaMessages.rtoChecksHeaderTableTool)}
              </TableCell>
              <TableCell className='signature-area-rto-checks-table-header' align='left'>
                {formatMessage(signatureAreaMessages.rtoChecksHeaderTableOwner)}
              </TableCell>
              <TableCell className='signature-area-rto-checks-table-header' align='left'>
                {formatMessage(signatureAreaMessages.rtoChecksHeaderTableQuantity)}
              </TableCell>
              <TableCell className='signature-area-rto-checks-table-header' align='left'>
                {formatMessage(signatureAreaMessages.rtoChecksHeaderTablePriceLimit)}
              </TableCell>
              <TableCell className='signature-area-rto-checks-table-header' align='left'>
                {formatMessage(signatureAreaMessages.rtoChecksHeaderTablePriceMode)}
              </TableCell>
              <TableCell className='signature-area-rto-checks-table-header' align='left'>
                {formatMessage(signatureAreaMessages.rtoChecksHeaderTableValidity)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rtoModule.moduleInformation?.map((item: DocumentModuleRtoInformation, index: number) => (
              <TableRow key={index}>
                <TableCell className='signature-area-rto-checks-table-row' align='left'>
                  {item.documentName}
                </TableCell>
                <TableCell className='signature-area-rto-checks-table-row' align='left'>
                  {item.isinCode}
                </TableCell>
                <TableCell className='signature-area-rto-checks-table-row' align='left'>
                  {item.tool}
                </TableCell>
                <TableCell className='signature-area-rto-checks-table-row' align='left'>
                  {item.owner}
                </TableCell>
                <TableCell className='signature-area-rto-checks-table-row' align='left'>
                  {item.quantity}
                </TableCell>
                <TableCell className='signature-area-rto-checks-table-row' align='left'>
                  {currencyFormatter(item.priceLimit?.value, {currencyDisplay: 'code', currency: item.priceLimit?.currency})}
                </TableCell>
                <TableCell className='signature-area-rto-checks-table-row' align='left'>
                  {item.priceMode}
                </TableCell>
                <TableCell className='signature-area-rto-checks-table-row' align='left'>
                  {item.validity}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className='signature-area-rto-checks-list'>
        <div className='signature-area-rto-checks-row acknowledgment'>{formatMessage(signatureAreaMessages.rtoChecksAcknowledgmentLabel)}</div>
        {(Object.keys(rtoModule.moduleChecks) as (keyof DocumentModuleRtoChecks)[]).map(key => (
          <div className='signature-area-rto-checks-row' key={key}>
            <label>{formatMessage(mapKeyLabel[key])}</label>
            <label>{retrieveCheckValue(rtoModule.moduleChecks[key])}</label>
            <AppCheckbox checked={checked[key]} id={key} onChange={handleChange} disabled={isLoadingGetUrlForSignature}/>
          </div>
        ))}
      </div>
      <div className='signature-area-rto-checks-buttons'>
        <a className='back' onClick={clickGoBack}>
          <Icon path={mdiChevronLeft} size={0.8} />
          <span>{formatMessage(commonButtonMessages.backLink)}</span>
        </a>
        <AppButton
          onClick={handleClickRejected}
          variant={AppButtonVariant.Secondary}
          disabled={disableButtons() || isLoadingGetUrlForSignature}
          label={formatMessage(signatureAreaMessages.rejectLabel)}
        />
        <AppButton
          onClick={handleClickForward}
          label={formatMessage(signatureAreaMessages.forwardLabel)}
          disabled={disableButtons() || isLoadingRejectProposal}
          loading={isLoadingGetUrlForSignature}
        />
      </div>
    </div>
  );
};
