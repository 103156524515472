import React, { useCallback, useState } from 'react';
import { AppInputText, AppInputTextProps } from './app-input-text';
import './app-input-text.scss';

type AppInputPasswordProps = Omit<AppInputTextProps, 'icon' | 'type'>;

export const AppInputPassword = (props: AppInputPasswordProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const showHidePwd = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return <AppInputText {...props} svgIcon={showPassword ? 'blind' : 'eye'} type={showPassword ? 'text' : 'password'} onIconClick={showHidePwd} />;
};
