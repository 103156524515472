import { mdiCloseCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { appRoutes, commonButtonMessages, emailVerificationUserRegistrationMessages, useEmailVerificationPageLogic } from 'azshared';
import React, { FormEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType } from '../../../../components/buttons/app-button';
import { Card } from '../../../../components/card/card';
import { AppDialog } from '../../../../components/dialog/app-dialog';
import { AppInputText } from '../../../../components/input-text/app-input-text';
import { AppLink } from '../../../../components/labels/app-link';
import { useGlobalActions, useGoToLoginFromRegistration } from '../../../../hooks/global-actions';
import { LoginContainer } from '../../login-container/login-container';
import './email-user-registration-page.scss';

export const EmailVerificationUserRegistrationPage = () => {
  const { formatMessage } = useIntl();
  const goToLoginFromRegistration = useGoToLoginFromRegistration();

  const {
    buttonDisabled,
    email,
    verificationEmailCode,
    verificationEmailRequestStatus,
    verificationEmailError,
    handleClose,
    handleSubmit,
    updateVerificationEmailCode
  } = useEmailVerificationPageLogic(useGlobalActions());

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );

  return (
    <div>
      <LoginContainer>
        <div className='email-verification-user-registration-page'>
          <Card
            title={formatMessage(emailVerificationUserRegistrationMessages.pageTitle)}
            subtitle={formatMessage(emailVerificationUserRegistrationMessages.pageSubtitle)}
            back={formatMessage(commonButtonMessages.backLink)}
          >
            <div className='email-input-text'>
              <AppInputText label={formatMessage(emailVerificationUserRegistrationMessages.labelInsertEmailInputTextDisabled)} value={email} disabled />
            </div>

            <div className='description-disclaimer'>{formatMessage(emailVerificationUserRegistrationMessages.descriptionDisclaimer)}</div>
            <form onSubmit={onSubmit}>
              <AppInputText
                label={formatMessage(emailVerificationUserRegistrationMessages.labelInsertCodeVerificationInputText)}
                placeholder={formatMessage(emailVerificationUserRegistrationMessages.placeholderInsertCodeVerificationInputText)}
                value={verificationEmailCode}
                onChange={updateVerificationEmailCode}
              />
              <div className='button'>
                <AppButton
                  label={formatMessage(emailVerificationUserRegistrationMessages.buttonVerificationCodeByMail)}
                  type={AppButtonType.SUBMIT}
                  disabled={buttonDisabled}
                  loading={verificationEmailRequestStatus === 'loading'}
                />
              </div>
            </form>
            {/* ********** */}

            <div className='disclaimer'>
              {formatMessage(commonButtonMessages.linkBackToLogin)}
              <AppLink to={appRoutes.Login} replace={false} label={formatMessage(commonButtonMessages.linkToLogin)} onClick={goToLoginFromRegistration} />
            </div>
          </Card>
        </div>
        <AppDialog open={verificationEmailError?.type === 'TROPPI_TENTATIVI'}>
          <div className='dialog-error-email-verification'>
            <Icon path={mdiCloseCircleOutline} size={2} color={'red'} />
            <div className='title-dialog'>{formatMessage(emailVerificationUserRegistrationMessages.errorTooManyAttemptsTitle)}</div>
            <div className='text-dialog'>{formatMessage(emailVerificationUserRegistrationMessages.errorTooManyAttemptsMessage)}</div>
            <div className='button-dialog'>
              <AppButton
                type={AppButtonType.BUTTON}
                onClick={handleClose}
                label={formatMessage(emailVerificationUserRegistrationMessages.errorTooManyAttemptsButtonLabel)}
              />
            </div>
          </div>
        </AppDialog>
      </LoginContainer>
    </div>
  );
};
