/* eslint-disable react/jsx-no-bind */
import { getAmlQuestionById, homeOnboardingSelfAmlFatcaCrsSurveyMessages } from 'azshared';
import { Domanda } from 'azshared/build/main/lib/api/generated';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppInputText } from '../../../../../../components/input-text/app-input-text';
import { AppRadioButtonConfig } from '../../../../../../components/radio/app-radio-button';
import { AppRadioGroup } from '../../../../../../components/radio/app-radio-group';
import { AppDateSelect } from '../../../../../../components/select/app-date-select';
import { AmlSectionBodyProps } from '../../aml-fatca-crs-survey-page';

export const AmlPepSectionBody = (props: AmlSectionBodyProps) => {
  const { amlConsistency } = props;

  const { formatMessage } = useIntl();
  const [pepAnswerId, setPepAnswerId] = useState('');
  const pepQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 54);

  useEffect(() => {
    const pepAnswer = props.getAmlAnswerDetailsByQuestionId('' + pepQuestion?.idDomanda) as string;
    if (pepAnswer && pepAnswer != '') {
      setPepAnswerId(pepAnswer);
    }
  }, [pepQuestion?.idDomanda, props]);

  const positionQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 55);
  const startDatePositionQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 56);
  const sourceOfIncomeQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 57);
  const pepAsQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 58);
  const incomeBracketQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 59);
  const corporateInvestmentsQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 60);
  const judicialProceedingsQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 61);
  const pepFamilyMemberQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 62);
  const importantPoliticPositionsQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 63);
  const importantCorporatePositionsQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 64);
  const importantPublicAdministrationPositionsQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 65);
  const importantPublicFundsProvisionPositionsQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 66);

  const mapListaRisposteToRadioButtonOptions = (question: Domanda) =>
    question?.listaRisposte?.map(
      (resp) =>
        ({
          value: resp.idRisposta?.toString() ?? '',
          label: `${resp.descRisposta ?? ''} ${resp.descSubRisposta ?? ''}`
        } as AppRadioButtonConfig)
    ) ?? [];

  return (
    <div className={'margin-top'}>
      <h3 className={amlConsistency?.consistency?.find((c) => c.questionId === pepQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''}>
        {pepQuestion?.testoDomanda}
      </h3>
      <div className={'sub-section radio-first-sub'}>
        <AppRadioGroup
          selectedValue={props.getAmlAnswerDetailsByQuestionId('' + pepQuestion?.idDomanda) as string}
          options={mapListaRisposteToRadioButtonOptions(pepQuestion)}
          onSelectedValueChanged={(value) => {
            props.onSave([{ questionCode: '' + pepQuestion?.idDomanda, value: value }]);
            setPepAnswerId(value);
          }}
        />
      </div>
      {(pepAnswerId === '63' || pepAnswerId === '64') && (
        <div>
          <div className={'sub-section margin-top'}>
            <div className={'sub-section-field'}>
              <AppInputText
                valid={amlConsistency?.consistency?.find((c) => c.questionId === positionQuestion?.idDomanda)?.valid}
                type={'text'}
                placeholder={
                  props.getAmlAnswerDetailsByQuestionId('' + positionQuestion?.idDomanda)
                    ? (props.getAmlAnswerDetailsByQuestionId('' + positionQuestion?.idDomanda) as string)
                    : positionQuestion?.testoDomanda
                }
                required={true}
                onChange={(value) => {
                  value != undefined
                    ? props.onSave([
                        {
                          questionCode: '' + positionQuestion?.idDomanda,
                          value: value
                        }
                      ])
                    : props.onSave([{ questionCode: '' + positionQuestion?.idDomanda, value: '' }]);
                }}
              />
            </div>
            <AppDateSelect
              valid={amlConsistency?.consistency?.find((c) => c.questionId === startDatePositionQuestion?.idDomanda)?.valid}
              placeholder={
                props.getAmlAnswerDetailsByQuestionId('' + startDatePositionQuestion?.idDomanda) != undefined
                  ? new Date(props.getAmlAnswerDetailsByQuestionId('' + startDatePositionQuestion?.idDomanda) as string).toLocaleDateString()
                  : formatMessage(homeOnboardingSelfAmlFatcaCrsSurveyMessages.positionStartDate)
              }
              value={
                props.getAmlAnswerDetailsByQuestionId('' + startDatePositionQuestion?.idDomanda)
                  ? new Date(props.getAmlAnswerDetailsByQuestionId('' + startDatePositionQuestion?.idDomanda) as string)
                  : undefined
              }
              onSingleDateChange={(value) => {
                value != undefined
                  ? props.onSave([
                      {
                        questionCode: '' + startDatePositionQuestion?.idDomanda,
                        value: value.toDateString()
                      }
                    ])
                  : props.onSave([{ questionCode: '' + startDatePositionQuestion?.idDomanda, value: '' }]);
              }}
            />
          </div>
          <div className={'sub-section max-width'}>
            <AppInputText
              valid={amlConsistency?.consistency?.find((c) => c.questionId === sourceOfIncomeQuestion?.idDomanda)?.valid}
              type={'text'}
              placeholder={
                props.getAmlAnswerDetailsByQuestionId('' + sourceOfIncomeQuestion?.idDomanda)
                  ? (props.getAmlAnswerDetailsByQuestionId('' + sourceOfIncomeQuestion?.idDomanda) as string)
                  : sourceOfIncomeQuestion?.testoDomanda
              }
              required={true}
              onChange={(value) => {
                value != undefined
                  ? props.onSave([
                      {
                        questionCode: '' + sourceOfIncomeQuestion?.idDomanda,
                        value: value
                      }
                    ])
                  : props.onSave([{ questionCode: '' + sourceOfIncomeQuestion?.idDomanda, value: '' }]);
              }}
            />
          </div>
          <div
            className={`pep-sub-section ${
              amlConsistency?.consistency?.find((c) => c.questionId === pepAsQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
            }`}>
            <h4>{pepAsQuestion?.testoDomanda}</h4>
            <div>
              <AppRadioGroup
                selectedValue={
                  props.getAmlAnswerDetailsByQuestionId('' + pepAsQuestion?.idDomanda)
                    ? (props.getAmlAnswerDetailsByQuestionId('' + pepAsQuestion?.idDomanda) as string)
                    : pepAsQuestion?.testoDomanda
                }
                options={mapListaRisposteToRadioButtonOptions(pepAsQuestion)}
                onSelectedValueChanged={(value: string) => {
                  props.onSave([{ questionCode: '' + pepAsQuestion?.idDomanda, value: value }]);
                }}
              />
            </div>
          </div>
          <div
            className={`pep-sub-section ${
              amlConsistency?.consistency?.find((c) => c.questionId === incomeBracketQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
            }`}>
            <h4>{incomeBracketQuestion?.testoDomanda}</h4>
            <div>
              <AppRadioGroup
                selectedValue={
                  props.getAmlAnswerDetailsByQuestionId('' + incomeBracketQuestion?.idDomanda)
                    ? (props.getAmlAnswerDetailsByQuestionId('' + incomeBracketQuestion?.idDomanda) as string)
                    : incomeBracketQuestion?.testoDomanda
                }
                options={mapListaRisposteToRadioButtonOptions(incomeBracketQuestion)}
                onSelectedValueChanged={(value: string) => {
                  props.onSave([{ questionCode: '' + incomeBracketQuestion?.idDomanda, value: value }]);
                }}
              />
            </div>
          </div>
          <div
            className={`sub-section sub-section-text-and-radio ${
              amlConsistency?.consistency?.find((c) => c.questionId === corporateInvestmentsQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
            }`}>
            <div>
              <h4>{corporateInvestmentsQuestion?.testoDomanda}</h4>
            </div>
            <AppRadioGroup
              selectedValue={
                props.getAmlAnswerDetailsByQuestionId('' + corporateInvestmentsQuestion?.idDomanda)
                  ? (props.getAmlAnswerDetailsByQuestionId('' + corporateInvestmentsQuestion?.idDomanda) as string)
                  : corporateInvestmentsQuestion?.testoDomanda
              }
              options={mapListaRisposteToRadioButtonOptions(corporateInvestmentsQuestion)}
              onSelectedValueChanged={(value: string) => {
                props.onSave([{ questionCode: '' + corporateInvestmentsQuestion?.idDomanda, value: value }]);
              }}
            />
          </div>
          <div
            className={`sub-section sub-section-text-and-radio ${
              amlConsistency?.consistency?.find((c) => c.questionId === judicialProceedingsQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
            }`}>
            <div>
              <h4>{judicialProceedingsQuestion?.testoDomanda}</h4>
            </div>
            <AppRadioGroup
              selectedValue={
                props.getAmlAnswerDetailsByQuestionId('' + judicialProceedingsQuestion?.idDomanda)
                  ? (props.getAmlAnswerDetailsByQuestionId('' + judicialProceedingsQuestion?.idDomanda) as string)
                  : judicialProceedingsQuestion?.testoDomanda
              }
              options={mapListaRisposteToRadioButtonOptions(judicialProceedingsQuestion)}
              onSelectedValueChanged={(value: string) => {
                props.onSave([{ questionCode: '' + judicialProceedingsQuestion?.idDomanda, value: value }]);
              }}
            />
          </div>
        </div>
      )}
      {pepAnswerId === '65' && (
        <div>
          <div
            className={`pep-sub-section ${
              amlConsistency?.consistency?.find((c) => c.questionId === pepFamilyMemberQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
            }`}>
            <h4>{pepFamilyMemberQuestion?.testoDomanda}</h4>
            <div>
              <AppRadioGroup
                selectedValue={props.getAmlAnswerDetailsByQuestionId('' + pepFamilyMemberQuestion?.idDomanda) as string}
                options={mapListaRisposteToRadioButtonOptions(pepFamilyMemberQuestion)}
                onSelectedValueChanged={(value: string) => {
                  props.onSave([{ questionCode: '' + pepFamilyMemberQuestion?.idDomanda, value: value }]);
                }}
              />
            </div>
          </div>
          <div
            className={`pep-sub-section ${
              amlConsistency?.consistency?.find((c) => c.questionId === incomeBracketQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
            }`}>
            <h4>{incomeBracketQuestion?.testoDomanda}</h4>
            <div>
              <AppRadioGroup
                selectedValue={
                  props.getAmlAnswerDetailsByQuestionId('' + incomeBracketQuestion?.idDomanda)
                    ? (props.getAmlAnswerDetailsByQuestionId('' + incomeBracketQuestion?.idDomanda) as string)
                    : incomeBracketQuestion?.testoDomanda
                }
                options={mapListaRisposteToRadioButtonOptions(incomeBracketQuestion)}
                onSelectedValueChanged={(value: string) => {
                  props.onSave([{ questionCode: '' + incomeBracketQuestion?.idDomanda, value: value }]);
                }}
              />
            </div>
          </div>
          <div
            className={`sub-section sub-section-text-and-radio ${
              amlConsistency?.consistency?.find((c) => c.questionId === corporateInvestmentsQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
            }`}>
            <div>
              <h4>{corporateInvestmentsQuestion?.testoDomanda}</h4>
            </div>
            <AppRadioGroup
              selectedValue={
                props.getAmlAnswerDetailsByQuestionId('' + corporateInvestmentsQuestion?.idDomanda)
                  ? (props.getAmlAnswerDetailsByQuestionId('' + corporateInvestmentsQuestion?.idDomanda) as string)
                  : corporateInvestmentsQuestion?.testoDomanda
              }
              options={mapListaRisposteToRadioButtonOptions(corporateInvestmentsQuestion)}
              onSelectedValueChanged={(value: string) => {
                props.onSave([{ questionCode: '' + corporateInvestmentsQuestion?.idDomanda, value: value }]);
              }}
            />
          </div>
          <div
            className={`sub-section sub-section-text-and-radio ${
              amlConsistency?.consistency?.find((c) => c.questionId === judicialProceedingsQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
            }`}>
            <div>
              <h4>{judicialProceedingsQuestion?.testoDomanda}</h4>
            </div>
            <AppRadioGroup
              selectedValue={
                props.getAmlAnswerDetailsByQuestionId('' + judicialProceedingsQuestion?.idDomanda)
                  ? (props.getAmlAnswerDetailsByQuestionId('' + judicialProceedingsQuestion?.idDomanda) as string)
                  : judicialProceedingsQuestion?.testoDomanda
              }
              options={mapListaRisposteToRadioButtonOptions(judicialProceedingsQuestion)}
              onSelectedValueChanged={(value: string) => {
                props.onSave([{ questionCode: '' + judicialProceedingsQuestion?.idDomanda, value: value }]);
              }}
            />
          </div>
          <div
            className={`sub-section sub-section-text-and-radio ${
              amlConsistency?.consistency?.find((c) => c.questionId === importantPoliticPositionsQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
            }`}>
            <div>
              <h4>{importantPoliticPositionsQuestion?.testoDomanda}</h4>
            </div>
            <AppRadioGroup
              selectedValue={
                props.getAmlAnswerDetailsByQuestionId('' + importantPoliticPositionsQuestion?.idDomanda)
                  ? (props.getAmlAnswerDetailsByQuestionId('' + importantPoliticPositionsQuestion?.idDomanda) as string)
                  : importantPoliticPositionsQuestion?.testoDomanda
              }
              options={mapListaRisposteToRadioButtonOptions(importantPoliticPositionsQuestion)}
              onSelectedValueChanged={(value: string) => {
                props.onSave([{ questionCode: '' + importantPoliticPositionsQuestion?.idDomanda, value: value }]);
              }}
            />
          </div>
        </div>
      )}
      <div
        className={`sub-section sub-section-text-and-radio  margin-top ${
          amlConsistency?.consistency?.find((c) => c.questionId === importantCorporatePositionsQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
        }`}>
        <div>
          <h4>{importantCorporatePositionsQuestion?.testoDomanda}</h4>
        </div>
        <AppRadioGroup
          selectedValue={
            props.getAmlAnswerDetailsByQuestionId('' + importantCorporatePositionsQuestion?.idDomanda)
              ? (props.getAmlAnswerDetailsByQuestionId('' + importantCorporatePositionsQuestion?.idDomanda) as string)
              : importantCorporatePositionsQuestion?.testoDomanda
          }
          options={mapListaRisposteToRadioButtonOptions(importantCorporatePositionsQuestion)}
          onSelectedValueChanged={(value: string) => {
            props.onSave([{ questionCode: '' + importantCorporatePositionsQuestion?.idDomanda, value: value }]);
          }}
        />
      </div>
      <div
        className={`sub-section sub-section-text-and-radio ${
          amlConsistency?.consistency?.find((c) => c.questionId === importantPublicAdministrationPositionsQuestion?.idDomanda)?.valid === false
            ? 'question-invalid'
            : ''
        }`}>
        <div>
          <h4>{importantPublicAdministrationPositionsQuestion?.testoDomanda}</h4>
        </div>
        <AppRadioGroup
          selectedValue={
            props.getAmlAnswerDetailsByQuestionId('' + importantPublicAdministrationPositionsQuestion?.idDomanda)
              ? (props.getAmlAnswerDetailsByQuestionId('' + importantPublicAdministrationPositionsQuestion?.idDomanda) as string)
              : importantPublicAdministrationPositionsQuestion?.testoDomanda
          }
          options={mapListaRisposteToRadioButtonOptions(importantPublicAdministrationPositionsQuestion)}
          onSelectedValueChanged={(value: string) => {
            props.onSave([{ questionCode: '' + importantPublicAdministrationPositionsQuestion?.idDomanda, value: value }]);
          }}
        />
      </div>
      <div
        className={`sub-section sub-section-text-and-radio ${
          amlConsistency?.consistency?.find((c) => c.questionId === importantPublicFundsProvisionPositionsQuestion?.idDomanda)?.valid === false
            ? 'question-invalid'
            : ''
        }`}>
        <div>
          <h4>{importantPublicFundsProvisionPositionsQuestion?.testoDomanda}</h4>
        </div>
        <AppRadioGroup
          selectedValue={
            props.getAmlAnswerDetailsByQuestionId('' + importantPublicFundsProvisionPositionsQuestion?.idDomanda)
              ? (props.getAmlAnswerDetailsByQuestionId('' + importantPublicFundsProvisionPositionsQuestion?.idDomanda) as string)
              : importantPublicFundsProvisionPositionsQuestion?.testoDomanda
          }
          options={mapListaRisposteToRadioButtonOptions(importantPublicFundsProvisionPositionsQuestion)}
          onSelectedValueChanged={(value: string) => {
            props.onSave([{ questionCode: '' + importantPublicFundsProvisionPositionsQuestion?.idDomanda, value: value }]);
          }}
        />
      </div>
    </div>
  );
};
