/* eslint-disable react/jsx-no-bind */
import { getAmlQuestionById, homeOnboardingSelfAmlFatcaCrsSurveyMessages, TabItem } from 'azshared';
import { Domanda } from 'azshared/build/main/lib/api/generated';
import { DominioRequestIdDominioEnum } from 'azshared/build/main/lib/api/generated/dob';
import { fieldsDependencies } from 'azshared/build/main/lib/hooks/home/onboarding-self/fields-validation';
import React from 'react';
import { useIntl } from 'react-intl';
import { AppInputText } from '../../../../../../components/input-text/app-input-text';
import { AppSelect } from '../../../../../../components/select/app-select';
import { AmlSectionBodyProps } from '../../aml-fatca-crs-survey-page';

export const AmlTitolareSectionBody = (props: AmlSectionBodyProps) => {
  const { amlConsistency } = props;
  const { formatMessage } = useIntl();
  const studyTitleQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 44);
  const relationshipPurposeQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 45);
  const relationshipPurposeOtherQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 46);
  const employmentSectorQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 47);
  const employmentSectorOtherQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 48);
  const professionQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 49);
  const previousProfessionQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 50);

  const mapAnswersToOptions = (answers: any): TabItem<string>[] => {
    if (answers) {
      const options = Object.keys(answers).map((k) => {
        return { type: k, label: answers[k] } as TabItem<string>;
      });
      return options;
    }
    return [];
  };

  return (
    <div>
      <h3 className={'margin-top'}>{formatMessage(homeOnboardingSelfAmlFatcaCrsSurveyMessages.titolareSectionTitle)}</h3>
      <div className={'sub-section'}>
        <div
          className={`sub-section-field ${
            amlConsistency?.consistency?.find((c) => c.questionId === studyTitleQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
          }`}>
          <h4>{studyTitleQuestion?.testoDomanda}</h4>
          <AppSelect
            onChange={(value) => {
              props.onSave([{ questionCode: '' + studyTitleQuestion?.idDomanda, value: value as string }]);
            }}
            options={mapAnswersToOptions(props.listaDomini?.find((dominio) => dominio.idDominio === DominioRequestIdDominioEnum.TitoloStudio)?.valoriDominio)}
            value={
              props.getAmlAnswerDetailsByQuestionId('' + studyTitleQuestion?.idDomanda)
                ? props.getAmlAnswerDetailsByQuestionId('' + studyTitleQuestion?.idDomanda)
                : studyTitleQuestion?.testoDomanda
            }
            placeholder={'-'}
            required={true}
          />
        </div>
        <div
          className={`sub-section-field ${
            amlConsistency?.consistency?.find((c) => c.questionId === relationshipPurposeQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
          }`}>
          <h4>{relationshipPurposeQuestion?.testoDomanda}</h4>
          <AppSelect
            onChange={(value) => {
              props.onSave([{ questionCode: '' + relationshipPurposeQuestion?.idDomanda, value: value as string }]);
              if (
                value != '5' &&
                (props.getAmlAnswerDetailsByQuestionId('' + relationshipPurposeOtherQuestion?.idDomanda) == '' ||
                  props.getAmlAnswerDetailsByQuestionId('' + relationshipPurposeOtherQuestion?.idDomanda))
              ) {
                props.deleteAnswerByQuestionId('' + relationshipPurposeOtherQuestion?.idDomanda);
              }
            }}
            options={mapAnswersToOptions(props.listaDomini?.find((dominio) => dominio.idDominio === DominioRequestIdDominioEnum.NaturaRapporto)?.valoriDominio)}
            value={
              props.getAmlAnswerDetailsByQuestionId('' + relationshipPurposeQuestion?.idDomanda)
                ? props.getAmlAnswerDetailsByQuestionId('' + relationshipPurposeQuestion?.idDomanda)
                : relationshipPurposeQuestion?.testoDomanda
            }
            placeholder={'-'}
            required={true}
          />
        </div>
        <div
          className={`sub-section-field ${
            amlConsistency?.consistency?.find((c) => c.questionId === relationshipPurposeOtherQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
          }`}>
          <h4>{relationshipPurposeOtherQuestion?.testoDomanda}</h4>
          <AppInputText
            type={'text'}
            placeholder={'Altro'}
            required={true}
            onChange={(value) => {
              value != undefined
                ? props.onSave([
                    {
                      questionCode: '' + relationshipPurposeOtherQuestion?.idDomanda,
                      value: value
                    }
                  ])
                : props.onSave([{ questionCode: '' + relationshipPurposeOtherQuestion?.idDomanda, value: '' }]);
            }}
            disabled={!props.isEnabled(fieldsDependencies.NATURA_RAPPORTO_ALTRO.dependencies)}
            value={
              (props.getAmlAnswerDetailsByQuestionId('' + relationshipPurposeOtherQuestion?.idDomanda) as string)
                ? (props.getAmlAnswerDetailsByQuestionId('' + relationshipPurposeOtherQuestion?.idDomanda) as string)
                : ''
            }
          />
        </div>
      </div>
      <div className={'sub-section'}>
        <div
          className={`sub-section-field ${
            amlConsistency?.consistency?.find((c) => c.questionId === employmentSectorQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
          }`}>
          <h4>{employmentSectorQuestion?.testoDomanda}</h4>
          <AppSelect
            onChange={(value) => {
              props.onSave([{ questionCode: '' + employmentSectorQuestion?.idDomanda, value: value as string }]);
              if (value != '07' && props.getAmlAnswerDetailsByQuestionId('' + employmentSectorOtherQuestion?.idDomanda)) {
                props.deleteAnswerByQuestionId('' + employmentSectorOtherQuestion?.idDomanda);
              }
            }}
            options={mapAnswersToOptions(
              props.listaDomini?.find((dominio) => dominio.idDominio === DominioRequestIdDominioEnum.SettoreOccupazioneIAml)?.valoriDominio
            )}
            value={
              props.getAmlAnswerDetailsByQuestionId('' + employmentSectorQuestion?.idDomanda)
                ? props.getAmlAnswerDetailsByQuestionId('' + employmentSectorQuestion?.idDomanda)
                : employmentSectorQuestion?.testoDomanda
            }
            placeholder={'-'}
            required={true}
            disabled={!props.isEnabled(fieldsDependencies.SETTORE_OCCUPAZIONE.dependencies)}
          />
        </div>
        <div
          className={`sub-section-field dropdown-scroll ${
            amlConsistency?.consistency?.find((c) => c.questionId === employmentSectorOtherQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
          }`}>
          <h4>{employmentSectorOtherQuestion?.testoDomanda}</h4>
          <AppSelect
            onChange={(value) => {
              props.onSave([{ questionCode: '' + employmentSectorOtherQuestion?.idDomanda, value: value as string }]);
            }}
            options={mapAnswersToOptions(
              props.listaDomini?.find((dominio) => dominio.idDominio === DominioRequestIdDominioEnum.SettoreOccupazioneIi)?.valoriDominio
            )}
            value={
              props.getAmlAnswerDetailsByQuestionId('' + employmentSectorOtherQuestion?.idDomanda)
                ? props.getAmlAnswerDetailsByQuestionId('' + employmentSectorOtherQuestion?.idDomanda)
                : employmentSectorOtherQuestion?.testoDomanda
            }
            placeholder={'Altro'}
            required={true}
            disabled={!props.isEnabled(fieldsDependencies.SETTORE_OCCUPAZIONE_ALTRO.dependencies)}
          />
        </div>
      </div>
      <div className={'sub-section'}>
        <div
          className={`sub-section-field ${
            amlConsistency?.consistency?.find((c) => c.questionId === professionQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
          }`}>
          <h4>{professionQuestion?.testoDomanda}</h4>
          <AppSelect
            onChange={(value) => {
              props.onSave([{ questionCode: '' + professionQuestion?.idDomanda, value: value as string }]);
              if (
                value != '145' &&
                props.getAmlAnswerDetailsByQuestionId('50') != '145' &&
                (props.getAmlAnswerDetailsByQuestionId('51') || props.getAmlAnswerDetailsByQuestionId('52') || props.getAmlAnswerDetailsByQuestionId('53'))
              ) {
                props.deleteAnswerByQuestionId('51');
                props.deleteAnswerByQuestionId('52');
                props.deleteAnswerByQuestionId('53');
              }
              if ((value == '002' || value == '003' || value == '110') && props.getAmlAnswerDetailsByQuestionId('' + employmentSectorQuestion?.idDomanda)) {
                props.deleteAnswerByQuestionId('' + employmentSectorQuestion?.idDomanda);
              }
              if (value != '003' && props.getAmlAnswerDetailsByQuestionId('' + previousProfessionQuestion?.idDomanda)) {
                props.deleteAnswerByQuestionId('' + previousProfessionQuestion?.idDomanda);
              }
            }}
            options={mapAnswersToOptions(
              props.listaDomini?.find((dominio) => dominio.idDominio === DominioRequestIdDominioEnum.ProfessioneAttualeAml)?.valoriDominio
            )}
            value={
              props.getAmlAnswerDetailsByQuestionId('' + professionQuestion?.idDomanda)
                ? props.getAmlAnswerDetailsByQuestionId('' + professionQuestion?.idDomanda)
                : professionQuestion?.testoDomanda
            }
            placeholder={'-'}
            required={true}
          />
        </div>
        <div
          className={`sub-section-field ${
            amlConsistency?.consistency?.find((c) => c.questionId === previousProfessionQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
          }`}>
          <h4>{previousProfessionQuestion?.testoDomanda}</h4>
          <AppSelect
            onChange={(value) => {
              props.onSave([{ questionCode: '' + previousProfessionQuestion?.idDomanda, value: value as string }]);
              if (
                value != '145' &&
                (props.getAmlAnswerDetailsByQuestionId('51') || props.getAmlAnswerDetailsByQuestionId('52') || props.getAmlAnswerDetailsByQuestionId('53'))
              ) {
                props.deleteAnswerByQuestionId('51');
                props.deleteAnswerByQuestionId('52');
                props.deleteAnswerByQuestionId('53');
              }
            }}
            options={mapAnswersToOptions(
              props.listaDomini?.find((dominio) => dominio.idDominio === DominioRequestIdDominioEnum.ProfessioneAttualeAml)?.valoriDominio
            )}
            value={
              props.getAmlAnswerDetailsByQuestionId('' + previousProfessionQuestion?.idDomanda)
                ? props.getAmlAnswerDetailsByQuestionId('' + previousProfessionQuestion?.idDomanda)
                : previousProfessionQuestion?.testoDomanda
            }
            placeholder={'-'}
            required={true}
            disabled={!props.isEnabled(fieldsDependencies.PROFESSIONE_PRECEDENTE.dependencies)}
          />
        </div>
      </div>
    </div>
  );
};
