import {
  amountFormatter,
  DashboardWalletSummary,
  HomeCard,
  shortLabelForWalletMacroCategoryList,
  walletPageMessages
} from 'azshared';
import React, {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';
import {AppDialog} from '../../../../../components/dialog/app-dialog';
import {AppProgressBar} from '../../../../../components/progress-bar/app-progress-bar';
import {AppSvgIcon} from '../../../../../components/svg-icon/app-svg-icon';
import {PirDeposit} from '../../../components/PirDeposit';
import './home-my-wallet-card.scss';

interface HomeMyWalletCardBarsProps {
  card: HomeCard<DashboardWalletSummary>;
  pirBoxDisclaimer: string
}

export const HomeMyWalletCardBars = (props: HomeMyWalletCardBarsProps) => {  
  const [openModal, setOpenModal] = useState(false);
  const {formatMessage} = useIntl();
  const { card , pirBoxDisclaimer} = props;

  const handleModal = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  return (
    <div className='macrocategories-list'>
      {card.value?.macroCategories &&
        card.value?.macroCategories.map((macroCategory, index) => (
          <div key={index} className='macrocategories-progress-bar'>
            <div className='amount-macrocategory'>
              <div
                className='amount-macrocategory-label'>{formatMessage(shortLabelForWalletMacroCategoryList(macroCategory.type))}</div>
              <div className={`amount-macrocategory-amount ${!card.value?.flagShowBalance ? 'blurry-text' : ''}`}>
                {amountFormatter(macroCategory.amount).split(' ', 2)[0]}{' '}
                <span className={`amount-symbol ${!card.value?.flagShowBalance ? 'blurry-text' : ''}`}>
                  {amountFormatter(macroCategory.amount).split(' ', 2)[1]}
                </span>
              </div>
            </div>
            <AppProgressBar
              value={(macroCategory.percentage || 0) * 100}
              color={macroCategory.color ? macroCategory.color : '#C4C4C4'}
            />            
          </div>
        ))}        
       <>
        {pirBoxDisclaimer === 'MY_WALLET' &&<PirDeposit/>}       
      </>      
      
      <AppDialog
        open={openModal}
        onClose={handleModal}
      >
        <div className='home-wallet-card-modal'>
          <div className='back-modal'>
            <AppSvgIcon icon='arrow-previous' onClick={handleModal}/>
            <p>{formatMessage(walletPageMessages.walletBackTextModal)}</p>
          </div>
          <div className='modal-text'>
            {formatMessage(walletPageMessages.walletTotalModal)}
          </div>

        </div>
      </AppDialog>
    </div>
  );
};
