import { CommunicationsDocument, documentsAreaMessages, useCommunicationDocumentsTabLogic, DocumentsAreaSections } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import { Card, CardSize } from '../../../../../components/card/card';
import { Loader, LoaderSize } from '../../../../../components/loader/loader';
import { NoDocumentsEmptyState } from '../../../../../components/no-documents-empty-state/no-documents-empty-state';
import { useGlobalActions } from '../../../../../hooks/global-actions';
import { CommunicationsTable } from '../communication-table/communications-table';

import './communications-card.scss';

interface CommunicationCardProps {
  onReadDocument: (document: CommunicationsDocument, documentsAreaSection: DocumentsAreaSections) => void;
  onDownloadDocument: (document: CommunicationsDocument, documentsAreaSection: DocumentsAreaSections) => void;
}

export const CommunicationsCard = ( props: CommunicationCardProps ) => {
  const { formatMessage } = useIntl();
  const globalActions = useGlobalActions();

  const { communications, isRequestLoading } = useCommunicationDocumentsTabLogic(globalActions);

  return (
    <div className='communications-card'>
      <Card sizeVariant={CardSize.MAXI}>
        {!isRequestLoading && (!communications || communications?.length === 0) && <NoDocumentsEmptyState text={formatMessage(documentsAreaMessages.unavailableDocuments)} />}
        {isRequestLoading && (
          <div className='communications-card-loader'>
            <Loader loadersize={LoaderSize.XL} />
          </div>
        )}
        {!isRequestLoading && communications?.length > 0 &&  (
          <CommunicationsTable
          documents={communications}
          {...props}
        />
        )}
      </Card>
    </div>
  );
};
