import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import { signatureAreaMessages, SignatureDocumentServiceActivationModule, useSignatureAreaLogic } from 'azshared';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Card, CardSize } from '../../../components/card/card';
import { AppErrorDialog } from '../../../components/dialog/app-error-dialog';
import { AppSuccessDialog } from '../../../components/dialog/app-success-dialog';
import { AppHeader } from '../../../components/header/header';
import { Loader, LoaderSize } from '../../../components/loader/loader';
import { NoDocumentsEmptyState } from '../../../components/no-documents-empty-state/no-documents-empty-state';
import { AppSvgIcon } from '../../../components/svg-icon/app-svg-icon';
import { useGlobalActions } from '../../../hooks/global-actions';
import { DocumentDetailDialog } from '../../document-detail-dialog/document-detail-dialog';
import { RejectProposalDialog } from './components/reject-proposal-dialog/reject-proposal-dialog';
import { SignatureAreaRow } from './components/signature-area-row';
import { SignatureAreaTabElement } from './components/signature-area-tab-element';
import './signature-area-page.scss';
import { AppButton, AppButtonType, AppButtonVariant } from '../../../components/buttons/app-button';
import { AppDialog } from '../../../components/dialog/app-dialog';

export interface SignatureAreaPageProps {
  isBackFromNamirial?: boolean;
}

export const SignatureAreaPage = (props: SignatureAreaPageProps) => {
  const { formatMessage } = useIntl();
  const globalActions = useGlobalActions();
  const { isBackFromNamirial } = props;

  const [documentDetailDialogOpen, setDocumentDetailDialogOpen] = useState(false);
  const [rejectProposalDialogOpen, setRejectProposalDialogOpen] = useState(false);
  const [openRepeatQuestionnairePopup, setOpenRepeatQuestionnairePopup] = useState(false);
  const [repeatMifid, setRepeatMifid] = useState(false);
  const [repeatAml, setRepeatAml] = useState(false);
  const [rejectProposalId, setRejectProposalId] = useState<string>();
  const [bundleId, setBundleId] = useState<string>();

  const [repeatQuestionnaireMessage, setRepeatQuestionnaireMessage] = useState<string>();

  const {
    selectedSection,
    documents,
    isLoadingDocuments,
    selectedModule,
    openSignedSucceededPopup,
    openSignedFailedPopup,
    setSelectedSection,
    updateDocumentsList,
    setSelectedModule,
    startProcedure,
    rejectProposal,
    backFromNamirial,
    closeSignedNotificationPopup,
    practiceUnderProcessing,
    cancelPracticeButtonEnabled,
    handleCancelPractice,
    isProspect,
    repeatQuestionnaire,
    flagProfilaturaMifid
  } = useSignatureAreaLogic(globalActions);

  useEffect(() => {
    if (!isBackFromNamirial) {
      updateDocumentsList();
    }
  }, [updateDocumentsList, isBackFromNamirial]);

  useEffect(() => {
    if (isBackFromNamirial) {
      backFromNamirial();
    }
  }, [isBackFromNamirial, backFromNamirial]);

  useEffect(() => {
    if (repeatMifid) {
      setRepeatQuestionnaireMessage(formatMessage(signatureAreaMessages.disclaimerMifidSurveryTitle));
    } else if (repeatAml) {
      setRepeatQuestionnaireMessage(formatMessage(signatureAreaMessages.disclaimerAMLSurveryTitle));
    }
  }, [repeatMifid, repeatAml, formatMessage]);

  const handleOpenDetail = useCallback(
    (moduleId: string, documentId: string) => {
      setSelectedModule(moduleId, documentId);
      setDocumentDetailDialogOpen(true);
    },
    [setSelectedModule]
  );

  const closeDocumentDetailDialog = useCallback(() => {
    setDocumentDetailDialogOpen(false);
  }, [setDocumentDetailDialogOpen]);

  const closeRepeatQuestionnairePopup = useCallback(() => {
    setOpenRepeatQuestionnairePopup(false);
    setRepeatMifid(false);
    setRepeatAml(false);
  }, [setOpenRepeatQuestionnairePopup]);

  const handleOpenRepeatPopup = useCallback((questionnaire: string) => {
    if (documents.length > 0) {
      setBundleId(documents[0].id);
      setOpenRepeatQuestionnairePopup(true);
      if (questionnaire === 'mifid') {
        setRepeatMifid(true);
        setRepeatAml(false);
      } else if (questionnaire === 'aml') {
        setRepeatAml(true);
        setRepeatMifid(false);
      }
    }
  }, [documents]);

  const onRepeatMifid = useCallback(() => handleOpenRepeatPopup('mifid'), [handleOpenRepeatPopup]);
  const onRepeatAml = useCallback(() => handleOpenRepeatPopup('aml'), [handleOpenRepeatPopup]);

  const handleRejectProposal = useCallback(
    (bundle: string) => {
      setRejectProposalDialogOpen(true);
      setRejectProposalId(bundle);
    },
    [setRejectProposalId, setRejectProposalDialogOpen]
  );

  const closeRejectProposalDialog = useCallback(() => {
    setRejectProposalDialogOpen(false);
    setRejectProposalId(undefined);
  }, [setRejectProposalId, setRejectProposalDialogOpen]);

  const handleConfirmRejectProposal = useCallback(() => {
    setRejectProposalDialogOpen(false);
    rejectProposal(rejectProposalId || '');
  }, [rejectProposal, rejectProposalId]);

  const handleRepeatQuestionnaire = useCallback(
    () => {
      setRejectProposalId(bundleId);
      rejectProposal(bundleId || '');
      if (repeatMifid) {
        repeatQuestionnaire('mifid');
      } else if (repeatAml) {
        repeatQuestionnaire('aml');
      }
    },
    [rejectProposal, repeatQuestionnaire, repeatMifid, repeatAml, bundleId]
  );

  return (
    <div className='signature-area-page'>
      <AppHeader />
      <div className='card-signature-area'>
        <Card title={formatMessage(signatureAreaMessages.pageTitle)} back={''} sizeVariant={CardSize.MAXI}>
          <div className='signature-area-page-header'>
            <SignatureAreaTabElement
              text={formatMessage(signatureAreaMessages.allSectionTitle)}
              section='ALL'
              isSelected={selectedSection === 'ALL'}
              onTabSelected={setSelectedSection}
            />
            <SignatureAreaTabElement
              text={formatMessage(signatureAreaMessages.toSignSectionTitle)}
              isSelected={selectedSection === 'TO_SIGN'}
              section='TO_SIGN'
              onTabSelected={setSelectedSection}
            />
            <SignatureAreaTabElement
              text={formatMessage(signatureAreaMessages.expiringSectionTitle)}
              isSelected={selectedSection === 'EXPIRING'}
              section='EXPIRING'
              onTabSelected={setSelectedSection}
            />

            <div className='signature-area-page-header-refresh'>
            {practiceUnderProcessing===1 && <span className='signature-area-page-header-refresh-label'>{formatMessage(signatureAreaMessages.singlePracticeUnderProcessing)}</span>}
            {practiceUnderProcessing>1 && <span className='signature-area-page-header-refresh-label'>{formatMessage(signatureAreaMessages.multiplePracticeUnderProcessing, {practiceUnderProcessing})}</span>}
            <AppSvgIcon icon='refresh' onClick={updateDocumentsList} />
            </div>

            {!isLoadingDocuments && documents.length === 0 && <NoDocumentsEmptyState text={formatMessage(signatureAreaMessages.noDocumentsMessage)} />}
          </div>

          {(isLoadingDocuments || (!isLoadingDocuments && documents.length > 0)) && (
            <TableContainer className='signature-area-page-table'>
              <Table aria-label='sticky collapsible table'>
                <TableHead>
                  <TableRow>
                    <TableCell className='signature-area-page-table-header'>
                      <div className='signature-area-page-table-header-content'>{formatMessage(signatureAreaMessages.TitleDocumentLabel)}</div>
                    </TableCell>
                    <TableCell className='signature-area-page-table-header'>
                      <div className='signature-area-page-table-header-content'>{formatMessage(signatureAreaMessages.clientLabel)}</div>
                    </TableCell>
                    <TableCell className='signature-area-page-table-header' align='center'>
                      <div className='signature-area-page-table-header-content'>{formatMessage(signatureAreaMessages.totalDocumentLabel)}</div>
                    </TableCell>
                    <TableCell className='signature-area-page-table-header' />
                    <TableCell className='signature-area-page-table-header arrow' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {isLoadingDocuments && (
                      <TableRow>
                        <TableCell style={{ padding: 0, border: 'none' }} colSpan={6}>
                          <div className='table-loader'>
                            <Loader loadersize={LoaderSize.XL} />
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                    {!isLoadingDocuments &&
                      documents.map((document) => (
                        <SignatureAreaRow
                          key={document.id}
                          document={document}
                          openDetail={handleOpenDetail}
                          rejectProposal={handleRejectProposal}
                          startProcedure={startProcedure}
                          selectedSection={selectedSection}
                          isProspect={isProspect}
                        />
                      ))}
                  </>
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {(cancelPracticeButtonEnabled && documents.length > 0) &&
            <div className='edit-questionnaires-area'>
              <button onClick={onRepeatAml}>{formatMessage(signatureAreaMessages.editAmlSurveryLabel)}</button>
              {flagProfilaturaMifid && <button onClick={onRepeatMifid}>{formatMessage(signatureAreaMessages.editMifidSurveryLabel)}</button>}
              <button onClick={handleCancelPractice}>{formatMessage(signatureAreaMessages.cancelPracticeLabel)}</button>
            </div>
          }
          <DocumentDetailDialog
            operations={(selectedModule as SignatureDocumentServiceActivationModule)?.moduleOperation}
            information={(selectedModule as SignatureDocumentServiceActivationModule)?.moduleInformation}
            signatories={(selectedModule as SignatureDocumentServiceActivationModule)?.moduleSignatory}
            type={selectedModule?.type}
            filename={selectedModule?.title ?? ''}
            key={documentDetailDialogOpen ? 'open' : 'closed'}
            documentDetailDialogOpen={documentDetailDialogOpen}
            closeDocumentDetailDialog={closeDocumentDetailDialog}
          />
          <RejectProposalDialog
            open={rejectProposalDialogOpen}
            onCancel={closeRejectProposalDialog}
            onClose={closeRejectProposalDialog}
            onReject={handleConfirmRejectProposal}
          />
          <AppSuccessDialog open={openSignedSucceededPopup} onClose={closeSignedNotificationPopup}>
            <div className='text-dialog'>{formatMessage(signatureAreaMessages.signedSucceededDialogText)}</div>
          </AppSuccessDialog>
          <AppErrorDialog open={openSignedFailedPopup} onClose={closeSignedNotificationPopup}>
            <div className='text-dialog'>{formatMessage(signatureAreaMessages.signedFailedDialogText)}</div>
          </AppErrorDialog>

          <AppDialog open={openRepeatQuestionnairePopup} onClose={closeRepeatQuestionnairePopup}>
            <div className='exit-app-dialog-section'>
              <div className='exit-app-dialog-section-title'>{repeatQuestionnaireMessage}</div>
              <div className='exit-app-dialog-section-desc'>{formatMessage(signatureAreaMessages.disclaimerRepeatQuestionnaireText)}</div>
              <div className='exit-app-dialog-section-btn'>
                <AppButton
                  type={AppButtonType.BUTTON}
                  variant={AppButtonVariant.Primary}
                  label={formatMessage(signatureAreaMessages.goBackLabel)}
                  onClick={closeRepeatQuestionnairePopup}
                />
                <AppButton
                  type={AppButtonType.BUTTON}
                  variant={AppButtonVariant.Primary}
                  label={formatMessage(signatureAreaMessages.repeatQuestionnaire)}
                  onClick={handleRepeatQuestionnaire}
                />
              </div>
            </div>
          </AppDialog>
        </Card>
      </div>
    </div>
  );
};
