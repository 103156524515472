import React from 'react';

import { Link, LinkProps } from 'react-router-dom';

import './app-link.scss';

interface AppLinkProps extends LinkProps {
  label: string;
}

export const AppLink = (props: AppLinkProps) => (
  <Link {...props} className='app-link'>
    {props.label}
  </Link>
);
