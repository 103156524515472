import { useAppSelector, useHomeLogic, useLogoutLogic, UserState } from 'azshared';
import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { Menu } from '../../components/menu/menu';
import { useGlobalActions } from '../../hooks/global-actions';
import { menuItems } from '../../utils/menu-items';
import { AppMenuHeader } from './components/menu-header';
import './home-container.scss';

export const HomeContainer = () => {
	const globalActions = useGlobalActions();
	const { selectedSection, onSelectedSectionChanged, onProfileIconPress } = useHomeLogic();
	const {isLoggingOut, onLogout } = useLogoutLogic(globalActions);
	const { name, surname, informativeMode, businessName, userType } = useAppSelector<UserState>((state) => state.data.user);
	const menuItemsByRole = useMemo(() => menuItems.filter(item => !informativeMode || (item.section !== 'documents' && item.section !== 'sign')), [informativeMode]);

	return (
		<div className='home-container'>
			<Menu
				menuItems={menuItemsByRole}
				selectedSection={selectedSection || 'home'}
				onSelectedSectionChanged={onSelectedSectionChanged}
				onLogout={onLogout}
				isLoggingOut={isLoggingOut}
			>
				<AppMenuHeader onProfileIconPress={onProfileIconPress} name={name} surname={surname} businessName={businessName} userType={userType}/>
			</Menu>

			<div className='content'>
				<Outlet />
			</div>
		</div>
	);
};
