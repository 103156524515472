import React from 'react';
import './app-toggle.scss';

export interface AppToggleProps {
  label?: string;
  disabled?: boolean;
  checked: boolean;
  onToggle: () => void;
}

export const AppToggle = (props: AppToggleProps) => {
  const { label, disabled = false, checked = false, onToggle } = props;
  const id = `toggle-${new Date().getTime()}`;
  return (
    <div className='app-toggle-container'>
      <label htmlFor={id} className={`app-toggle${disabled ? ' disabled' : ''}`}>
        <input id={id} disabled={disabled} checked={checked} onChange={onToggle} type='checkbox' name='checkbox' />
        <span className={`slider round${disabled ? ' disabled' : ''}`} />
      </label>
      {label && <span className='label'>{label}</span>}
    </div>
  );
};
