import { IconButton } from '@mui/material';
import { performanceFilterCategoriesMap, ProductMacroCategoryType } from 'azshared';
import React, { MouseEvent, RefObject, useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppSvgIcon } from '../../../../../components/svg-icon/app-svg-icon';
import useEventListener from '../../../../../hooks/use-event-listener';
import { PerformanceFilterProps } from './performance-filter-props';
import './performance-filter.scss';

export const PerformanceCategoryFilter = (props: PerformanceFilterProps) => {
	const { filter, onFilterChange } = props;

	const { formatMessage } = useIntl();
	const [ openCategoryFilter, setOpenCategoryFilter ] = useState(false);
  const refDropdown: RefObject<HTMLDivElement> = useRef(null);

	const handleOpenCategoryFilter = useCallback(
		(e: MouseEvent<HTMLButtonElement | HTMLLabelElement>) => {
			e.preventDefault();
			setOpenCategoryFilter(!openCategoryFilter);
		},
		[ openCategoryFilter, setOpenCategoryFilter ]
	);

	const handleSelectCategory = useCallback(
		(e: MouseEvent<HTMLDivElement>) => {
			e.preventDefault();
			onFilterChange({ ...filter, macroCategory: (e.target as HTMLDivElement).id as ProductMacroCategoryType});
			setOpenCategoryFilter(false);
		},
		[ filter, onFilterChange ]
	);

	const handleClick = useCallback(
		(event: Event) => {
      if (refDropdown.current && !refDropdown.current.contains(event.target as Node)) {
        setOpenCategoryFilter(false);
      }
    },
    [refDropdown]
	);

	useEventListener('mousedown', handleClick);

	return (
		<div className='my-wallet-section-icon my-wallet-section-icon-filter'>
			<IconButton onClick={handleOpenCategoryFilter}>
				<AppSvgIcon icon='filter' />
			</IconButton>
			<label onClick={handleOpenCategoryFilter} className='my-wallet-section-icon-label'>
				{formatMessage(performanceFilterCategoriesMap[filter.macroCategory])}
			</label>
			<div
				className={`app-performance-filter-dropdown app-performance-filter-macro-categories-dropdown ${openCategoryFilter
					? 'is-open'
					: ''}`} ref={refDropdown}>
				{Object.keys(performanceFilterCategoriesMap).filter((key) => key !== filter.macroCategory).map((key) => (
					<div
						key={key}
						id={key}
						className='app-performance-filter-macro-categories-dropdown-item'
						onClick={handleSelectCategory}>
						{formatMessage(performanceFilterCategoriesMap[key])}
					</div>
				))}
			</div>
		</div>
	);
};
