import { faqsPageMessages } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import { AppHeader } from '../../../../../components/header/header';
import { FaqCard } from '../faq-card/faq-card';

export const FaqPage = () => {
  const { formatMessage } = useIntl();

  return (
    <div className='faq-page'>
      <AppHeader faqPage={true} />
      <h2>{formatMessage(faqsPageMessages.title)}</h2>
      <div className='faq-page-card'>
        <FaqCard />
      </div>
    </div>
  );
};
