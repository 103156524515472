import { RangeDate} from 'azshared';
import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useEventListener from '../../hooks/use-event-listener';
import AppDateDropdown, { DropdownDirectionType } from '../date-dropdown/app-date-dropdown';
import { AppDateSelectInnerText } from './app-date-select-inner-text';
import Calendar from 'react-calendar';
import './app-select.scss';

export interface AppDateSelectProps {
	label?: string;
	placeholder?: string;
	disabled?: boolean;
	errorMessage?: string;
	required?: boolean;
	value: RangeDate | Date | undefined;
	arrangement?: DropdownDirectionType;
  onChange?: (value: RangeDate) => void;
  isValid?: (value: RangeDate) => boolean;
	onSingleDateChange?: (value: Date) => void;
	valid?: boolean;
}

export const AppDateSelect = (props: AppDateSelectProps) => {
	const {
		label,
		placeholder,
		disabled,
		errorMessage = '',
		required,
		value,
		arrangement = 'vertical',
		onChange,
		isValid = () => true,
		onSingleDateChange,
		valid = true
	} = props;
	const [ isOpen, setIsOpen ] = useState(false);
	const [ rangeDate, setRangeDate ] = useState<RangeDate>({
    from: undefined,
    to: undefined
  });

	const refDropdown: RefObject<HTMLDivElement> = useRef(null);
	const refInput: RefObject<HTMLDivElement> = useRef(null);
	const refSingleDateInput: RefObject<HTMLInputElement> = useRef(null);

  const enableApplyButton = useMemo(() => isValid(rangeDate), [ rangeDate, isValid ]);
  
  useEffect(() => {
		if (value != undefined && !(value instanceof Date)) {
			setRangeDate(value as RangeDate);
		}
	}, [value]);

	const handleDateChange = useCallback(
		(values: Date | [Date, Date]) => {
			if (Array.isArray(values)) {
				setRangeDate({
					from: values[0],
					to: values[1]
				});
			}
		},
		[ setRangeDate ]
	);
	
	const handleSingleDateChange = useCallback(
		(value: Date) => {
			if (onSingleDateChange) {
				onSingleDateChange(value);
				setIsOpen(false);
			}
		},
		[onSingleDateChange]
	);

	const toggle = useCallback(
		() => {
			if (!disabled) {
				setIsOpen(!isOpen);
			}
		},
		[ disabled, isOpen ]
	);


  const handleSubmit = useCallback(
		() => {
			toggle();
			if (onChange) {
				onChange(rangeDate);
			}
		},
		[ rangeDate, onChange, toggle ]
	);

	const handleClick = useCallback(
		(event: Event) => {
			if (
				refDropdown.current &&
				!refDropdown.current.contains(event.target as Node) &&
				!refInput?.current?.contains(event.target as Node)
			) {
				setIsOpen(false);
			}
		},
		[ refDropdown, refInput ]
	);

	const handleClickSingleDate = useCallback(
		(event: Event) => {
			if (
				refSingleDateInput.current &&
				!refSingleDateInput.current.contains(event.target as Node) &&
				!refInput?.current?.contains(event.target as Node)
			) {
				setIsOpen(false);
			}
		},
		[ refSingleDateInput, refInput ]
	);

	useEventListener('mousedown', handleClick);
	useEventListener('mousedown', handleClickSingleDate);

	return (
		<div className='app-select'>
			{label && (
				<label>
					{label}
					{required ? '*' : ''}
				</label>
			)}
			<div
				ref={refInput}
				tabIndex={0}
				className={`app-select-input ${(errorMessage || !valid) ? 'app-select-error' : ''} ${disabled
					? 'app-select-disabled'
					: ''} ${!value ? 'app-select-placeholder' : ''}`}
				onClick={toggle}
			>
				<AppDateSelectInnerText value={value as RangeDate} placeholder={placeholder}/>
			</div>
			{isOpen && !onSingleDateChange && (
				<AppDateDropdown
					value={rangeDate}
					arrangement={arrangement}
					ref={refDropdown}
					onDateChange={handleDateChange}
					onSubmit={handleSubmit}
					enableApplyButton={enableApplyButton}
				/>
			)}
			{isOpen && onSingleDateChange && (
					<Calendar
						value={value as Date}
						onChange={handleSingleDateChange}
						returnValue={'end'}
						selectRange={false}
						className='app-date-filter-calendar'
						tileClassName='app-date-filter-calendar-tile'
						minDetail='month'
						maxDate={new Date()}
						inputRef={refSingleDateInput}
					/>
			)}
			{errorMessage && (
				<div className='message'>
					<span className='error-message'>{errorMessage}</span>
				</div>
			)}
		</div>
	);
};
