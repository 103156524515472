import {
  amountFormatter,
  dateFormatter,
  DownloaderMediaType,
  FileDownloadedType,
  pirBoxCardMessages,
  useMyWalletDownloadFileLogic
} from 'azshared';
import { PirBoxProductSummary} from 'azshared/build/main/lib/model/pirbox/pirbox-product-summary';
import React, {useCallback, useEffect, useState} from 'react';
import {AppButtonIcon} from '../../../components/button-icon/app-button-icon';
import {PirBoxAccordion} from './pir-box-accordion';
import {useIntl} from 'react-intl';
import {SummaryTable} from './summary-table';
import {ProductsDetailsTable} from './products-details-table';
import {downloadFile} from '../../../utils/utils';
import {useGlobalActions} from '../../../hooks/global-actions';

interface PirBoxItemProps {
  summary: PirBoxProductSummary | undefined;
  onDownloadFile: (mandate: string, type: FileDownloadedType, mediaType: DownloaderMediaType) => void;
  onShowGraph: (mandate: string) => void;
}

export const PirBoxItem = (props: PirBoxItemProps) => {
  const {summary, onDownloadFile, onShowGraph} = props;
  const type = summary?.mandateCode.includes('A') ? 'ordinario' : 'alternative';
  const {formatMessage} = useIntl();

  const emptyAmount = {
    value: 0,
    currency: 'EUR'
  };
  const globalActions = useGlobalActions();

  const [clickOnDownload, setClickOnDownload] = useState(false);

  const {fileDownloaded, fileDownloadedRequestStatus} = useMyWalletDownloadFileLogic(globalActions);

  useEffect(() => {
    if (clickOnDownload && fileDownloadedRequestStatus === 'succeeded' && fileDownloaded.type === 'pir') {
      downloadFile(fileDownloaded.name, fileDownloaded.blob);
    }
  }, [clickOnDownload, fileDownloadedRequestStatus, fileDownloaded]);

  const handleDownload = useCallback(() => {
    setClickOnDownload(true);
    if (summary) onDownloadFile(summary.mandateCode, 'pir', 'pdf');
  }, [onDownloadFile, summary]);

  const handleShowGraph = useCallback(() => {
    if (summary) onShowGraph(summary.mandateCode);
  }, [onShowGraph, summary]);

  const dataScadenza = new Date(summary?.rapportiPadre.map((rap) => rap.dataScadenzaPirBox?.toString()).find((value) => value !== undefined) as string);
  return (
    <div className='pirbox-item'>
      <h4>{formatMessage(type === 'ordinario' ? pirBoxCardMessages.ordinaryDepositTitle : pirBoxCardMessages.alternativeDepositTitle)}</h4>
      <div className='pirbox-item-header'>
        <div className='invested-info'>
          <p className='label'>
            {formatMessage(pirBoxCardMessages.investableAmountLabel)}
            <sup >1</sup>
          </p>
          <p className='amount'>{amountFormatter(summary ? summary.totalInvestable : emptyAmount)}</p>
        </div>
        <div className='invested-info'>
          <p className='label'>
            {formatMessage(pirBoxCardMessages.netInvestedAmount)}
            <sup >1</sup>
          </p>
          <p className='amount'>{amountFormatter(summary ? summary.totalNet : emptyAmount)}</p>
        </div>
        <div className='invested-info'>
          <p className='label'>
            {formatMessage(pirBoxCardMessages.investableResidueLabel)}
            <sup>2</sup>
          </p>
          <p className='amount'>{amountFormatter(summary ? summary.totalInvestableResidue : emptyAmount)}</p>
        </div>
        {!Number.isNaN(dataScadenza.getTime()) && <div className='invested-info no-sup'>
          <p className='label'>{formatMessage(pirBoxCardMessages.pirboxExpirationDateLabel)}</p>
          <p className='amount'>{dateFormatter(dataScadenza)}</p>
        </div>}
        <div className='pirbox-buttons'>
          <AppButtonIcon onClick={handleShowGraph} className='pirbox-button' icon='pie-chart' label={formatMessage(pirBoxCardMessages.graphButtonLabel)}/>
          <AppButtonIcon onClick={handleDownload} className='pirbox-button' icon='printer' label={formatMessage(pirBoxCardMessages.printButtonLabel)}/>
        </div>
      </div>
      <div className='pirbox-disclaimers'>
       <p>1: {formatMessage(pirBoxCardMessages.extrordinaryTaxationDisclaimerMessage)}</p>
        <p>2: {formatMessage(pirBoxCardMessages.partiteViaggiantiDisclaimerMessage)}</p>
      </div>
      <PirBoxAccordion title={formatMessage(pirBoxCardMessages.summaryTableTitle)}>{<SummaryTable summary={summary as PirBoxProductSummary} expirationDate={dataScadenza}/>}</PirBoxAccordion>
      <PirBoxAccordion title={formatMessage(pirBoxCardMessages.productDetailTableTitle)}><ProductsDetailsTable/></PirBoxAccordion>
    </div>
  );
};
