import React from 'react';

import { textInformativeUserRegistrationMessages } from 'azshared';
import { useIntl } from 'react-intl';

import './informative-user-registration-page.scss';

export const TextInformative = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='text-informative'>
      <div className='title-informative'>
        {/* UPPERCASE SOTTOLINEATO GRASSETTO */}
        {formatMessage(textInformativeUserRegistrationMessages.titleInformative)}
      </div>
      <div className='subject-informative'>
        {/* SOTTOLINEATO GRASSETTO */}
        {formatMessage(textInformativeUserRegistrationMessages.subjectInformative)}
      </div>
      <div className='introduction'>{formatMessage(textInformativeUserRegistrationMessages.introduction)}</div>
      <div className='introduction'>{formatMessage(textInformativeUserRegistrationMessages.recap)}</div>
      <ul>
        {/* FIRST PARAGRAPH */}
        <li className='paragraph'>
          <div className='title-paragraph'>
            {/* indentato e UPPERCASE */}
            {formatMessage(textInformativeUserRegistrationMessages.titleFirstParagraph)}
          </div>
          <div className='text-paragraph'>{formatMessage(textInformativeUserRegistrationMessages.firstSectionFirstParagraph)}</div>
          <div className='text-paragraph'>
            <ul>
              <li>{formatMessage(textInformativeUserRegistrationMessages.secondSectionFirstParagraph)}</li>
              <li>{formatMessage(textInformativeUserRegistrationMessages.thirdSectionFirstParagraph)}</li>
              <li>{formatMessage(textInformativeUserRegistrationMessages.fourthSectionFirstParagraph)}</li>
            </ul>
          </div>
        </li>

        {/* SECOND PARAGRAPH */}
        <li className='paragraph'>
          <div className='title-paragraph'>
            {/* indentato e UPPERCASE */}
            {formatMessage(textInformativeUserRegistrationMessages.titleSecondParagraph)}
          </div>
          <div className='text-paragraph'>{formatMessage(textInformativeUserRegistrationMessages.firstSectionSecondParagraph)}</div>
          <div className='text-paragraph'>{formatMessage(textInformativeUserRegistrationMessages.secondSectionSecondParagraph)}</div>
        </li>

        {/* THIRD PARAGRAPH */}
        <li className='paragraph'>
          <div className='title-paragraph'>
            {/* indentato e UPPERCASE */}
            {formatMessage(textInformativeUserRegistrationMessages.titleThirdParagraph)}
          </div>
          <div className='text-paragraph'>{formatMessage(textInformativeUserRegistrationMessages.firstSectionThirdParagraph)}</div>
          <div className='text-paragraph'>{formatMessage(textInformativeUserRegistrationMessages.secondSectionThirdParagraph)}</div>
          <div className='text-paragraph'>{formatMessage(textInformativeUserRegistrationMessages.thirdSectionThirdParagraph)}</div>
        </li>

        {/* FOURTH PARAGRAPH */}
        <li className='paragraph'>
          <div className='title-paragraph'>
            {/* indentato e UPPERCASE */}
            {formatMessage(textInformativeUserRegistrationMessages.titleFourthParagraph)}
          </div>
          <div className='text-paragraph'>{formatMessage(textInformativeUserRegistrationMessages.firstSectionFourthParagraph)}</div>
          <div className='text-paragraph'>{formatMessage(textInformativeUserRegistrationMessages.secondSectionFourthParagraph)}</div>
          <div className='text-paragraph'>{formatMessage(textInformativeUserRegistrationMessages.thirdSectionFourthParagraph)}</div>
          <div className='text-paragraph'>{formatMessage(textInformativeUserRegistrationMessages.fourthSectionFourthParagraph)}</div>
        </li>

        {/* FIFTH PARAGRAPH */}
        <li className='paragraph'>
          <div className='title-paragraph'>
            {/* indentato e UPPERCASE */}
            {formatMessage(textInformativeUserRegistrationMessages.titleFifthParagraph)}
          </div>
          <div className='text-paragraph'>{formatMessage(textInformativeUserRegistrationMessages.firstSectionFifthParagraph)}</div>
          <div className='text-paragraph'>{formatMessage(textInformativeUserRegistrationMessages.secondSectionFifthParagraph)}</div>
        </li>

        {/* SIXTH PARAGRAPH */}
        <li className='paragraph'>
          <div className='title-paragraph'>
            {/* indentato e UPPERCASE */}
            {formatMessage(textInformativeUserRegistrationMessages.titleSixthParagraph)}
          </div>
          <div className='text-paragraph'>{formatMessage(textInformativeUserRegistrationMessages.firstSectionSixthParagraph)}</div>
          <div className='text-paragraph'>{formatMessage(textInformativeUserRegistrationMessages.secondSectionFifthParagraph)}</div>
        </li>
      </ul>
    </div>
  );
};
