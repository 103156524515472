// eslint-disable-next-line @typescript-eslint/no-empty-function
export const emptyFunction = () => {};

export const downloadFile = (filename: string, blob: Blob) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

export const openBlobInNewTab = (blob: Blob) => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.setAttribute('target', '_blank');

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const mockFileDownloaded = async (url: string, filename: string) => {
  const blob = await fetch(url).then((x) => {
    return x.blob();
  });

  downloadFile(filename, blob);
};
