import React, { MouseEvent, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { BreadcrumbItem, mapBreadcrumb } from '../../utils/breadcrumb-item';
import './app-breadcrumb.scss';

export const AppBreadcrumb = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { formatMessage } = useIntl();
  const items: BreadcrumbItem[] = useMemo(() => location?.pathname.split('/').flatMap((item) => mapBreadcrumb[item] || [])
  , [location]);

  const linkTo = useCallback(
    (event: MouseEvent<HTMLSpanElement>) => {
      const id = (event.target as HTMLSpanElement).id;
      navigate(mapBreadcrumb[id].path);
    },
    [navigate]
  );

  const title = document.getElementsByTagName('title')[0];
  title.textContent = 'MyAzimut - ' + formatMessage(items[items.length - 1].label);

  return (
    <div className='app-breadcrumb'>
      {items?.map((item, index) => (
        <span className='app-breadcrumb-item' key={item.key} aria-selected={index === items.length - 1} onClick={linkTo} id={item.key}>
          {formatMessage(item.label)}
          {index !== items.length - 1 ? ' / ' : ''}
        </span>
      ))}
    </div>
  );
};
