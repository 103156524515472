import { ProductPerformanceList } from 'azshared';
import React from 'react';
import { PerformanceFilter } from '../performance-filter/performance-filter';
import { PerformanceTable } from '../performance-table/performance-table';
import './performance-section.scss';

interface PerformanceSectionProps {
	performanceList: ProductPerformanceList;
	isLoading: boolean;
}

export const PerformanceSection = (props: PerformanceSectionProps) => {
	const { performanceList, isLoading } = props;
	return (
		<div className='app-product-performance-section'>
			<PerformanceFilter />
			<PerformanceTable performanceList={performanceList} isLoading={isLoading} />
		</div>
	);
};
