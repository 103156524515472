import { mdiChevronLeft, mdiCloseCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { changePhoneNumberMessages, commonButtonMessages, useChangePhoneNumberDialogLogic } from 'azshared';
import React, { FormEvent, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType } from '../../../components/buttons/app-button';
import { AppDialog } from '../../../components/dialog/app-dialog';
import { AppInputText } from '../../../components/input-text/app-input-text';
import { AppTimeline } from '../../../components/timeline/app-timeline';
import { useGlobalActions } from '../../../hooks/global-actions';

export interface ChangePhoneNumberDialogsProps {
  steps: string[];
}

export const ChangePhoneNumberDialogs = (props: ChangePhoneNumberDialogsProps) => {
  const globalActions = useGlobalActions();
  const { steps } = props;

  const { openDialogChangePhoneNumber, closeChangePhoneNumber } = useChangePhoneNumberDialogLogic(globalActions);

  return (
    <div>
      {/* ***** Dialog Phone Number not modificable ***** */}
      <ErrorDialogPhoneNumberNotEditable />
      <AppDialog open={openDialogChangePhoneNumber} onClose={closeChangePhoneNumber}>
        <div className='dialog-change-phone-number'>
          {/* ***** STEP 1 ***** */}
          <InsertNewPhoneNumber steps={steps} />
          {/* ***** STEP 2 ***** */}
          <VerificationNewPhoneNumber steps={steps} />
          {/* ***** STEP 3 ***** */}
          <SummaryNewPhoneNumber steps={steps} />
        </div>
      </AppDialog>
      {/* ***** Error dialog Too Many Attempts ***** */}
      <ErrorDialogTooManyAttempts />
    </div>
  );
};

interface DialogChangePhoneNumberProps {
  steps: string[];
}

const InsertNewPhoneNumber = (props: DialogChangePhoneNumberProps) => {
  const { formatMessage } = useIntl();
  const globalActions = useGlobalActions();

  const { steps } = props;

  const {
    step,
    disabledButtonFirstStep,
    handleSubmitInsertPhoneNumber,
    phoneNumber,
    updatePhoneNumber,
    confirmNewPhoneNumber,
    updateConfirmNewPhoneNumberChangePhoneNumber,
    insertPhoneNumberChangePhoneNumberRequestStatus
  } = useChangePhoneNumberDialogLogic(globalActions);

  const onSubmitInsertPhoneNumber = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      handleSubmitInsertPhoneNumber();
    },
    [handleSubmitInsertPhoneNumber]
  );

  const onCopy = useCallback((e: FormEvent) => {
    e.preventDefault();
  }, []);

  const onPaste = useCallback((e: FormEvent) => {
    e.preventDefault();
  }, []);

  return (
    <div>
      {/* ***** STEP 1 ***** */}
      {step === 'INSERIMENTO_DATI' && (
        <div>
          <div className='timeline-change-phone-number'>
            <AppTimeline steps={steps} stepSelected={[0]} />
          </div>
          <form className='form-dialog' onSubmit={onSubmitInsertPhoneNumber}>
            <div className='step-1'>
              <div className='phone-number-input-text'>
                <AppInputText
                  placeholder={formatMessage(changePhoneNumberMessages.newPhoneNumberlPlaceholder)}
                  value={phoneNumber}
                  label={formatMessage(changePhoneNumberMessages.newPhoneNumberlLabel)}
                  onChange={updatePhoneNumber}
                  onCopy={onCopy}
                  onPaste={onPaste}
                />
                <AppInputText
                  placeholder={formatMessage(changePhoneNumberMessages.confirmNewPhoneNumberlPlaceholder)}
                  value={confirmNewPhoneNumber}
                  label={formatMessage(changePhoneNumberMessages.confirmNewPhoneNumberlLabel)}
                  onChange={updateConfirmNewPhoneNumberChangePhoneNumber}
                  onCopy={onCopy}
                  onPaste={onPaste}
                />
              </div>
              <div className='button-dialog'>
                <AppButton
                  type={AppButtonType.SUBMIT}
                  label={formatMessage(changePhoneNumberMessages.changePhoneNumberGoOnButton)}
                  loading={insertPhoneNumberChangePhoneNumberRequestStatus === 'loading'}
                  disabled={disabledButtonFirstStep}
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

const VerificationNewPhoneNumber = (props: DialogChangePhoneNumberProps) => {
  const { formatMessage } = useIntl();
  const globalActions = useGlobalActions();

  const { steps } = props;

  const {
    step,
    backToStep1,
    handleSubmitVerificationPhoneNumber,
    phoneNumber,
    changePhoneNumberVerificationCodeEmail,
    changePhoneNumberVerificationCodePhoneNumber,
    updateVerificationCodeEmail,
    updateVerificationCodePhoneNumber,
    verificationPhoneNumberChangePhoneNumberRequestStatus,
    buttonDisabled,
    countdown,
    timeoutError
  } = useChangePhoneNumberDialogLogic(globalActions);

  const onSubmitVerificationCode = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      handleSubmitVerificationPhoneNumber();
    },
    [handleSubmitVerificationPhoneNumber]
  );

  return (
    <div>
      {/* ***** STEP 2 ***** */}
      {step === 'VERIFICA_OTP' && (
        <div>
          <div className='back-link' onClick={backToStep1}>
            <Icon path={mdiChevronLeft} size={0.8} />
            <span>{formatMessage(commonButtonMessages.backLink)}</span>
          </div>
          <div className='timeline-change-phone-number'>
            <AppTimeline steps={steps} stepSelected={[0, 1]} />
          </div>
          <form className='form-dialog' onSubmit={onSubmitVerificationCode}>
            <div className='step-2'>
              <div className='phone-number-input-text'>
                <AppInputText value={phoneNumber} label={formatMessage(changePhoneNumberMessages.newPhoneNumberlLabelDisabled)} disabled />
                {/* disclaimer */}
                <div className='phone-number-input-text-disclaimer'>{formatMessage(changePhoneNumberMessages.desclaimerVerificationOtp)}</div>
                {/* codice di verifica email */}
                <AppInputText
                  placeholder={formatMessage(changePhoneNumberMessages.placeholderChangePhoneNumberVerificationCodeEmail)}
                  value={changePhoneNumberVerificationCodeEmail}
                  label={formatMessage(changePhoneNumberMessages.labelChangePhoneNumberVerificationCodeEmail)}
                  onChange={updateVerificationCodeEmail}
                />
                {/* codice di verifica numero di cellulare */}
                <AppInputText
                  placeholder={formatMessage(changePhoneNumberMessages.placeholderChangePhoneNumberVerificationCodePhoneNumber)}
                  value={changePhoneNumberVerificationCodePhoneNumber}
                  label={formatMessage(changePhoneNumberMessages.labelChangePhoneNumberVerificationCodePhoneNumber)}
                  onChange={updateVerificationCodePhoneNumber}
                />
              </div>
              <div className='button-dialog'>
                <AppButton
                  disabled={buttonDisabled}
                  type={AppButtonType.SUBMIT}
                  label={formatMessage(changePhoneNumberMessages.changePhoneNumberGoOnButton)}
                  loading={verificationPhoneNumberChangePhoneNumberRequestStatus === 'loading'}
                />
                <div className='count-down '>
                  {timeoutError && <div className='count-down-error'>{formatMessage(changePhoneNumberMessages.expiredOtpCodesError)}</div>}
                  {!timeoutError && formatMessage(changePhoneNumberMessages.disclaimerCountDown) + countdown}
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

const SummaryNewPhoneNumber = (props: DialogChangePhoneNumberProps) => {
  const { formatMessage } = useIntl();
  const globalActions = useGlobalActions();

  const { steps } = props;

  const { step, closeStep3ChangePhoneNumber, phoneNumber } = useChangePhoneNumberDialogLogic(globalActions);

  return (
    <div>
      {/* ***** STEP 3 ***** */}
      {step === 'RIEPILOGO' && (
        <div>
          <div className='timeline-change-phone-number'>
            <AppTimeline steps={steps} stepSelected={[0, 1, 2]} />
          </div>
          <div className='step-3'>
            <div>
              {formatMessage(changePhoneNumberMessages.titleSummaryChangePhoneNumber)}
              <div className='phone-number'>{phoneNumber}</div>
            </div>
            <hr />
            <div className='disclaimer'>{formatMessage(changePhoneNumberMessages.disclaimerSummaryChangePhoneNumber)}</div>
            <div className='button-dialog'>
              <AppButton label={formatMessage(changePhoneNumberMessages.changePhoneNumberConfirmButton)} onClick={closeStep3ChangePhoneNumber} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ErrorDialogPhoneNumberNotEditable = () => {
  const { formatMessage } = useIntl();
  const globalActions = useGlobalActions();

  const { errorPhoneNumberNotEditable, closeErrorPhoneNumberNotEditable } = useChangePhoneNumberDialogLogic(globalActions);

  const message = useMemo(() => {
    if (errorPhoneNumberNotEditable === 'OTHER_DOCUMENTS_TO_SIGN') {
      return changePhoneNumberMessages.errorPhoneNumberNotEditable;
    } else if (errorPhoneNumberNotEditable === 'LAST_UPDATING_UNTIL_5_DAYS') {
      return changePhoneNumberMessages.errorPhoneNumberNotEditableAfterFirstFiveDays;
    } else if (errorPhoneNumberNotEditable === 'EMAIL-VERIFICATION') {
      return changePhoneNumberMessages.errorPhoneNumberNotEditableEmailVerification;
    }
  }, [errorPhoneNumberNotEditable]);

  return (
    <div>
      <AppDialog open={!!errorPhoneNumberNotEditable}>
        <div className='dialog-error-change-phone-number'>
          <Icon path={mdiCloseCircleOutline} size={2} color={'red'} />
          <div className='title'>{message && formatMessage(changePhoneNumberMessages.errorTooManyAttemptsTitle)}</div>
          <div className='text-dialog'>{message && formatMessage(message)}</div>
          <div className='button-dialog'>
            <AppButton onClick={closeErrorPhoneNumberNotEditable} label={formatMessage(changePhoneNumberMessages.errorPhoneNumberNotEditableButtonLabel)} />
          </div>
        </div>
      </AppDialog>
    </div>
  );
};

const ErrorDialogTooManyAttempts = () => {
  const { formatMessage } = useIntl();
  const globalActions = useGlobalActions();

  const { tooManyAttemptsErrorChangePhoneNumber, handleCloseErrorTooManyAttemptsChangePhoneNumber } = useChangePhoneNumberDialogLogic(globalActions);

  return (
    <div>
      <AppDialog open={tooManyAttemptsErrorChangePhoneNumber}>
        <div className='dialog-error-change-phone-number'>
          <Icon path={mdiCloseCircleOutline} size={2} color={'red'} />
          <div className='title-dialog'>{formatMessage(changePhoneNumberMessages.errorTooManyAttemptsTitle)}</div>
          <div className='text-dialog'>{formatMessage(changePhoneNumberMessages.errorTooManyAttemptsMessage)}</div>
          <div className='button-dialog'>
            <AppButton
              onClick={handleCloseErrorTooManyAttemptsChangePhoneNumber}
              label={formatMessage(changePhoneNumberMessages.errorTooManyAttemptsButtonLabel)}
            />
          </div>
        </div>
      </AppDialog>
    </div>
  );
};
