import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import { archivePracticeType, documentsAreaMessages, Practice, PracticeDocument } from 'azshared';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { DocumentsTable, DocumentsTableProps } from '../document-table/documents-table';
import './practice-row.scss';

export interface PracticeRowProps extends Omit<DocumentsTableProps, 'documents'> {
  practice: Practice<PracticeDocument>;
}

export const AppPracticeRow = (props: PracticeRowProps) => {
  const { practice } = props;
  const { formatMessage } = useIntl();

  const [openAccordion, setOpenAccordion] = useState(false);

  const handleOpenAccordion = useCallback(() => {
    setOpenAccordion(!openAccordion);
  }, [openAccordion]);

  const practiceTile = useMemo(() => 
    formatMessage(
      practice.practiceNumber === archivePracticeType ? documentsAreaMessages.practiceArchivedTitle : documentsAreaMessages.practiceTitle, 
      {practiceNumber: practice?.practiceNumber}), 
    [formatMessage, practice.practiceNumber]
  );

  return (
    <Fragment>
      <TableRow className={`practice-row ${openAccordion ? 'is-open' : ''}`}>        
        <TableCell
          className='practice-row-cell'
          component='th'
          scope='row'
          sx={{
            borderBottomLeftRadius: 8,
            borderTopLeftRadius: 8
          }}>
          {practiceTile}
        </TableCell>
        <TableCell className='practice-row-cell'>
          {practice.customerName} {practice.customerCode}
        </TableCell>
        <TableCell className='practice-row-cell' align='center'>
          {practice.documents?.length} {formatMessage(documentsAreaMessages.documentTab)} 
        </TableCell>
        <TableCell
          className='practice-row-cell practice-row-cell-expansion'
          sx={{
            borderBottomRightRadius: 8,
            borderTopRightRadius: 8
          }}>
          <IconButton aria-label='expand row' size='small' onClick={handleOpenAccordion}>
            <Icon size={1.5} path={openAccordion ? mdiChevronUp : mdiChevronDown} color={'#2E6CD1'} />
          </IconButton>
        </TableCell>
        
      </TableRow>
      <TableRow className={openAccordion ? 'is-expansion-visible' : ''}>
        <TableCell className='is-expansion-visible-cell' colSpan={6}>
          <Collapse in={openAccordion} timeout='auto' unmountOnExit>
            <Box>
              <DocumentsTable key={practice.practiceNumber} documents={practice.documents} {...props} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0, border: 'none' }} colSpan={6}>
          <div className='white-row' />          
        </TableCell>         
      </TableRow>     
    </Fragment>
  );
};
