import { DatiFatcaConsistency, homeOnboardingSelfAmlFatcaCrsSurveyMessages } from 'azshared';
import { DatiFatca } from 'azshared/build/main/lib/api/generated/dob';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Card, CardSize } from '../../../../../../components/card/card';
import { AppInputText } from '../../../../../../components/input-text/app-input-text';
import { AppRadioButtonConfig } from '../../../../../../components/radio/app-radio-button';
import { AppRadioGroup } from '../../../../../../components/radio/app-radio-group';
import './fatca-section.scss';
import { ResidenzeFiscali } from './residenze-fiscali/residenze-fiscali';

export interface FatcaSectionProps {
  fatca: DatiFatca;
  onChange: (key: keyof DatiFatca, fatcaValue: any) => void;
  dominioNazioni?: { [key: string]: string };
  consistency: DatiFatcaConsistency;
}

export const FatcaSection = (props: FatcaSectionProps) => {
  const { fatca, dominioNazioni, consistency, onChange } = props;
  const { formatMessage } = useIntl();

  const fatcaOpt: AppRadioButtonConfig[] = [
    { value: 'true', label: formatMessage(homeOnboardingSelfAmlFatcaCrsSurveyMessages.fatcaResponse1) },
    { value: 'false', label: formatMessage(homeOnboardingSelfAmlFatcaCrsSurveyMessages.fatcaResponse2) }
  ];

  const onResidenteUSAChange = useCallback((value: string) => onChange('residenteUSA', value === 'true'), [onChange]);
  const onTinChange = useCallback((v) => onChange('tin', v), [onChange]);

  return (
    <Card sizeVariant={CardSize.MAXI}>
      <div className='section'>
        <h3>{formatMessage(homeOnboardingSelfAmlFatcaCrsSurveyMessages.fatcaTitle)}</h3>
        <div className={`first-question ${consistency?.residenteUSA === false ? 'question-invalid' : ''}`}>
          <h5>{formatMessage(homeOnboardingSelfAmlFatcaCrsSurveyMessages.fatcaQuestion)}</h5>
          <AppRadioGroup selectedValue={`${fatca?.residenteUSA}`} options={fatcaOpt} onSelectedValueChanged={onResidenteUSAChange} />
          <AppInputText
            placeholder={formatMessage(homeOnboardingSelfAmlFatcaCrsSurveyMessages.tin)}
            value={fatca?.tin ?? ''}
            valid={consistency?.tin}
            required={fatca?.residenteUSA}
            disabled={!fatca?.residenteUSA}
            onChange={onTinChange}
          />
        </div>
        {!fatca?.residenteUSA && (
          <div>
            <h5>{formatMessage(homeOnboardingSelfAmlFatcaCrsSurveyMessages.crsQuestion)}</h5>
            <h6>{formatMessage(homeOnboardingSelfAmlFatcaCrsSurveyMessages.crsQuestionDescription)}</h6>
            <ResidenzeFiscali
              consistency={consistency}
              onChange={onChange}
              residenzeFiscali={fatca?.listaResidenzaFiscale || []}
              dominioNazioni={dominioNazioni}
            />
          </div>
        )}
      </div>
    </Card>
  );
};
