import { SignatureAreaSections } from 'azshared';
import React, { useCallback } from 'react';

interface SignatureTabBarProps {
  text: string;
  isSelected: boolean;
  section: SignatureAreaSections;
  onTabSelected: (section: SignatureAreaSections) => void;
}

export const SignatureAreaTabElement = (props: SignatureTabBarProps) => {
  const { text, isSelected, section, onTabSelected } = props;

  const onButtonClick = useCallback(() => onTabSelected(section), [section, onTabSelected]);

  return (
    <button className='signature-tab-bar' aria-selected={isSelected} onClick={onButtonClick}>
      {text}
    </button>
  );
};
