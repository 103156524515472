import { AppMainSection, homeMessages } from 'azshared';
import { MessageDescriptor } from 'react-intl';
import { AppCustomIcons } from './custom-icons';

export interface MenuItemInterface {
  icon: AppCustomIcons;
  label: MessageDescriptor;
  section: AppMainSection;
}

export const menuItems: MenuItemInterface[] = [
  {
    icon: 'category',
    label: homeMessages.menuItemHome,
    section: 'home'
  },
  {
    icon: 'wallet',
    label: homeMessages.menuItemWallet,
    section: 'wallet'
  },
  {
    icon: 'sign',
    label: homeMessages.menuItemSign,
    section: 'sign'
  },
  {
    icon: 'documents',
    label: homeMessages.menuItemDocuments,
    section: 'documents'
  },
  {
    icon: 'videocamera',
    label: homeMessages.menuItemForYou,
    section: 'for-you'
  },
  {
    icon: 'news',
    label: homeMessages.menuItemNews,
    section: 'news'
  }
];
