import { appRoutes, commonButtonMessages, insertFiscalCodeUserRegistrationMessages, useInsertFiscalCodePageLogic } from 'azshared';
import React, { FormEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType } from '../../../../components/buttons/app-button';
import { Card } from '../../../../components/card/card';
import { AppInputText } from '../../../../components/input-text/app-input-text';
import { AppLink } from '../../../../components/labels/app-link';
import { AppRequiredNote } from '../../../../components/required-note/required-note';
import { useGlobalActions, useGoToLoginFromRegistration } from '../../../../hooks/global-actions';
import { LoginContainer } from '../../login-container/login-container';
import './insert-fiscal-code-user-registration-page.scss';

export const InsertFiscalCodeUserRegistrationPage = () => {
  const { formatMessage } = useIntl();
  const goToLoginFromRegistration = useGoToLoginFromRegistration();

  const {
    buttonDisabled,
    userCode,
    vatCodeFiscalCode,
    fiscalCodeLegalPerson,
    insertFiscalCodeLegalPersonRequestStatus,
    updateFiscalCodeLegalPerson,
    handleSubmit
  } = useInsertFiscalCodePageLogic(useGlobalActions());

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );

  return (
    <div>
      <LoginContainer>
        <div className='insert-fiscal-code-user-registration-page'>
          <Card
            title={formatMessage(insertFiscalCodeUserRegistrationMessages.pageTitle)}
            subtitle={formatMessage(insertFiscalCodeUserRegistrationMessages.pageSubtitle)}
            back={formatMessage(commonButtonMessages.backLink)}
          >
            <div className='description-disclaimer'>
              {formatMessage(insertFiscalCodeUserRegistrationMessages.diclaimerInsertFiscalCode, {
                userCode: userCode,
                vatCodeFiscalCode: vatCodeFiscalCode
              })}
            </div>
            <form onSubmit={onSubmit}>
              <div>
                <AppInputText
                  label={formatMessage(insertFiscalCodeUserRegistrationMessages.labelInsertFiscalCodeInputText)}
                  placeholder={formatMessage(insertFiscalCodeUserRegistrationMessages.placeholderInsertFiscalCodeInputText)}
                  required
                  value={fiscalCodeLegalPerson}
                  onChange={updateFiscalCodeLegalPerson}
                />
              </div>
              <AppRequiredNote />
              <div className='button'>
                <AppButton
                  label={formatMessage(insertFiscalCodeUserRegistrationMessages.buttonInsertFiscalCode)}
                  type={AppButtonType.SUBMIT}
                  disabled={buttonDisabled}
                  loading={insertFiscalCodeLegalPersonRequestStatus === 'loading'}
                />
              </div>
            </form>
            <div className='disclaimer'>
              {formatMessage(commonButtonMessages.linkBackToLogin)}
              <AppLink to={appRoutes.Login} replace={false} label={formatMessage(commonButtonMessages.linkToLogin)} onClick={goToLoginFromRegistration} />
            </div>
          </Card>
        </div>
      </LoginContainer>
    </div>
  );
};
