import { dashboardMessages, useAppSelector, useDashboardPageLogic, UserState } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import { AppHeader } from '../../../components/header/header';
import { useGlobalActions } from '../../../hooks/global-actions';
import { HomeAzimutForYouCard } from './home-azimut-for-you-card/home-azimut-for-you-card';
import { HomeDocumentsCard } from './home-documents-card/home-documents-card';
import { HomeFinancialConsultantCard } from './home-financial-advisor-card/home-financial-consultant-card';
import { HomeMarketingAreaCard } from './home-marketing-area-card/home-marketing-area-card';
import { HomeNewsCard } from './home-news-card/home-news-card';
import { HomeSignatureAreaCard } from './home-signature-area-card/home-signature-area-card';
import { HomeWalletCard } from './home-wallet-card/home-wallet-card';
import { HomeToDoActivitiesCard } from './home-to-do-activities-card/home-to-do-activities-card';
import './home-page.scss';


export const HomePage = () => {
  const globalActions = useGlobalActions();
  const { formatMessage } = useIntl();
  const { definitivo, practiceData, selectionDialogOpen, onModalitySelected, openDialog, documents, signature, news, financialAdvisor, video, sections, selectedSection, onSelectedSection, myWallet, setFlagShowBalance, sharedAccounts, dossierRequestStatus, toDoActivities, startWebCollaborationDialogOpen, startWebCollaboration, onCloseStartWebCollaborationDialog } =
    useDashboardPageLogic(globalActions);

  const { informativeMode, isProspect } = useAppSelector<UserState>((state) => state.data.user);

  return (
    <div className='home-page'>
      <AppHeader />
      {isProspect ?
        <h2>{formatMessage(dashboardMessages.title)}</h2> :
        <h2>{formatMessage(dashboardMessages.walletCardTitle)}</h2>
      }
        <div className='home-page-content'>
          <div className='home-page-wallet-section'>
            {/* Wallet section */}
            {isProspect &&
              <HomeToDoActivitiesCard
                practiceData={practiceData}
                buttonDisabled={!definitivo}
                toDoActivities={toDoActivities}
                handleSelectionDialog={openDialog}
                onModalitySelected={onModalitySelected}
                openSelectionDialog={selectionDialogOpen}
                startWebCollaborationDialogOpen={startWebCollaborationDialogOpen}
                startWebCollaboration={startWebCollaboration}
                onCloseStartWebCollaborationDialog={onCloseStartWebCollaborationDialog}
              />
            }
            {isProspect &&
              <HomeMarketingAreaCard/>
            }
            {!isProspect &&
              <HomeWalletCard
              dossierRequestStatus={dossierRequestStatus}
              myWalletHomeCard={myWallet}
              sharedAccounts={sharedAccounts}
              sections={sections}
              selectedSection={selectedSection}
              onSelectedSection={onSelectedSection}
              setFlagShowBalance={setFlagShowBalance}/>
            }
            {!isProspect && selectedSection !== 'PIR_BOX' && (
              <>
                <div className='home-page-second-column'>
                  {/* card: signature, azimut and news */}
                  {!informativeMode && <HomeSignatureAreaCard documents={signature} />}
                  <HomeMarketingAreaCard />
                  <HomeNewsCard news={news} />
                </div>
                <div className='home-page-third-column'>
                  {/* card: document, financial consultant, azimut for you */}
                  {!informativeMode && <HomeDocumentsCard totalDocuments={documents} />}
                  <HomeFinancialConsultantCard isProspect={isProspect} consultant={financialAdvisor} />
                  <HomeAzimutForYouCard video={video} />
                </div>
              </>
            )}
          </div>
          <div className='home-page-second-column'>
            {/* card: signature, azimut and news */}
            {!informativeMode && <HomeSignatureAreaCard documents={signature}/>}
            {!isProspect && <HomeMarketingAreaCard />}
            <HomeNewsCard news={news} />
          </div>
        <div className='home-page-third-column'>
          {/* card: document, financial consultant, azimut for you */}
          {!informativeMode && !isProspect &&
            <HomeDocumentsCard totalDocuments={documents} />
          }
          <HomeFinancialConsultantCard
            consultant={financialAdvisor}
            isProspect={isProspect}
          />
          <HomeAzimutForYouCard video={video} />
        </div>
      </div>
    </div>
  );
};
