import {
  AdminConsoleDocument,
  commonButtonMessages,
  customerServiceMessages,
  CustomerServiceSearchFilters,
  messagesForSearchError,
  PersonaTipoUtenteEnum,
  resetCustomerServiceDocuments,
  resetCustomerServiceSearchState,
  resultsTableMessages,
  searchCustomerService,
  SortColumn,
  useAppDispatch,
  useAppSelector
} from 'azshared';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppBreadcrumb } from '../../../components/breadcrumb/app-breadcrumb';
import { Card, CardSize } from '../../../components/card/card';
import { AppSuccessDialog } from '../../../components/dialog/app-success-dialog';
import { useGlobalActions } from '../../../hooks/global-actions';
import { CredentialRecoveryDialog, CredentialsRecoverySelected } from './credential-recovery-dialog/credential-recovery-dialog';
import './customer-service-page.scss';
import { CustomerServiceTable } from './results/results-table';
import { SearchFilter } from './search-filter/search-filter';
import { UserUnlockingDialog } from './user-unlocking-dialog/user-unlocking-dialog';

export const CustomerServicePage = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { showToast, closeToast } = useGlobalActions();
  const [openRecoveryCredentialDialog, setOpenRecoveryCredentialDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openUserUnlockingDialog, setOpenUserUnlockingDialog] = useState(false);
  const [successDialogMessage, setSuccessDialogMessage] = useState<string>();
  const [userCode, setUserCode] = useState<string>();
  const [userType, setUserType] = useState<PersonaTipoUtenteEnum>();
  const [searchFilter, setSearchFilter] = useState<CustomerServiceSearchFilters>();

  const { searchRequestStatus, searchError, pagination, documents } = useAppSelector((state) => state.features.adminConsole.customerService);

  useEffect(() => {
    if (searchRequestStatus === 'succeeded') {
      dispatch(resetCustomerServiceSearchState());
    }
  }, [dispatch, searchRequestStatus]);

  useEffect(() => {
    if (searchError) {
      const errorMessages = messagesForSearchError(searchError);
      showToast({
        type: 'error',
        title: formatMessage(errorMessages.title),
        description: errorMessages.description && formatMessage(errorMessages.description),
        close: closeToast
      });
      dispatch(resetCustomerServiceSearchState());
    }
  }, [searchError, dispatch, formatMessage, showToast, closeToast]);

  const handleSubmit = useCallback(
    (params: CustomerServiceSearchFilters) => {
      dispatch(searchCustomerService({ filter: params, pagination: { page: 1, sorting: {} } }));
      setSearchFilter(params);
    },
    [dispatch]
  );

  const handleReset = useCallback(() => {
    dispatch(resetCustomerServiceDocuments());
  }, [dispatch]);

  const handleCloseRecoveryCredentialDialog = useCallback(() => {
    setOpenRecoveryCredentialDialog(false);
    setUserCode(undefined);
    setUserType(undefined);
  }, [setOpenRecoveryCredentialDialog]);

  const handleRecoveryCredentialSuccess = useCallback(
    (value: CredentialsRecoverySelected) => {
      setOpenRecoveryCredentialDialog(false);
      setSuccessDialogMessage(
        formatMessage(customerServiceMessages.credentialRecoveryProcedureSent, {
          type: value?.type?.label,
          channel: value?.channel?.label
        })
      );
      setOpenSuccessDialog(true);
      setUserCode(undefined);
      setUserType(undefined);
    },
    [setOpenRecoveryCredentialDialog, setOpenSuccessDialog, setSuccessDialogMessage, formatMessage]
  );

  const handleCloseUserUnlockingDialog = useCallback(() => {
    setOpenUserUnlockingDialog(false);
    setUserCode(undefined);
  }, [setOpenUserUnlockingDialog]);

  const handleUserUnlockingSuccess = useCallback(() => {
    setOpenUserUnlockingDialog(false);
    setSuccessDialogMessage(formatMessage(customerServiceMessages.userUnlock));
    setOpenSuccessDialog(true);
    setUserCode(undefined);
  }, [setOpenUserUnlockingDialog, setOpenSuccessDialog, setSuccessDialogMessage, formatMessage]);

  const handleCloseSuccessDialog = useCallback(() => {
    setOpenSuccessDialog(false);
  }, [setOpenSuccessDialog]);

  const handleUnlockUser = useCallback((code: string) => {
    setOpenUserUnlockingDialog(true);
    setUserCode(code);
  }, []);

  const handleRecoveryCredentials = useCallback((code: string, type: PersonaTipoUtenteEnum) => {
    setOpenRecoveryCredentialDialog(true);
    setUserCode(code);
    setUserType(type);
  }, []);

  const handleShowPage = useCallback(
    (page: number) => {
      if (searchFilter) {
        dispatch(searchCustomerService({ filter: searchFilter, pagination: { ...pagination, page } }));
      }
    },
    [dispatch, pagination, searchFilter]
  );

  const handleShowPreviousPage = useCallback(() => {
    if (searchFilter) {
      dispatch(
        searchCustomerService({
          filter: searchFilter,
          pagination: { ...pagination, page: pagination.page - 1 }
        })
      );
    }
  }, [dispatch, pagination, searchFilter]);

  const handleShowNextPage = useCallback(() => {
    if (searchFilter) {
      dispatch(
        searchCustomerService({
          filter: searchFilter,
          pagination: { ...pagination, page: pagination.page + 1 }
        })
      );
    }
  }, [dispatch, pagination, searchFilter]);

  const handleOrderedBy = useCallback(
    (sorting: SortColumn<AdminConsoleDocument>) => {
      if (searchFilter) {
        dispatch(searchCustomerService({ filter: searchFilter, pagination: { ...pagination, sorting } }));
      }
    },
    [dispatch, pagination, searchFilter]
  );

  return (
    <div className='customer-service-page'>
      <div className='customer-service-card'>
        <AppBreadcrumb />
        <Card title={formatMessage(customerServiceMessages.pageTitle)} sizeVariant={CardSize.MAXI}>
          <SearchFilter onSubmit={handleSubmit} onReset={handleReset} />

          <CredentialRecoveryDialog
            userCode={userCode || ''}
            userType={userType}
            open={openRecoveryCredentialDialog}
            onClose={handleCloseRecoveryCredentialDialog}
            onSuccess={handleRecoveryCredentialSuccess}
          />
          <UserUnlockingDialog
            userCode={userCode  || ''}
            open={openUserUnlockingDialog}
            onClose={handleCloseUserUnlockingDialog}
            onSuccess={handleUserUnlockingSuccess}
          />
          <AppSuccessDialog open={openSuccessDialog} onClose={handleCloseSuccessDialog} buttonLabel={formatMessage(commonButtonMessages.closeButton)}>
            {successDialogMessage  || ''}
          </AppSuccessDialog>

          {(documents.length > 0 || (documents.length === 0 && searchRequestStatus === 'loading')) && (
            <div className='results-table-container'>
              <div className='title-result-table'>{formatMessage(resultsTableMessages.titleTable)}</div>
              <CustomerServiceTable
                pagination={pagination}
                documents={documents}
                recoveryCredentials={handleRecoveryCredentials}
                unlockUser={handleUnlockUser}
                showPage={handleShowPage}
                showNextPage={handleShowNextPage}
                showPreviousPage={handleShowPreviousPage}
                orderedBy={handleOrderedBy}
              />
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};
