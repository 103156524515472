import {
  homeCompilationSelectionDialogMessages,
  Questionnaire,
  SurveyBookingStatus,
  useCompilationSelectionLogic
} from 'azshared';
import React, { useCallback } from 'react';
import {useIntl} from 'react-intl';
import {AppButton, AppButtonType, AppButtonVariant} from '../../../../../components/buttons/app-button';
import {AppDialog} from '../../../../../components/dialog/app-dialog';
import './compilation-selection-dialog.scss';
import {AppRadioGroup} from '../../../../../components/radio/app-radio-group';
import {AppRadioButtonConfig} from '../../../../../components/radio/app-radio-button';
import {parseISO} from 'date-fns';

interface CompilationSelectionDialogProps {
  open: boolean;
  onClose: () => void;
  onPressButton: (type: Questionnaire, mode?: SurveyBookingStatus) => void;
  meetingDate?: string;
  compileType?: string;
}

export const CompilationSelectionDialog = (props: CompilationSelectionDialogProps) => {
  const {formatMessage} = useIntl();

  const {value, handleSelectionOfValue} = useCompilationSelectionLogic();

  const options: AppRadioButtonConfig[] = [
    {
      value: 'InPresenza',
      label: formatMessage(homeCompilationSelectionDialogMessages.radioLeftLabel),
      description: formatMessage(homeCompilationSelectionDialogMessages.radioLeftDescriptionLabel),
      disabled: props?.compileType === 'InPresenza'
    },
    {
      value: 'WebCollaboration',
      label: formatMessage(homeCompilationSelectionDialogMessages.radioRightLabel),
      description: formatMessage(homeCompilationSelectionDialogMessages.radioRightDescriptionLabel),
      disabled: props?.compileType === 'WebCollaboration'
    }
  ];

  const title = props.meetingDate ? formatMessage(homeCompilationSelectionDialogMessages.cardTitleWithMeetingDate, {
    date: parseISO(props.meetingDate)?.toLocaleDateString('it-IT') || ''
  }) : formatMessage(homeCompilationSelectionDialogMessages.cardTitle);

  const handleClick = useCallback(() => props.onPressButton('MiFID', value as SurveyBookingStatus), [props, value]);

  return (
    <AppDialog open={props.open} onClose={props.onClose}>
      <div className='compilation-selection-dialog'>
        <div className='compilation-selection-dialog-title-section'>
          <div
            className='compilation-selection-dialog-title-no-margin'>{title}</div>
          {!props.meetingDate && <div
            className='compilation-selection-dialog-title-no-margin'>{formatMessage(homeCompilationSelectionDialogMessages.cardSecondTitle)}</div>}
        </div>
        <div className='compilation-selection-dialog-radio-section'>
          <AppRadioGroup selectedValue={value} onSelectedValueChanged={handleSelectionOfValue} options={options}
                         horizontal={true}/>
        </div>
        <div className='compilation-selection-dialog-button-section'>
          <AppButton
            type={AppButtonType.BUTTON}
            variant={AppButtonVariant.Primary}
            disabled={!value || value === props?.compileType}
            label={formatMessage(homeCompilationSelectionDialogMessages.ctaLabel)}
            onClick={handleClick}
          />
        </div>
      </div>
    </AppDialog>
  );
};