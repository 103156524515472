import { mdiChevronLeft } from '@mdi/js';
import { Icon } from '@mdi/react';
import React, { ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './card.scss';

export enum CardSize {
	SMALL = 'small',
	MEDIUM = 'medium',
	MAXI = 'maxi'
}

export interface CardProps {
	title?: string;
	subtitle?: string;
	disclaimer?: string;
	back?: string;
	children?: ReactNode;
	sizeVariant?: CardSize;
	clickGoBack?: () => void;
}

export const Card = (props: CardProps) => {
	const { title, subtitle, disclaimer, children, back, sizeVariant = CardSize.SMALL, clickGoBack } = props;

	const navigate = useNavigate();
	const onBackClick = useCallback(
		() => {
			navigate(-1);
		},
		[ navigate ]
	);

	return (
		<div className={`card-container ${sizeVariant} `}>
			{title && <h2 className='title'>{title}</h2>}
			{subtitle && <p className='subtitle'>{subtitle}</p>}
			<div className='card'>
				{back && (
					<a className='back' onClick={clickGoBack ? clickGoBack : onBackClick}>
						<Icon path={mdiChevronLeft} size={0.8} />
						<span>{back}</span>
					</a>
				)}
				{children}
			</div>
			{disclaimer && <div className='disclaimer'>{disclaimer}</div>}
		</div>
	);
};
