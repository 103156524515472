import React, { MouseEvent, useCallback } from 'react';
import { AppCustomIcons } from '../../utils/custom-icons';
import { AppSvgIcon } from '../svg-icon/app-svg-icon';
import './app-button-icon.scss';

export interface AppButtonIconProps {
	id?: string;
	icon: AppCustomIcons;
	onClick?: (e: MouseEvent<HTMLButtonElement | HTMLLabelElement | SVGSVGElement>, id?: string) => void;
	label?: string;
	className?: string;
	labelClassName?: string;
	iconClassName?: string;
}

export const AppButtonIcon = (props: AppButtonIconProps) => {
	const handleClick = useCallback(
		(e: MouseEvent<SVGSVGElement | HTMLLabelElement>) => {
			e.preventDefault();
			if (props.onClick) props.onClick(e, props.id);
		},
		[ props ]
	);

	return (
		<div className={`app-button-icon ${props.className ?? ''}`}>
			<AppSvgIcon icon={props.icon} className={`app-button-icon-svg ${props.iconClassName ?? ''}`} onClick={handleClick}/>
			{props.label && (
				<label htmlFor={props.id} className={`app-button-icon-label ${props.labelClassName ?? ''}`} onClick={handleClick}>
					{props.label}
				</label>
			)}
		</div>
	);
};
