import {
  ApiRequestStatus,
  appRoutes,
  DashboardWalletSummary,
  HomeCard,
  walletPageMessages
} from 'azshared';
import React, { useCallback, useMemo} from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Card, CardSize } from '../../../../../components/card/card';
import { Loader, LoaderSize } from '../../../../../components/loader/loader';
import { AppSvgIcon } from '../../../../../components/svg-icon/app-svg-icon';
import { HomeMyWalletCardBars } from './home-my-wallet-card-bars';
import { HomeMyWalletCardCarousel } from './home-my-wallet-card-carousel';
import { HomeMyWalletCardShowBalance } from './home-my-wallet-card-show-balance';
import { HomeMyWalletCardTotalAmount } from './home-my-wallet-card-total-amount';
import './home-my-wallet-card.scss';

interface HomeMyWalletCardProps {
  card: HomeCard<DashboardWalletSummary>;
  dossierRequestStatus: ApiRequestStatus;
  setFlagShowBalance: (value: boolean) => void;
  pirBoxDisclaimer: string
}

export const HomeMyWalletCard = (props: HomeMyWalletCardProps) => {
  const { formatMessage } = useIntl();
  const { card, setFlagShowBalance, dossierRequestStatus, pirBoxDisclaimer } = props; 
  const navigate = useNavigate();

  const goToWalletPage = useCallback(() => {
    navigate(appRoutes.Wallet);
  }, [navigate]);

  const isLoading = useMemo(() => dossierRequestStatus === 'loading' || card.requestStatus === 'loading', [dossierRequestStatus, card]);
  
  return (
    <div className='home-wallet'>
    <Card sizeVariant={CardSize.MAXI}>
      <div className='home-wallet-card-header'>
        <div className='home-wallet-card-header-title'>
          <AppSvgIcon icon='home-wallet' />
          <h4> {formatMessage(walletPageMessages.myWalletLabel)} </h4>
        </div>
        {!isLoading && (<HomeMyWalletCardShowBalance card={card} onShowBalance={setFlagShowBalance} /> )}
      </div>
      {!isLoading && (
        <>
          <HomeMyWalletCardTotalAmount card={card}/>
          <HomeMyWalletCardCarousel card={card}/>
          <HomeMyWalletCardBars pirBoxDisclaimer={pirBoxDisclaimer} card={card}/>                  
          <div className='link-go-to-wallet' onClick={goToWalletPage}>
            <span>{formatMessage(walletPageMessages.walletWidgetLinkGoToWallet)}</span>
            <AppSvgIcon icon='home-arrow' />
          </div>
           
        </>
      )}
          
      {isLoading && (
        <div className='home-wallet-card-loader'>
          <Loader loadersize={LoaderSize.XL} />
        </div>
      )}
      </Card>
      
    </div>
  );
};
