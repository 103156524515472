import React from 'react';
import {useIntl} from 'react-intl';
import { usePirBoxPageLogic, walletPageMessages } from 'azshared';
import './pir-box-disclaimer-financial-position.scss';

export const PirBoxDisclaimerFinancialPosition = () => {
    const {hasAlternative, hasOrdinary} = usePirBoxPageLogic();
    const {formatMessage} = useIntl();

    return <div className='real-economy-explain'> 
    {hasAlternative &&<p>{formatMessage(walletPageMessages.walletAlternativePirBoxPositionList)}</p>}
    {hasOrdinary &&<p>{formatMessage(walletPageMessages.walletOrdinaryPirBoxPositionList)}</p>}
    </div>;
};