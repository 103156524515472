import { mdiCheckCircle } from '@mdi/js';
import Icon from '@mdi/react';
import { webCollaborationMessages } from 'azshared';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AppDialog } from '../dialog/app-dialog';
import { AppSvgIcon } from '../svg-icon/app-svg-icon';
import './wait-for-other-user-dialog.scss';

interface WaitForOtherUserDialogProps {
  open: boolean;
  waitForProspect: boolean;
  type: 'success' | 'warning';
}

export const WaitForOtherUserDialog = (props: WaitForOtherUserDialogProps) => {
  const { formatMessage } = useIntl();

  const contentTextKey = useMemo(() => {
    if (props.type === 'success') {
      return 'mifidCompleted';
    }

    return props.waitForProspect ? 'waitForProspectReconnecting' : 'waitForCfReconnecting';
  }, [props.type, props.waitForProspect]);

  return (
    <AppDialog open={props.open} className='wait-for-other-user-dialog-wrapper'>
      <div className={`wait-for-other-user-dialog wait-for-other-user-dialog-${props.type}`}>
        <div className='wait-for-other-user-dialog-section'>
          <p className='wait-for-other-user-dialog-title'>
            {props.type === 'success' ? (
              <Icon className='wait-for-other-user-dialog-icon' path={mdiCheckCircle} />
            ) : (
              <AppSvgIcon icon='alert' className='wait-for-other-user-dialog-icon' />
            )}
            {formatMessage(webCollaborationMessages[props.type === 'success' ? 'mifidCompletedTitle' : 'waitForReconnectingTitle'])}
          </p>
          <p className='wait-for-other-user-dialog-content'>{formatMessage(webCollaborationMessages[contentTextKey])}</p>
        </div>
      </div>
    </AppDialog>
  );
};
