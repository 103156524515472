import { homeAzimutForYouCardMessages, HomeCard, Video } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import { Loader, LoaderSize } from '../../../../components/loader/loader';
import { VideoPlayer } from '../../../../components/video-player/video-player';

import './home-azimut-for-you-card.scss';

export const HomeAzimutForYouCard = (props: { video: HomeCard<Video> }) => {
  const { formatMessage } = useIntl();
  const { video } = props;
  return (
    <div className='home-azimut-for-you-card'>
      {video.requestStatus !== 'loading' && (
        <div className='home-azimut-for-you-card-video'>
          {video.value && (
            <VideoPlayer video={video.value} hideDetails={true} title={formatMessage(homeAzimutForYouCardMessages.cardTitle)} titleIcon={'home-video'} />
          )}
        </div>
      )}
      {video.requestStatus === 'loading' && (
        <div className='home-azimut-for-you-card-loader'>
          <Loader loadersize={LoaderSize.XL} />
        </div>
      )}
    </div>
  );
};
