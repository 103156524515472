import { useVideoPageLogic, videoLibraryMessages } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import { Card, CardSize } from '../../../components/card/card';
import { AppHeader } from '../../../components/header/header';
import { AppSvgIcon } from '../../../components/svg-icon/app-svg-icon';

import './azimut-for-you.scss';
import { VideoPlayer } from '../../../components/video-player/video-player';

export const AzimutForYouPage = () => {
  const { formatMessage } = useIntl();
  const { videos } = useVideoPageLogic();

  return (
    <div className='azimut-for-you-page'>
      <AppHeader />
      <Card title={formatMessage(videoLibraryMessages.azimutForYouLable)} sizeVariant={CardSize.MAXI}>
        <div className='azimut-for-you-page-card'>
          <div className='foreground-section'>
            <div className='foreground-section-title'>
              <AppSvgIcon icon={'video'} />
              <span>{formatMessage(videoLibraryMessages.inTheForegroundLabel)}</span>
            </div>
            {videos?.foreground.map((foreground) => (
              <VideoPlayer key={foreground.videoId} video={foreground} foreground={true} />
            ))}
          </div>
          <div className='other-section'>
            <div className='other-section-title'>{formatMessage(videoLibraryMessages.otherVideoLabel)}</div>
            {videos?.other.map((video) => (
              <VideoPlayer video={video} key={video.videoId} />
            ))}
          </div>
        </div>
      </Card>
    </div>
  );
};
