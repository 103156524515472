import {
  appRoutes,
  clearRetrievePasswordError,
  commonButtonMessages,
  messagesForRetrievePasswordError,
  RecuperoPasswordRequestSendToEnum,
  resetRetrievePasswordState,
  retrievePassword,
  retrievePasswordMessages,
  RetrievePasswordState,
  useAppSelector,
  env
} from 'azshared';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppButton, AppButtonType } from '../../../components/buttons/app-button';
import { Card } from '../../../components/card/card';
import { AppSuccessDialog } from '../../../components/dialog/app-success-dialog';
import { AppInputText } from '../../../components/input-text/app-input-text';
import { AppLink } from '../../../components/labels/app-link';
import { AppRadioButtonProps } from '../../../components/radio/app-radio-button';
import { AppRadioGroup } from '../../../components/radio/app-radio-group';
import { showAppToast } from '../../../components/toast/app-toast';
import { closeAppToast } from '../../../hooks/global-actions';
import { LoginContainer } from '../login-container/login-container';
import './password-recovery-page.scss';

export const PasswordRecoveryPage = () => {
  const { retrievePasswordRequestStatus, retrievePasswordError }: RetrievePasswordState = useAppSelector(state => state.features.retrievePassword);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const [selectedOption, setSelectedOption] = useState<RecuperoPasswordRequestSendToEnum>(RecuperoPasswordRequestSendToEnum.Email);

  const onSelectedOptionChanged = useCallback((option: string) => setSelectedOption(option as RecuperoPasswordRequestSendToEnum), []);

  const retrievePasswordOptions: AppRadioButtonProps[] = [
    {
      value: RecuperoPasswordRequestSendToEnum.Email,
      label: formatMessage(retrievePasswordMessages.labelFirstRadioButton)
    },
    {
      value: RecuperoPasswordRequestSendToEnum.Sms,
      label: formatMessage(retrievePasswordMessages.labelSecondRadioButton)
    }
  ];

  const [username, setUsername] = useState<string>();

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      dispatch(
        retrievePassword({
          email: username,
          selectedOption: selectedOption
        })
      );
    },
    [dispatch, username, selectedOption]
  );

  const closeSuccessDialog = useCallback(() => {
    setOpenSuccessDialog(false);
    navigate(appRoutes.Login, { replace: true });
  }, [navigate]);

  useEffect(() => {
    if (retrievePasswordRequestStatus === 'succeeded') {
      dispatch(resetRetrievePasswordState());
      setOpenSuccessDialog(true);
    }
  }, [dispatch, navigate, retrievePasswordRequestStatus]);

  useEffect(() => {
    if (retrievePasswordError) {
      const errorMessages = messagesForRetrievePasswordError(retrievePasswordError);
      const link =
        retrievePasswordError === 'NOT_MIGRATED_USER' ? env.oldMyAzimutUrl  : undefined;
      showAppToast({
        type: 'error',
        title: formatMessage(errorMessages.title, {
          a: (...chunks) =>
            `<a href=${link} target="_self" rel="noopener noreferrer">${chunks}</a>`
        }),
        description: errorMessages.description && formatMessage(errorMessages.description),
        close: closeAppToast
      });
      dispatch(clearRetrievePasswordError());
    }
  }, [retrievePasswordError, dispatch, formatMessage]);

  return (
    <LoginContainer>
      <div className='password-recovery'>
        <Card
          title={formatMessage(retrievePasswordMessages.pageTitle)}
          subtitle={formatMessage(retrievePasswordMessages.pageSubtitle)}
          back={formatMessage(retrievePasswordMessages.backLink)}
        >
          <div className='radio-button'>
            <AppRadioGroup options={retrievePasswordOptions} selectedValue={selectedOption} onSelectedValueChanged={onSelectedOptionChanged} />
          </div>
          <form onSubmit={handleSubmit}>
            <div className='input-box'>
              <span className='description'>{formatMessage(retrievePasswordMessages.labelUsernameInputText)}</span>
              <AppInputText placeholder={formatMessage(retrievePasswordMessages.placeholderUsernameInputText)} value={username} onChange={setUsername} />
            </div>
            <div className='button'>
              <AppButton
                type={AppButtonType.SUBMIT}
                label={formatMessage(retrievePasswordMessages.retrievePasswordButton)}
                loading={retrievePasswordRequestStatus === 'loading'}
              />
            </div>
          </form>
          <span className='disclaimer'>
            {formatMessage(retrievePasswordMessages.disclaimer)}
            <AppLink to={appRoutes.Login} label={formatMessage(commonButtonMessages.linkToLogin)} />
          </span>
        </Card>
      </div>
      <AppSuccessDialog
        open={openSuccessDialog}
        onClose={closeSuccessDialog}
        buttonLabel={formatMessage(retrievePasswordMessages.successDialogRetrievePasswordButtonLabel)}
      >
        <div className='text-dialog'>
          {selectedOption === RecuperoPasswordRequestSendToEnum.Email && formatMessage(retrievePasswordMessages.successMessageRetrievePasswordByEmail)}
          {selectedOption === RecuperoPasswordRequestSendToEnum.Sms && formatMessage(retrievePasswordMessages.successMessageRetrievePasswordBySMS)}
        </div>
      </AppSuccessDialog>
    </LoginContainer>
  );
};
