import { Chip } from '@mui/material';
import React from 'react';
import './pill.scss';

export interface AppPillProps {
	color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
	label: string;
}

export const AppPill = (props: AppPillProps) => <Chip className='app-pill' color={props.color} label={props.label} />;
