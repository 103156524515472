import { mdiCalendarMonthOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { IconButton } from '@mui/material';
import { useMyWalletPerformanceDateFilterLogic, walletPagePerformanceSectionMessages } from 'azshared';
import React, { ChangeEvent, MouseEvent, RefObject, useCallback, useRef, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { useIntl } from 'react-intl';
import AppDateDropdown from '../../../../../components/date-dropdown/app-date-dropdown';
import { AppTabBar } from '../../../../../components/tab-bar/tab-bar';
import useEventListener from '../../../../../hooks/use-event-listener';
import './performance-filter.scss';

export const PerformanceDateFilter = () => {
	const {
		dateTabs,
		flagOpenDateFilter,
		performanceFilter,
		rangeDate,
		enableApplyButton,
		onSelectedDateTab,
		onToggleDateFilter,
		onCalendarDateChanged,
		onApplyClick
	} = useMyWalletPerformanceDateFilterLogic();
	const { formatMessage } = useIntl();
	const [top, setTop] = useState(0);
	const [right, setRight] = useState(0);
	const refDropdown: RefObject<HTMLDivElement> = useRef(null);
	const refIcon: RefObject<any> = useRef(null);
	const refLabel: RefObject<HTMLLabelElement> = useRef(null);
	const handleToggleDateFilter = useCallback(
		(e: MouseEvent<HTMLButtonElement | HTMLLabelElement>) => {
			e.preventDefault();
			onToggleDateFilter();
			const element = document.getElementById('my-wallet-section-icon');
			if (element?.getBoundingClientRect()) {
				const topPos = element.getBoundingClientRect().top + window.scrollY + element.getBoundingClientRect().height;
				const rightPos = window.innerWidth - element.getBoundingClientRect().right;
				setTop(topPos);
				setRight(rightPos);
			}
		},
		[ onToggleDateFilter ]
	);

	const handleDateChange = useCallback(
		(values: Date | [Date, Date], e: ChangeEvent<HTMLInputElement>) => {
			e.preventDefault();
			if (Array.isArray(values)) {
				onCalendarDateChanged({
					from: values[0],
					to: values[1]
				});
			}
		},
		[ onCalendarDateChanged ]
	);

	const handleSubmit = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();
			onApplyClick();
		},
		[ onApplyClick ]
	);

	const handleClick = useCallback(
		(event: Event) => {
      if (refDropdown.current 
		&& !refDropdown.current.contains(event.target as Node) 
		&& !refIcon?.current?.contains(event.target as Node)
		&& !refLabel?.current?.contains(event.target as Node)) {
        onToggleDateFilter(false);
      }
    },
    [refDropdown, onToggleDateFilter]
	);

	useEventListener('mousedown', handleClick);

	return (
		<div className='my-wallet-section-icon' id='my-wallet-section-icon'>
			<IconButton onClick={handleToggleDateFilter} ref={refIcon}>
				<Icon path={mdiCalendarMonthOutline} color='#2E6CD1' size={1} />
			</IconButton>
			<label onClick={handleToggleDateFilter} className='my-wallet-section-icon-label' ref={refLabel}>
				{formatMessage(walletPagePerformanceSectionMessages.dateFilter)}
			</label>
			<div
				className={`app-performance-filter-dropdown app-performance-filter-date-dropdown ${flagOpenDateFilter
					? 'is-open'
					: ''}`}
				style={{top, right}}
				ref={refDropdown}
			>
				<AppTabBar tabs={dateTabs} onTabSelected={onSelectedDateTab} value={performanceFilter.dateType}/>
				{performanceFilter.dateType === 'SPECIFIC_DATE' && (
					<AppDateDropdown
						value={rangeDate}
						onDateChange={handleDateChange}
						onSubmit={handleSubmit}
						enableApplyButton={enableApplyButton}
					/>
				)}
			</div>
		</div>
	);
};
