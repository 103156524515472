import {TabItem} from 'azshared';
import React, {RefObject, useCallback, useMemo, useRef, useState} from 'react';
import {AppSelectDropdown} from './app-select-dropdown';
import {AppSelectErrorMessage} from './app-select-error-message';
import './app-select.scss';

interface InternalAppOption<T extends string> extends TabItem<T> {
  isSelected?: boolean;
}

export interface AppSelectProps<T extends string> {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  errorMessage?: string;
  required?: boolean;
  value?: T | T[];
  multiple?: boolean;
  options: TabItem<T>[];
  onChange?: (value: T | T[], field?: any) => void;
  onSelectAll?: () => void;
  valid?: boolean;
}

export const AppSelect = <T extends string>(props: AppSelectProps<T>) => {
  const {label, placeholder, disabled, errorMessage = '', required, value, multiple, options, valid = true} = props;
  const [isOpen, setIsOpen] = useState(false);

  const refInput: RefObject<HTMLDivElement> = useRef(null);

  const toggle = useCallback(() => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  }, [disabled, isOpen]);

  const internalOptions: InternalAppOption<T>[] = useMemo(() => {
    return options.map(option => ({
      ...option,
      isSelected: multiple || Array.isArray(value) ? value?.includes(option.type) : value === option.type
    }));
  }, [multiple, options, value]);

  const innerText = useMemo(() => {
    const text = internalOptions
      ?.filter(item => item.isSelected)
      .map(item => item.label)
      .join(', ');

    return text || placeholder || '';
  }, [placeholder, internalOptions]);

  return (
    <div className='app-select'>
      {label && (
        <label>
          {label}
          {required ? '*' : ''}
        </label>
      )}
      <div
        ref={refInput}
        tabIndex={0}
        className={`app-select-input ${errorMessage || !valid ? 'app-select-error' : ''} ${disabled ? 'app-select-disabled' : ''} ${
          !value ? 'app-select-placeholder' : ''
        }`}
        onClick={toggle}>
        <p>{innerText}</p>
      </div>
      <AppSelectDropdown {...props} refInput={refInput} open={isOpen} onClose={toggle}/>
      <AppSelectErrorMessage errorMessage={errorMessage}/>
    </div>
  );
};
