import {
  ApiRequestStatus,
  DashboardWalletSummary,
  HomeCard,
  TabItem,
  usePirBoxPageLogic,
  WalletSections
} from 'azshared';
import React from 'react';
import { AppTabBar } from '../../../../components/tab-bar/tab-bar';
import { PirBoxCard } from '../../pir-box/pir-box-card';
import { HomeMyWalletCard } from './home-my-wallet-card/home-my-wallet-card';
import './home-wallet-card.scss';


interface HomeWalletCardProps {
  myWalletHomeCard: HomeCard<DashboardWalletSummary>;
  sharedAccounts: HomeCard<DashboardWalletSummary>;
  selectedSection: WalletSections;
  onSelectedSection: (selectedSection: WalletSections) => void;
  sections: TabItem<WalletSections>[];
  setFlagShowBalance: (value: boolean) => void;
  dossierRequestStatus: ApiRequestStatus;
}

export const HomeWalletCard = (props: HomeWalletCardProps) => {
  const {
    myWalletHomeCard,
    sections,
    selectedSection,
    onSelectedSection,
    setFlagShowBalance,
    sharedAccounts,
    dossierRequestStatus
  } = props;
  const {
    productsSummary,
    mandateCodes,
    onDownloadFile,
    onShowGraph,
    graphDetails,
    graphRenderEnabled,
    onPrevious
  } = usePirBoxPageLogic();
  const summary = mandateCodes.map((value) => productsSummary.get(value));

  
  return (
    <div className='home-wallet-card'>
      <AppTabBar tabs={sections} onTabSelected={onSelectedSection} value={selectedSection} />
      {selectedSection === 'MY_WALLET' &&
        <HomeMyWalletCard pirBoxDisclaimer={selectedSection} dossierRequestStatus={dossierRequestStatus} card={myWalletHomeCard}
                          setFlagShowBalance={setFlagShowBalance} />}
      {selectedSection === 'SHARED_ACCOUNTS' &&
        <HomeMyWalletCard pirBoxDisclaimer={selectedSection} dossierRequestStatus={dossierRequestStatus} card={sharedAccounts}
                          setFlagShowBalance={setFlagShowBalance} />}
      {selectedSection === 'PIR_BOX' &&
        <PirBoxCard summaries={summary} onDownloadFile={onDownloadFile} onShowGraph={onShowGraph}
                    graphRenderEnabled={graphRenderEnabled} dataGraph={graphDetails} onPrevious={onPrevious} />}
    </div>
  );
};
