import { store } from 'azshared';
import { env } from 'azshared';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import App from './App';
import './index.scss';

const script = document.createElement('script');
script.type = 'text/javascript';
script.async = true;
script.charset = 'UTF-8';
script.innerHTML =
  'window["adrum-start-time"] = new Date().getTime();' +
  '(function(config){' +
  'config.appKey = "' +
  env.appDynamicsKey +
  '";' +
  // +'config.adrumExtUrlHttp = "http://'+ env.appDynamicsAdrunUrl +'";'
  'config.adrumExtUrlHttps = "https://' +
  env.appDynamicsAdrunUrl +
  '";' +
  // +'config.beaconUrlHttp = "http://'+ env.appDynamicsBeaconUrl +'";'
  'config.beaconUrlHttps = "https://' +
  env.appDynamicsBeaconUrl +
  '";' +
  'config.resTiming = {"bufSize":200,"clearResTimingOnBeaconSend":true};' +
  'config.maxUrlLength = 512;' +
  'config.spa = {"spa2":true};' +
  '})(window["adrum-config"] || (window["adrum-config"] = {}));';
const firstScript = document.getElementsByTagName('script')[0];
firstScript?.parentNode?.insertBefore(script, firstScript);

const adrumScript = document.createElement('script');
adrumScript.src = '//cdn.appdynamics.com/adrum/adrum-22.2.0.3616.js';
firstScript?.parentNode?.insertBefore(adrumScript, firstScript);

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider defaultLocale='it' locale='it'>
      <Provider store={store}>
        <HashRouter>
          <App />
        </HashRouter>
      </Provider>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
