import { mdiCheck } from '@mdi/js';
import Icon from '@mdi/react';
import { Chip } from '@mui/material';
import React, { MouseEvent, useCallback } from 'react';
import { AppBadge } from '../badge/badge';
import './chip.scss';

export interface AppChipProps {
	id?: string;
	active: boolean;
	count?: number;
	label: string;
	onClick?: (e: MouseEvent<HTMLDivElement>, id?: string) => void;
}

export const AppChip = (props: AppChipProps) => {
	const handleClick = useCallback(
		(e: MouseEvent<HTMLDivElement>) => {
			props.onClick && props.onClick(e, props.id);
		},
		[ props ]
	);

	const chip = (
		<Chip
			className={`app-chip ${props.active ? 'is-active' : ''}`}
			variant='outlined'
			label={props.label}
			onClick={handleClick}
			icon={<Icon path={mdiCheck} size={'1rem'} />}
		/>
	);

	if (props.count) {
		return <AppBadge counter={props.count}>{chip}</AppBadge>;
	}

	return chip;
};
