import React from 'react';
import {
  labelForWalletCategoryType,
  RealEconomyAssetSummary,
  walletErDetailPageMessages
} from 'azshared';
import {useIntl} from 'react-intl';
import {Loader, LoaderSize} from '../../../../../components/loader/loader';
import InfographicPie from '../../../../../components/infographic-pie/infographic-pie';
import './portfolio-asset-class.scss';

interface Props {
  loading: boolean;
  items: RealEconomyAssetSummary;
}

export const PortfolioAssetClass = (props: Props) => {
  const {formatMessage} = useIntl();
  const {loading, items} = props;

  return <div className='portfolio-summary'>
    <h4>{formatMessage(walletErDetailPageMessages.portfolioAssetClassTitle)}</h4>
    {!loading && <><p/></>}
    <div className={'portfolio-container' + (loading ? '-loader' : '')}>
      {loading && <Loader loadersize={LoaderSize.XL}/>}
      {!loading &&
        <InfographicPie
          items={items?.category?.map(m => {
            return {value: m.quote, label: formatMessage(labelForWalletCategoryType(m.type))};
          })}
          title={formatMessage(walletErDetailPageMessages.portfoglioSummaryTotal)}
          subtitle={'' + items.totalAmount}
          legend
          legendAlignment={'left'}
        />
      }
    </div>
  </div>;

};