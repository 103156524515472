import { ACTION_REHYDRATE_USER, appRoutes, PERSIST_STORE_KEY, RegistrationPageWrapper, useApiResponseInterceptorLogic, useAppSelector, UserState } from 'azshared';
import React, { useCallback, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router';
import { Routes } from 'react-router-dom';
import { PrivateRoute } from './components/private-route/private-route';
import { AdminConsoleContainer } from './features/admin-console/admin-console-container';
import { CustomerServicePage } from './features/admin-console/customer-service/customer-service-page';
import { AzimutForYouPage } from './features/home/azimut-for-you/azimut-for-you';
import { DocumentsAreaPage } from './features/home/documents-area/documents-area-page';
import { HomeContainer } from './features/home/home-container';
import { HomePage } from './features/home/home-page/home-page';
import { FaqPage } from './features/home/news-faq/faq/faq-page/faq-page';
import { NewsPage } from './features/home/news-faq/news-page';
import { ProfilePage } from './features/home/profile/profile-page';
import { SignatureAreaPage } from './features/home/signature-area/signature-area-page';
import { WalletPage } from './features/home/wallet/wallet-page';
import IosMarketplace from './features/ios-marketplace/ios-marketplace';
import { CredentialsRecoveryPage } from './features/user-access/credentials-recovery/credentials-recovery-page';
import { FirstAccessPage } from './features/user-access/first-access/first-access-page';
import { LoginPage } from './features/user-access/login-page/login-page';
import { PasswordRecoveryPage } from './features/user-access/password-recovery/password-recovery-page';
import { UnlockUserInsertUsernamePage } from './features/user-access/unlock-user/unlock-user-insert-username/unlock-user-insert-username-page';
import { UnlockUserRequestPage } from './features/user-access/unlock-user/unlock-user-request/unlock-user-request-page';
import { EmailVerificationUserRegistrationPage } from './features/user-access/user-registration/email-user-registration/email-verification-user-registration-page';
import { InsertEmailUserRegistrationPage } from './features/user-access/user-registration/email-user-registration/insert-email-user-registration-page';
import { InformativeUserRegistrationPage } from './features/user-access/user-registration/informative/informative-user-registration-page';
import { InsertDataUserRegistrationPage } from './features/user-access/user-registration/insert-data-user-registration/insert-data-user-registration-page';
import { InsertFiscalCodeUserRegistrationPage } from './features/user-access/user-registration/insert-fiscal-code/insert-fiscal-code-user-registration-page';
import { InsertPhoneNumberUserRegistrationPage } from './features/user-access/user-registration/phone-number-user-registration/insert-phone-number-user-registration-page';
import { InsertSecondaryPhoneNumberUserRegistrationPage } from './features/user-access/user-registration/phone-number-user-registration/insert-secondary-phone-number-user-registration-page';
import { PhoneNumberVerificationUserRegistrationPage } from './features/user-access/user-registration/phone-number-user-registration/phone-number-verification-user-registration-page';
import { SignatureAreaUserRegistrationPage } from './features/user-access/user-registration/signature-area/signature-area-page';
import { UserRegistrationPage } from './features/user-access/user-registration/user-registration-page';
import { WelcomeUserRegistrationPage } from './features/user-access/user-registration/welcome/welcome-user-registration-page';
import { UserTypeRecoveryPage } from './features/user-access/user-type-recovery/user-type-recovery';
import { UserRecoveryMultipleMatchPage } from './features/user-access/username-recovery/username-recovery-multiple-match-page';
import { UserRecoveryPage } from './features/user-access/username-recovery/username-recovery-page';
import { ReferralCodePage } from './features/onboarding-self/referral-code/referral-code-page';
import { ThankYouPage } from './features/onboarding-self/thank-you-page/thank-you-page';
import { InvydioEmbeddedSessionPage } from './features/onboarding-self/invydio-session-page/invydio-session-page';
import { MifidSurveyPage } from './features/home/onboarding-self/mifid-survey-page/mifid-survey-page';
import { useGlobalActions } from './hooks/global-actions';
import {ErDetailPage} from './features/home/wallet/er/detail/er-detail-page';
import { AmlFatcaCrsSurveyPage } from './features/home/onboarding-self/aml-fatca-crs-survey-page/aml-fatca-crs-survey-page';
import {FinalizeOnboardingPage} from './features/onboarding-self/finalize-onboarding-page/finalize-onboarding-page';
import {SetPasswordPage} from './features/onboarding-self/set-password/set-password-page';
import { SplashPage } from './features/user-access/splash-page/splash-page';
import { DesktopRoute } from './components/private-route/desktop-route';
import { CFRoomPage } from './features/web-collaboration/room/cf-room-page';

function App() {
  const userState: UserState = useAppSelector((state) => state.data?.user);
  const dispatch = useDispatch();
  useApiResponseInterceptorLogic(useGlobalActions());

  const beforeUnloadListener = useCallback(
    (event) => {
      if (userState?.token) {
        sessionStorage.setItem(PERSIST_STORE_KEY, JSON.stringify(userState));
      }
      event.preventDefault();
      return '';
    },
    [userState]
  );

  useEffect(() => {
    const user = sessionStorage.getItem(PERSIST_STORE_KEY);
    if (user) {
      dispatch({
        type: ACTION_REHYDRATE_USER,
        payload: JSON.parse(user)
      });
      sessionStorage.removeItem(PERSIST_STORE_KEY);
    }
    addEventListener('beforeunload', beforeUnloadListener);

    return () => {
      removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, [beforeUnloadListener, dispatch]);

  return (
    <>
      <div className='App'>
          <Routes>
            <Route path={appRoutes.InvydioEmbeddedSession} element={<InvydioEmbeddedSessionPage />} />
            <Route path={appRoutes.ReferralCode} element={<ReferralCodePage />} />
            <Route path={'/splashpage'} element={<SplashPage />} />
            <Route element={<DesktopRoute />}>
              <Route path={appRoutes.Login} element={<LoginPage />} />
              <Route path={appRoutes.FirstAccess} element={<FirstAccessPage />} />
              <Route path={appRoutes.RetrieveCredentials} element={<CredentialsRecoveryPage />} />
              <Route path={appRoutes.RetrievePassword} element={<PasswordRecoveryPage />} />
              <Route path={appRoutes.RetrieveUsername} element={<UserRecoveryPage />} />
              <Route path={appRoutes.RetrieveUserType} element={<UserTypeRecoveryPage />} />
              <Route path={appRoutes.RetrieveUsernameMultipleMatch} element={<UserRecoveryMultipleMatchPage />} />
              <Route path={appRoutes.UserRegistration} element={<UserRegistrationPage />} />
              <Route path={appRoutes.InsertDataUserRegistration} element={RegistrationPageWrapper(<InsertDataUserRegistrationPage />)} />
              <Route path={appRoutes.InformativeUserRegistration} element={RegistrationPageWrapper(<InformativeUserRegistrationPage />)} />
              <Route path={appRoutes.WelcomeUserRegistration} element={RegistrationPageWrapper(<WelcomeUserRegistrationPage />)} />
              <Route path={appRoutes.InsertEmailUserRegistration} element={RegistrationPageWrapper(<InsertEmailUserRegistrationPage />)} />
              <Route path={appRoutes.EmailVerificationUserRegistration} element={RegistrationPageWrapper(<EmailVerificationUserRegistrationPage />)} />
              <Route path={appRoutes.InsertPhoneNumberUserRegistration} element={RegistrationPageWrapper(<InsertPhoneNumberUserRegistrationPage />)} />
              <Route path={appRoutes.InsertSecondaryPhoneNumberUserRegistration} element={RegistrationPageWrapper(<InsertSecondaryPhoneNumberUserRegistrationPage />)} />
              <Route
                path={appRoutes.PhoneNumberVerificationUserRegistration}
                element={RegistrationPageWrapper(<PhoneNumberVerificationUserRegistrationPage />)}
              />
              <Route path={appRoutes.InsertFiscalCodeUserRegistration} element={RegistrationPageWrapper(<InsertFiscalCodeUserRegistrationPage />)} />
              <Route path={appRoutes.SignatureAreaUserRegistration} element={RegistrationPageWrapper(<SignatureAreaUserRegistrationPage />)} />
              <Route path={appRoutes.UnlockUserRequest} element={<UnlockUserRequestPage />} />
              <Route path={appRoutes.UnlockUserInsertUsername} element={<UnlockUserInsertUsernamePage />} />
              <Route path={appRoutes.ThankYouPage} element={<ThankYouPage />} />
              <Route path={appRoutes.FinalizeOnboarding} element={<FinalizeOnboardingPage />} />
              <Route path={appRoutes.SetPassword} element={<SetPasswordPage />} />
<Route path={appRoutes.IosMarketplace} element={<IosMarketplace />} />
              <Route element={<PrivateRoute />}>
                <Route path={appRoutes.HomeSection} element={<HomeContainer />}>
                  <Route path={appRoutes.Dashboard} element={<HomePage />} />
                  <Route path={appRoutes.ProfilePage} element={<ProfilePage />} />
                  <Route path={appRoutes.Sign} element={<SignatureAreaPage />} />
                    <Route path={appRoutes.SignBackFromNamirial} element={<SignatureAreaPage isBackFromNamirial={true} />} />
                  <Route path={appRoutes.Wallet} element={<WalletPage />} />
                  <Route path={appRoutes.WalletEr} element={<WalletPage />} />
                  <Route path={appRoutes.WalletErDetail} element={<ErDetailPage />} />
                  <Route path={appRoutes.Documents} element={<DocumentsAreaPage />} />
                  <Route path={appRoutes.News} element={<NewsPage />} />
                  <Route path={appRoutes.Faqs} element={<FaqPage />} />
                  <Route path={appRoutes.ForYou} element={<AzimutForYouPage />} />
                  <Route path={appRoutes.MifidSurveyPage} element={<MifidSurveyPage />} />
                  <Route path={`${appRoutes.MifidSurveyPage}/:roomId`} element={<MifidSurveyPage />} />
                  <Route path={appRoutes.AmlFatcaCrsSurveyPage} element={<AmlFatcaCrsSurveyPage />} />
                </Route>
              </Route>
            </Route>

            <Route path={appRoutes.Room} element={<CFRoomPage />} />

            <Route path={appRoutes.AdminConsoleSection} element={<AdminConsoleContainer />}>
              <Route path={appRoutes.CustomerService} element={<CustomerServicePage />} />
            </Route>
          </Routes>
      </div>
      <Toaster />
      <SplashPage />
    </>
  );
}

export default App;
