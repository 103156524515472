import {
  commonButtonMessages,
  credentialRecoveryMessages,
  customerServiceCredentialRecovery,
  messagesForCustomerServiceGenericError, PersonaTipoUtenteEnum, RecuperoCredenzialiRequestCanaleDiRecuperoEnum, RecuperoCredenzialiRequestDatoDaRecuperareEnum, resetCustomerServiceCredentialRecoveryState,
  useAppSelector
} from 'azshared';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType, AppButtonVariant } from '../../../../components/buttons/app-button';
import { AppDialog } from '../../../../components/dialog/app-dialog';
import { AppRadioButtonConfig } from '../../../../components/radio/app-radio-button';
import { AppRadioGroup } from '../../../../components/radio/app-radio-group';
import { useGlobalActions } from '../../../../hooks/global-actions';
import { useAppDispatch } from '../../../../hooks/redux';
import './credential-recovery-dialog.scss';

export interface CredentialsRecoverySelected {
  channel?: AppRadioButtonConfig;
  type?: AppRadioButtonConfig;
}

export interface CredentialRecoveryDialogProps {
  userCode: string;
  userType?: PersonaTipoUtenteEnum;
  open: boolean;
  onClose: () => void;
  onSuccess: (value: CredentialsRecoverySelected) => void;
}

export const CredentialRecoveryDialog = (props: CredentialRecoveryDialogProps) => {
  const { open, onClose, onSuccess, userCode, userType } = props;
  const { formatMessage } = useIntl();
  const [credentialTypeSelectedOption, setCredentialTypeSelectedOption] = useState<RecuperoCredenzialiRequestDatoDaRecuperareEnum>(RecuperoCredenzialiRequestDatoDaRecuperareEnum.CodiceUtente);
  const [channelSelectedOption, setChannelSelectedOption] = useState<RecuperoCredenzialiRequestCanaleDiRecuperoEnum>(RecuperoCredenzialiRequestCanaleDiRecuperoEnum.Email);
  const dispatch = useAppDispatch();
  const { showToast, closeToast } = useGlobalActions();

  const chooseCredentialTypeOptions: AppRadioButtonConfig[] = useMemo(() =>[
    {
      value: RecuperoCredenzialiRequestDatoDaRecuperareEnum.CodiceUtente,
      label: formatMessage(credentialRecoveryMessages.labelUserCodeRadio)
    },
    {
      value: RecuperoCredenzialiRequestDatoDaRecuperareEnum.Password,
      label: formatMessage(credentialRecoveryMessages.labelPasswordRadio)
    }
  ], [formatMessage]);

  const chooseChannelOptions: AppRadioButtonConfig[] = useMemo(() =>[
    {
      value: RecuperoCredenzialiRequestCanaleDiRecuperoEnum.Email,
      label: formatMessage(credentialRecoveryMessages.labelEmailRadio),
      disabled: credentialTypeSelectedOption === 'codiceUtente' && userType === 'PF'
    },
    {
      value: RecuperoCredenzialiRequestCanaleDiRecuperoEnum.Sms,
      label: formatMessage(credentialRecoveryMessages.labelSMSRadio)
    }
  ], [formatMessage, userType, credentialTypeSelectedOption]);

  useEffect(() => {
    if (credentialTypeSelectedOption === 'codiceUtente' && userType === 'PF') {
      setChannelSelectedOption('sms');
    }
  }, [credentialTypeSelectedOption, userType]);

  const handleSubmit = useCallback(() => {
    dispatch(
      customerServiceCredentialRecovery({
        codiceUtente: userCode,
        datoDaRecuperare: credentialTypeSelectedOption,
        canaleDiRecupero: channelSelectedOption
      })
    );
  }, [dispatch, credentialTypeSelectedOption, channelSelectedOption, userCode]);

  const handleCredentialTypeSelectedOption = useCallback(
    (value: string) => {
      setCredentialTypeSelectedOption(value as RecuperoCredenzialiRequestDatoDaRecuperareEnum); 
    },
    [setCredentialTypeSelectedOption]
  );

  const handleChannelSelectedOption = useCallback(
    (value: string) => {
      setChannelSelectedOption(value as RecuperoCredenzialiRequestCanaleDiRecuperoEnum); 
    },
    [setChannelSelectedOption]
  );

  const { credentialRecoveryStatus, credentialRecoveryError } = useAppSelector(state => state.features.adminConsole.customerService);

  useEffect(() => {
    if (credentialRecoveryStatus === 'succeeded') {
      dispatch(resetCustomerServiceCredentialRecoveryState());
      const typeSelected = chooseCredentialTypeOptions?.find(item => item.value === credentialTypeSelectedOption);
      const channelSelected = chooseChannelOptions?.find(item => item.value === channelSelectedOption);
      onSuccess({
        type: typeSelected,
        channel: channelSelected
      });
    }
  }, [dispatch, onSuccess, credentialRecoveryStatus, credentialTypeSelectedOption, channelSelectedOption, chooseCredentialTypeOptions, chooseChannelOptions]);

  useEffect(() => {
    if (credentialRecoveryError) {
      const errorMessages = messagesForCustomerServiceGenericError();
      showToast({
        type: 'error',
        title: formatMessage(errorMessages.title),
        description: errorMessages.description && formatMessage(errorMessages.description),
        close: closeToast
      });
      dispatch(resetCustomerServiceCredentialRecoveryState());
    }
  }, [credentialRecoveryError, dispatch, formatMessage, showToast, closeToast]);

  return (
    <AppDialog open={open} onClose={onClose}>
      <form className='dialog-recovery-credential' onSubmit={handleSubmit}>
        <h2 className='dialog-recovery-credential-title'>{formatMessage(credentialRecoveryMessages.title)}</h2>
        <AppRadioGroup
          options={chooseCredentialTypeOptions}
          label={formatMessage(credentialRecoveryMessages.labelCredentialToRecoveryRadioGroup)}
          onSelectedValueChanged={handleCredentialTypeSelectedOption}
          selectedValue={credentialTypeSelectedOption}
        />
        <AppRadioGroup
          options={chooseChannelOptions}
          label={formatMessage(credentialRecoveryMessages.labelChannelRadioGroup)}
          selectedValue={channelSelectedOption}
          onSelectedValueChanged={handleChannelSelectedOption}
        />
        <div className='dialog-recovery-credential-footer'>
          <AppButton variant={AppButtonVariant.Secondary} label={formatMessage(commonButtonMessages.cancel)} onClick={onClose} />
          <AppButton type={AppButtonType.SUBMIT} label={formatMessage(commonButtonMessages.send)} loading={credentialRecoveryStatus === 'loading'} />
        </div>
      </form>
    </AppDialog>
  );
};
