import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import { AdminConsoleDocument, dateFormatter, PersonaTipoUtenteEnum } from 'azshared';
import React, { Fragment, useCallback } from 'react';
import { AppSvgIcon } from '../../../../components/svg-icon/app-svg-icon';

interface CustomerServiceRowProps {
  document: AdminConsoleDocument;
  unlockUser: (userCode: string) => void;
  recoveryCredentials: (userCode: string, userType: PersonaTipoUtenteEnum) => void;
}

export const CustomerServiceRow = (props: CustomerServiceRowProps) => {
  const { document, recoveryCredentials, unlockUser } = props;

  const onRecoveryCredentials = useCallback(() => {
    recoveryCredentials(document.codiceCliente, document.userType);
  }, [document, recoveryCredentials]);

  const onUnlockUser = useCallback(() => {
    unlockUser(document.codiceCliente);
  }, [document.codiceCliente, unlockUser]);

  return (
    <Fragment>
      <TableRow className='results-table-row'>
        <TableCell
          className='results-table-row-cell'
          component='th'
          scope='row'
          sx={{
            borderBottomLeftRadius: 8,
            borderTopLeftRadius: 8
          }}
        >
          {document.codiceCliente}
        </TableCell>
        <TableCell className='results-table-row-cell' align='left'>
          {document.intestatario}
        </TableCell>
        <TableCell className='results-table-row-cell' align='left'>
          {document.figuraAccessoria}
        </TableCell>
        <TableCell className='results-table-row-cell' align='left'>
          {dateFormatter(document.dataAttivazioneServizio)}
        </TableCell>
        <TableCell className='results-table-row-cell' align='left'>
          {document.stato}
        </TableCell>
        <TableCell className='results-table-row-cell' align='left'>
          {document.email}
        </TableCell>
        <TableCell className='results-table-row-cell' align='left'>
          {document.telefono}
        </TableCell>
        <TableCell
          className='results-table-row-cell'
          sx={{
            borderBottomRightRadius: 8,
            borderTopRightRadius: 8
          }}
        >
          <AppSvgIcon icon={'recovery-credentials'} onClick={onRecoveryCredentials} />
          <AppSvgIcon icon={'unlock'} onClick={onUnlockUser} />
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
