import { mdiCheckCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { commonButtonMessages } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import { AppButton } from '../buttons/app-button';
import { AppDialog, AppDialogProps } from './app-dialog';
import './app-success-dialog.scss';

interface AppSuccessDialogProps extends AppDialogProps {
  buttonLabel?: string;
}

export const AppSuccessDialog = (props: AppSuccessDialogProps) => {
  const { formatMessage } = useIntl();
  return (
    <AppDialog open={props.open}>
      <div>
        <div className='success-dialog-content'>
          <Icon path={mdiCheckCircleOutline} size={2} color={'#2ED47A'} />
          <div className='text-dialog'>{props.children}</div>
        </div>
        <div className='success-dialog-button'>
          <AppButton label={props.buttonLabel || formatMessage(commonButtonMessages.ok)} onClick={props.onClose} />
        </div>
      </div>
    </AppDialog>
  );
};
