import { usePirBoxPageLogic, useWalletLogic, walletPageMessages } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import { AppHeader } from '../../../components/header/header';
import { AppTabBar } from '../../../components/tab-bar/tab-bar';
import { PirBoxCard } from '../pir-box/pir-box-card';
import { MyWalletSection } from './my-wallet/my-wallet-section';
import './wallet-page.scss';
export const WalletPage = () => {
  const { formatMessage } = useIntl();
  
  const { sections, selectedSection, onSelectedSection } = useWalletLogic();
  const {
    productsSummary,
    mandateCodes,
    onDownloadFile,
    onShowGraph,
    graphRenderEnabled,
    graphDetails,
    onPrevious
  } = usePirBoxPageLogic();
  const summary = mandateCodes.map((value) => productsSummary.get(value));

  return (
    <div className='wallet-page'>
      <AppHeader />
      <h2>{formatMessage(walletPageMessages.pageTitle)}</h2>
      <AppTabBar tabs={sections} onTabSelected={onSelectedSection} value={selectedSection} />

      {selectedSection === 'MY_WALLET' && <MyWalletSection sectionMode={selectedSection} />}
      {selectedSection === 'SHARED_ACCOUNTS' && <MyWalletSection sectionMode={selectedSection} />}
      {selectedSection === 'PIR_BOX' &&
        <PirBoxCard summaries={summary} onDownloadFile={onDownloadFile} onShowGraph={onShowGraph}
                    graphRenderEnabled={graphRenderEnabled} dataGraph={graphDetails} onPrevious={onPrevious} />}
    </div>
  );
};
