import { PirBoxProductSummary } from 'azshared/build/main/lib/model/pirbox/pirbox-product-summary';
import React from 'react';
import { AppSvgIcon } from '../../../components/svg-icon/app-svg-icon';
import { PirBoxItem } from './pir-box-item';
import { useIntl } from 'react-intl';
import './pir-box-card.scss';
import { DownloaderMediaType, FileDownloadedType, pirBoxCardMessages } from 'azshared';
import { PirBoxCharts } from './pir-box-charts';
import { VisualizzaGraficoPIRResponse } from 'azshared/build/main/lib/api/generated/anagrafica';

interface PirBoxCardProps {
  summaries: (PirBoxProductSummary | undefined)[];
  onDownloadFile: (
    mandate: string,
    type: FileDownloadedType,
    mediaType: DownloaderMediaType
  ) => void;
  onShowGraph: (mandate: string) => void;
  graphRenderEnabled: boolean;
  dataGraph: VisualizzaGraficoPIRResponse;
  onPrevious: () => void;
}

export const PirBoxCard = (props: PirBoxCardProps) => {
  const { formatMessage } = useIntl();
  const { onDownloadFile, onShowGraph, graphRenderEnabled, dataGraph, onPrevious } = props;

  return (
    <div className={'pirbox-card'}>
      <div className='pirbox-title'>
        <AppSvgIcon icon={'home-wallet'} />
        <span className='title-label'>{formatMessage(pirBoxCardMessages.cardTitle)}</span>
      </div>
      {!graphRenderEnabled && props.summaries.map((summary) => <PirBoxItem key={summary?.mandateCode} summary={summary}
                                                                           onDownloadFile={onDownloadFile}
                                                                           onShowGraph={onShowGraph} />)}
      {graphRenderEnabled && <div><PirBoxCharts dataSource={dataGraph} onPrevious={onPrevious}/></div>}
    </div>
  );
};
