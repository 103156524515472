import { commonButtonMessages, webCollaborationPermissionRequestsDialogMessages } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType, AppButtonVariant } from '../buttons/app-button';
import { AppDialog } from '../dialog/app-dialog';
import './web-collaboration-permission-requests-dialog.scss';

interface WebCollaborationPermissionRequestsDialogProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
}

export const WebCollaborationPermissionRequestsDialog = (props: WebCollaborationPermissionRequestsDialogProps) => {
  const { formatMessage } = useIntl();
  return (
    <AppDialog open={props.open}>
      <div className='web-collaboration-permission-requests-dialog'>
        <div className='web-collaboration-permission-requests-dialog-section'>
          <h3 className='web-collaboration-permission-requests-dialog-title'>{formatMessage(webCollaborationPermissionRequestsDialogMessages.title)}</h3>
          <p className='web-collaboration-permission-requests-dialog-content'>{formatMessage(webCollaborationPermissionRequestsDialogMessages.content)}</p>
          <p className='web-collaboration-permission-requests-dialog-content'>{formatMessage(webCollaborationPermissionRequestsDialogMessages.contentLine2)}</p>
        </div>
        <div className='web-collaboration-permission-requests-dialog-button-section'>
          <AppButton
            type={AppButtonType.BUTTON}
            variant={AppButtonVariant.Secondary}
            label={formatMessage(commonButtonMessages.cancel)}
            onClick={props.onClose}
            labelClassName='web-collaboration-permission-requests-dialog-icon-button-label'
            className='web-collaboration-permission-requests-dialog-icon-button'
          />
          <AppButton
            type={AppButtonType.BUTTON}
            variant={AppButtonVariant.Primary}
            label={formatMessage(webCollaborationPermissionRequestsDialogMessages.enabledButton)}
            onClick={props.onContinue}
            labelClassName='web-collaboration-permission-requests-dialog-icon-button-label'
            className='web-collaboration-permission-requests-dialog-icon-button'
          />
        </div>
      </div>
    </AppDialog>
  );
};
