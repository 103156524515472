import { faqsPageMessages } from 'azshared';
import { MessageDescriptor } from 'react-intl';

export interface FaqSubAnswer {
  title: MessageDescriptor;
  subAnswer: MessageDescriptor;
}

export interface FaqAnswer {
  title?: MessageDescriptor;
  answer: MessageDescriptor;
  subAnswer?: FaqSubAnswer[];
}

export interface FaqQuestion {
  question: MessageDescriptor;
  answers: FaqAnswer[];
}

export const faq: FaqQuestion[] = [
  {
    question: faqsPageMessages.faqWhatAreInvestmentServices,
    answers: [
      {
        answer: faqsPageMessages.faqWhatAreInvestmentServicesAnswer
      }
    ]
  },
  {
    question: faqsPageMessages.faqWhichAreInvestmentServices,
    answers: [
      {
        title: faqsPageMessages.faqWhichAreInvestmentServicesFirstAnswerTitle,
        answer: faqsPageMessages.faqWhichAreInvestmentServicesFirstAnswerDescription
      },
      {
        title: faqsPageMessages.faqWhichAreInvestmentServicesSecondAnswerTitle,
        answer: faqsPageMessages.faqWhichAreInvestmentServicesSecondAnswerDescription
      },
      {
        title: faqsPageMessages.faqWhichAreInvestmentServicesThirdAnswerTitle,
        answer: faqsPageMessages.faqWhichAreInvestmentServicesThirdAnswerDescription
      },
      {
        title: faqsPageMessages.faqWhichAreInvestmentServicesFourthAnswerTitle,
        answer: faqsPageMessages.faqWhichAreInvestmentServicesFourthAnswerDescription
      },
      {
        title: faqsPageMessages.faqWhichAreInvestmentServicesFifthAnswerTitle,
        answer: faqsPageMessages.faqWhichAreInvestmentServicesFifthAnswerDescription
      },
      {
        title: faqsPageMessages.faqWhichAreInvestmentServicesSixthAnswerTitle,
        answer: faqsPageMessages.faqWhichAreInvestmentServicesSixthAnswerDescription
      },
      {
        title: faqsPageMessages.faqWhichAreInvestmentServicesSeventhAnswerTitle,
        answer: faqsPageMessages.faqWhichAreInvestmentServicesSeventhAnswerDescription
      }
    ]
  },
  {
    question: faqsPageMessages.faqInvestmentServicesProvider,
    answers: [
      {
        answer: faqsPageMessages.faqInvestmentServicesProviderAnswer,
        subAnswer: [
          {
            title: faqsPageMessages.faqInvestmentServicesProviderFirstSubAnswerTitle,
            subAnswer: faqsPageMessages.faqInvestmentServicesProviderFirstSubAnswerDescription
          },
          {
            title: faqsPageMessages.faqInvestmentServicesProviderSecondSubAnswerTitle,
            subAnswer: faqsPageMessages.faqInvestmentServicesProviderSecondSubAnswerDescription
          },
          {
            title: faqsPageMessages.faqInvestmentServicesProviderThirdSubAnswerTitle,
            subAnswer: faqsPageMessages.faqInvestmentServicesProviderThirdSubAnswerDescription
          },
          {
            title: faqsPageMessages.faqInvestmentServicesProviderFourthSubAnswerTitle,
            subAnswer: faqsPageMessages.faqInvestmentServicesProviderFourthSubAnswerDescription
          },
          {
            title: faqsPageMessages.faqInvestmentServicesProviderFifthSubAnswerTitle,
            subAnswer: faqsPageMessages.faqInvestmentServicesProviderFifthSubAnswerDescription
          },
          {
            title: faqsPageMessages.faqInvestmentServicesProviderSixthSubAnswerTitle,
            subAnswer: faqsPageMessages.faqInvestmentServicesProviderSixthSubAnswerDescription
          },
          {
            title: faqsPageMessages.faqInvestmentServicesProviderSeventhSubAnswerTitle,
            subAnswer: faqsPageMessages.faqInvestmentServicesProviderSeventhSubAnswerDescription
          },
          {
            title: faqsPageMessages.faqInvestmentServicesProviderEighthSubAnswerTitle,
            subAnswer: faqsPageMessages.faqInvestmentServicesProviderEighthSubAnswerDescription
          },
          {
            title: faqsPageMessages.faqInvestmentServicesProviderNinthSubAnswerTitle,
            subAnswer: faqsPageMessages.faqInvestmentServicesProviderNinthSubAnswerDescription
          }
        ]
      }
    ]
  },
  {
    question: faqsPageMessages.faqWhatAreFinancialTools,
    answers: [
      {
        answer: faqsPageMessages.faqWhatAreFinancialToolsAnswer
      }
    ]
  },
  {
    question: faqsPageMessages.faqMostCommonFinancialTools,
    answers: [
      {
        answer: faqsPageMessages.faqMostCommonFinancialToolsFirstAnswer
      },
      {
        answer: faqsPageMessages.faqMostCommonFinancialToolsSecondAnswer
      },
      {
        answer: faqsPageMessages.faqMostCommonFinancialToolsThirdAnswer
      },
      {
        answer: faqsPageMessages.faqMostCommonFinancialToolsFourthAnswer
      },
      {
        answer: faqsPageMessages.faqMostCommonFinancialToolsFifthAnswer
      }
    ]
  },
  {
    question: faqsPageMessages.faqBenchmark,
    answers: [
      {
        answer: faqsPageMessages.faqBenchmarkAnswer
      }
    ]
  },
  {
    question: faqsPageMessages.faqRating,
    answers: [
      {
        answer: faqsPageMessages.faqRatingAnswer
      }
    ]
  },
  {
    question: faqsPageMessages.faqInvestmentTimeHorizon,
    answers: [
      {
        answer: faqsPageMessages.faqInvestmentTimeHorizonAnswer
      }
    ]
  },
  {
    question: faqsPageMessages.faqPerformance,
    answers: [
      {
        answer: faqsPageMessages.faqPerformanceAnswer
      }
    ]
  },
  {
    question: faqsPageMessages.faqSwitch,
    answers: [
      {
        answer: faqsPageMessages.faqSwitchAnswer
      }
    ]
  }
];
