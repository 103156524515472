import { documentsAreaMessages } from 'azshared';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Card, CardSize } from '../../../../../components/card/card';
import { Loader, LoaderSize } from '../../../../../components/loader/loader';
import { NoDocumentsEmptyState } from '../../../../../components/no-documents-empty-state/no-documents-empty-state';
import { DocumentsTable, DocumentsTableProps } from '../document-table/documents-table';

export interface DocumentCardProps extends DocumentsTableProps {
  title: string;
  flagIsVisible: boolean;
}

export const DocumentCard = (props: DocumentCardProps) => {
  const { formatMessage } = useIntl();

  return (
    <Fragment>
      {props.flagIsVisible && (
        <Card sizeVariant={CardSize.MAXI}>
          <h4>{props.title}</h4>
          {props.isLoading && (
            <div className='practice-card-loader'>
              <Loader loadersize={LoaderSize.XL} />
            </div>
          )}
          {(!props.documents || props.documents.length === 0) && !props.isLoading && <NoDocumentsEmptyState text={formatMessage(documentsAreaMessages.unavailableDocuments)} />}
          {props.documents?.length > 0 && !props.isLoading &&  <DocumentsTable {...props} />}
        </Card>
      )}
    </Fragment>
  );
};
