import {
  DashboardWalletSummary,
  HomeCard,
  percentageFormatter,
  shortLabelForWalletMacroCategoryType
} from 'azshared';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AppSvgIcon } from '../../../../../components/svg-icon/app-svg-icon';
import useElementSize from '../../../../../hooks/use-element-size';
import './home-my-wallet-card.scss';



interface HomeMyWalletCardCarouselProps {
  card: HomeCard<DashboardWalletSummary>;
}

const cardSize = 90;
const cardGap = 20;

const computeVisibleSlides = (width: number, totalSlides: number): number => {
  const carouselWidth = width - cardGap;
  return Math.min(Math.floor(carouselWidth / (cardSize + cardGap)), totalSlides);
};

export const HomeMyWalletCardCarousel = (props: HomeMyWalletCardCarouselProps) => {
  const { formatMessage } = useIntl();
  const { card } = props; 
  const [carouselRef, { width }] = useElementSize();

  const visibleSlides = useMemo(() => {
    if (card.value?.macroCategories) {
      return computeVisibleSlides(width, card.value?.macroCategories.length);
    }
  }, [width, card.value?.macroCategories]);


  return (
      <div className='home-wallet-card-carousel' ref={carouselRef}>
        <CarouselProvider
          naturalSlideWidth={cardSize}
          naturalSlideHeight={cardSize}
          totalSlides={card.value?.macroCategories ? card.value?.macroCategories.length : 0}
          visibleSlides={visibleSlides}
          isIntrinsicHeight={true}
          step={1}
          touchEnabled={false}
          dragEnabled={true}>
          <Slider>
            {card.value?.macroCategories.map((item, index) => (
              <Slide key={item.type} index={index}>
                <div className='macrocategory-carousel'>
                  <div className='macrocategory-carousel-percentage'>
                  {percentageFormatter(item.percentage || 0, {significantDigits: item.percentage ? 2 : 0, isPercentage: true})}</div>
                  <div className='macrocategory-carousel-label'>{formatMessage(shortLabelForWalletMacroCategoryType(item.type))}</div>
                  <div className='macrocategory-carousel-dot' style={{ backgroundColor: item.color}}></div>
                </div>
              </Slide>
            ))}
          </Slider>
          { card.value && <div className='home-wallet-card-arrow-buttons'>
            <ButtonBack>
              <AppSvgIcon icon={'arrow-left'} />
            </ButtonBack>
            <ButtonNext>
              <AppSvgIcon icon={'arrow-right'} />
            </ButtonNext>
          </div>}
        </CarouselProvider>
      </div>
  );
};
