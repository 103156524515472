import React from 'react';
import './mifid-section.scss';
import { Domanda, Sezione } from 'azshared/build/main/lib/api/generated/dob';
import { UseFormReturn } from 'react-hook-form';
import { SurveyQuestion } from './survey-question';

interface MifidSectionProps {
  section?: Sezione;
  form: UseFormReturn;
}

function sort(a: Domanda, b: Domanda) {
  if (a?.numDomanda && b?.numDomanda) {
    return parseInt(a.numDomanda.split('.').join(''), 10) - parseInt(b.numDomanda.split('.').join(''), 10); // a.numDomanda.localeCompare(b.numDomanda);
  }
  if (a?.numDomanda && b.strutturaInternaDomande?.domande?.[0]?.numDomanda) {
    return a.numDomanda.localeCompare(b.strutturaInternaDomande.domande[0].numDomanda);
  }
  if (b?.numDomanda && a.strutturaInternaDomande?.domande?.[0]?.numDomanda) {
    return a.strutturaInternaDomande.domande[0].numDomanda.localeCompare(b.numDomanda);
  }
  if (a?.numDomanda) {
    return -1;
  }
  if (b?.numDomanda) {
    return 1;
  }
  return (a?.idDomanda ?? 0) - (b?.idDomanda ?? 0);
}

// filtering unused answers
function filter(a: Domanda) {
  return ![a.idDomanda || -1, ...(a.strutturaInternaDomande?.domande?.map((d) => d.idDomanda || -1) || [])].some((d) => [263, 264, 265, 125, 126].includes(d));
}

export const MifidSection = ({ section, form }: MifidSectionProps) => {
  return (
    <div className='mifid-section'>
      <h3>{section?.descSezione ?? ''}</h3>
      {section?.listaDomande
        ?.filter(filter)
        .sort(sort)
        .map((d) => (<>
          {d.pathImage && <img className='mifid-image' alt={'image'} src={d.pathImage} />}
          <SurveyQuestion key={d.idDomanda} form={form} question={d} />
        </>))}
    </div>
  );
};
