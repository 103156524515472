import React from 'react';
import InfographicPie from '../../../../components/infographic-pie/infographic-pie';
import { useIntl } from 'react-intl';
import { pirBoxCardMessages } from 'azshared';
import { VisualizzaGraficoPIRResponse } from 'azshared/build/main/lib/api/generated/anagrafica';
import './pir-box-pie-chart.scss';

export interface PirBoxPieChartProps {
  dataSource: VisualizzaGraficoPIRResponse;
}

export const PirBoxPieChart = (props: PirBoxPieChartProps) => {
  const { formatMessage } = useIntl();
  const { dataSource } = props;
  const type = dataSource.codMandatoPir?.includes('A') ? 'ordinario' : 'alternative';
  const title = type === 'ordinario' ? pirBoxCardMessages.ordinaryDepositTitle : pirBoxCardMessages.alternativeDepositTitle;

  return <div>
    <h4 className='pir-box-pie-chart-title'>{formatMessage(title)} - {formatMessage(pirBoxCardMessages.composition)}</h4>
    <InfographicPie
      legend
      items={[{
      value: dataSource?.percentualeCompliant || 0,
      label: formatMessage(pirBoxCardMessages.qualified)
    }, { value: dataSource?.percentualeNoCompliant || 0, label: formatMessage(pirBoxCardMessages.notQualified) }]} />
  </div>;
};