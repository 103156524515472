import { appRoutes, PERSIST_STORE_KEY, useAppSelector } from 'azshared';
import React, { Fragment, useMemo} from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const PrivateRoute = () => {
  const storedToken: string = useAppSelector((state) => state.data?.user?.token);

  const token = useMemo(() => {
    if (storedToken) {
      return storedToken;
    }
    
    const user = sessionStorage.getItem(PERSIST_STORE_KEY);
    if (user) {
      return JSON.parse(user)?.token;
    }

    return undefined;
    
  }, [storedToken]);

  return (
    <Fragment>
      { !token ? <Navigate to={appRoutes.Login} replace /> : <Outlet />}
    </Fragment>
  );
};
