import { documentsAreaCommonSliceActions, downloadDocumentsFile, selectDocumentsAreaCommonState, SignatureDocumentModule, useAppSelector } from 'azshared';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { downloadFile } from '../../../../../utils/utils';

export const SignatureAreaLink = (props: {module: SignatureDocumentModule, token?: string}) => {
  const { module, token } = props;
  const dispatch = useDispatch();
  const { fileDownloaded, fileDownloadedRequestStatus } =
    useAppSelector(selectDocumentsAreaCommonState);
  const [isDownloaded, setIsDownloaded] = useState(true);


  const onDownloadDocument = useCallback(() => {
    if (!module.fileUrl) {
      dispatch(downloadDocumentsFile({document: module as any, token }));
      setIsDownloaded(false);
    }
  }, [dispatch, module, token]);

  useEffect(() => {
    if (fileDownloadedRequestStatus === 'succeeded' && fileDownloaded && !isDownloaded) {
        setIsDownloaded(true);
        downloadFile(fileDownloaded.name, fileDownloaded.blob);
        dispatch(documentsAreaCommonSliceActions.clearDownloadFile());
    }
  }, [
    dispatch,
    fileDownloadedRequestStatus,
    fileDownloaded,
    isDownloaded
  ]);

  return (
    <a className='app-link' href={module.fileUrl} target='_blank' rel='noreferrer' onClick={onDownloadDocument}>
      {module.title}
    </a>
  );
};