import { commonButtonMessages, signatureAreaMessages } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonVariant } from '../../../../../components/buttons/app-button';
import { AppDialog } from '../../../../../components/dialog/app-dialog';
import './reject-proposal-dialog.scss';

interface RejectProposalDialogProps {
	open: boolean;
	onClose: () => void;
	onCancel: () => void;
    onReject: () => void;
}

export const RejectProposalDialog = (props: RejectProposalDialogProps) => {

    const {
		open,
        onClose,
        onCancel,
        onReject
	} = props;
	const { formatMessage } = useIntl();


    return <AppDialog open={open} onClose={onClose}>
        <div>
        <div className='reject-proposal-dialog-content'>
          <div className='text-dialog'>{formatMessage(signatureAreaMessages.rejectProposalDialogText)}</div>
        </div>
        <div className='reject-proposal-dialog-button'>
          <AppButton variant={AppButtonVariant.Secondary} label={formatMessage(commonButtonMessages.cancel)} onClick={onCancel} />
          <AppButton label={formatMessage(signatureAreaMessages.rejectLabel)} onClick={onReject} />
        </div>
      </div>
    </AppDialog>;

};