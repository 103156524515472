/* eslint-disable react/jsx-no-bind */
import { DatiFatcaConsistency, homeOnboardingSelfAmlFatcaCrsSurveyMessages } from 'azshared';
import { DatiFatca, DominioEntry, DominioEntryCodiceDominioEnum, ResidenzaFiscale } from 'azshared/build/main/lib/api/generated/dob';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppInputText } from '../../../../../../../components/input-text/app-input-text';
import { AppSearchableSelect, AppSearchableSelectOption } from '../../../../../../../components/select/app-searchable-select';
import { AppSvgIcon } from '../../../../../../../components/svg-icon/app-svg-icon';
import './residenze-fiscali.scss';

export interface ResidenzeFiscaliProps {
  residenzeFiscali: Array<ResidenzaFiscale>;
  onChange: (key: keyof DatiFatca, fatcaValue: any) => void;
  dominioNazioni?: { [key: string]: string };
  consistency: DatiFatcaConsistency;
}

export const ResidenzeFiscali = (props: ResidenzeFiscaliProps) => {
  const { residenzeFiscali, dominioNazioni, onChange, consistency } = props;
  const { formatMessage } = useIntl();

  const [nRow, setRow] = useState<number>(0);
  const [opts, setOpts] = useState<AppSearchableSelectOption[]>([]);

  useEffect(() => {
    if (dominioNazioni) {
      const options = Object.entries(dominioNazioni).flatMap(([key, value]) => {
        return residenzeFiscali.some((res) => res.codiceNazione?.chiave === key) ? [] : { value: key, label: value };
      });
      setOpts(options);
    }
  }, [dominioNazioni, residenzeFiscali]);
  useEffect(() => {
    const row = residenzeFiscali?.length || 1;
    setRow(row > 3 ? 3 : row);
  }, [residenzeFiscali]);

  const addRow = useCallback(() => {
    if (nRow == residenzeFiscali?.length) {
      setRow(nRow + 1);
    }
  }, [nRow, residenzeFiscali]);
  const removeRow = useCallback(
    (index) => {
      onChange(
        'listaResidenzaFiscale',
        residenzeFiscali.filter((_r, i) => i != index)
      );
    },
    [onChange, residenzeFiscali]
  );

  const addNazione = useCallback(
    (index, codiceNazione?: DominioEntry) => {
      const residenza = residenzeFiscali.at(index);
      if (!residenza && codiceNazione) {
        onChange('listaResidenzaFiscale', residenzeFiscali.concat([{ codiceNazione }]));
        return;
      }
      if (residenza) {
        residenza.codiceNazione = codiceNazione;
        onChange('listaResidenzaFiscale', residenzeFiscali.filter((_r, i) => i != index).concat([residenza]));
      }
    },
    [onChange, residenzeFiscali]
  );
  const removeNazione = useCallback(
    (index) => {
      const residenza = residenzeFiscali.at(index);
      if (residenza) {
        residenza.codiceNazione = undefined;
        onChange('listaResidenzaFiscale', residenzeFiscali.filter((_r, i) => i != index).concat([residenza]));
      }
    },
    [onChange, residenzeFiscali]
  );
  const addCodiceFiscaleEstero = useCallback(
    (index, codiceFiscaleEstero?: string) => {
      const residenza = residenzeFiscali.at(index);
      if (!residenza && codiceFiscaleEstero) {
        onChange('listaResidenzaFiscale', residenzeFiscali.concat([{ codiceFiscaleEstero }]));
        return;
      }
      if (residenza) {
        residenza.codiceFiscaleEstero = codiceFiscaleEstero;
        onChange('listaResidenzaFiscale', residenzeFiscali.filter((_r, i) => i != index).concat([residenza]));
      }
    },
    [onChange, residenzeFiscali]
  );

  return (
    <>
      {[...Array(nRow).keys()].map((i) => (
        <div key={i} className='div-horizontal-top-spaced'>
          <div className='search-box'>
            <AppSearchableSelect
              options={opts}
              valid={consistency?.listaResidenzaFiscale?.[i]?.codiceNazione}
              value={opts?.find((o) => o.value === residenzeFiscali?.[i]?.codiceNazione?.chiave)}
              placeholder={formatMessage(homeOnboardingSelfAmlFatcaCrsSurveyMessages.residenzaFiscalePlaceholder)}
              onChange={(value, action) => {
                if (action.action === 'select-option') {
                  addNazione(i, { codiceDominio: DominioEntryCodiceDominioEnum.NazioneResidenzaFiscale, chiave: value?.value });
                } else if (action.action === 'clear') {
                  removeNazione(i);
                }
              }}
            />
            <AppInputText
              placeholder={formatMessage(homeOnboardingSelfAmlFatcaCrsSurveyMessages.codiceFiscalePlaceholder)}
              value={residenzeFiscali?.[i]?.codiceFiscaleEstero ?? ''}
              valid={consistency?.listaResidenzaFiscale?.[i]?.codiceFiscaleEstero}
              required={residenzeFiscali?.[i]?.codiceNazione !== undefined}
              onChange={(v) => addCodiceFiscaleEstero(i, v)}
            />
          </div>
          {i == 0 && nRow < 3 && (
            <div
              className={
                'div-horizontal div-horizontal-action' +
                (residenzeFiscali?.[i]?.codiceFiscaleEstero && residenzeFiscali?.[i]?.codiceNazione ? '' : ' cta-disabled')
              }
              onClick={addRow}>
              <AppSvgIcon icon='cross' />
              <div className='div-horizontal div-horizontal-blue-spaced'>{formatMessage(homeOnboardingSelfAmlFatcaCrsSurveyMessages.insertOther)}</div>
            </div>
          )}
          {i != 0 && nRow > 1 && (
            <div className='div-horizontal div-horizontal-action' onClick={() => removeRow(i)}>
              <AppSvgIcon icon='close' />
              <div className='div-horizontal div-horizontal-blue-spaced'>{formatMessage(homeOnboardingSelfAmlFatcaCrsSurveyMessages.remove)}</div>
            </div>
          )}
        </div>
      ))}
    </>
  );
};
