import { mdiClose } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import React, { ReactChild } from 'react';

export interface AppDialogProps {
  open: boolean;
  children: ReactChild;
  onClose?: () => void;
  className?: string;
}

export const AppDialog = (props: AppDialogProps) => {
  const { open, children, onClose, className } = props;

  return (
    <Dialog open={open} sx={{ backgroundColor: '#8f8f8fa3' }} maxWidth={false} className={className}>
      <DialogContent sx={{ color: '#2D3748' }}>{children}</DialogContent>
      {onClose && (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#2E6CD1'
          }}
          className='app-dialog-close-button'>
          <Icon path={mdiClose} size={1} className='app-dialog-close-button-icon' />
        </IconButton>
      )}
    </Dialog>
  );
};
