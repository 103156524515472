import React from 'react';
import { ChartBar } from './chart-bar';
import './bar-pair.scss';
export const BarPair = (props: any) => {
    const {value, max} = props; 
  return (
    <div className='datapoint-bars'>
      <div className='main-bar'>
        <ChartBar max={max} value={value} isMain={true}/>
      </div>
      <div>
        <ChartBar max={max} value={max} />
      </div>
    </div>
  );
};
