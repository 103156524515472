import {PercentageBar} from '../../../../../components/barchart/bars/percentage-bar';
import React from 'react';
import {useIntl} from 'react-intl';
import {ProductConcentration, walletErDetailPageMessages} from 'azshared';
import './residual-duration-chart.scss';
import {Loader, LoaderSize} from '../../../../../components/loader/loader';

export interface ResidualDurationChartProps {
  products: ProductConcentration[],
  isLoading: boolean,
}

export const ResidualDurationChart = (props: ResidualDurationChartProps) => {
  const {formatMessage} = useIntl();
  const {products, isLoading} = props;


  return (
    <div className={'residual-duration-chart'}>
      <h4>{formatMessage(walletErDetailPageMessages.productExpirationDateTitle)}</h4>
      <div className={'residual-duration-content' + (isLoading ? '-loader' : '')}>
        {isLoading && <Loader loadersize={LoaderSize.XL}/>}
        {!isLoading && products.map((p, i) =>
          <PercentageBar
            key={'residual-duration-chart-percentage_' + i}
            percentage={p.expirationPercentage}
            title={p.product.productName}
            start={0}
            end={p.years}
            disabled={p.years === 0}
            step={1}
            xLegend={p.years === 0 ? undefined : formatMessage(walletErDetailPageMessages.productYears)}
            yLegend={p.years === 0 ? formatMessage(walletErDetailPageMessages.productND) : formatMessage(walletErDetailPageMessages.productLabel)}
          />
        )}
      </div>
    </div>
  );
};