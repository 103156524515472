import './er-detail-page.scss';
import React, { useCallback, useMemo } from 'react';
import { AppHeader } from '../../../../../components/header/header';
import { amountFormatter, useAppSelector, useErDetailLogic, walletErDetailPageMessages } from 'azshared';
import { useIntl } from 'react-intl';
import { Card, CardSize } from '../../../../../components/card/card';
import { VideoPlayer } from '../../../../../components/video-player/video-player';
import { useGlobalActions } from '../../../../../hooks/global-actions';
import { ErDetailTable } from '../../components/er-detail-table/er-detail-table';
import { PortfolioSummaryChart } from '../../components/portfolio-summary/portfolio-summary';
import { PortfolioAssetClass } from '../../components/portfolio-asset-class/portfolio-asset-class';
import { ErRiskProfile } from './risk-profile/risk-profile';
import { ProductConcentrationChart } from '../../components/product-concentration/product-concentration';
import { ResidualDurationChart } from '../../components/residual-duration-chart/residual-duration-chart';
import { usePdfGeneration } from '../../../../../hooks/use-pdf-generation';
import { downloadFile } from '../../../../../utils/utils';
import { AppButton } from '../../../../../components/buttons/app-button';

export const ErDetailPage = () => {
  const { formatMessage } = useIntl();
  const globalActions = useGlobalActions();

  const { video, totalAmount, products, isLoadingWallet, portfolioSummary, realEconomyAssetSummary } = useErDetailLogic(globalActions);
  const { residuePercentage } = useAppSelector((state) => state.features.walletStore.erUnderlyingCompanies);
  const { generatePdf } = usePdfGeneration();

  const pdfTitle = useMemo(() => {
    return formatMessage(walletErDetailPageMessages.pageTitle) + '.pdf';
  }, [formatMessage]);

  const getPdf = useCallback(() => {
    const divToPrint = document.getElementById('toPrint');
    const pageBreaker = document.getElementById('pageBreaker');
    if (pageBreaker){
      pageBreaker.className='page-breaker-div';
    }
    const graphSpacer = document.getElementById('graphSpacer');
    if (graphSpacer){
      graphSpacer.className='graph-spacer-div';
    }
    generatePdf(divToPrint)
      ?.then(v => {
        downloadFile(pdfTitle, v);
      });
  }, [generatePdf, pdfTitle]);
  
  return (
    <div className='wallet-er-detail-page'>
      <AppHeader />
      <h2>{formatMessage(walletErDetailPageMessages.pageTitle)}</h2>
      <div className='page-content'>
        <Card sizeVariant={CardSize.MAXI}>
          <div id='toPrint' className='card-content'>
            <div className='video-section'>
              {video.requestStatus !== 'loading' && (
                <div className='video-card' data-html2canvas-ignore='true'>
                  <div className='video'>
                    {video.value && (
                      <VideoPlayer
                        video={video.value}
                        hideDetails={true}
                        title={formatMessage(walletErDetailPageMessages.videoTitle)}
                        titleIcon={'home-video'}
                      />
                    )}
                  </div>
                </div>
              )}
              <div>
                <h4>{formatMessage(walletErDetailPageMessages.reportVideoTitle)}</h4>
                <p>{formatMessage(walletErDetailPageMessages.videoDescription)}</p>
              </div>
            </div>
            <div id='graphSpacer'/>
            <div className='graphs-section'>
              <PortfolioSummaryChart loading={isLoadingWallet} summary={portfolioSummary} />
              <PortfolioAssetClass loading={isLoadingWallet} items={realEconomyAssetSummary} />
            </div>
            <hr className='separator' />
            <div className='table-section'>
              <div className='table-section-header'>
                <span>{formatMessage(walletErDetailPageMessages.tableHeader)}</span>
                {!isLoadingWallet && (
                  <span>
                    {formatMessage(walletErDetailPageMessages.tableHeaderAmount)} {totalAmount && amountFormatter(totalAmount)}
                  </span>
                )}
              </div>
              <ErDetailTable products={products} isLoading={isLoadingWallet} />
            </div>
          <hr className='separator' />
            <div id='pageBreaker'/>
            <div className='graphs-section' >
              <ProductConcentrationChart loading={isLoadingWallet} products={products} />
              <div className='right'>
                <ErRiskProfile percentage={residuePercentage * 100} />
                <ResidualDurationChart products={products} isLoading={isLoadingWallet} />
              </div>
            </div>
            <div className='page-actions' data-html2canvas-ignore='true'>
              {!isLoadingWallet && <AppButton label='stampa pdf' onClick={getPdf} />}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
