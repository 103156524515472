import React, { useCallback } from 'react';

interface PageIndicatorProps {
  pageNumber: number;
  currentPage: number;

  onPageSelected: (pageNumber: number) => void;
}

export const PageIndicator = (props: PageIndicatorProps) => {
  const { currentPage, pageNumber, onPageSelected } = props;

  const onClick = useCallback(() => onPageSelected(pageNumber), [pageNumber, onPageSelected]);

  return (
    <span key={pageNumber} className={`page-indicator page-number${currentPage === pageNumber ? ' current' : ''}`} onClick={onClick}>
      {pageNumber}
    </span>
  );
};
