import { unlockUserInsertUsernameMessages, useUnlockUser } from 'azshared';
import React, { FormEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType } from '../../../../components/buttons/app-button';
import { Card } from '../../../../components/card/card';
import { AppSuccessDialog } from '../../../../components/dialog/app-success-dialog';
import { AppInputText } from '../../../../components/input-text/app-input-text';
import { useGlobalActions } from '../../../../hooks/global-actions';
import { LoginContainer } from '../../login-container/login-container';
import './unlock-user-insert-username-page.scss';

export const UnlockUserInsertUsernamePage = () => {
  const { formatMessage } = useIntl();

  const { handleSubmit, unlockUserRequestStatus, username, closeSuccessDialog, openSuccessDialog } = useUnlockUser(useGlobalActions());

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );

  return (
    <LoginContainer>
      <div className='unlock-user-insert-username'>
        <Card
          title={formatMessage(unlockUserInsertUsernameMessages.pageTitle)}
          subtitle={formatMessage(unlockUserInsertUsernameMessages.pageSubtitle)}
          back={formatMessage(unlockUserInsertUsernameMessages.backTo)}
        >
          <form onSubmit={onSubmit}>
            <div className='input-username'>
              <AppInputText
                label={formatMessage(unlockUserInsertUsernameMessages.labelInsertUsernameUnlockUser)}
                placeholder={formatMessage(unlockUserInsertUsernameMessages.placeholderInsertUsernameUnlockUser)}
                value={username}
                disabled
              />
            </div>
            <div className='button'>
              <AppButton
                type={AppButtonType.SUBMIT}
                label={formatMessage(unlockUserInsertUsernameMessages.buttonUnlockUserInsertUsername)}
                loading={unlockUserRequestStatus === 'loading'}
              />
            </div>
          </form>
        </Card>
      </div>
      <AppSuccessDialog
        open={openSuccessDialog}
        onClose={closeSuccessDialog}
        buttonLabel={formatMessage(unlockUserInsertUsernameMessages.successDialogUnlockUserButtonLabel)}
      >
        <div className='text-dialog'>{formatMessage(unlockUserInsertUsernameMessages.successMessageUnlockUser)}</div>
      </AppSuccessDialog>
    </LoginContainer>
  );
};
