import React, { useCallback } from 'react';
import { mdiEyeOutline } from '@mdi/js';
import { AppButton } from '../../../../components/buttons/app-button';
import { appRoutes, HomeCard, homeSignatureAreaCardMessages, signatureAreaActions } from 'azshared';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

import './home-signature-area-card.scss';
import { AppSvgIcon } from '../../../../components/svg-icon/app-svg-icon';
import { Loader, LoaderSize } from '../../../../components/loader/loader';

export const HomeSignatureAreaCard = (props: { documents: HomeCard<number>}) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { documents } = props;

  const goToSignatureArea = useCallback(() => {
    navigate(appRoutes.Sign);
    dispatch(signatureAreaActions.updateSelectedSection('TO_SIGN'));
  }, [dispatch, navigate]);

  return (
    <div className='home-signature-area-card'>
      <div className='home-signature-area-card-header'>
        <AppSvgIcon icon='home-pencil' />
        <h4> {formatMessage(homeSignatureAreaCardMessages.cardTitle)} </h4>
      </div>
      {!!documents.value && documents.value > 0 && documents.requestStatus !== 'loading' && (
        <>
          <div className='home-signature-area-card-description'>
            {formatMessage(homeSignatureAreaCardMessages.descriptionSignatureAreaCardFirstPart)}
            <span>{documents.value}</span>
            {formatMessage(homeSignatureAreaCardMessages.descriptionSignatureAreaCardSecondPart)}
          </div>
          <div className='home-signature-area-card-button'>
            <AppButton label={formatMessage(homeSignatureAreaCardMessages.buttonLabelSignatureAreaCard)} iconLeft={mdiEyeOutline} onClick={goToSignatureArea} />
          </div>
        </>
      )}
      {documents.value === 0 &&  documents.requestStatus !== 'loading' && (
        <div className='home-signature-area-card-no-documents'>
          <div className='home-signature-area-card-no-documents-description'>
            {formatMessage(homeSignatureAreaCardMessages.descriptionSignatureAreaCardNoDocuments)}
          </div>
          <div className='img-no-documents' />
        </div>
      )}
      {documents.requestStatus === 'loading' &&  <div className='home-signature-area-card-loader'> <Loader loadersize={LoaderSize.XL}/> </div>}
    </div>
  );
};
