import { mdiChevronLeft, mdiCloseCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { changeEmailMessages, commonButtonMessages, useChangeEmailDialogLogic } from 'azshared';
import React, { FormEvent, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType } from '../../../components/buttons/app-button';
import { AppDialog } from '../../../components/dialog/app-dialog';
import { AppInputText } from '../../../components/input-text/app-input-text';
import { AppTimeline } from '../../../components/timeline/app-timeline';
import { useGlobalActions } from '../../../hooks/global-actions';

export interface ChangeEmailDialogsProps {
  steps: string[];
}

export const ChangeEmailDialogs = (props: ChangeEmailDialogsProps) => {
  const globalActions = useGlobalActions();
  const { steps } = props;

  const { openDialogChangeEmail, closeChangeEmail } = useChangeEmailDialogLogic(globalActions);

  return (
    <div>
      {/* ***** Dialog Email not modifiable ***** */}
      <ErrorDialogEmailNotModifiable />
      <AppDialog open={openDialogChangeEmail} onClose={closeChangeEmail}>
        <div className='dialog-change-email'>
          {/* ***** STEP 1 ***** */}
          <InsertNewEmail steps={steps} />
          {/* ***** STEP 2 ***** */}
          <VerificationNewEmail steps={steps} />
          {/* ***** STEP 3 ***** */}
          <SummaryNewEmail steps={steps} />
        </div>
      </AppDialog>
      {/* ***** Error dialog Too Many Attempts ***** */}
      <ErrorDialogTooManyAttempts />
    </div>
  );
};

interface DialogChangeEmailProps {
  steps: string[];
}

const InsertNewEmail = (props: DialogChangeEmailProps) => {
  const { formatMessage } = useIntl();
  const globalActions = useGlobalActions();

  const { steps } = props;

  const { handleSubmitInsertEmail, step, email, disabledButtonFirstStep, updateEmail, confirmNewEmail, updateConfirmNewEmailChangeEmail, insertEmailChangeEmailRequestStatus } =
    useChangeEmailDialogLogic(globalActions);

  const onSubmitInsertEmail = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      handleSubmitInsertEmail();
    },
    [handleSubmitInsertEmail]
  );

  const onCopy = useCallback((e: FormEvent) => {
    e.preventDefault();
  }, []);

  const onPaste = useCallback((e: FormEvent) => {
    e.preventDefault();
  }, []);

  return (
    <div>
      {/* ***** STEP 1 ***** */}
      {step === 'INSERIMENTO_DATI' && (
        <div>
          <div className='timeline-change-email'>
            <AppTimeline steps={steps} stepSelected={[0]} />
          </div>
          <form className='form-dialog' onSubmit={onSubmitInsertEmail}>
            <div className='step-1'>
              <div className='email-input-text'>
                <AppInputText
                  placeholder={formatMessage(changeEmailMessages.newEmailPlaceholder)}
                  value={email}
                  label={formatMessage(changeEmailMessages.newEmailLabel)}
                  onChange={updateEmail}
                  onCopy={onCopy}
                  onPaste={onPaste}
                />
                <AppInputText
                  placeholder={formatMessage(changeEmailMessages.confirmNewEmailPlaceholder)}
                  value={confirmNewEmail}
                  label={formatMessage(changeEmailMessages.confirmNewEmailLabel)}
                  onChange={updateConfirmNewEmailChangeEmail}
                  onCopy={onCopy}
                  onPaste={onPaste}
                />
              </div>
              <div className='button-dialog'>
                <AppButton
                  type={AppButtonType.SUBMIT}
                  label={formatMessage(changeEmailMessages.changeEmailGoOnButton)}
                  loading={insertEmailChangeEmailRequestStatus === 'loading'}
                  disabled={disabledButtonFirstStep}
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

const VerificationNewEmail = (props: DialogChangeEmailProps) => {
  const { formatMessage } = useIntl();
  const globalActions = useGlobalActions();

  const { steps } = props;

  const {
    handleSubmitVerificationEmail,
    step,
    email,
    backToStep1,
    changeEmailVerificationCodeEmail,
    changeEmailVerificationCodePhoneNumber,
    updateVerificationCodeEmail,
    updateVerificationCodePhoneNumber,
    buttonDisabled,
    verificationEmailChangeEmailRequestStatus,
    countdown,
    timeoutError
  } = useChangeEmailDialogLogic(globalActions);

  const onSubmitVerificationCode = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      handleSubmitVerificationEmail();
    },
    [handleSubmitVerificationEmail]
  );

  return (
    <div>
      {/* ***** STEP 2 ***** */}
      {step === 'VERIFICA_OTP' && (
        <div>
          <div className='back-link' onClick={backToStep1}>
            <Icon path={mdiChevronLeft} size={0.8} />
            <span>{formatMessage(commonButtonMessages.backLink)}</span>
          </div>
          <div className='timeline-change-email'>
            <AppTimeline steps={steps} stepSelected={[0, 1]} />
          </div>
          <form className='form-dialog' onSubmit={onSubmitVerificationCode}>
            <div className='step-2'>
              <div className='email-input-text'>
                <AppInputText value={email} label={formatMessage(changeEmailMessages.newEmailLabelDisabled)} disabled />
                {/* disclaimer */}
                <div className='email-input-text-disclaimer'>{formatMessage(changeEmailMessages.desclaimerVerificationOtp)}</div>
                {/* codice di verifica email */}
                <AppInputText
                  placeholder={formatMessage(changeEmailMessages.placeholderChangeEmailVerificationCodeEmail)}
                  value={changeEmailVerificationCodeEmail}
                  label={formatMessage(changeEmailMessages.labelChangeEmailVerificationCodeEmail)}
                  onChange={updateVerificationCodeEmail}
                />
                {/* codice di verifica numero di cellulare */}
                <AppInputText
                  placeholder={formatMessage(changeEmailMessages.placeholderChangeEmailVerificationCodePhoneNumber)}
                  value={changeEmailVerificationCodePhoneNumber}
                  label={formatMessage(changeEmailMessages.labelChangeEmailVerificationCodePhoneNumber)}
                  onChange={updateVerificationCodePhoneNumber}
                />
              </div>
              <div className='button-dialog'>
                <AppButton
                  disabled={buttonDisabled}
                  type={AppButtonType.SUBMIT}
                  label={formatMessage(changeEmailMessages.changeEmailGoOnButton)}
                  loading={verificationEmailChangeEmailRequestStatus === 'loading'}
                />
                <div className='count-down '>
                  {timeoutError && <div className='count-down-error'>{formatMessage(changeEmailMessages.expiredOtpCodesError)}</div>}
                  {!timeoutError && formatMessage(changeEmailMessages.disclaimerCountDown) + countdown}
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

const SummaryNewEmail = (props: DialogChangeEmailProps) => {
  const { formatMessage } = useIntl();
  const globalActions = useGlobalActions();

  const { steps } = props;

  const { step, email, closeStep3ChangeEmail } = useChangeEmailDialogLogic(globalActions);

  return (
    <div>
      {/* ***** STEP 3 ***** */}
      {step === 'RIEPILOGO' && (
        <div>
          <div className='timeline-change-email'>
            <AppTimeline steps={steps} stepSelected={[0, 1, 2]} />
          </div>
          <div className='step-3'>
            <div>
              {formatMessage(changeEmailMessages.titleSummaryChangeEmail)}
              <div className='email'>{email}</div>
            </div>
            <hr />
            <div className='disclaimer'>{formatMessage(changeEmailMessages.disclaimerSummaryChangeEmail)}</div>
            <div className='button-dialog'>
              <AppButton label={formatMessage(changeEmailMessages.changeEmailConfirmButton)} onClick={closeStep3ChangeEmail} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ErrorDialogEmailNotModifiable = () => {
  const { formatMessage } = useIntl();
  const globalActions = useGlobalActions();

  const { errorEmailNotEditable, closeErrorEmailNotEditable } = useChangeEmailDialogLogic(globalActions);

  const message = useMemo(() => {
    if (errorEmailNotEditable === 'OTHER_DOCUMENTS_TO_SIGN') {
      return changeEmailMessages.errorEmailNotEditable;
    } else if (errorEmailNotEditable === 'LAST_UPDATING_UNTIL_5_DAYS') {
      return changeEmailMessages.errorEmailNotEditableAfterFirstFiveDays;
    } else if (errorEmailNotEditable === 'PHONE_VERIFICATION') {
      return changeEmailMessages.errorEmailNotEditablePhoneNumberVerification;
    }
  }, [errorEmailNotEditable]);

  return (
    <AppDialog open={!!errorEmailNotEditable}>
      <div className='dialog-error-change-email'>
        <Icon path={mdiCloseCircleOutline} size={2} color={'red'} />
        <div className='text-attention'>{message && formatMessage(changeEmailMessages.errorTooManyAttemptsTitle)}</div>
        <div className='text-dialog'>{message && formatMessage(message)}</div>
        <div className='button-dialog'>
          <AppButton onClick={closeErrorEmailNotEditable} label={formatMessage(changeEmailMessages.errorEmailNotEditableButtonLabel)} />
        </div>
      </div>
    </AppDialog>
  );
};

const ErrorDialogTooManyAttempts = () => {
  const { formatMessage } = useIntl();
  const globalActions = useGlobalActions();

  const { tooManyAttemptsErrorChangeEmail, handleCloseErrorTooManyAttemptsChangeEmail } = useChangeEmailDialogLogic(globalActions);

  return (
    <div>
      <AppDialog open={tooManyAttemptsErrorChangeEmail}>
        <div className='dialog-error-email-verification'>
          <Icon path={mdiCloseCircleOutline} size={2} color={'red'} />
          <div className='title-dialog'>{formatMessage(changeEmailMessages.errorTooManyAttemptsTitle)}</div>
          <div className='text-dialog'>{formatMessage(changeEmailMessages.errorTooManyAttemptsMessage)}</div>
          <div className='button-dialog'>
            <AppButton onClick={handleCloseErrorTooManyAttemptsChangeEmail} label={formatMessage(changeEmailMessages.errorTooManyAttemptsButtonLabel)} />
          </div>
        </div>
      </AppDialog>
    </div>
  );
};
