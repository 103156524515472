import { PercentageBar } from '../../../../../../components/barchart/bars/percentage-bar';
import React from 'react';
import { useIntl } from 'react-intl';
import { walletErDetailPageMessages } from 'azshared';
import './risk-profile.scss';

export interface ErRiskProfileProps {
    percentage: number,
}

export const ErRiskProfile = (props: ErRiskProfileProps) =>{
    const {formatMessage} = useIntl();
    const {percentage} = props;

    return(
        <div className='er-risk-profile'>
            <h4>{formatMessage(walletErDetailPageMessages.riskProfileSectionTitle)}</h4>
            <PercentageBar percentage={percentage} title={formatMessage(walletErDetailPageMessages.investableResidueWeightLabel)} />
        </div>
    );
};