import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { bannerCookiePolicyMessages, updateHideBannerCookiePolicy } from 'azshared';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks/redux';
import './banner-cookie-policy.scss';

export const BannerCookiePolicy = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { hideBannerCookiePolicy } = useAppSelector(state => state.data.user);

  const handleClose = useCallback(() => {
    dispatch(updateHideBannerCookiePolicy(true));
  }, [dispatch]);

  if (hideBannerCookiePolicy) {
    return null;
  }

  return (
    <div className='banner-cookie-policy'>
      <div className='text-banner'>
        {formatMessage(bannerCookiePolicyMessages.bannerMessage)}
        <a className='banner-link' href={`${process.env.PUBLIC_URL}/static/documents/cookie-policy.pdf`} download={'cookie-policy.pdf'}>
          {formatMessage(bannerCookiePolicyMessages.bannerLink)}
        </a>
      </div>
      <span className='icon' onClick={handleClose}>
        <Icon size={0.8} path={mdiClose} />
      </span>
    </div>
  );
};
