import {
  commonButtonMessages,
  customerServiceUnlockUser,
  messagesForCustomerServiceGenericError,
  resetCustomerServiceUnlockUserState,
  useAppSelector,
  userUnlockingMessages
} from 'azshared';
import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonVariant } from '../../../../components/buttons/app-button';
import { AppDialog } from '../../../../components/dialog/app-dialog';
import { useGlobalActions } from '../../../../hooks/global-actions';
import { useAppDispatch } from '../../../../hooks/redux';
import './user-unlocking-dialog.scss';

export interface UserUnlockingDialogProps {
  userCode: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const UserUnlockingDialog = (props: UserUnlockingDialogProps) => {
  const { open, onClose, onSuccess, userCode } = props;
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { showToast, closeToast } = useGlobalActions();

  const handleSubmit = useCallback(() => {
    dispatch(customerServiceUnlockUser(userCode));
  }, [dispatch, userCode]);

  const { unlockUserStatus, unlockUserError } = useAppSelector(state => state.features.adminConsole.customerService);

  useEffect(() => {
    if (unlockUserStatus === 'succeeded') {
      dispatch(resetCustomerServiceUnlockUserState());
      onSuccess();
    }
  }, [dispatch, onSuccess, unlockUserStatus]);

  useEffect(() => {
    if (unlockUserError) {
      const errorMessages = messagesForCustomerServiceGenericError();
      showToast({
        type: 'error',
        title: formatMessage(errorMessages.title),
        description: errorMessages.description && formatMessage(errorMessages.description),
        close: closeToast
      });
      dispatch(resetCustomerServiceUnlockUserState());
    }
  }, [unlockUserError, dispatch, formatMessage, showToast, closeToast]);

  return (
    <AppDialog open={open} onClose={onClose}>
      <div className='dialog-user-unlocking'>
        <h2 className='dialog-user-unlocking-title'>{formatMessage(userUnlockingMessages.title)}</h2>
        <p className='dialog-user-unlocking-content'>{formatMessage(userUnlockingMessages.text)}</p>
        <div className='dialog-user-unlocking-footer'>
          <AppButton variant={AppButtonVariant.Secondary} label={formatMessage(commonButtonMessages.cancel)} onClick={onClose} />
          <AppButton onClick={handleSubmit} label={formatMessage(commonButtonMessages.send)} loading={unlockUserStatus === 'loading'} />
        </div>
      </div>
    </AppDialog>
  );
};
