import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { commonFormMessages } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import { AppInputWrapper } from './input-wrapper';

export interface AppDateInputProps {
	label?: string;
	value?: Date;
	placeholder?: string;
	onChange?: (date: Date | null, value?: string | null) => void;
	disableFuture?: boolean;
	disabled?: boolean;
}

const empty = () => null;

export const AppDateInput = (props: AppDateInputProps) => {
	const { formatMessage } = useIntl();
	const {
		label,
		value,
		placeholder = formatMessage(commonFormMessages.placeholderDateInput),
		onChange,
		disableFuture = true,
		disabled
	} = props;
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDatePicker
				clearable
				label={label}
				value={value || null}
				placeholder={placeholder}
				onChange={onChange || empty}
				TextFieldComponent={AppInputWrapper as any}
				format='dd/MM/yyyy'
				keyboardIcon={null}
				disableFuture={disableFuture}
				disableToolbar
				open={false}
				disabled={disabled}
			/>
		</MuiPickersUtilsProvider>
	);
};
