import React from 'react';
import {useIntl} from 'react-intl';
import {usePirBoxPageLogic, walletPageMessages} from 'azshared';
import './PirDeposit.scss';

export const PirDeposit = () => {
  const {formatMessage} = useIntl();
  const {hasOrdinary, hasAlternative} = usePirBoxPageLogic();

  return (
    <div className='pir-box'>
      {hasAlternative &&<p>{formatMessage(walletPageMessages.walletAlternativePirBox)}</p>}
      {hasOrdinary &&<p>{formatMessage(walletPageMessages.walletOrdinaryPirBox)}</p>}
    </div>
  );
};