import {
  DownloaderMediaType,
  underlyingMFPColumns,
  underlyingULColumns,
  useMyWalletDownloadFileLogic,
  useMyWalletMorningstarLogic,
  useProductDetailsLogic,
  walletProductDetailDialogMessages
} from 'azshared';
import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AppDialog } from '../../../../../components/dialog/app-dialog';
import { AppDownloadFile } from '../../../../../components/download-file/download-file';
import { AppTabBar } from '../../../../../components/tab-bar/tab-bar';
import { useGlobalActions } from '../../../../../hooks/global-actions';
import { downloadFile } from '../../../../../utils/utils';
import { PdfView } from './pdf-view';
import './products-detail-dialog.scss';
import { TransactionsTable } from './transactions-table';
import { UnderlyingTable } from './underlying-table';

interface ProductsDetailDialogProps {
  closeProductDetails: () => void;
}

export const ProductsDetailDialog = (props: ProductsDetailDialogProps) => {
  const globalActions = useGlobalActions();
  const { formatMessage } = useIntl();
  const { closeProductDetails } = props;
  const {
    selectedSection,
    onSelectedSection,
    selectedProduct,
    transactionList,
    flagOpenProductDetails,
    onTransactionsPaginationPrev,
    onTransactionsPaginationNext,
    onTransactionsPaginationChange,
    tabs,
    isLoadingTransactions,
    isLoadingUnderlying,
    underlyingGroupsList,
    onUnderlyingPaginationChange,
    onUnderlyingPaginationNext,
    onUnderlyingPaginationPrev
  } = useProductDetailsLogic(globalActions);

  const { onDownloadFile, fileDownloaded, fileDownloadedRequestStatus } = useMyWalletDownloadFileLogic(globalActions);

  const morningstar = useMyWalletMorningstarLogic(globalActions);

  const handleDownloadFile = useCallback(
    (mediaType: DownloaderMediaType) => {
      switch (selectedSection) {
        case 'TRANSACTIONS':
          return onDownloadFile('transaction', mediaType);
        case 'UNDERLYING':
          return onDownloadFile('underlying', mediaType);
        default:
          return onDownloadFile('transaction', mediaType);
      }
    },
    [selectedSection, onDownloadFile]
  );

  useEffect(() => {
    if (
      fileDownloadedRequestStatus === 'succeeded' &&
      (fileDownloaded.type === 'transaction' || fileDownloaded.type === 'underlying')
    ) {
      downloadFile(fileDownloaded.name, fileDownloaded.blob);
    }
  }, [fileDownloadedRequestStatus, fileDownloaded]);

  const holders = useMemo(() => selectedProduct?.titleHolders?.join(', '), [selectedProduct]);

  return (
    <AppDialog open={flagOpenProductDetails} onClose={closeProductDetails} className='products-detail-dialog'>
      <Fragment>
        <h4>{`${formatMessage(walletProductDetailDialogMessages.title)} ${selectedProduct?.contract} ${holders ? '- ' + holders : ''}`}</h4>
        <div className='products-detail-dialog-content'>
          <AppTabBar tabs={tabs} onTabSelected={onSelectedSection} value={selectedSection} />
          <div className='products-detail-dialog-icons products-detail-dialog-icons-pdf'>
            {selectedSection !== 'PDFVIEW' && <AppDownloadFile mediaType='pdf' className='products-detail-dialog-icon' onClick={handleDownloadFile} />}
            {selectedSection !== 'PDFVIEW' && <AppDownloadFile mediaType='xlsx' className='products-detail-dialog-icon' onClick={handleDownloadFile} />}
          </div>
          {selectedSection === 'TRANSACTIONS' && (
            <TransactionsTable
              transactionList={transactionList}
              isLoading={isLoadingTransactions}
              onTransactionsPaginationChange={onTransactionsPaginationChange}
              onTransactionsPaginationNext={onTransactionsPaginationNext}
              onTransactionsPaginationPrev={onTransactionsPaginationPrev}
            />
          )}
          {selectedSection === 'UNDERLYING' && (
            <div>
              <UnderlyingTable
                isLoading={isLoadingUnderlying}
                underlyingGroups={underlyingGroupsList}
                onUnderlyingPaginationChange={onUnderlyingPaginationChange}
                onUnderlyingPaginationNext={onUnderlyingPaginationNext}
                onUnderlyingPaginationPrev={onUnderlyingPaginationPrev}
                columns={underlyingGroupsList.type === 'ManagedFunds' ? underlyingMFPColumns : underlyingULColumns}
              />
            </div>
          )}
          {selectedSection === 'PDFVIEW' && <PdfView {...morningstar}/>}
        </div>
      </Fragment>
    </AppDialog>
  );
};
