import { TableCell } from '@mui/material';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import {
  Column,
  documentsAreaMessages,
  DocumentType,
  PracticeDocument,
  retrieveColumnsForDocuments,
  DocumentsAreaSections
} from 'azshared';
import React, { Fragment, MouseEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppButtonIcon } from '../../../../../components/button-icon/app-button-icon';
import { Loader, LoaderSize } from '../../../../../components/loader/loader';
import { AppPill } from '../../../../../components/pill/pill';
import { AppSvgIcon } from '../../../../../components/svg-icon/app-svg-icon';
import { AppTableCell } from '../../../../../components/table/table-cell';
import { AppTableCellHeader } from '../../../../../components/table/table-cell-header';
import './documents-table.scss';

export interface DocumentsTableProps {
  documentType: DocumentType;
  documents: PracticeDocument[];
  isLoading?: boolean;
  onOpenDetails?: (documentDetails?: PracticeDocument) => void;
  onReadDocument: (document: PracticeDocument, documentsAreaSection: DocumentsAreaSections, documentType: DocumentType) => void;
  onDownloadDocument: (document: PracticeDocument, documentsAreaSection: DocumentsAreaSections, documentType: DocumentType) => void;
}

export const DocumentsTable = (props: DocumentsTableProps) => {
  const { formatMessage } = useIntl();
  const { documents, documentType, isLoading, onOpenDetails, onReadDocument, onDownloadDocument } = props;

  const columns: Column<PracticeDocument>[] = retrieveColumnsForDocuments(documentType);

  const handleReadDocument = useCallback(
    (e: MouseEvent) => {
      const id = (e.target as HTMLAnchorElement).id;
      const document = documents.find((doc) => doc.id === id);
      if (document) {
        onReadDocument(document, 'DOCUMENTS', documentType);
      }
    },
    [documents, documentType, onReadDocument]
  );

  const handleDownload = useCallback(
    (_: MouseEvent, id?: string) => {
      const document = documents.find((doc) => doc.id === id);
      if (document?.id) {
        onDownloadDocument(document, 'DOCUMENTS', documentType);
      }
    },
    [documents, documentType, onDownloadDocument]
  );

  const handleOpenDetails = useCallback(
    (_: MouseEvent<SVGSVGElement>, id?: string) => {
      const document = documents.find((doc) => doc.title === id);
      onOpenDetails && onOpenDetails(document);
    },
    [onOpenDetails, documents]
  );

  return (
    <TableContainer className='documents-table'>
      <Table aria-label='table'>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <AppTableCellHeader key={index} className='documents-table-header'>
                {column.headerLabel && formatMessage(column.headerLabel)}
              </AppTableCellHeader>
            ))}
          </TableRow>
        </TableHead>
        {isLoading && (
          <TableBody>
            <TableRow>
              <TableCell colSpan={columns.length + 1}>
                <div className='table-loader'>
                  <Loader loadersize={LoaderSize.XL} />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
        {!isLoading && (
          <TableBody>
            {documents?.map((document, index) => (
              <TableRow key={index} className={`documents-table-row ${!document.flagIsRead ? 'documents-table-row-bold' : ''}`}>
                {columns.map((col, i) => (
                  <Fragment key={i}>
                    {col.key === 'title' && (
                      <AppTableCell key={col.key} align='left' type={col.type}>
                        <div className='documents-table-row-title'>
                          <AppButtonIcon icon='download-document' onClick={handleDownload} id={document.id}/>
                          <a href={document.fileUrl} target='_blank' id={document.id} onClick={handleReadDocument} rel='noreferrer'>
                            {document[col.key]}
                          </a>
                          {(documentType === 'Disposition' || documentType === 'ExpiredDocuments') &&
                            (document.code === 'QuestionnaireModule' || document.code === 'ServiceActivationModule') && (
                              <AppSvgIcon id={document.title} icon='info' onClick={handleOpenDetails} />
                            )}
                        </div>
                      </AppTableCell>
                    )}
                    {col.key === 'flagIsRead' && (
                      <AppTableCell key={col.key} align='left' type={col.type}>
                        {!document.flagIsRead && <AppPill color='success' label={formatMessage(documentsAreaMessages.notReadBadge)}></AppPill>}
                      </AppTableCell>
                    )}
                    {col.key !== 'title' && col.key !== 'flagIsRead' && (
                      <AppTableCell key={col.key + 'other'} align='left' type={col.type}>
                        {document[col.key]}
                      </AppTableCell>
                    )}
                  </Fragment>
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};
