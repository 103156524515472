import Icon from '@mdi/react';
import React, { MouseEvent } from 'react';
import { Loader, LoaderSize, LoaderVariant } from '../loader/loader';
import './app-button.scss';

export enum AppButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Link = 'link'
}

export enum AppButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset'
}

export interface AppButtonProps {
  type?: AppButtonType;
  label?: string;
  iconRight?: string;
  iconLeft?: string;
  icon?: string;
  autocase?: boolean;
  disabled?: boolean;
  variant?: AppButtonVariant;
  loading?: boolean;
  className?: string;
  labelClassName?: string;
  onClick?: (a: MouseEvent<HTMLButtonElement>) => void;
}

const buttonToLoaderVariantMap: Record<AppButtonVariant, LoaderVariant> = {
  [AppButtonVariant.Primary]: LoaderVariant.PRIMARY,
  [AppButtonVariant.Secondary]: LoaderVariant.SECONDARY,
  [AppButtonVariant.Link]: LoaderVariant.PRIMARY
};

export const AppButton = (props: AppButtonProps) => {
  const { type = AppButtonType.BUTTON, className, label, disabled, variant = AppButtonVariant.Primary, icon, iconLeft, iconRight, autocase, loading, onClick } = props;

  if (loading) {
    return (
      <button className={`app-button ${variant}`}>
        <Loader loadersize={LoaderSize.XS} loadercolor={buttonToLoaderVariantMap[variant]} />
      </button>
    );
  }

  return (
    <button className={`app-button ${variant} ${className}`} type={type} onClick={onClick} disabled={disabled}>
      {icon && <Icon path={icon} size={0.8} />}
      {iconLeft && <Icon className='icon-left' path={iconLeft} size={0.8} />}
      {label != null && <span className={props.labelClassName ?? ''}>{(autocase ? label : label.toUpperCase())}</span>}
      {iconRight && <Icon className='icon-right' path={iconRight} size={0.8} />}
    </button>
  );
};
