import React, { useCallback, useMemo } from 'react';
import { Domanda, ElencoDominioResponse } from 'azshared/build/main/lib/api/generated/dob';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { Controller } from 'react-hook-form';
import { RootState, useAppSelector } from 'azshared';
import { AppSelect } from '../../../../../components/select/app-select';
import { AppRadioButtonConfig } from '../../../../../components/radio/app-radio-button';
import { AppRadioGroup } from '../../../../../components/radio/app-radio-group';
import { AppCheckboxGroup } from '../../../../../components/checkbox/app-checkbox-group';

interface SurveyQuestionProps {
  form: UseFormReturn;
  question?: Domanda;
}

export const SurveyQuestion = ({ form, question }: SurveyQuestionProps) => {
  const domains = useAppSelector((state: RootState) => state.features.dob.utility?.getDominioResponse as ElencoDominioResponse);
  const {
    control,
    trigger,
    watch
  } = form;
  const data = watch();
  const type = useMemo(() => {
    return question?.inputType ?? '';
  }, [question]);
  const required = useMemo(() => {
    return question?.flagMandatory;
  }, [question]);
  const name = useMemo(() => {
    return `survey-${question?.idDomanda}`;
  }, [question]);
  const label = useMemo(() => {
    return `${question?.testoDomanda}${question?.subTestoDomanda ? '\n' : ''}${question?.subTestoDomanda ? question?.subTestoDomanda : ''}`;
  }, [question]);

  // Options list
  const dropDownOptions = useMemo(() => {
    let domain = question?.tipoDominio;

    if (domain === 'DOM_PROFESSIONE_ATTUALE') domain = 'DOM_PROFESSIONE_ATTUALE_MIFID';

    if (!domain) {
      return (
        question?.listaRisposte?.map((r) => ({
          key: r.idRisposta?.toString() ?? '',
          label: `${r.descRisposta} ${r.descSubRisposta || ''}`
        })) ?? []
      );
    }

    const domainValues = domains.listaDomini?.find((d) => d.idDominio === domain)?.valoriDominio;

    if (domainValues) {
      const options = [];
      for (const key in domainValues) {
        options.push({
          key,
          label: domainValues[key]
        });
      }

      return options;
    }

    return [];
  }, [question, domains]);

  const radioButtonOptions = useMemo(() => {
    if (!question?.listaRisposte) {
      return [];
    }

    return question.listaRisposte.map((r) => ({
      id: r.idRisposta?.toString(),
      value: r.idRisposta?.toString(),
      label: `${r.descRisposta} ${r.descSubRisposta || ''}`
    })) as AppRadioButtonConfig[];
  }, [question]);

  const checkboxOptions = useMemo(() => {
    if (!question?.listaRisposte) {
      return [];
    }

    return question.listaRisposte.map((r) => ({
      id: r.idRisposta?.toString() ?? '',
      label: `${r.descRisposta} ${r.descSubRisposta || ''}`
    }));
  }, [question]);
  // -------------
  // AppSelect
  const renderAppSelect = useCallback(({ field, fieldState: { error } }) => {
    return (<div>
      <h4>{label}</h4>
      <AppSelect
        onChange={(val) => {field.onChange(val); trigger();}} /* eslint-disable-line react/jsx-no-bind */
        valid={!error?.message}
        value={field.value}
        required={required}
        options={dropDownOptions.map((o) => ({ type: o.key, label: o.label }))}
      />
    </div>);
  }, [dropDownOptions, label, trigger, required]);

  // AppRadio Group YES NO
  const renderAppGroupYesNo = useCallback(({ field, fieldState: { error } }) => {
    return (<div>
        {question?.subTestoDomanda ? (
          <div>
            <h4>{question.testoDomanda}</h4>
            <h6>{question.subTestoDomanda}</h6>
          </div>
        ) : question?.testoDomanda && (
           <h4>{question?.testoDomanda}</h4>
        )}
        <AppRadioGroup
          options={radioButtonOptions.map((o) => ({ value: o.value, label: o.label }))}
          selectedValue={field?.value}
          valid={!error}
          onSelectedValueChanged={(val) => {field.onChange(val); trigger();}} /* eslint-disable-line react/jsx-no-bind */
        />
      </div>
    );
  }, [question, radioButtonOptions, trigger]);
  // -------------

  const renderRadioCheckBox = useCallback(({ field, fieldState: { error } }) => {
      const displayValue = field.value?.split(',')[0];
      return (<div>
          {question?.subTestoDomanda ? (
            <div>
              <h4>{question.testoDomanda}</h4>
              <h6>{question.subTestoDomanda}</h6>
            </div>
          ) : (
            <h4>{question?.testoDomanda}</h4>
          )}
          <AppRadioGroup
            options={radioButtonOptions.map((o) => ({ value: o.value, label: o.label }))}
            selectedValue={displayValue}
            valid={!error}
            onSelectedValueChanged={(val) => {field.onChange(val); trigger();}} /* eslint-disable-line react/jsx-no-bind */
          />
        </div>
      );
      
  }, [question, radioButtonOptions, trigger]);

  // Checkbox
  const renderCheckbox = useCallback(({ field, fieldState: { error } }) => {
    return (<div>
        {label && <h4>{label}</h4>}
        <div>
          <AppCheckboxGroup
            options={checkboxOptions.map((option) => ({ value: option.id, label: option.label }))}
            valid={!error}
            selectedValues={field.value?.split(',')}
            onSelectedValueChanged={(val) => {field.onChange(val.join(','));}} /* eslint-disable-line react/jsx-no-bind */
          />
        </div>
      </div>
    );
    }, [checkboxOptions, label]);
  // -------------

  // RENDERING
  if (['DROPDOWN', 'DROP_NUCLEOFAMILIARE', 'DROP_STATOCIVILE'].includes(type)) {
    return (
      <Controller
        name={name}
        control={control}
        render={renderAppSelect}
      />
    );
  }
  
  if (['YES_NO', 'GENERIC_YN', 'RADIO_BUTTON'].includes(type)) {
    return (
      <Controller
        name={name}
        control={control}
        render={renderAppGroupYesNo}
      />
    );
  }

  if (['SUB_YES_NO', 'MULTIPLE_CHOICE_VERO_FALSO'].includes(type)) {
    if (
      !question?.strutturaInternaDomande ||
      !Array.isArray(question.strutturaInternaDomande.domande) ||
      !Array.isArray(question.strutturaInternaDomande.listaDominiRisposte) ||
      !question.strutturaInternaDomande.listaDominiRisposte.length
    ) {
      return <></>;
    }

    const first = question.strutturaInternaDomande.listaDominiRisposte[0];

    const title = label?.split('<br>').map((s) => (
      <>
        {s}
        <br />
      </>
    ));

    return (
      <>
        <h4>{title}</h4>
        {question.strutturaInternaDomande.domande.map((sub) => (
          <SurveyQuestion key={sub.idDomanda} form={form}
                          question={{ ...sub, inputType: first.descrizione, listaRisposte: first.risposte }} />
        ))}{' '}
      </>
    );
  }

  if (['CHECKBOX'].includes(type)) {
    return (
      <Controller
        name={name}
        control={control}
        render={renderCheckbox}
      />
    );
  }

  if (['SUB_RADIOCHECK_ESG_PF','SUB_RADIOCHECK_ESG_2_PF', 'SUB_RADIOCHECK_PF'].includes(type)) {
    const isRadio = type === 'SUB_RADIOCHECK_ESG_2_PF';
    if (question?.strutturaInternaDomande == null) return <></>;
    if (!Array.isArray(question.strutturaInternaDomande?.domande)) return <></>;
    if (question.strutturaInternaDomande?.domande?.length > 1) return <></>;
    const mainQuestion = question.strutturaInternaDomande?.domande[0];
    const mainAnswers = question.strutturaInternaDomande.listaDominiRisposte
    ?.at(0)
    ?.risposte?.filter((risposta) => risposta.listaDipendenze?.length === 0);
    const subQuestionAnswers = question.strutturaInternaDomande.listaDominiRisposte
    ?.at(0)
    ?.risposte?.filter((risposta) => risposta.listaDipendenze?.length !== 0);
    const mainQuestionToRender = {
      ...mainQuestion,
      listaRisposte: mainAnswers || []
      } as Domanda;
    const subQuestionToRender = {
      idDomanda: question.idDomanda,
      inputType: isRadio ? 'RADIO_BUTTON':'CHECKBOX',
      testoDomanda: '',
      listaRisposte: subQuestionAnswers || []
      } as Domanda;
    const showSubQuestion = isRadio ? data[`survey-${mainQuestionToRender.idDomanda}`]?.includes('330') : data[`survey-${mainQuestionToRender.idDomanda}`]?.includes('276'); 
    return (
      <div>
        <SurveyQuestion form={form} question={mainQuestionToRender}/>
        {showSubQuestion && <div className='sub-radio-check-box'><SurveyQuestion form={form} question={subQuestionToRender}/></div>}
      </div>
    );
  }

  if (['RADIO_CHECKBOX_ESG_PF','RADIO_CHECKBOX_2_ESG_PF', 'RADIO_CHECKBOX_PF'].includes(type)){
    return(
      <Controller
        name={name}
        control={control}
        render={renderRadioCheckBox}
      />
    );
  }

  return <div>Question {question?.idDomanda}</div>;
};
