import { getAmlQuestionById, homeOnboardingSelfAmlFatcaCrsSurveyMessages } from 'azshared';
import { Domanda } from 'azshared/build/main/lib/api/generated';
import { DominioRequestIdDominioEnum } from 'azshared/build/main/lib/api/generated/dob';
import { fieldsDependencies } from 'azshared/build/main/lib/hooks/home/onboarding-self/fields-validation';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppInputText } from '../../../../../../components/input-text/app-input-text';
import { AppSearchableSelect, AppSearchableSelectOption } from '../../../../../../components/select/app-searchable-select';
import { AmlSectionBodyProps } from '../../aml-fatca-crs-survey-page';

export const AmlAttivitaSectionBody = (props: AmlSectionBodyProps) => {
  const { formatMessage } = useIntl();
  const { amlConsistency } = props;

  const countryQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 51);
  const provinceQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 52);
  const yearQuestion: Domanda | undefined = getAmlQuestionById(props.questionnaireSection, 53);

  const mapAnswersToAppSearchableOpt = (answers: { [key: string]: string } | undefined): AppSearchableSelectOption[] => {
    if (answers) {
      const options = Object.keys(answers).map((k) => {
        return { value: k, label: answers[k] as string } as AppSearchableSelectOption;
      });
      return options;
    }
    return [];
  };

  const mapAnswerToAppSearchableOpt = useCallback(
    (answer: string | undefined, domain: string): null | AppSearchableSelectOption => {
      if (answer && answer != '') {
        const options: { [key: string]: string } | undefined = props.listaDomini?.find((dominio) => dominio.idDominio === domain)?.valoriDominio;
        if (options) {
          const appSearchableOptions = Object.keys(options).map((k) => {
            return { value: k, label: options[k] as string } as AppSearchableSelectOption;
          });
          const temp = appSearchableOptions.find((k) => k.value === answer);
          if (temp == undefined) {
            return null;
          }
          return temp;
        }
        return null;
      }
      return null;
    },
    [props.listaDomini]
  );

  const handleChangeNation = useCallback(
    (value) => {
      props.onSave([{ questionCode: '' + countryQuestion?.idDomanda, value: value as string }]);
      if (value != 'ITA' && props.getAmlAnswerDetailsByQuestionId('' + provinceQuestion?.idDomanda)) {
        props.deleteAnswerByQuestionId('' + provinceQuestion?.idDomanda);
      }
    },
    [countryQuestion?.idDomanda, props, provinceQuestion?.idDomanda]
  );

  const handleChangeProvince = useCallback(
    (value) => {
      props.onSave([{ questionCode: '' + provinceQuestion?.idDomanda, value: value as string }]);
    },
    [props, provinceQuestion?.idDomanda]
  );

  const handleChangeOther = useCallback(
    (value) => {
      value != undefined
        ? props.onSave([
            {
              questionCode: '' + yearQuestion?.idDomanda,
              value: value
            }
          ])
        : props.onSave([{ questionCode: '' + yearQuestion?.idDomanda, value: '' }]);
    },
    [props, yearQuestion?.idDomanda]
  );

  return (
    <div>
      <h3 className={'margin-top'}>{formatMessage(homeOnboardingSelfAmlFatcaCrsSurveyMessages.attivitaSectionTitle)}</h3>
      <div className={'sub-section'}>
        <div
          className={`sub-section-field ${
            amlConsistency?.consistency?.find((c) => c.questionId === countryQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
          }`}>
          <h4>{formatMessage(homeOnboardingSelfAmlFatcaCrsSurveyMessages.nazioneAttivitaTitle)}</h4>
          <AppSearchableSelect
            options={mapAnswersToAppSearchableOpt(
              props.listaDomini?.find((dominio) => dominio.idDominio === DominioRequestIdDominioEnum.NazioneProfessione)?.valoriDominio
            )}
            value={mapAnswerToAppSearchableOpt(
              props.getAmlAnswerDetailsByQuestionId('' + countryQuestion?.idDomanda) as string,
              DominioRequestIdDominioEnum.NazioneProfessione
            )}
            placeholder={countryQuestion?.testoDomanda || ''}
            onChange={handleChangeNation}
            menuMargin={false}
            disabled={!props.isEnabled(fieldsDependencies.NAZIONE_INSEDIAMENTO.dependencies)}
          />
        </div>
        <div
          className={`sub-section-field ${
            amlConsistency?.consistency?.find((c) => c.questionId === provinceQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
          }`}>
          <h4>{formatMessage(homeOnboardingSelfAmlFatcaCrsSurveyMessages.provinciaAttivitaTitle)}</h4>
          <AppSearchableSelect
            options={mapAnswersToAppSearchableOpt(
              props.listaDomini?.find((dominio) => dominio.idDominio === DominioRequestIdDominioEnum.ProvinceProfessione)?.valoriDominio
            )}
            value={mapAnswerToAppSearchableOpt(
              props.getAmlAnswerDetailsByQuestionId('' + provinceQuestion?.idDomanda) as string,
              DominioRequestIdDominioEnum.ProvinceProfessione
            )}
            placeholder={formatMessage(homeOnboardingSelfAmlFatcaCrsSurveyMessages.provinciaAttivitaPlaceholder)}
            onChange={handleChangeProvince}
            menuMargin={false}
            disabled={!props.isEnabled(fieldsDependencies.PROVINCIA_ATTIVITA.dependencies)}
          />
        </div>
      </div>
      <div className={'sub-section'}>
        <div
          className={`sub-section-field ${
            amlConsistency?.consistency?.find((c) => c.questionId === yearQuestion?.idDomanda)?.valid === false ? 'question-invalid' : ''
          }`}>
          <h4>{yearQuestion?.testoDomanda}</h4>
          <AppInputText
            type={'number'}
            placeholder={'Altro'}
            required={true}
            onChange={handleChangeOther}
            disabled={!props.isEnabled(fieldsDependencies.ANNO_INIZIO.dependencies)}
          />
        </div>
      </div>
    </div>
  );
};
