import { AppMainSection } from 'azshared';
import React, { useCallback } from 'react';
import { AppCustomIcons } from '../../utils/custom-icons';
import { Loader, LoaderSize, LoaderVariant } from '../loader/loader';
import { AppSvgIcon } from '../svg-icon/app-svg-icon';
import './menu-item.scss';

interface MenuItemProps {
  label: string;
  icon: AppCustomIcons;
  isSelected?: boolean;
  section?: AppMainSection;
  isLoading?: boolean;
  onSelected: (section?: AppMainSection) => void;
}

export const MenuItem = (props: MenuItemProps) => {
  const { label, icon, isSelected, section, onSelected, isLoading } = props;
  const onClick = useCallback(() => onSelected(section), [section, onSelected]);

  if (isLoading) {
    return (
      <div className='app-menu-item app-menu-item-loading' aria-selected={true}>
        <Loader loadersize={LoaderSize.XS} loadercolor={LoaderVariant.PRIMARY} />
      </div>
    );
  }

  return (
    <div className='app-menu-item' aria-selected={isSelected} onClick={onClick}>
      <AppSvgIcon icon={icon} />
      {label}
    </div>
  );
};
