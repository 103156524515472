import { mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import { AdminConsoleUserState, commonButtonMessages, customerServiceMessages, CustomerServiceSearchFilters, TabItem } from 'azshared';
import React, { FormEvent, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType, AppButtonVariant } from '../../../../components/buttons/app-button';
import { AppInputText } from '../../../../components/input-text/app-input-text';
import { AppSelect } from '../../../../components/select/app-select';
import './search-filter.scss';



export interface SearchFilterProps {
	onSubmit: (params: CustomerServiceSearchFilters) => void;
	onReset: () => void;
}

export const SearchFilter = (props: SearchFilterProps) => {
	const { onSubmit, onReset } = props;
	const { formatMessage } = useIntl();
	const [ ownerName, setOwnerName ] = useState<string>();
	const [ ownerSurname, setOwnerSurname ] = useState<string>();
	const [ ragioneSociale, setRagioneSociale ] = useState<string>();
	const [ customerCode, setCustomerCode ] = useState<string>();
	const [ status, setStatus ] = useState<AdminConsoleUserState>();

	const statusOptions: TabItem<AdminConsoleUserState>[] = useMemo(()=> [
		{
			type: 'ATTIVO',
			label: formatMessage(customerServiceMessages.statusSelectActiveOption)
		},
		{
			type: 'BLOCCATO',
			label: formatMessage(customerServiceMessages.statusSelectBlockedOption)
		}
	], [formatMessage]);

	const handleSubmit = useCallback(
		(e: FormEvent) => {
			e.preventDefault();
			onSubmit({
				ownerName,
				ownerSurname,
				ragioneSociale,
				customerCode,
				status
			});
		},
		[ onSubmit, ownerName, ownerSurname,  ragioneSociale, customerCode, status ]
	);

	const handleReset = useCallback(
		(e: FormEvent) => {
			e.preventDefault();
			onReset();
			setOwnerName('');
			setOwnerSurname('');
			setRagioneSociale('');
			setCustomerCode('');
			setStatus(undefined);
		},
		[ onReset ]
	);

	const handleChange = useCallback((value: string | string[]) => {
		setStatus(value as AdminConsoleUserState);
	}, []);

	return (
		<form className='customer-service-search-filter' onSubmit={handleSubmit} onReset={handleReset}>
			<div className='search-filter-title'>
				<Icon path={mdiMagnify} size={'1rem'} color={'#C4C4C4'} />{' '}
				{formatMessage(customerServiceMessages.widgetTitle)}
			</div>
			<div className='search-filter-fields'>
				<AppInputText
					value={ownerName}
					onChange={setOwnerName}
					label={formatMessage(customerServiceMessages.ownerNameInputLabel)}
					placeholder={formatMessage(customerServiceMessages.ownerNameInputPlaceholder)}
				/>
					<AppInputText
					value={ownerSurname}
					onChange={setOwnerSurname}
					label={formatMessage(customerServiceMessages.ownerSurnameInputLabel)}
					placeholder={formatMessage(customerServiceMessages.ownerSurnameInputPlaceholder)}
				/>
				<AppInputText
					value={ragioneSociale}
					onChange={setRagioneSociale}
					label={formatMessage(customerServiceMessages.ragioneSocialeInputLabel)}
					placeholder={formatMessage(customerServiceMessages.ragioneSocialeInputPlaceholder)}
				/>
				<AppInputText
					value={customerCode}
					onChange={setCustomerCode}
					label={formatMessage(customerServiceMessages.customerCodeInputLabel)}
					placeholder={formatMessage(customerServiceMessages.customerCodeInputPlaceholder)}
				/>
				<AppSelect
					value={status}
					onChange={handleChange}
					label={formatMessage(customerServiceMessages.statusSelectLabel)}
					options={statusOptions}
				/>
			</div>
			<div className='search-filter-buttons'>
				<AppButton
					type={AppButtonType.RESET}
					variant={AppButtonVariant.Secondary}
					label={formatMessage(commonButtonMessages.cancel)}
				/>
				<AppButton type={AppButtonType.SUBMIT} label={formatMessage(commonButtonMessages.search)} />
			</div>
		</form>
	);
};
