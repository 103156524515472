import { appRoutes, commonButtonMessages, welcomeUserRegistrationMessages } from 'azshared';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AppButton, AppButtonType } from '../../../../components/buttons/app-button';
import { Card, CardSize } from '../../../../components/card/card';
import { AppLink } from '../../../../components/labels/app-link';
import { useGoToLoginFromRegistration } from '../../../../hooks/global-actions';
import { useAppSelector } from '../../../../hooks/redux';
import { LoginContainer } from '../../login-container/login-container';
import './welcome-user-registration-page.scss';

export const WelcomeUserRegistrationPage = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const goToLoginFromRegistration = useGoToLoginFromRegistration();

  const { nameUser, surnameUser } = useAppSelector(state => state.features.userRegistration);

  const goAhead = useCallback(() => {
    navigate(appRoutes.InformativeUserRegistration, { replace: false });
  }, [navigate]);

  return (
    <div>
      <LoginContainer>
        <div className='welcome-user-registration-page'>
          <Card
            title={formatMessage(welcomeUserRegistrationMessages.pageTitle)}
            subtitle={formatMessage(welcomeUserRegistrationMessages.pageSubtitle)}
            back={formatMessage(commonButtonMessages.backLink)}
            sizeVariant={CardSize.MEDIUM}
          >
            <div className='welcome-text'>
              {formatMessage(welcomeUserRegistrationMessages.textWelcomeUserRegistration, {
                name: nameUser,
                surname: surnameUser
              })}
            </div>
            <div className='button'>
              <AppButton label={formatMessage(commonButtonMessages.continuedButton)} type={AppButtonType.SUBMIT} onClick={goAhead} />
            </div>
            <div className='disclaimer'>
              {formatMessage(commonButtonMessages.linkBackToLogin)}
              <AppLink to={appRoutes.Login} replace={false} label={formatMessage(commonButtonMessages.linkToLogin)} onClick={goToLoginFromRegistration} />
            </div>
          </Card>
        </div>
      </LoginContainer>
    </div>
  );
};
