import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import { amountFormatter, DocumentModuleOperation, signatureAreaMessages } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import './document-detail-operations-table.scss';

export const DocumentDetailOperationsTable = (props: { operations?: DocumentModuleOperation[] }) => {
  const { formatMessage } = useIntl();

  return (
    <TableContainer className='document-detail-operations-table'>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <TableCell className='document-detail-operations-table-header'>{formatMessage(signatureAreaMessages.documentDetailTableFieldsMandate)}</TableCell>
            <TableCell className='document-detail-operations-table-header' align='left'>
              {formatMessage(signatureAreaMessages.documentDetailTableFieldsCompany)}
            </TableCell>
            <TableCell className='document-detail-operations-table-header' align='left'>
              {formatMessage(signatureAreaMessages.documentDetailTableFieldsProduct)}
            </TableCell>
            <TableCell className='document-detail-operations-table-header' align='left'>
              {formatMessage(signatureAreaMessages.documentDetailTableFieldsOperation)}
            </TableCell>
            <TableCell className='document-detail-operations-table-header' align='left'>
              {formatMessage(signatureAreaMessages.documentDetailTableFieldsPaymetType)}
            </TableCell>
            <TableCell className='document-detail-operations-table-header' align='left'>
              {formatMessage(signatureAreaMessages.documentDetailTableFieldsImport)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.operations?.map((document, index) => (
            <TableRow key={index} className='document-detail-operations-table-row'>
              <TableCell
                className='document-detail-operations-table-row-cell'
                component='th'
                scope='row'
                sx={{
                  borderBottomLeftRadius: 8,
                  borderTopLeftRadius: 8
                }}>
                {document.mandate}
              </TableCell>
              <TableCell className='document-detail-operations-table-row-cell' align='left'>
                {document.company}
              </TableCell>
              <TableCell className='document-detail-operations-table-row-cell' align='left'>
                {document.product}
              </TableCell>
              <TableCell className='document-detail-operations-table-row-cell' align='left'>
                {document.operation}
              </TableCell>
              <TableCell className='document-detail-operations-table-row-cell' align='left'>
                {document.paymentType}
              </TableCell>
              <TableCell
                className='document-detail-operations-table-row-cell'
                sx={{
                  borderBottomRightRadius: 8,
                  borderTopRightRadius: 8
                }}>
                {amountFormatter(document.import, {currencyDisplay: 'code'})}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
