import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

export interface LoaderProps {
  loadersize: LoaderSize;
  loadercolor?: LoaderVariant;
  className?: string;
}

export enum LoaderSize {
  XS = 16,
  S = 32,
  M = 40,
  L = 60,
  XL = 82
}

export enum LoaderVariant {
  PRIMARY,
  SECONDARY
}

export const Loader = (props: LoaderProps) => {
  const { loadersize, loadercolor, className } = props;
  return (
    <Box sx={{ position: 'relative' }} className={className}>
      <CircularProgress
        variant='determinate'
        sx={{
          color: '#C1C1C2'
        }}
        size={loadersize}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant='indeterminate'
        disableShrink
        sx={{
          color: loadercolor === LoaderVariant.SECONDARY ? '#FFFFFF' : '#2E6CD1',
          animationDuration: '5000ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round'
          }
        }}
        size={loadersize}
        thickness={4}
        {...props}
      />
    </Box>
  );
};
