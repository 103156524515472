import React, { ChangeEvent, useCallback } from 'react';
import { AppRadioButton, AppRadioButtonConfig } from './app-radio-button';
import './app-radio-button.scss';

export interface AppRadioGroupProps {
  selectedValue?: string;
  options: AppRadioButtonConfig[];
  onSelectedValueChanged: (value: string, field?: any) => void;
  horizontal?: boolean;
  label?: string;
  renderLabelFirst?: boolean;
  valid?: boolean;
  layered?: boolean;
}

export const AppRadioGroup = (props: AppRadioGroupProps) => {
  const { renderLabelFirst, selectedValue, options, onSelectedValueChanged, horizontal, label, valid = true, layered } = props;

  const onSelect = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.currentTarget.closest('.mifid-question')?.classList.remove('error');
      onSelectedValueChanged(e.target.value);
    },
    [onSelectedValueChanged]
  );

  return (
    <div className={`app-radio-group ${horizontal ? 'horizontal-radio-button-group' : ''}`}>
      {label && <label className='app-radio-group-title'>{label}</label>}
      {options.map((option, index) => (
        <AppRadioButton
          valid={valid}
          key={index}
          renderLabelFirst={renderLabelFirst}
          checked={selectedValue === option.value}
          onSelect={onSelect}
          {...option}
          layered={layered}
        />
      ))}
    </div>
  );
};
