import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableFooter } from '@mui/material';
import { summaryTableMessages, percentageFormatter, amountFormatter } from 'azshared';
import { PirBoxPosition } from 'azshared/build/main/lib/model/pirbox/pirbox-product-summary';
import React from 'react';
import { useIntl } from 'react-intl';

export interface PirBoxSummaryTablePositionProps{
  position: PirBoxPosition,
  expirationDate: Date;
}

export const PirBoxSummaryTablePosition = (props: PirBoxSummaryTablePositionProps) => {
  const {formatMessage} = useIntl();
  const {typology,percentage,products} = props.position;
  const { expirationDate} = props;
  const isExpired= expirationDate < new Date(Date.now());
  const displayDisclaimer6 = (!Number.isNaN(expirationDate.getTime()) && !isExpired && ((typology === 'Non Qualificata' && percentage > 30) || (typology === 'Qualificata' && percentage > 70)));
   
  let totalDivested = 0;
  let totalInvested = 0;
  let totalCommitment= 0;

  for (const product of products) {
    totalDivested += product.totalDivested.value;
    totalInvested += product.totalInvested.value;
    totalCommitment += product.committment.value;
  }


  const totalAmountDivested = {
    value: totalDivested,
    currency: products[0].totalDivested.currency
  };

  const totalAmountInvested = {
    value: totalInvested,
    currency: products[0].totalInvested.currency
  };

  const totalCommitmentAmount = {
    value: totalCommitment,
    currency: products[0].committment.currency
  };
  return (
    <div>
      <div className='typology-section'>
        <div>
          <p className='cell-weight'>{formatMessage(summaryTableMessages.typology)}</p>
          <p>{typology}</p>
        </div>
        <div>
          <p className='cell-weight'>
            {formatMessage(summaryTableMessages.composition)}
            <sup>3{displayDisclaimer6 && ', 6'}</sup>
          </p>
          <p>{percentageFormatter(percentage)}</p>
        </div>
      </div>
      <div>
        <TableContainer className='document-detail-operations-table'>
          <Table aria-label='collapsible table'>
            <TableHead>
              <TableRow className='document-detail-operations-table-row'>
                <TableCell className='document-detail-operations-table-header'>
                  <p>{formatMessage(summaryTableMessages.productTitle)}</p>
                </TableCell>
                <TableCell className='document-detail-operations-table-header' align='left'>
                  <p>
                    {formatMessage(summaryTableMessages.invested)}
                    <sup>5</sup>
                  </p>
                </TableCell>
                <TableCell className='document-detail-operations-table-header' align='left'>
                  <p>
                    {formatMessage(summaryTableMessages.divested)}
                    <sup>5</sup>
                  </p>
                </TableCell>
                <TableCell className='document-detail-operations-table-header' align='left'>
                  <p>{formatMessage(summaryTableMessages.commitmentBooked)}</p>
                </TableCell>
                <TableCell className='document-detail-operations-table-header' align='left'>
                  <p>
                    {formatMessage(summaryTableMessages.concetration)}
                    <sup>3</sup>
                  </p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products?.map((product: any) => {
                return (
                  <TableRow key={product?.titleCode}>
                    <TableCell
                      className='document-detail-operations-table-row-cell product-title'
                      component='th'
                      scope='row'
                      sx={{
                        borderBottomLeftRadius: 8,
                        borderTopLeftRadius: 8
                      }}>
                      {product?.description}
                      {!Number.isNaN(expirationDate.getTime()) && product?.concentration > 20 && <sup>7</sup>}
                    </TableCell>
                    <TableCell className='document-detail-operations-table-row-cell' align='left'>
                      {amountFormatter(product?.totalInvested)}
                    </TableCell>
                    <TableCell className='document-detail-operations-table-row-cell' align='left'>
                      {amountFormatter(product?.totalDivested)}
                    </TableCell>
                    <TableCell className='document-detail-operations-table-row-cell' align='left'>
                      {amountFormatter(product?.committment)}
                    </TableCell>
                    <TableCell className='document-detail-operations-table-row-cell' align='left'>
                      {percentageFormatter(product?.concentration)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter className='document-detail-operations-table-row'>
              <TableRow>
                <TableCell className='summary-footer'>
                  <p className='cell-weight'>{formatMessage(summaryTableMessages.total)}</p>
                </TableCell>
                <TableCell>{amountFormatter(totalAmountInvested)}</TableCell>
                <TableCell>{amountFormatter(totalAmountDivested)}</TableCell>
                <TableCell>{amountFormatter(totalCommitmentAmount)}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
