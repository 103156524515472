import jspdf from 'jspdf';
import html2canvas from 'html2canvas';


export const usePdfGeneration = () => {
  const generatePdf = (element: HTMLElement | null) => {
    if(element===null) return;
    const pdfCanvas = new jspdf({
      unit: 'px'
    });
    const scale = 2;
    return html2canvas(element, {
      scale
    }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const imgProps = pdfCanvas.getImageProperties(imgData);
      const pdfWidth = pdfCanvas.internal.pageSize.getWidth() - 30;
      const pdfHeight = ((imgProps.height * pdfWidth) / imgProps.width);
      const nPages = Math.ceil(pdfHeight / pdfCanvas.internal.pageSize.height);

      if (nPages > 1) {
        const differenceMargin = ((15 * imgProps.width) / pdfWidth);
        const differenceCrop = (imgProps.width * (pdfCanvas.internal.pageSize.getHeight() - 15) / pdfCanvas.internal.pageSize.getWidth()) - differenceMargin;
        for (let index = 0; index < nPages; index++) {
          const tempCanvas1 = document.createElement('canvas'),
            tCtx1 = tempCanvas1.getContext('2d');

          tempCanvas1.width = imgProps.width;
          if (index + 1 < nPages) {
            tempCanvas1.height = differenceCrop;
          } else {
            tempCanvas1.height = imgProps.height - differenceCrop;
          }
          if(tCtx1!==null)
            tCtx1.drawImage(canvas, 0, -(index * differenceCrop));


          const img1 = tempCanvas1.toDataURL('image/png');
          const imgProps1 = pdfCanvas.getImageProperties(img1);
          const pdfHeight1 = (imgProps1.height * pdfWidth) / imgProps1.width;
          pdfCanvas.addImage(img1, 'PNG', 15, 15, pdfWidth, pdfHeight1, '', 'FAST');
          if (index + 1 < nPages) {
            pdfCanvas.addPage();
          }
        }

      } else {
        pdfCanvas.addImage(imgData, 'PNG', 15, 15, pdfWidth, pdfHeight, '', 'SLOW');
      }
      return pdfCanvas.output('blob');
    });
  };
  
  return {generatePdf};
};