import { useMyWalletPerformanceFilterLogic } from 'azshared';
import React from 'react';
import { PerformanceCategoryFilter } from './performance-category-filter';
import { PerformanceDateFilter } from './performance-date-filter';
import './performance-filter.scss';

export const PerformanceFilter = () => {
	const { performanceFilter, onPerformanceFilterChange } = useMyWalletPerformanceFilterLogic();

	return (
		<div className='app-performance-filter'>
			<PerformanceCategoryFilter filter={performanceFilter} onFilterChange={onPerformanceFilterChange} />
			<PerformanceDateFilter />
		</div>
	);
};
