import {
  HomeCard,
  Questionnaire,
  QuestionnaireState,
  QuestionnairesProspect,
  SurveyBookingStatus,
  appRoutes,
  homeToDoActivitiesCardMessages,
  signatureAreaActions,
  useAppDispatch
} from 'azshared';
import { PraticaSelf } from 'azshared/build/main/lib/api/generated/dob';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { AppButton } from '../../../../components/buttons/app-button';
import { AppLink } from '../../../../components/labels/app-link';
import { AppSvgIcon } from '../../../../components/svg-icon/app-svg-icon';
import { CompilationSelectionDialog } from '../../activity-area/components/compilation-selection-dialog/compilation-selection-dialog';
import { StartWebCollaborationDialog } from '../../activity-area/components/start-web-collaboration-dialog/start-web-collaboration-dialog';
import './home-to-do-activities-card.scss';

interface HomeToDoActivitiesCardInterface {
  toDoActivities: HomeCard<QuestionnairesProspect>;
  practiceData: HomeCard<PraticaSelf>;
  openSelectionDialog: boolean;
  handleSelectionDialog: (open: boolean) => void;
  onModalitySelected: (type: Questionnaire, mode?: SurveyBookingStatus) => void;
  buttonDisabled: boolean;
  startWebCollaborationDialogOpen: boolean;
  startWebCollaboration: () => void;
  onCloseStartWebCollaborationDialog: () => void;
}

export const HomeToDoActivitiesCard = (props: HomeToDoActivitiesCardInterface) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    buttonDisabled,
    practiceData,
    toDoActivities,
    openSelectionDialog,
    handleSelectionDialog,
    onModalitySelected,
    startWebCollaborationDialogOpen,
    startWebCollaboration,
    onCloseStartWebCollaborationDialog
  } = props;

  const { meetingDate, compileType, flagProfilaturaMifid } = practiceData?.value || {};

  const goToSignatureArea = useCallback(() => {
    navigate(appRoutes.Sign);
    dispatch(signatureAreaActions.updateSelectedSection('TO_SIGN'));
  }, [dispatch, navigate]);

  const onClick = useCallback(() => handleSelectionDialog(true), [handleSelectionDialog]);
  const onClose = useCallback(() => handleSelectionDialog(false), [handleSelectionDialog]);
  const onLink = useCallback((e) => e.preventDefault(), []);

  const enableFacta = useMemo(
    () =>
      (toDoActivities.value?.AML_FACTA === 'ToComplete' || (toDoActivities.value?.AML_FACTA === 'Completed' && toDoActivities.value?.MIFID !== 'Completed')) &&
      toDoActivities.error !== 'GENERIC_ERROR',
    [toDoActivities.error, toDoActivities.value?.AML_FACTA, toDoActivities.value?.MIFID]
  );

  return (
    <div className='home-to-do-activities-card'>
      <CompilationSelectionDialog
        meetingDate={meetingDate}
        compileType={compileType}
        onPressButton={onModalitySelected}
        open={openSelectionDialog}
        onClose={onClose}
      />
      <StartWebCollaborationDialog onContinue={startWebCollaboration} open={startWebCollaborationDialogOpen} onClose={onCloseStartWebCollaborationDialog} />
      <div className='home-to-do-activities-card-header'>
        <AppSvgIcon icon='puzzle-grey' />
        <h4>{formatMessage(homeToDoActivitiesCardMessages.title)}</h4>
      </div>
      <br />
      <div className='home-to-do-activities-card-body'>
        <div className='grid-item padding'>{formatMessage(homeToDoActivitiesCardMessages.questionnaireAMLandFATCA)}</div>
        {!enableFacta && (
          <>
            <div className='grid-item arrow-grey padding'>
              <AppSvgIcon icon='arrow-grey' />
            </div>
            <div className='grid-item home-to-do-activities-card-body-go-to-questionnaire padding disabled'>
              {formatMessage(homeToDoActivitiesCardMessages.goToQuestionnaire)}
            </div>
          </>
        )}
        {enableFacta && (
          <>
            <div className='grid-item arrow-blue padding'>
              <AppSvgIcon icon='arrow-blue' />
            </div>
            <div className='grid-item home-to-do-activities-card-body padding'>
              <AppLink to={appRoutes.AmlFatcaCrsSurveyPage} replace={false} label={formatMessage(homeToDoActivitiesCardMessages.goToQuestionnaire)} />
            </div>
          </>
        )}
        <div className='grid-item padding'>
          <QuestionnaireStatusDot status={toDoActivities.value?.AML_FACTA} />
        </div>
        {flagProfilaturaMifid && (
          <>
            <div className='grid-item'>{formatMessage(homeToDoActivitiesCardMessages.questionnaireMIFID)}</div>
            {toDoActivities.value?.MIFID !== 'ToComplete' && (
              <div className='grid-item arrow-grey'>
                <AppSvgIcon icon='arrow-grey' />
              </div>
            )}
            {toDoActivities.value?.MIFID === 'ToComplete' && (
              <div className='grid-item arrow-blue'>
                <AppSvgIcon icon='arrow-blue' />
              </div>
            )}
            {toDoActivities.value?.MIFID !== 'ToComplete' && (
              <div className='grid-item home-to-do-activities-card-body-go-to-questionnaire disabled'>
                {formatMessage(homeToDoActivitiesCardMessages.goToQuestionnaire)}
              </div>
            )}
            {toDoActivities.value?.MIFID === 'ToComplete' && (
              <div className='grid-item home-to-do-activities-card-body-go-to-questionnaire' onClick={onClick}>
                {practiceData.value?.isMifidEnable ? (
                  <AppLink
                    to={appRoutes.MifidSurveyPage}
                    replace={false}
                    label={formatMessage(homeToDoActivitiesCardMessages.goToQuestionnaire)}
                    onClick={onLink}
                  />
                ) : (
                  formatMessage(homeToDoActivitiesCardMessages.goToQuestionnaire)
                )}
              </div>
            )}
            <div className='grid-item'>
              <QuestionnaireStatusDot status={toDoActivities.value?.MIFID} />
            </div>
          </>
        )}
      </div>
      <br />
      <div className='home-to-do-activities-card-satus'>
        <div className='grid-item'>
          <AppSvgIcon icon='single-dot-grey' />
          {formatMessage(homeToDoActivitiesCardMessages.notFillable)}
        </div>
        <div className='grid-item'>
          <AppSvgIcon icon='single-dot-yellow' />
          {formatMessage(homeToDoActivitiesCardMessages.toComplete)}
        </div>
        <div className='grid-item'>
          <AppSvgIcon icon='single-dot-green' />
          {formatMessage(homeToDoActivitiesCardMessages.completed)}
        </div>
      </div>
      <br />
      <div className='home-to-do-activities-card-body-button'>
        {toDoActivities.value?.AML_FACTA === 'Completed' && (toDoActivities.value?.MIFID === 'Completed' || !flagProfilaturaMifid) && buttonDisabled && (
          <div className='noSignature'>
            <AppSvgIcon icon={'idea'} />
            {formatMessage(homeToDoActivitiesCardMessages.noSignature)}
          </div>
        )}
        <AppButton
          label='Firma'
          onClick={goToSignatureArea}
          disabled={buttonDisabled || toDoActivities.value?.AML_FACTA !== 'Completed' || (flagProfilaturaMifid && toDoActivities.value?.MIFID !== 'Completed')}
        />
      </div>
    </div>
  );
};

interface QuestionnaireStatusDotInterface {
  status: QuestionnaireState | undefined;
}

const QuestionnaireStatusDot = (props: QuestionnaireStatusDotInterface): JSX.Element => {
  const { status } = props;

  switch (status) {
    case 'NotFillable':
      return <AppSvgIcon icon='single-dot-grey' />;
    case 'ToComplete':
      return <AppSvgIcon icon='single-dot-yellow' />;
    case 'Completed':
      return <AppSvgIcon icon='single-dot-green' />;
  }

  return <></>;
};
