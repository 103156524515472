import { Table, TableBody, TableContainer } from '@mui/material';
import { documentsAreaMessages, Practice, PracticeDocument } from 'azshared';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Card, CardSize } from '../../../../../components/card/card';
import { Loader, LoaderSize } from '../../../../../components/loader/loader';
import { NoDocumentsEmptyState } from '../../../../../components/no-documents-empty-state/no-documents-empty-state';
import { AppPracticeRow, PracticeRowProps } from '../practice-row/practice-row';
import './practice-card.scss';

export interface PracticeCardProps extends Omit<PracticeRowProps, 'practice'> {
  title: string;
  flagIsVisible: boolean;
  practices: Practice<PracticeDocument>[];
}

export const PracticeCard = (props: PracticeCardProps) => {
  const { formatMessage } = useIntl();
  
  return (
    <Fragment>
      {props.flagIsVisible && (
        <Card sizeVariant={CardSize.MAXI}>
          <h4>{props.title}</h4>
          {props.isLoading && (
            <div className='practice-card-loader'>
              <Loader loadersize={LoaderSize.XL} />
            </div>
          )}
          {!props.isLoading && (!props.practices || props.practices.length === 0) && (
            <NoDocumentsEmptyState text={formatMessage(documentsAreaMessages.unavailableDocuments)} />
          )}
          {!props.isLoading && props.practices?.length > 0 && (
            <TableContainer className='practice-card-table'>
              <Table aria-label='collapsible table'>
                <TableBody>
                  {props.practices.map((practice, index) => (
                    <AppPracticeRow key={index} practice={practice} {...props} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Card>
      )}
    </Fragment>
  );
};
