import React from 'react';

import './no-documents-empty-state.scss';

export const NoDocumentsEmptyState = ( props: {text: string}) => {

	return (
		<div className='no-documents-empty-state'>
			<div className='img-no-documents' />
			{props.text}
		</div>
	);
};
