import { Checkbox, FormControlLabel } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import React, { ChangeEvent, useCallback } from 'react';
import './app-checkbox.scss';

export interface AppCheckboxProps {
  label?: string | number | React.ReactElement;
  disabled?: boolean;
  indeterminate?: boolean;
  checked?: boolean;
  id?: string;
  color?: OverridableStringUnion<'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default'>;
  onChange?: (value: boolean, id?: string) => void;
}

export const AppCheckbox = (props: AppCheckboxProps) => {
  const { id, label, disabled = false, indeterminate = false, checked = false, onChange, color } = props;

  const handleChange = useCallback(
    (_: ChangeEvent<HTMLInputElement>, value: boolean) => {
      if (onChange) {
        _.currentTarget.closest('.mifid-question')?.classList.remove('error');
        onChange(value, id);
      }
    },
    [id, onChange]
  );

  return (
    <FormControlLabel
      className={'app-checkbox'}
      control={<Checkbox disabled={disabled} checked={checked} indeterminate={indeterminate} onChange={handleChange} color={color} />}
      label={label || ''}
    />
  );
};
