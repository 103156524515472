import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import {
  amountFormatter,
  BaseProductCategory,
  Column,
  getSelectedWalletSection,
  ManagedMacroProduct,
  ManagedProductCategory,
  myWalletSliceActions,
  Product,
  ProductCategory,
  ProductMacroCategoryType,
  retrieveColumnsForFinancialPositions,
  sharedAccountsSliceActions,
  useAppDispatch,
  useAppSelector,
  usePirBoxPageLogic,
  walletFinancialPositionsMessages
} from 'azshared';
import React, { Fragment, MouseEvent, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AppTableCell } from '../../../../../components/table/table-cell';
import { AppTableCellHeader } from '../../../../../components/table/table-cell-header';
import { ManagedTable } from '../managed-table/managed-table';
import './financial-positions-category-table.scss';

interface FinancialPositionCategoryTableProps {
  productCategory: ProductCategory;
  macroCategoryType: ProductMacroCategoryType;
}

export const FinancialPositionCategoryTable = (props: FinancialPositionCategoryTableProps) => {
  const { productCategory, macroCategoryType } = props;
  const { positions, mandateCodes} = usePirBoxPageLogic();
  
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const columns: Column<Product>[] = retrieveColumnsForFinancialPositions(macroCategoryType, productCategory?.type);

  const selectedWalletSection = useAppSelector(getSelectedWalletSection);

  const actions = useMemo(() => (selectedWalletSection === 'MY_WALLET' ? myWalletSliceActions : sharedAccountsSliceActions), [selectedWalletSection]);

  const getProduct = useCallback(
    (isin: string) => {
      let product: Product = null as unknown as Product;
      if (macroCategoryType === 'Managed') {
        (productCategory as ManagedProductCategory).macroProducts.some((macroProd: ManagedMacroProduct) => {
          product = macroProd.products.find((prod) => prod.isin === isin) as Product;

          return !!product;
        });

        return product;
      }
      return (productCategory as BaseProductCategory).products.find((prod) => prod.isin === isin) as Product;
    },
    [productCategory, macroCategoryType]
  );
 
  const pirDeposit = mandateCodes.map((value) => positions.get(value));

  const pirBoxProducts = pirDeposit[0]?.positions;

  const handleClick = useCallback(
    (e: MouseEvent<HTMLSpanElement>) => {
      const isin = (e.target as HTMLSpanElement).id;
      dispatch(actions.setSelectedProduct(getProduct(isin)));
    },
    [dispatch, actions, getProduct]
  );

  const hasExGarantito = useMemo(()=>{
    return (productCategory as BaseProductCategory)?.products?.find(p => ['3411E', '3412E'].includes(p.isin))!==undefined;
  }, [productCategory]);

  if (macroCategoryType === 'Managed') {
    return <ManagedTable productCategory={productCategory as ManagedProductCategory} onSelectedProduct={handleClick} />;
  }

  const baseProduct = productCategory as BaseProductCategory;

  let contractIsPir = false;

  pirBoxProducts?.forEach(product => {
    return baseProduct.products.forEach(prod => {
      if(product.codMandato === prod.contract){
        contractIsPir = true;
      }
    });
  });

  return (
    <Fragment>
      <TableContainer className='category-table'>
        <Table aria-label='table'>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <AppTableCellHeader key={index} className='category-table-header'>
                  {formatMessage(column.headerLabel)}
                </AppTableCellHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(productCategory as BaseProductCategory)?.products.map((product, index) => (
              <TableRow key={index} className='category-table-row'>
                {columns.map((col) => (
                  <Fragment key={col.key}>
                    {col.key === 'productName' && (
                      <AppTableCell key={col.key} align='left' type={col.type}>
                        <span
                          id={product.isin}
                          className={`${
                            macroCategoryType === 'TraditionalInsurance' || macroCategoryType === 'Liquidity' ? '' : 'category-table-row-cell-product-name'
                          }`}
                          onClick={handleClick}>
                          {product[col.key]}{['3411E', '3412E'].includes(product.isin) && '**'}
                          {contractIsPir && '*'}
                        </span>
                      </AppTableCell>
                    )}
                    {col.key !== 'productName' && (
                      <AppTableCell key={col.key} align='left' type={col.type}>
                        {product[col.key]}                        
                      </AppTableCell>
                    )}
                  </Fragment>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className='category-table-footer'>
        <p className='category-table-footer-total-amount'>
          <span>{formatMessage(walletFinancialPositionsMessages.footerTotalLabel)}</span>
          {amountFormatter(productCategory?.totalAmount, { currencyDisplay: 'code' })}
        </p>
        {macroCategoryType==='Welfare' && <p className='category-table-footer-disclaimer'>
          {formatMessage(walletFinancialPositionsMessages.welfareCategoryFooterDisclaimer)}
        </p>}
        {macroCategoryType==='Welfare' && hasExGarantito && <p className='category-table-footer-disclaimer'>
          {formatMessage(walletFinancialPositionsMessages.welfareCategoryFooterExGarantitoDisclaimer)}
          </p>}
      </div>
    </Fragment>
  );
};
