import React from 'react';
import { SingleProductDetailTable } from './single-product-detail-table';
import './products-details-table.scss';
import { summaryTableMessages, usePirBoxPageLogic } from 'azshared';
import { useIntl } from 'react-intl';

export const ProductsDetailsTable = () => {
    const {positions, mandateCodes} = usePirBoxPageLogic();
    const positionList = mandateCodes.map((value) => positions.get(value));
    const {formatMessage} = useIntl();
    
    const singleProductTable = positionList[0]?.positions.map((position) => {
        return <div key={position.codMandato}>
                <SingleProductDetailTable key={position.codMandato} position={position}/>
            </div>;
    });

    return <div className='products-table-container'>
        {singleProductTable}
        <p>{formatMessage(summaryTableMessages.disclaimer1)}</p>
        <p>{formatMessage(summaryTableMessages.disclaimer2)}</p>
    </div>;
};