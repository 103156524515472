import { TextFieldProps } from '@mui/material';
import React, { ChangeEvent, useCallback } from 'react';
import { AppInputText } from '../input-text/app-input-text';

export const AppInputWrapper = (props: TextFieldProps) => {
	const { placeholder, disabled, value, onChange, label } = props;

	const handleChange = useCallback(
		(_: string | undefined, e: ChangeEvent<HTMLInputElement>) => {
			onChange && onChange(e);
		},
		[ onChange ]
	);

	return (
		<AppInputText
			placeholder={placeholder}
			disabled={disabled}
			value={value as string}
			label={label as string}
			onChange={handleChange}
			arrangement={'horizontal'}
		/>
	);
};
