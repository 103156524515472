import {mdiHelpCircleOutline, mdiOpenInNew} from '@mdi/js';
import {appRoutes, dashboardMessages, faqsPageMessages, useAppSelector, useOpenIdPageLogic, UserState} from 'azshared';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {AppBreadcrumb} from '../breadcrumb/app-breadcrumb';
import {AppButton, AppButtonVariant} from '../buttons/app-button';

import './header.scss';
import {useGlobalActions} from '../../hooks/global-actions';

interface AppHeaderProps {
  faqPage?: boolean;
}

export const AppHeader = (props: AppHeaderProps) => {
  const {faqPage} = props;
  const {formatMessage} = useIntl();
  const navigate = useNavigate();
  const userState: UserState = useAppSelector(state => state.data.user);

  const {preLogin} = useOpenIdPageLogic(useGlobalActions());

  const goToFaqPage = useCallback(() => {
    navigate(appRoutes.Faqs);
  }, [navigate]);

  return (
    <div className='header'>
      <div className='header-breadcrumb'><AppBreadcrumb/></div>
      <div className='header-button-section'>
        {userState.userType === 'PG' && <div className='marketplace'>
          <AppButton
            iconLeft={mdiOpenInNew}
            variant={AppButtonVariant.Primary}
            label={formatMessage(dashboardMessages.marketplaceButton)}
            onClick={preLogin}
            autocase={true}
          />
        </div>}
        <div className='faq'>
          <AppButton
            iconLeft={mdiHelpCircleOutline}
            variant={faqPage ? AppButtonVariant.Primary : AppButtonVariant.Secondary}
            label={formatMessage(faqsPageMessages.buttonLabel)}
            onClick={goToFaqPage}
          />
        </div>
      </div>
    </div>
  );
};
