import { appRoutes, customerServiceMessages, homeMessages } from 'azshared';
import { MessageDescriptor } from 'react-intl';

export interface BreadcrumbItem {
  key: string;
  label: MessageDescriptor;
  path: string;
}

export const mapBreadcrumb: { [key: string]: BreadcrumbItem} = {
  home:{ key: 'home', label: homeMessages.menuItemHome, path: appRoutes.Dashboard},
  wallet:{ key: 'wallet', label: homeMessages.menuItemWallet, path: appRoutes.Wallet},
  sign:{ key: 'sign', label: homeMessages.menuItemSign, path: appRoutes.Sign},
  documents:{ key: 'documents', label: homeMessages.menuItemDocuments, path: appRoutes.Documents},
  'for-you' :{ key: 'for-you', label: homeMessages.menuItemForYou, path: appRoutes.ForYou},
  news:{ key: 'news', label: homeMessages.menuItemNews, path: appRoutes.News},
  faqs:{ key: 'faqs', label: homeMessages.menuItemFaq, path: appRoutes.Faqs},
  'profile-page':{ key: 'profile-page', label: homeMessages.menuItemPersonalArea, path: appRoutes.ProfilePage},
  'customer-service':{ key: 'customer-service', label: customerServiceMessages.breadcrumbClientSupport, path: appRoutes.CustomerService},
  'er':{ key: 'er', label: homeMessages.breadcrumbEr, path: appRoutes.WalletEr},
  'detail':{ key: 'detail', label: homeMessages.breadcrumbDetail, path: appRoutes.WalletErDetail}
};
