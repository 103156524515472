import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import {
	DocumentModuleSignatory,
	signatureAreaMessages
} from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import './document-detail-signatory-table.scss';

export const DocumentDetailSignatoryTable = (props: { signatories?: DocumentModuleSignatory[] }) => {
	const { formatMessage } = useIntl();

	return (
		<TableContainer className='document-detail-signatory-table'>
			<Table aria-label='collapsible table'>
				<TableHead>
					<TableRow>
						<TableCell className='document-detail-signatory-table-header'>
							{formatMessage(signatureAreaMessages.documentDetailTableFieldsSignatory)}
						</TableCell>
						<TableCell className='document-detail-signatory-table-header' align='left'>
							{formatMessage(signatureAreaMessages.documentDetailTableFieldsFinancialAdvisor)}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.signatories?.map((document, index) => (
						<TableRow key={index} className='document-detail-signatory-table-row'>
							<TableCell
								className='document-detail-signatory-table-row-cell'
								component='th'
								scope='row'
								sx={{
									borderBottomLeftRadius: 8,
									borderTopLeftRadius: 8
								}}
							>
								{document.signatory}
							</TableCell>
							<TableCell
								className='document-detail-signatory-table-row-cell'
								sx={{
									borderBottomRightRadius: 8,
									borderTopRightRadius: 8
								}}
							>
								{document.financialAdvisor}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
