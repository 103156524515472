import { IconButton } from '@mui/material';
import {
  DashboardWalletSummary,
  HomeCard,
  walletPageMessages
} from 'azshared';
import React, { MouseEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppSvgIcon } from '../../../../../components/svg-icon/app-svg-icon';
import './home-my-wallet-card.scss';



interface HomeMyWalletCardShowBalanceProps {
  card: HomeCard<DashboardWalletSummary>;
  onShowBalance: (value: boolean) => void;
}

export const HomeMyWalletCardShowBalance = (props: HomeMyWalletCardShowBalanceProps) => {
  const { formatMessage } = useIntl();
  const { card, onShowBalance } = props; 

  const handleClickShowBalance = useCallback(
    (e: MouseEvent<HTMLButtonElement | HTMLLabelElement>) => {
      e.preventDefault();
      onShowBalance(!card.value?.flagShowBalance);
    },
    [card.value?.flagShowBalance, onShowBalance]
  );


  return (
    <div className='home-wallet-card-header-icon-eye'>
          <IconButton onClick={handleClickShowBalance}>
            <AppSvgIcon icon={!card.value?.flagShowBalance ? 'eye-blue' : 'blind-blue'} />
          </IconButton>
          <label onClick={handleClickShowBalance} className='home-wallet-card-header-icon-label'>
            {formatMessage(!card.value?.flagShowBalance ? walletPageMessages.showBalance : walletPageMessages.blurBalance)}
          </label>
        </div>
  );
};
