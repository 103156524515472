import { amountFormatter, pirBoxCardMessages } from 'azshared';
import { VisualizzaGraficoPIRResponse } from 'azshared/build/main/lib/api/generated/anagrafica';
import React, {useCallback, useMemo} from 'react';
import { useIntl } from 'react-intl';
import { AppButtonIcon } from '../button-icon/app-button-icon';
import './barchart.scss';
import { BarChartDatapoint } from './datapoint/datapoint';
import {usePdfGeneration} from '../../hooks/use-pdf-generation';
import {downloadFile} from '../../utils/utils';

export interface BarChartProps {
  dataSource: VisualizzaGraficoPIRResponse;
}

export const BarChart = (props: BarChartProps) => {
  const { dataSource } = props;
  const type = dataSource.codMandatoPir?.includes('A') ? 'ordinario' : 'alternative';
  const title = type === 'ordinario' ? pirBoxCardMessages.ordinaryDepositTitle : pirBoxCardMessages.alternativeDepositTitle;
  const { formatMessage } = useIntl();

  const {generatePdf} = usePdfGeneration();
  
  const pdfTitle = useMemo(() => {
    return formatMessage(title)+'.pdf';
  }, [formatMessage, title]);

  const getPdf = useCallback(() => {
    generatePdf(document.getElementById('barchart-container'))
      ?.then(v => downloadFile(pdfTitle, v));
  }, [generatePdf, pdfTitle]);

  return (
    <div id='barchart-container' className='barchart-container'>
      <div className='barchart'>
        <div className='chart-header'>
          <div className='chart-title'>
            <h4>
              {formatMessage(title)} - {formatMessage(pirBoxCardMessages.investmentPlan)}
            </h4>
          </div>
          <div className='chart-legend'>
            <div className='value-legend-item main'>
              <div className='legend-item-dot'></div>
              <div className='legend-item-label'>{formatMessage(pirBoxCardMessages.netInvestedAmount)}</div>
            </div>
            <div className='value-legend-item secondary'>
              <div className='legend-item-dot'></div>
              <div className='legend-item-label'>{formatMessage(pirBoxCardMessages.investableAmountLabel)}</div>
            </div>
          </div>
        </div>
        <div className='chart-body'>
          <div className='datapoint-container'>
            {props.dataSource.anni &&
              props.dataSource.anni.map((elem) => (
                <BarChartDatapoint key={elem.anno} data={{ label: elem.anno, value: elem.importoInvestitoAnno, max: elem.importoMaxInvestibileAnno }} />
              ))}
          </div>
          <div className='chart-buttons' data-html2canvas-ignore='true'>
            <AppButtonIcon className='printer-button' icon='printer' label='Stampa PDF' onClick={getPdf} />
          </div>
        </div>
        <div className='chart-footer'>
          <div className='chart-footer-column'>
            <p className='column-label'>
              {formatMessage(pirBoxCardMessages.investableAmountLabel)}
              {<sup className='disclaimer-suffix'>1</sup>}
            </p>
            <p className='column-amount'>{amountFormatter({ value: dataSource.importoTotInvestibile as number, currency: 'EUR' })}</p>
          </div>
          <div className='chart-footer-column'>
            <p className='column-label'>
              {formatMessage(pirBoxCardMessages.netInvestedAmount)}
              {<sup className='disclaimer-suffix'>1</sup>}
            </p>
            <p className='column-amount'>{amountFormatter({ value: dataSource.importoTotNetto as number, currency: 'EUR' })}</p>
          </div>
          <div className='chart-footer-column'>
            <p className='column-label'>
              {formatMessage(pirBoxCardMessages.investableResidueLabel)}
              {<sup className='disclaimer-suffix'>2</sup>}
            </p>
            <p className='column-amount'>{amountFormatter({ value: dataSource.residuoTotInvestibile as number, currency: 'EUR' })}</p>
          </div>
        </div>
          <div className='pirbox-disclaimers'>
            {<p>1: {formatMessage(pirBoxCardMessages.extrordinaryTaxationDisclaimerMessage)}</p>}
            {<p>2: {formatMessage(pirBoxCardMessages.partiteViaggiantiDisclaimerMessage)}</p>}
          </div>
      </div>
    </div>
  );
};
