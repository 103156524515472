import { mdiEyeOutline } from '@mdi/js';
import { News, newsMessages } from 'azshared';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppButton } from '../../../../../components/buttons/app-button';

import './news-card.scss';

interface NewsCardProps {
  news: News;
}

export const NewsCard = (props: NewsCardProps) => {
  const { news } = props;
  const { formatMessage } = useIntl();

  const [showDetail, setShowDetail] = useState(false);

  const showNewsInfo = useCallback(() => {
    return setShowDetail(true);
  }, []);

  const closeNewsInfo = useCallback(() => {
    return setShowDetail(false);
  }, []);

  return (
    <div className={`news-card ${!showDetail ? 'card-close' : ''}`}>
      <div className='news-card-header'>
        <div className='news-card-title'>{news.title}</div>
        <div className='news-card-description'>{news.description}</div>
      </div>
      {!showDetail && (
        <div className='news-card-button'>
          <AppButton label={formatMessage(newsMessages.viewButtonLabel)} iconLeft={mdiEyeOutline} onClick={showNewsInfo} autocase={true}/>
        </div>
      )}
      {showDetail && (
        <div className='news-card-detail'>
          <div className='news-card-content'>{news.content}</div>
          <div className='news-card-button'>
            <AppButton label={formatMessage(newsMessages.closeButtonLabel)} onClick={closeNewsInfo} autocase={true}/>
          </div>
        </div>
      )}
    </div>
  );
};
