import { DocumentsFilter, getDocumentsListFilter, labelForDocumentsChip, Practice, PracticeDocument, useAppSelector, useDocumentsTabLogic, DocumentsAreaSections, DocumentType } from 'azshared';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useGlobalActions } from '../../../../hooks/global-actions';
import { DocumentDetailDialog } from '../../../document-detail-dialog/document-detail-dialog';
import { DocumentCard } from '../components/document-card/document-card';
import { AppDocumentsFilter } from '../components/documents-filter/documents-filter';
import { PracticeCard } from '../components/practice-card/practice-card';
import './documents-section.scss';

interface DocumentsSectionProps {
  onReadDocument : (document: PracticeDocument, documentsAreaSection: DocumentsAreaSections, documentType: DocumentType)=> void;
  onDownloadDocument: (document: PracticeDocument, documentsAreaSection: DocumentsAreaSections, documentType: DocumentType) => void;
}

export const DocumentsSection = (props:DocumentsSectionProps ) => {
  const globalActions = useGlobalActions();
  const { formatMessage } = useIntl();
  const { documents, onOpenDetails, onCloseDetails, flagOpenDetails, documentDetails } = useDocumentsTabLogic(globalActions);

  const filter: DocumentsFilter = useAppSelector(getDocumentsListFilter);

  return (
    <div className='documents-section'>
      <AppDocumentsFilter />
      <div className='documents-section-cards'>
        {Object.entries(documents).map(([documentType, document]) => (
          <Fragment key={documentType}>
            {documentType === 'Contract' && (
              <DocumentCard
                documentType={documentType}
                title={formatMessage(labelForDocumentsChip(documentType))}
                documents={document.items as PracticeDocument[]}
                isLoading={document.isLoading}
                flagIsVisible={filter.documentsFilters[documentType].show}
                {...props}
              />
            )}
            {(documentType === 'Disposition' || documentType === 'ExpiredDocuments') && (
              <PracticeCard
                documentType={documentType}
                title={formatMessage(labelForDocumentsChip(documentType))}
                practices={document.items as Practice<PracticeDocument>[]}
                isLoading={document.isLoading}
                flagIsVisible={filter.documentsFilters[documentType].show}
                onOpenDetails={onOpenDetails}
                {...props}
              />
              
            )}
            
          </Fragment>
        ))}
      </div>
      <DocumentDetailDialog
        operations={documentDetails?.moduleOperation}
        information={documentDetails?.moduleInformation}
        signatories={documentDetails?.moduleSignatory}
        type={documentDetails?.code ? documentDetails.code : ''}
        filename={documentDetails?.title}
        key={flagOpenDetails ? 'open' : 'closed'}
        documentDetailDialogOpen={flagOpenDetails}
        closeDocumentDetailDialog={onCloseDetails}
      />
    </div>
  );
};
