import { PersistentStorage } from 'azshared';

class WebStorage implements PersistentStorage {
  storeValue = (key: string, value: string, expires?: Date) => {
    document.cookie = `${key}=${value}; path=/; expires=${expires?.toUTCString()}`;
    return Promise.resolve(true);
  };
  setCookieOnDomain = (key: string, value: string, domain: string, expires?: Date) => {
    document.cookie = `${key}=${value}; path=/; domain=${domain}; expires=${expires?.toUTCString()}`;
    return Promise.resolve(true);
  };
  readValue = (key: string) => {
    const cookies = document.cookie.split(';');
    const cookie = cookies.find(item => item.split('=')[0].trim() === key);
    return Promise.resolve(cookie?.split('=')[1] || undefined);
  };
  deleteValue = (key: string) => {
    document.cookie = `${key}=; path=/; Max-Age=0;`;
    return Promise.resolve(true);
  };
  clear = () => {
    const cookies = document.cookie.split(';');
    cookies.forEach(cookie => {
      const key = cookie.split('=')[0].trim();
      this.deleteValue(key);
    });
    return Promise.resolve(true);
  };
}

const webStorage = new WebStorage();
export default webStorage;
