import {setPasswordPageMessages, useSetPasswordPageLogic} from 'azshared';
import React, {FormEvent, useCallback} from 'react';
import {useIntl} from 'react-intl';
import {AppButton, AppButtonType} from '../../../components/buttons/app-button';
import {Card} from '../../../components/card/card';
import {AppInputPassword} from '../../../components/input-text/app-input-password';
import {AppRequiredNote} from '../../../components/required-note/required-note';
import {useGlobalActions} from '../../../hooks/global-actions';
import {LoginContainer} from '../../user-access/login-container/login-container';
import './set-password-page.scss';

export const SetPasswordPage = () => {
  const {formatMessage} = useIntl();

  const globalActions = useGlobalActions();

  const {
    newPassword,
    confirmNewPassword,
    status,
    setConfirmNewPassword,
    setNewPassword,
    handleSubmit
  } = useSetPasswordPageLogic(globalActions);

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );

  return (
    <LoginContainer>
      <div className='first-access-page'>
        <Card title={formatMessage(setPasswordPageMessages.pageTitle)}
              subtitle={formatMessage(setPasswordPageMessages.pageSubtitle)}>
          <form onSubmit={onSubmit} className='form-first-access-page'>
            <div>
              <AppInputPassword
                placeholder={formatMessage(setPasswordPageMessages.newPasswordPlaceholder)}
                label={formatMessage(setPasswordPageMessages.newPassword)}
                required
                value={newPassword}
                onChange={setNewPassword}
              />
              <AppInputPassword
                placeholder={formatMessage(setPasswordPageMessages.confirmNewPasswordPlaceholder)}
                label={formatMessage(setPasswordPageMessages.confirmNewPassword)}
                required
                value={confirmNewPassword}
                onChange={setConfirmNewPassword}
              />
            </div>
            <AppRequiredNote/>
            <div className='disclaimer'>{formatMessage(setPasswordPageMessages.attemptsDisclaimer)}</div>

            <div className='button'>
              <AppButton
                type={AppButtonType.SUBMIT}
                label={formatMessage(setPasswordPageMessages.action)}
                loading={status === 'loading'}
              />
            </div>
          </form>
        </Card>
      </div>
    </LoginContainer>
  );
};
