import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import { AdminConsoleDocument, PaginationResponse, PersonaTipoUtenteEnum, resultsTableMessages, SortColumn, useAppSelector } from 'azshared';
import React, { Fragment, MouseEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Loader, LoaderSize } from '../../../../components/loader/loader';
import { PageSelector } from './page-selector/page-selector';
import { CustomerServiceRow } from './results-row';
import './results-table.scss';

interface CustomerServiceTableProps {
	documents: AdminConsoleDocument[];
	pagination: PaginationResponse<AdminConsoleDocument>;
	unlockUser: (userCode: string) => void;
	recoveryCredentials: (userCode: string, userType: PersonaTipoUtenteEnum) => void;

	showPage: (page: number) => void;
	showPreviousPage: () => void;
	showNextPage: () => void;
	orderedBy: (sorting: SortColumn<AdminConsoleDocument>) => void;
}

interface Column {
	label: string;
	key: keyof AdminConsoleDocument;
}

export const CustomerServiceTable = (props: CustomerServiceTableProps) => {
	const {
		documents,
		unlockUser,
		recoveryCredentials,
		pagination,
		showPage,
		showPreviousPage,
		showNextPage,
		orderedBy
	} = props;

	const { searchRequestStatus } = useAppSelector((state) => state.features.adminConsole.customerService);

	const { formatMessage } = useIntl();

	const columns: Column[] = [
		{
			label: formatMessage(resultsTableMessages.labelClientCode),
			key: 'codiceCliente'
		},
		{ label: formatMessage(resultsTableMessages.labelAccountHolder), key: 'intestatario' },
		{ label: formatMessage(resultsTableMessages.labelFiguraAccessoria), key: 'figuraAccessoria' },
		{
			label: formatMessage(resultsTableMessages.labelActivationDateService),
			key: 'dataAttivazioneServizio'
		},
		{ label: formatMessage(resultsTableMessages.labelState), key: 'stato' },
		{ label: formatMessage(resultsTableMessages.labelEmail), key: 'email' },
		{ label: formatMessage(resultsTableMessages.labelPhone), key: 'telefono' }
	];

	const handleClick = useCallback(
		(e: MouseEvent<HTMLTableCellElement>) => {
			const key = (e.target as HTMLTableCellElement).id as keyof AdminConsoleDocument;
			const sorting: SortColumn<AdminConsoleDocument> = {};

			if (pagination.sorting && key in pagination.sorting) {
				if (pagination?.sorting?.[key] === 'ASC') {
					sorting[key] = 'DESC';
				}
			} else {
				sorting[key] = 'ASC';
			}

			orderedBy(sorting);
		},
		[ orderedBy, pagination.sorting ]
	);

	return (
		<Fragment>
			<TableContainer
				className='results-table'
				sx={{
					maxHeight: '300px',
					overflowY: searchRequestStatus === 'loading' ? 'hidden' : 'auto'
				}}
			>
				<Table aria-label='collapsible table' stickyHeader>
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.key}
									id={column.key}
									className={`results-table-header ${pagination.sorting?.[column.key]?.toLowerCase() || ''} ${pagination.sorting &&
									column.key in pagination.sorting
										? 'active'
										: ''}`}
									onClick={handleClick}
								>
									{column.label}
								</TableCell>
							))}
							<TableCell className='results-table-header' />
							<TableCell className='results-table-header' />
						</TableRow>
					</TableHead>

					{searchRequestStatus === 'loading' && (
						<TableBody>
							<TableRow>
								<TableCell
									style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }}
									colSpan={columns.length + 1}
								>
									<div className='table-loader'>
										<Loader loadersize={LoaderSize.XL} />
									</div>
								</TableCell>
							</TableRow>
						</TableBody>
					)}
					{searchRequestStatus !== 'loading' && (
						<TableBody>
							{documents.map((document, index) => (
								<CustomerServiceRow
									key={index}
									document={document}
									recoveryCredentials={recoveryCredentials}
									unlockUser={unlockUser}
								/>
							))}
						</TableBody>
					)}
				</Table>
			</TableContainer>
			<div className='paginator'>
				<PageSelector
					pagination={pagination}
					showPreviousPage={showPreviousPage}
					showNextPage={showNextPage}
					showPage={showPage}
				/>
			</div>
		</Fragment>
	);
};
